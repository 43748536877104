import * as R from 'ramda';
import React from 'react';
import Checkbox from './CustomCheckbox';

const KeyValue = ({ label, value }) => <span
  style={{
    lineHeight: '28px',
    fontSize: '16px',
    color: '#74798c'
  }}
>
  {label} :&nbsp;
  <span style={{ color: '#201c45' }}>{value}</span>
</span>;

const buildAddress = address => {
  const fields = R.compose(
    R.join(' '),
    R.reject(R.isNil),
    R.propOr([], 'fields')
  )(address);

  return `${fields} ${R.propOr('', 'postCode', address)} ${R.propOr('', 'city', address)}`;
};

const Establishment = ({
  establishment,
  onSelect,
  selectedEstablishment = {}
}) => {
  return <div
    style={{
      width: '100%',
      padding: '20px',
      borderRadius: '5px',
      border: 'solid 1px #efebeb',
      backgroundColor: '#f9f9f9',
      marginTop: '30px'
    }}
  >
    <Checkbox
      style={{ float: 'right' }}
      onChange={e => onSelect(e.target.checked, establishment)}
      checked={selectedEstablishment.siret === establishment.siret}
    />

    <KeyValue label="Dénomination" value={establishment.name}/>
    <br/>
    <KeyValue label="Adresse" value={buildAddress(establishment.address)}/>
    <br/>
    <KeyValue label="SIRET" value={establishment.siret}/>
  </div>;
};

export default Establishment;
