import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation MakeDuplicataStatusMutation($token: String!) {
  makeDuplicataStatus(token: $token) { 
    ok
    status
    statusError
    error
  }
}
`;

export default ({ propositionId, token }, done) => {
  const variables = {
    propositionId,
    token
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, status, error, statusError } = response.makeDuplicataStatus;
        done(ok, { value: status, error: statusError }, error);
      },
      onError: err => console.error(err),
    }
  );
};
