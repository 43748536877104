import './SelectCG.less';

import * as R from 'ramda';
import React from 'react';

import PropTypes from 'prop-types';

import Select from 'react-select';

import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../graphql/Environment';

const SelectCGQuery = graphql`
  query SelectCGQuery {
    data {
      managementCenters {
        name
        code
      }
    }
  }
`;

const SelectCG = ({
  onChange,
  title,
  value
}) => (
  <QueryRenderer
    environment={environment}
    query={SelectCGQuery}
    render={({ props }) => {
      if (R.isNil(props)) return null;
      return (
        <div className="uikit-select-cg">
          <span className="uikit-selection-cg-name">
            {title}
          </span>
          <Select
            value={value}
            labelKey="name"
            valueKey="code"
            options={R.sortBy(R.prop('name'))(props.data.managementCenters)}
            onChange={onChange}
            simpleValue={true}
            placeholder="Sélectionner"
          />
        </div>
      );
    }}
  />
);

SelectCG.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string
};

export default SelectCG;
