import * as R from 'ramda';

import './UploadButton.less';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { scanDocument } from '../../graphql/Environment';

const scanFile = async (file) => {
  return await scanDocument(file);
};

const UploadButton = ({
  onChange,
  file,
  disabled
}) => {

  const [error, setError] = useState(null);

  const onDrop = async (files) => {
    setError(false);
    if (await scanFile(files[0])) {
      onChange(files[0]);
    } else {
      setError('scanFailed');
    }
  };

  const onDropRejected = () => {
    setError('badFormat');
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf',
    onDrop,
    onDropRejected,
    maxSize: 5000000
  });

  if (R.isNil(file)) {
    return (
      <React.Fragment>
        <div className={`upload-button${disabled ? '-disabled' : ''}`} {...getRootProps()}>
          <input {...getInputProps()} disabled={disabled}/>
          <p>
            {'Cliquer pour sélectionner le document'}
          &nbsp;
            <strong>{'pdf'}</strong>
          &nbsp;
            {'à télécharger'}
          </p>
        </div>
        {
          R.equals(error, 'badFormat') && (
            <div className="upload-button-error">
              {'Le fichier n\'est pas valide (la taille maximale acceptée est de 5MB)'}
            </div>
          )
        }
        {
          R.equals(error, 'scanFailed') && (
            <div className="upload-button-error">
              {'Le fichier semble corrompu'}
            </div>
          )
        }
      </React.Fragment>
    );
  } else {
    return (
      <div className="upload-button-selected-file">
        <div>{file.name}</div>
        <div className="upload-button-clear-file" onClick={() => onChange(null)} role="button" tabIndex={0}>x</div>
      </div>
    );
  }
};

UploadButton.propTypes = {
  file: PropTypes.object,
  onChange: PropTypes.func
};

export default UploadButton;
