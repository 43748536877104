/**
 * @flow
 * @relayHash 1d68976fee055a5fb4c47e8819747e23
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EscAdminQueryVariables = {||};
export type EscAdminQueryResponse = {|
  +data: ?{|
    +countries: ?$ReadOnlyArray<?{|
      +code: ?string,
      +country: ?string,
    |}>
  |}
|};
export type EscAdminQuery = {|
  variables: EscAdminQueryVariables,
  response: EscAdminQueryResponse,
|};
*/


/*
query EscAdminQuery {
  data {
    countries {
      code
      country
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Data",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "countries",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EscAdminQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EscAdminQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "1d68976fee055a5fb4c47e8819747e23",
    "metadata": {},
    "name": "EscAdminQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '708929eddda0395e0c66b7a93b2983e9';

module.exports = node;
