import * as R from 'ramda';
import React, { useState } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../graphql/Environment';
import { connect } from 'react-redux';
import { Button, ToggleButton } from '../../../uikit';
import { redux } from '@fasstech/spid-front';
import { FieldTitle } from '../../../uikit/UIKit';

const mapIndexed = R.addIndex(R.map);

const CcnPackQuery = graphql`
  query CcnPackStepQuery ($ccnId: String!, $offerType: OfferTypeEnum!, $brokerage: Boolean, $postCode: Int!, $ccnVersion: Int!) {
    bases(ccnId: $ccnId, offerType: $offerType, brokerage: $brokerage, postCode: $postCode, ccnVersion: $ccnVersion) {
      id
      name
      college
      type
      group
    }
    ccn (ccnId: $ccnId) {
      id
      PREVOYANCE {
        offers {
          version
          offers {
            TA {
              _1 {
                  offerId: id
                  name
                  incapacityOfWork {
                      amount
                      franchise
                      duration
                  }
                  disability {
                      categ1
                      categ2
                      categ3
                  }
                  deathBenefit {
                      doubleAccident
                      aloneWithNoCharge
                      marriedWithNoCharge
                      aloneWithCharge
                      marriedWithCharge
                      increase
                  }
                  reductedDeathBenefit {
                      reducted
                      doubleAccident
                      rate
                      rent
                  }
                  absoluteDisability
                  doubleEffect
                  TA
                  TB
              }
              _2 {
                  offerId: id
                  name
                  incapacityOfWork {
                      amount
                      franchise
                      duration
                  }
                  disability {
                      categ1
                      categ2
                      categ3
                  }
                  deathBenefit {
                      doubleAccident
                      aloneWithNoCharge
                      marriedWithNoCharge
                      aloneWithCharge
                      marriedWithCharge
                      increase
                  }
                  reductedDeathBenefit {
                      reducted
                      doubleAccident
                      rate
                      rent
                  }
                  absoluteDisability
                  doubleEffect
                  TA
                  TB
              }
              _3 {
                  offerId: id
                  name
                  incapacityOfWork {
                      amount
                      franchise
                      duration
                  }
                  disability {
                      categ1
                      categ2
                      categ3
                  }
                  deathBenefit {
                      doubleAccident
                      aloneWithNoCharge
                      marriedWithNoCharge
                      aloneWithCharge
                      marriedWithCharge
                      increase
                  }
                  reductedDeathBenefit {
                      reducted
                      doubleAccident
                      rate
                      rent
                  }
                  absoluteDisability
                  doubleEffect
                  TA
                  TB
              }
              _4 {
                  offerId: id
                  name
                  incapacityOfWork {
                      amount
                      franchise
                      duration
                  }
                  disability {
                      categ1
                      categ2
                      categ3
                  }
                  deathBenefit {
                      doubleAccident
                      aloneWithNoCharge
                      marriedWithNoCharge
                      aloneWithCharge
                      marriedWithCharge
                      increase
                  }
                  reductedDeathBenefit {
                      reducted
                      doubleAccident
                      rate
                      rent
                  }
                  absoluteDisability
                  doubleEffect
                  TA
                  TB
              }
            }
            TA_TB {
              _1 {
                  offerId: id
                  name
                  incapacityOfWork {
                      amount
                      franchise
                      duration
                  }
                  disability {
                      categ1
                      categ2
                      categ3
                  }
                  deathBenefit {
                      doubleAccident
                      aloneWithNoCharge
                      marriedWithNoCharge
                      aloneWithCharge
                      marriedWithCharge
                      increase
                  }
                  reductedDeathBenefit {
                      doubleAccident
                      rate
                      rent
                  }
                  absoluteDisability
                  doubleEffect
                  TA
                  TB
              }
              _2 {
                  offerId: id
                  name
                  incapacityOfWork {
                      amount
                      franchise
                      duration
                  }
                  disability {
                      categ1
                      categ2
                      categ3
                  }
                  deathBenefit {
                      doubleAccident
                      aloneWithNoCharge
                      marriedWithNoCharge
                      aloneWithCharge
                      marriedWithCharge
                      increase
                  }
                  reductedDeathBenefit {
                      doubleAccident
                      rate
                      rent
                  }
                  absoluteDisability
                  doubleEffect
                  TA
                  TB
              }
              _3 {
                  offerId: id
                  name
                  incapacityOfWork {
                      amount
                      franchise
                      duration
                  }
                  disability {
                      categ1
                      categ2
                      categ3
                  }
                  deathBenefit {
                      doubleAccident
                      aloneWithNoCharge
                      marriedWithNoCharge
                      aloneWithCharge
                      marriedWithCharge
                      increase
                  }
                  reductedDeathBenefit {
                      doubleAccident
                      rate
                      rent
                  }
                  absoluteDisability
                  doubleEffect
                  TA
                  TB
              }
              _4 {
                  offerId: id
                  name
                  incapacityOfWork {
                      amount
                      franchise
                      duration
                  }
                  disability {
                      categ1
                      categ2
                      categ3
                  }
                  deathBenefit {
                      doubleAccident
                      aloneWithNoCharge
                      marriedWithNoCharge
                      aloneWithCharge
                      marriedWithCharge
                      increase
                  }
                  reductedDeathBenefit {
                      doubleAccident
                      rate
                      rent
                  }
                  absoluteDisability
                  doubleEffect
                  TA
                  TB
              }
            }
          }
        }
      }
    }
  }
`;

const cardBorder = 'solid #a09696 1px';

const Card = ({
  children,
  width = '20%',
  first,
  last
}) => {
  return <div
    style={{
      borderRight: cardBorder,
      borderTop: cardBorder,
      borderBottom: cardBorder,
      textAlign: 'center',
      width,
      ...(first ? {
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderLeft: cardBorder
      } : {}),
      ...(last ? {
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
      } : {})
    }}
  >
    {children}
  </div>;
};

const Header = ({
  children,
  first = false,
  last = false
}) => {
  return <div
    style={{
      width: '100%',
      height: '44px',
      backgroundColor: '#00b9e4',
      fontWeight: 'bold',
      fontSize: '1.1em',
      padding: '10px 0',
      color: 'white',
      borderTopLeftRadius: first ? '10px' : undefined,
      borderTopRightRadius: last ? '10px' : undefined
    }}
  >
    {children}
  </div>;
};

const Value = ({
  children,
  height = '40px',
  padding = '10px 0',
  rate = false,
  isLast = false,
}) => {
  const rateStyle = {
    backgroundColor: '#a6dfec',
    fontWeight: 'bold'
  };

  return <div
    style={{
      borderBottom: 'solid #cccccc 1px',
      textAlign: 'center',
      margin: 'auto',
      height,
      padding,
      border: isLast ? 'none' : undefined,
      ...(rate ? rateStyle : {})
    }}
  >
    {children}
  </div>;
};

const Label = ({
  children,
  isTitle = false,
  height = '40px',
  padding = '10px 0',
  isLast = false
}) => {
  return <div
    style={{
      fontWeight: isTitle ? 'bold' : 'normal',
      height,
      padding,
      borderBottom: isLast ? '' : 'solid #cccccc 1px',
      fontSize: isTitle ? '1.1em' : '1em'
    }}
  >
    {children}
  </div>;
};

const Labels = ({ isCreator }) => {
  return <div style={{ width: '30%', marginTop: '45px' }}>
    <Label isTitle>Incapacité de travail</Label>
    <Label>Montant</Label>
    <Label>Franchise</Label>
    <Label>Durée</Label>
    <Label isTitle>Invalidité</Label>
    <Label>1ère catégorie</Label>
    <Label>2ème catégorie</Label>
    <Label>3ème catégorie</Label>
    <Label isTitle>Capital décès</Label>
    <Label>Doublement accidents</Label>
    <Label padding={'0'} >{isCreator ? 'Célibataire, veuf, divorcé sans enfant à charge' : 'Célibataire, veuf, divorcé sans personne à charge'}</Label>
    <Label padding={'0'}>{isCreator ? 'Marié sans enfant à charge' : 'Marié ou pacsé sans personne à charge'}</Label>
    <Label padding={'0'}>Célibataire, veuf, divorcé avec une personne à charge</Label>
    {!isCreator && <Label padding={'0'}>Marié ou pacsé avec une personne à charge</Label>}
    <Label padding={'0'}>{isCreator ? 'Majoration par enfant à charge' : 'Majoration par personne à charge supplémentaire'}</Label>
    <Label padding={'0'} height={'45px'} isTitle>OU capital décès réduit + rente</Label>
    <Label>Doublement accidents</Label>
    <Label>Taux</Label>
    <Label>Rente éducation</Label>
    <Label height={'50px'} padding={isCreator ? '0' : '15px 0'} isTitle>{isCreator ? 'Invalidité permanente totale et définitive' : 'Invalidité absolue définitive'}</Label>
    <Label isTitle>Double effet</Label>
    <Label isTitle>TA</Label>
    <Label isTitle isLast>TB</Label>
  </div>;
};

const Offer = ({
  offer,
  first = false,
  last = false,
  next,
  disabled,
  isCreator,
}) => {
  const get = path => R.pathOr('', path.split('.'))(offer);

  return <Card
    width={first && last ? '50%' : '20%'}
    first={first}
    last={last}
  >
    <Header first={first} last={last}>{get('name')}</Header>
    <Value/>
    <Value>{get('incapacityOfWork.amount')}</Value>
    <Value>{get('incapacityOfWork.franchise')}</Value>
    <Value>{get('incapacityOfWork.duration')}</Value>
    <Value/>
    <Value>{get('disability.categ1')}</Value>
    <Value>{get('disability.categ2')}</Value>
    <Value>{get('disability.categ3')}</Value>
    <Value/>
    <Value>{get('deathBenefit.doubleAccident')}</Value>
    <Value>{get('deathBenefit.aloneWithNoCharge')}</Value>
    <Value>{get('deathBenefit.marriedWithNoCharge')}</Value>
    <Value>{get('deathBenefit.aloneWithCharge')}</Value>
    {!isCreator && <Value>{get('deathBenefit.marriedWithCharge')}</Value>}
    <Value>{get('deathBenefit.increase')}</Value>
    <Value height={'45px'}>{get('reductedDeathBenefit.reducted')}</Value>
    <Value>{get('reductedDeathBenefit.doubleAccident')}</Value>
    <Value>{get('reductedDeathBenefit.rate')}</Value>
    <Value>{get('reductedDeathBenefit.rent')}</Value>
    <Value height={'50px'} padding={'5px 0'}>{get('absoluteDisability')}</Value>
    <Value>{get('doubleEffect')}</Value>
    <Value rate>{get('TA')}</Value>
    <Value rate>{get('TB')}</Value>
    <Button
      style={{
        width: '100%',
        fontSize: '1em',
        borderRadius: `0 0 ${last ? '10px' : '0'} ${first ? '10px' : '0'}`,
        padding: '0'
      }}
      onClick={() => next(get('offerId'))}
      disabled={disabled}
    >
      Sélectionner<br/>cette offre
    </Button>
  </Card>;
};

const CcnPackStep = ({
  brokerage,
  ccnId,
  ccnVersion,
  onNext,
  onPrevious,
  postCode,
  updateBases
}) => {
  const [source, setSource] = useState('TA');
  const isCreator = ccnId === '0001-0001';

  return <QueryRenderer
    environment={environment}
    query={CcnPackQuery}
    variables={{
      ccnId,
      offerType: 'PREVOYANCE',
      brokerage,
      postCode,
      ccnVersion
    }}
    render={({ error, props }) => {
      if (error) {
        throw new Error('300');
      } else if (!props) return null;
      const ccn = R.pathOr({}, ['ccn'])(props);

      const bases = R.pathOr([], ['bases'])(props);

      const offers = R.compose(
        R.reject(R.isNil),
        R.values,
        R.pathOr({}, ['offers', source]),
        R.find(R.propEq('version', R.toString(ccnVersion))),
        R.pathOr({}, ['PREVOYANCE', 'offers'])
      )(ccn);

      const next = id => {
        updateBases(bases);
        onNext(R.assocPath(['C', id], { selected: true, options: [] })({}));
      };

      const displayToggle = R.compose(
        R.not,
        R.isEmpty,
        R.pathOr({}, ['offers', 'TA_TB']),
        R.find(R.propEq('version', R.toString(ccnVersion))),
        R.pathOr({}, ['PREVOYANCE', 'offers'])
      )(ccn);

      return <React.Fragment>
        <FieldTitle
          text={'Sélectionnez votre offre'}
          onBack={onPrevious}
        />
        {displayToggle && <div style={{ display: 'flex', marginBottom: '20px' }}>
          <ToggleButton
            style={{ margin: 'auto' }}
            onChange={setSource}
            value={source}
            options={[{ value: 'TA', text: 'TA' }, { value: 'TA_TB', text: 'TA TB' }]}
          />
        </div>}

        <div style={{ display: 'flex' }}>
          <Labels isCreator={isCreator}/>

          {mapIndexed((offer, index) => {
            return <Offer
              key={offer.offerId}
              next={next}
              offer={offer}
              first={index === 0}
              last={R.length(offers) === index + 1}
              isCreator={isCreator}
            />;
          })(offers)}
        </div>
      </React.Fragment>;
    }}
  />;
};

export default connect(
  ({ proposition }) => ({
    ccnId: R.pathOr('', ['data', 'ccn', 'id'], proposition)
  }),
  (dispatch, { colleges }) => ({
    updateBases: (bases) => dispatch(redux.actions.contractDescription.contractDescriptionSetBases(bases, colleges))
  })
)(CcnPackStep);
