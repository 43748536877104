import * as R from 'ramda';
import { graphql } from 'react-relay';
import { fetchQuery } from 'relay-runtime';
import environment from '../graphql/Environment';

const QSearchCommercialCodes = graphql`
 query useSearchCommercialCodesQuery($email: String!) {
   commercialCodes(email: $email) {
     email
     code
   }
 }
`;

const useSearchCommercialCodes = () => {
  return ({ email }, callback) => {
    try {
      if (!R.is(String, email) || R.length(email) < 4) { // Recherche à partir de 3 caractères
        return callback([]);
      }

      fetchQuery(environment, QSearchCommercialCodes, { email }).then(data => {
        if (!R.isNil(callback) && R.is(Function, callback)) {
          callback(data.commercialCodes);
        }
      });
    } catch (error) {
      console.error(error);
      callback([]);
    }
  };
};

export default useSearchCommercialCodes;
