/**
 * @flow
 * @relayHash b2b13d3fd256f7379e65348c67b876da
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BackgroundStepQueryVariables = {|
  id: string
|};
export type BackgroundStepQueryResponse = {|
  +checkRecommendedOffer: ?boolean
|};
export type BackgroundStepQuery = {|
  variables: BackgroundStepQueryVariables,
  response: BackgroundStepQueryResponse,
|};
*/


/*
query BackgroundStepQuery(
  $id: ID!
) {
  checkRecommendedOffer(id: $id)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "checkRecommendedOffer",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BackgroundStepQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BackgroundStepQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "b2b13d3fd256f7379e65348c67b876da",
    "metadata": {},
    "name": "BackgroundStepQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4b2bb0bd4aa5270fec771c53a7128105';

module.exports = node;
