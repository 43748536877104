import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
  mutation GenerateMedicalCheckLinkMutation($propositionId: ID!) {
    generateMedicalCheckLink(propositionId: $propositionId) {
      ok
      error
      link
    }
  }
`;

export default ({ propositionId }, done) => {
  const variables = {
    propositionId,
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: ({ generateMedicalCheckLink: { ok, error, link } }) => {
        done(ok, error, link);
      },
      onError: error => {
        console.error(error);
        done(false, error);
      },
    }
  );
};
