import React from 'react';

const LegalPersonsError = () => {
  return <div className="text-red-500">
    Personne Morale non retrouvée dans REPERE.<br/>
    {'Veuillez vérifier / créer l\'entité de la Personne Morale dans votre CRM avant de créer la proposition dans SPID.'}
  </div>;
};

export default LegalPersonsError;
