import React, { useState } from 'react';
import { FieldTitle } from '../../../uikit/UIKit';
import { connect } from 'react-redux';
import { compose, defaultTo, either, find, isEmpty, isNil, map, path, pathEq, pathOr, propEq } from 'ramda';
import {
  propositionGoNextStep,
  propositionGoPreviousStep,
  propositionSetOfferTypes
} from '../../../reduxActions/proposition';
import RadioGroup from '../../../uikit/buttons/RadioGroup';
import { Button } from '../../../uikit';
import { hooks } from '@fasstech/spid-front';
import * as R from 'ramda';

const isNilOrEmpty = either(isNil, isEmpty);

const useSelectOffersStep = ({
  isRelatedContract,
  startDate,
  unavailableBefore,
  protectionUnavailableBefore,
  healthUnavailableBefore,
  unavailableAfter,
  protectionUnavailableAfter,
  healthUnavailableAfter,
  setOfferTypes,
  goNextStep
}) => {
  const {
    startDatesAreBeforeUnavailability,
    startDatesAreAfterUnavailability
  } = hooks.useStartDate({
    form: {
      values: () => ({ startDate })
    },
    unavailableBefore,
    protectionUnavailableBefore,
    healthUnavailableBefore,
    unavailableAfter,
    protectionUnavailableAfter,
    healthUnavailableAfter
  });

  const [loading, setLoading] = useState(false);

  const { beforeUnavailabilityDates = [] } = startDatesAreBeforeUnavailability();
  const { afterUnavailabilityMessages } = startDatesAreAfterUnavailability();
  const enableMultiEquipment = isNilOrEmpty(beforeUnavailabilityDates) && isNilOrEmpty(afterUnavailabilityMessages) && !isRelatedContract;

  const offerTypeIsAvailableFrom = offerType => compose(
    isNil,
    find(propEq('offerType', offerType)),
    defaultTo([])
  );

  const offerTypeIsAvailable = offerType => offerTypeIsAvailableFrom(offerType)(beforeUnavailabilityDates) && offerTypeIsAvailableFrom(offerType)(afterUnavailabilityMessages);

  const onNext = offerTypes => () => {
    try {
      setLoading(true);

      setOfferTypes(offerTypes);
      goNextStep();
    } catch (error) {
      setLoading(false);
    }
  };

  const [multiEquipment, setMultiEquipment] = React.useState(enableMultiEquipment);

  return {
    onNext,
    enableMultiEquipment,
    multiEquipment,
    setMultiEquipment,
    offerTypeIsAvailable,
    beforeUnavailabilityDates,
    afterUnavailabilityMessages,
    loading
  };
};

const offerTypes = [
  { label: 'Santé', value: 'SANTE' },
  { label: 'Prévoyance', value: 'PREVOYANCE' },
];

const SelectOffersStep = ({
  goPreviousStep,
  goNextStep,
  setOfferTypes,
  isRelatedContract,
  unavailableBefore,
  protectionUnavailableBefore,
  healthUnavailableBefore,
  unavailableAfter,
  protectionUnavailableAfter,
  healthUnavailableAfter,
  startDate,
  ccn
}) => {
  const {
    onNext,
    enableMultiEquipment,
    multiEquipment,
    setMultiEquipment,
    offerTypeIsAvailable,
    beforeUnavailabilityDates,
    afterUnavailabilityMessages,
    loading
  } = useSelectOffersStep({
    isRelatedContract,
    startDate,
    unavailableBefore,
    protectionUnavailableBefore,
    healthUnavailableBefore,
    unavailableAfter,
    protectionUnavailableAfter,
    healthUnavailableAfter,
    setOfferTypes,
    goNextStep
  });

  return <>
    <FieldTitle
      text="Garantie(s) à couvrir ?"
      onBack={goPreviousStep}
    />

    {!isRelatedContract && <>
      <div
        style={{
          fontWeight: 'bold',
          fontSize: '20px',
          marginBottom: '15px'
        }}
      >
        Je souscris simultanément aux offres Santé et Prévoyance (Multi-équipement)
      </div>

      <RadioGroup
        options={[
          { label: 'Oui', value: 'true' },
          { label: 'Non', value: 'false' }
        ]}
        value={isNil(multiEquipment) ? null : JSON.stringify(multiEquipment)}
        onChange={v => {
          if (enableMultiEquipment) {
            setMultiEquipment(JSON.parse(v));
          }
        }}
      />
    </>}

    <div style={{
      margin: '30px auto 0 auto',
      width: '300px'
    }}>
      {multiEquipment && (
        <Button
          onClick={onNext(['SANTE', 'PREVOYANCE'])}
          fetching={loading}
        >
          Offres Santé et Prévoyance
        </Button>
      )}

      {!multiEquipment && <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        rowGap: '1rem'
      }}>
        {map(({ label, value }) => {
          return <Button
            key={`button-${value}`}
            fetching={loading}
            style={{ margin: 0 }}
            onClick={onNext([value])}
            disabled={!offerTypeIsAvailable(value)}
          >
            Offres {label}
          </Button>;
        })(offerTypes)}
      </div>}
    </div>

    {multiEquipment && <div style={{
      display: 'flex',
      columnGap: '1rem',
      width: '500px',
      margin: '20px auto 0 auto'
    }}>
      <img
        src="/icons/lightbulb.svg"
        style={{
          width: '40px'
        }}
      />

      <div style={{ color: 'red' }}>
        Le parcours Santé sera proposé et ensuite le parcours Prévoyance pour la même souscription.
      </div>
    </div>}

    {!enableMultiEquipment && !isNilOrEmpty(beforeUnavailabilityDates) &&
      map(({ offerType, date }) => {
        return <div
          key={`error-${offerType}`}
          className="mt-8 text-[#ff0000]"
        >
          <span className="font-bold">{offerType === 'PREVOYANCE' ? 'Prévoyance' : 'Santé'} :</span> La date d&#39;effet du contrat ne peut pas être antérieure à {date} pour la CCN {R.propOr('', 'shortTitle', ccn)}.
        </div>;
      })(beforeUnavailabilityDates)
    }

    {!enableMultiEquipment && !isNilOrEmpty(afterUnavailabilityMessages) &&
        R.map(({ offerType, date, message }) => {
          return <div
            key={`error-${offerType}`}
            className="mt-8 text-[#ff0000]"
          >
            <span className="font-bold">{offerType === 'PREVOYANCE' ? 'Prévoyance' : 'Santé'} :</span> {R.isNil(message) ? `La date d'effet du contrat ne peut pas être postérieure à ${date} pour la CCN ${R.propOr('', 'shortTitle', ccn)}.` : message}
          </div>;
        })(afterUnavailabilityMessages)
    }
  </>;
};

export default connect(
  ({ proposition }) => ({
    unavailableBefore: pathOr('', ['data', 'ccn', 'unavailableBefore'])(proposition),
    protectionUnavailableBefore: pathOr(null, ['data', 'ccn', 'PREVOYANCE', 'unavailableBefore'])(proposition),
    healthUnavailableBefore: pathOr(null, ['data', 'ccn', 'SANTE', 'unavailableBefore'])(proposition),
    unavailableAfter: R.pathOr({}, ['data', 'ccn', 'unavailableAfter'])(proposition),
    protectionUnavailableAfter: R.pathOr({}, ['data', 'ccn', 'PREVOYANCE', 'unavailableAfter'])(proposition),
    healthUnavailableAfter: R.pathOr({}, ['data', 'ccn', 'SANTE', 'unavailableAfter'])(proposition),
    selectedOfferTypes: pathOr([], ['data', 'proposition', 'offerTypes'], proposition),
    isRelatedContract: pathEq(['data', 'relatedContract', 'checked'], true, proposition),
    startDate: path(['data', 'startDate'], proposition),
    ccn: path(['data', 'startDate'], proposition)
  }),
  dispatch => ({
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    goNextStep: () => dispatch(propositionGoNextStep()),
    setOfferTypes: offerTypes => dispatch(propositionSetOfferTypes(offerTypes))
  })
)(SelectOffersStep);
