import * as R from 'ramda';
import React from 'react';
import { Redirect } from 'react-router-dom';

class ErrorStep extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {return <Redirect to={`/erreur?id=${R.propOr('0', 'errorId')(this.props)}`}/>;}

}

export default ErrorStep;
