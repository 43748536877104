import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation SignInMutation($email: String!, $password: String!) {
  signIn(email: $email, password: $password) { 
    ok
    error
    lockDuration
    user {
      id
      username
      email
      role
      extra {
        userCode
        region
        firstname
        lastname
        isManager
        managementCenter
        sales
        providers
      }
      resetPasswordRequired
    }
  }
}
`;

export default ({ email, password }, done) => {
  const variables = {
    email,
    password,
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, error, lockDuration } = response.signIn;
        done(ok, error, lockDuration);
      },
      onError: err => console.error(err),
    }
  );
};
