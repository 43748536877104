import './LinkAction.less';

import React from 'react';

import {
  Link
} from 'react-router-dom';

import {
  Text
} from '../../../uikit/UIKit';

const LinkAction = ({
  to,
  text,
  style
}) => (
  <Link
    style={style}
    className="connection-link-action"
    to={to}
  >
    <Text text={text}/>
  </Link>
);

LinkAction.defaultProps = {
  to: '/account/connect'
};

export default LinkAction;
