import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation SignUpMutation($email: String!, $password: String, $role: UserRoleEnum!, $userCode: Int, $region: String, $managementCenter: String, $lastname: String, $firstname: String, $sales: String, $commercialManagerId: String, $providerEmail: String) {
  signUp(email: $email, password: $password, role: $role, userCode: $userCode, region: $region, managementCenter: $managementCenter, lastname: $lastname, firstname: $firstname, sales: $sales, commercialManagerId: $commercialManagerId, providerEmail: $providerEmail) { 
    ok
    error
  }
}
`;

export default ({
  email,
  password,
  role,
  userCode,
  region,
  managementCenter,
  lastname,
  firstname,
  sales,
  commercialManagerId,
  providerEmail,
}, done) => {
  const variables = {
    email,
    password,
    role,
    userCode,
    region,
    managementCenter,
    lastname,
    firstname,
    sales,
    commercialManagerId,
    providerEmail,
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, error } = response.signUp;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
