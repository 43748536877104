import './WorkStoppage.less';

import * as R from 'ramda';
import React from 'react';

import withForm from '@thecodeisgreen/withform';

import {
  SelectButtons,
  TextInput
} from '../../../uikit/UIKit';

const WorkStoppage = ({
  value,
  onChange,
}) => {
  const onUpdateIsSelected = (isSelected) => {
    onChange(R.compose(
      R.assoc('isSelected', isSelected),
      R.assoc('count', isSelected ? R.propOr('0', 'count', value) : '0')
    )(value));
  };

  const onUpdateBrokerageId = (e) => {
    onChange(R.assoc('count', e.target.value, value));
  };

  return <div>
    <div className="work-stoppage">
      <div className="work-stoppage-yes-no">
        <SelectButtons
          options={[
            { title: 'Oui', value: true },
            { title: 'Non', value: false },
          ]}
          value={R.propOr(false, 'isSelected', value)}
          onChange={onUpdateIsSelected}
        />
      </div>
      <div className="work-stoppage-number">
        <span style={{ marginLeft: '32px' }}>Nombre&nbsp;:</span>
        <TextInput
          placeholder="0"
          type="text"
          style={{ width: '84px' }}
          value={R.propOr(null, 'count', value)}
          onChange={onUpdateBrokerageId}
        />
        <span style={{ marginLeft: '8px' }}>salariés</span>
      </div>
    </div>
  </div>;
};

export default withForm(WorkStoppage);
