import React, { useState } from 'react';
import './PropositionInfoDisplay.less';

const masquer = '[-] Masquer', afficher = '[+] Afficher';

const PropositionInfoDisplayHeader = ({
  children,
  title,
  hidden = false
}) => {
  const [display, setDisplay] = useState(!hidden);
  const [text, setText] = useState(hidden ? afficher : masquer);
  const onClick = () => {
    setDisplay(!display);
    setText(text === masquer ? afficher : masquer);
  };

  return <div>
    <h3 className={`proposition-info-header ${display ? 'proposition-info-header-expanded' : ''}`}>{title.toUpperCase()}<span onClick={onClick} role="button" tabIndex={0}>{text}</span></h3>
    {display && children}
  </div>;
};

export default PropositionInfoDisplayHeader;