/**
 * @flow
 * @relayHash a8177478812b4b4cf4b372f73f78df23
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollegeEnum = "AM" | "APP" | "C" | "NC" | "TOUS" | "%future added value";
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type SelectCcnQueryVariables = {||};
export type SelectCcnQueryResponse = {|
  +ccns: ?$ReadOnlyArray<?{|
    +id: ?string,
    +offerTypes: ?$ReadOnlyArray<?OfferTypeEnum>,
    +idcc: ?string,
    +codeBrochure: ?string,
    +title: ?string,
    +shortTitle: ?string,
    +naf: ?$ReadOnlyArray<?string>,
    +PREVOYANCE: ?{|
      +colleges: ?$ReadOnlyArray<?CollegeEnum>,
      +mandatoryColleges: ?$ReadOnlyArray<?CollegeEnum>,
      +bundledCollege: ?$ReadOnlyArray<?CollegeEnum>,
      +staffMustBeAboveZero: ?$ReadOnlyArray<?string>,
      +atLeastOneStaffMustBeAboveZero: ?$ReadOnlyArray<?string>,
      +collegesLabels: ?{|
        +AM: ?string,
        +NC: ?string,
        +C: ?string,
      |},
      +collegesOrder: ?$ReadOnlyArray<?CollegeEnum>,
      +displayPopupForColleges: ?{|
        +colleges: ?$ReadOnlyArray<?CollegeEnum>,
        +message: ?string,
      |},
      +maxNumberOfEmployees: ?{|
        +NC: ?number,
        +C: ?number,
        +AM: ?number,
      |},
      +maxNumberOfTotalEmployees: ?number,
      +mustHaveAtLeastOneOfIfBaseIsSelected: ?{|
        +basesIds: ?$ReadOnlyArray<?string>,
        +selectedBaseId: ?string,
      |},
      +conflictingColleges: ?$ReadOnlyArray<?{|
        +colleges: ?$ReadOnlyArray<?CollegeEnum>
      |}>,
      +contractDescriptionStepMessage: ?string,
      +contractNumbersByCollege: ?boolean,
      +unavailableBefore: ?$ReadOnlyArray<?string>,
      +unavailableAfter: ?{|
        +date: ?string,
        +message: ?string,
      |},
    |},
    +SANTE: ?{|
      +colleges: ?$ReadOnlyArray<?CollegeEnum>,
      +mandatoryColleges: ?$ReadOnlyArray<?CollegeEnum>,
      +bundledCollege: ?$ReadOnlyArray<?CollegeEnum>,
      +staffMustBeAboveZero: ?$ReadOnlyArray<?string>,
      +atLeastOneStaffMustBeAboveZero: ?$ReadOnlyArray<?string>,
      +collegesLabels: ?{|
        +AM: ?string,
        +NC: ?string,
        +C: ?string,
      |},
      +collegesOrder: ?$ReadOnlyArray<?CollegeEnum>,
      +meltedColleges: ?{|
        +colleges: ?$ReadOnlyArray<?CollegeEnum>,
        +targetCollege: ?CollegeEnum,
      |},
      +conflictingColleges: ?$ReadOnlyArray<?{|
        +colleges: ?$ReadOnlyArray<?CollegeEnum>
      |}>,
      +contractNumbersByCollege: ?boolean,
      +displayPopupForColleges: ?{|
        +colleges: ?$ReadOnlyArray<?CollegeEnum>,
        +message: ?string,
      |},
      +maxNumberOfEmployees: ?{|
        +NC: ?number,
        +C: ?number,
        +AM: ?number,
      |},
      +maxNumberOfTotalEmployees: ?number,
      +mustHaveAtLeastOneOfIfBaseIsSelected: ?{|
        +basesIds: ?$ReadOnlyArray<?string>,
        +selectedBaseId: ?string,
      |},
      +garantiesRdgUrl: ?string,
      +cotisationsRdgUrl: ?string,
      +contractDescriptionStepMessage: ?string,
      +unavailableBefore: ?$ReadOnlyArray<?string>,
      +unavailableAfter: ?{|
        +date: ?string,
        +message: ?string,
      |},
      +insurer: ?string,
    |},
    +hasBrokerage: ?boolean,
    +visionServiceUrl: ?string,
    +customerDetailsOptional: ?boolean,
    +unavailableBefore: ?$ReadOnlyArray<?string>,
    +unavailableAfter: ?{|
      +date: ?string,
      +message: ?string,
    |},
    +alert: ?string,
    +hasParitarism: ?boolean,
    +isPack: ?boolean,
    +disableAmendments: ?$ReadOnlyArray<?number>,
    +withClassicNumberOfContracts: ?boolean,
    +forbiddenDepartments: ?$ReadOnlyArray<?string>,
    +amendmentsSettings: ?{|
      +canUnselectOrigin: ?boolean,
      +collegesMustHaveBase: ?$ReadOnlyArray<?CollegeEnum>,
      +collegesMustHaveZeroEmployees: ?$ReadOnlyArray<?CollegeEnum>,
    |},
    +relatedContractsSettings: ?{|
      +contractNumbersCheckDisabled: ?boolean
    |},
    +brokerageSettings: ?{|
      +bundledCollege: ?$ReadOnlyArray<?CollegeEnum>,
      +unavailableBefore: ?string,
    |},
    +brokerageOnly: ?boolean,
    +tariffStructures: ?$ReadOnlyArray<?string>,
  |}>
|};
export type SelectCcnQuery = {|
  variables: SelectCcnQueryVariables,
  response: SelectCcnQueryResponse,
|};
*/


/*
query SelectCcnQuery {
  ccns {
    id
    offerTypes
    idcc
    codeBrochure
    title
    shortTitle
    naf
    PREVOYANCE {
      colleges
      mandatoryColleges
      bundledCollege
      staffMustBeAboveZero
      atLeastOneStaffMustBeAboveZero
      collegesLabels {
        AM
        NC
        C
      }
      collegesOrder
      displayPopupForColleges {
        colleges
        message
      }
      maxNumberOfEmployees {
        NC
        C
        AM
      }
      maxNumberOfTotalEmployees
      mustHaveAtLeastOneOfIfBaseIsSelected {
        basesIds
        selectedBaseId
      }
      conflictingColleges {
        colleges
      }
      contractDescriptionStepMessage
      contractNumbersByCollege
      unavailableBefore
      unavailableAfter {
        date
        message
      }
    }
    SANTE {
      colleges
      mandatoryColleges
      bundledCollege
      staffMustBeAboveZero
      atLeastOneStaffMustBeAboveZero
      collegesLabels {
        AM
        NC
        C
      }
      collegesOrder
      meltedColleges {
        colleges
        targetCollege
      }
      conflictingColleges {
        colleges
      }
      contractNumbersByCollege
      displayPopupForColleges {
        colleges
        message
      }
      maxNumberOfEmployees {
        NC
        C
        AM
      }
      maxNumberOfTotalEmployees
      mustHaveAtLeastOneOfIfBaseIsSelected {
        basesIds
        selectedBaseId
      }
      garantiesRdgUrl
      cotisationsRdgUrl
      contractDescriptionStepMessage
      unavailableBefore
      unavailableAfter {
        date
        message
      }
      insurer
    }
    hasBrokerage
    visionServiceUrl
    customerDetailsOptional
    unavailableBefore
    unavailableAfter {
      date
      message
    }
    alert
    hasParitarism
    isPack
    disableAmendments
    withClassicNumberOfContracts
    forbiddenDepartments
    amendmentsSettings {
      canUnselectOrigin
      collegesMustHaveBase
      collegesMustHaveZeroEmployees
    }
    relatedContractsSettings {
      contractNumbersCheckDisabled
    }
    brokerageSettings {
      bundledCollege
      unavailableBefore
    }
    brokerageOnly
    tariffStructures
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "colleges",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mandatoryColleges",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bundledCollege",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "staffMustBeAboveZero",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "atLeastOneStaffMustBeAboveZero",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "AM",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "NC",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "C",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "CcnLabelsColleges",
  "kind": "LinkedField",
  "name": "collegesLabels",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collegesOrder",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "CcnDisplayPopupForColleges",
  "kind": "LinkedField",
  "name": "displayPopupForColleges",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "CcnEmployeesColleges",
  "kind": "LinkedField",
  "name": "maxNumberOfEmployees",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxNumberOfTotalEmployees",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "MustHaveAtLeastOneOfIfBaseIsSelected",
  "kind": "LinkedField",
  "name": "mustHaveAtLeastOneOfIfBaseIsSelected",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "basesIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedBaseId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ConflictingColleges",
  "kind": "LinkedField",
  "name": "conflictingColleges",
  "plural": true,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractDescriptionStepMessage",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNumbersByCollege",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unavailableBefore",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "CcnUnavailableAfter",
  "kind": "LinkedField",
  "name": "unavailableAfter",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    (v10/*: any*/)
  ],
  "storageKey": null
},
v20 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Ccn",
    "kind": "LinkedField",
    "name": "ccns",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offerTypes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "idcc",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "codeBrochure",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortTitle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "naf",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OfferTypeInformations",
        "kind": "LinkedField",
        "name": "PREVOYANCE",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OfferTypeInformations",
        "kind": "LinkedField",
        "name": "SANTE",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MeltedColleges",
            "kind": "LinkedField",
            "name": "meltedColleges",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "targetCollege",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v15/*: any*/),
          (v17/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "garantiesRdgUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cotisationsRdgUrl",
            "storageKey": null
          },
          (v16/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "insurer",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasBrokerage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "visionServiceUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerDetailsOptional",
        "storageKey": null
      },
      (v18/*: any*/),
      (v19/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "alert",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasParitarism",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isPack",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "disableAmendments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "withClassicNumberOfContracts",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "forbiddenDepartments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CcnAmendmentsSettings",
        "kind": "LinkedField",
        "name": "amendmentsSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUnselectOrigin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collegesMustHaveBase",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collegesMustHaveZeroEmployees",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CcnRelatedContractSettings",
        "kind": "LinkedField",
        "name": "relatedContractsSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contractNumbersCheckDisabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CcnBrokerageSettings",
        "kind": "LinkedField",
        "name": "brokerageSettings",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v18/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "brokerageOnly",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tariffStructures",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectCcnQuery",
    "selections": (v20/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SelectCcnQuery",
    "selections": (v20/*: any*/)
  },
  "params": {
    "id": "a8177478812b4b4cf4b372f73f78df23",
    "metadata": {},
    "name": "SelectCcnQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b60a9bdc88d1df3cca02430f39d17098';

module.exports = node;
