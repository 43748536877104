/**
 * @flow
 * @relayHash 22483ba9c1753bcc599212d651ddcc4f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelectRegionQueryVariables = {||};
export type SelectRegionQueryResponse = {|
  +data: ?{|
    +regions: ?$ReadOnlyArray<?{|
      +code: ?string,
      +title: ?string,
    |}>
  |}
|};
export type SelectRegionQuery = {|
  variables: SelectRegionQueryVariables,
  response: SelectRegionQueryResponse,
|};
*/


/*
query SelectRegionQuery {
  data {
    regions {
      code
      title
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Data",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Region",
        "kind": "LinkedField",
        "name": "regions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectRegionQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SelectRegionQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "22483ba9c1753bcc599212d651ddcc4f",
    "metadata": {},
    "name": "SelectRegionQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '999e27d10fd8b87363c6b8edfedbc3f6';

module.exports = node;
