/**
 * @flow
 * @relayHash 99b2ca8b4de1d9f51fb7e8b60fedfaec
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConvertPropositionsCommercialesMutationVariables = {|
  propositionId: string
|};
export type ConvertPropositionsCommercialesMutationResponse = {|
  +convertPropositionsCommerciales: ?{|
    +ok: boolean,
    +token: ?string,
  |}
|};
export type ConvertPropositionsCommercialesMutation = {|
  variables: ConvertPropositionsCommercialesMutationVariables,
  response: ConvertPropositionsCommercialesMutationResponse,
|};
*/


/*
mutation ConvertPropositionsCommercialesMutation(
  $propositionId: String!
) {
  convertPropositionsCommerciales(propositionId: $propositionId) {
    ok
    token
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propositionId",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propositionId",
        "variableName": "propositionId"
      }
    ],
    "concreteType": "TokenResponse",
    "kind": "LinkedField",
    "name": "convertPropositionsCommerciales",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConvertPropositionsCommercialesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConvertPropositionsCommercialesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "99b2ca8b4de1d9f51fb7e8b60fedfaec",
    "metadata": {},
    "name": "ConvertPropositionsCommercialesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c638f5adcf06f71190938d051b683bd';

module.exports = node;
