/**
 * @flow
 * @relayHash 740a0483278ea0a7b5c16626944db4ff
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractNumbersStepQueryVariables = {|
  id: string
|};
export type ContractNumbersStepQueryResponse = {|
  +proposition: ?{|
    +id: ?string,
    +numberOfContracts: ?number,
    +contractNumbers: ?$ReadOnlyArray<?string>,
    +declarativeAnnexe: ?string,
    +outstandingAnnexe: ?string,
    +contractDescription: ?{|
      +C: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
      +NC: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
      +AM: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
      +TOUS: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
      +APP: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
    |},
    +staff: ?{|
      +C: ?{|
        +isSelected: ?boolean
      |},
      +NC: ?{|
        +isSelected: ?boolean
      |},
      +AM: ?{|
        +isSelected: ?boolean
      |},
      +APP: ?{|
        +isSelected: ?boolean
      |},
      +TOUS: ?{|
        +isSelected: ?boolean
      |},
    |},
    +amendment: ?{|
      +isAmendment: ?boolean,
      +formerNumberOfContracts: ?number,
      +formerColleges: ?$ReadOnlyArray<?string>,
      +formerContractDescription: ?{|
        +C: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +NC: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +AM: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +TOUS: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +APP: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
      |},
    |},
    +health: ?{|
      +numberOfContracts: ?number,
      +contractNumbers: ?$ReadOnlyArray<?string>,
      +amendment: ?{|
        +isAmendment: ?boolean,
        +formerNumberOfContracts: ?number,
        +formerColleges: ?$ReadOnlyArray<?string>,
        +formerContractDescription: ?{|
          +C: ?{|
            +bases: ?$ReadOnlyArray<?{|
              +baseId: ?string,
              +options: ?$ReadOnlyArray<?{|
                +optionId: ?string
              |}>,
            |}>
          |},
          +NC: ?{|
            +bases: ?$ReadOnlyArray<?{|
              +baseId: ?string,
              +options: ?$ReadOnlyArray<?{|
                +optionId: ?string
              |}>,
            |}>
          |},
          +AM: ?{|
            +bases: ?$ReadOnlyArray<?{|
              +baseId: ?string,
              +options: ?$ReadOnlyArray<?{|
                +optionId: ?string
              |}>,
            |}>
          |},
          +TOUS: ?{|
            +bases: ?$ReadOnlyArray<?{|
              +baseId: ?string,
              +options: ?$ReadOnlyArray<?{|
                +optionId: ?string
              |}>,
            |}>
          |},
          +APP: ?{|
            +bases: ?$ReadOnlyArray<?{|
              +baseId: ?string,
              +options: ?$ReadOnlyArray<?{|
                +optionId: ?string
              |}>,
            |}>
          |},
        |},
      |},
      +contractDescription: ?{|
        +C: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +NC: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +AM: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +TOUS: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +APP: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
      |},
      +staff: ?{|
        +C: ?{|
          +isSelected: ?boolean
        |},
        +NC: ?{|
          +isSelected: ?boolean
        |},
        +AM: ?{|
          +isSelected: ?boolean
        |},
        +APP: ?{|
          +isSelected: ?boolean
        |},
        +TOUS: ?{|
          +isSelected: ?boolean
        |},
      |},
    |},
  |}
|};
export type ContractNumbersStepQuery = {|
  variables: ContractNumbersStepQueryVariables,
  response: ContractNumbersStepQueryResponse,
|};
*/


/*
query ContractNumbersStepQuery(
  $id: ID!
) {
  proposition(id: $id) {
    id
    numberOfContracts
    contractNumbers
    declarativeAnnexe
    outstandingAnnexe
    contractDescription {
      C {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
      NC {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
      AM {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
      TOUS {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
      APP {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
    }
    staff {
      C {
        isSelected
      }
      NC {
        isSelected
      }
      AM {
        isSelected
      }
      APP {
        isSelected
      }
      TOUS {
        isSelected
      }
    }
    amendment {
      isAmendment
      formerNumberOfContracts
      formerColleges
      formerContractDescription {
        C {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        NC {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        AM {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        TOUS {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        APP {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
      }
    }
    health {
      numberOfContracts
      contractNumbers
      amendment {
        isAmendment
        formerNumberOfContracts
        formerColleges
        formerContractDescription {
          C {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          NC {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          AM {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          TOUS {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          APP {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
        }
      }
      contractDescription {
        C {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        NC {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        AM {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        TOUS {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        APP {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
      }
      staff {
        C {
          isSelected
        }
        NC {
          isSelected
        }
        AM {
          isSelected
        }
        APP {
          isSelected
        }
        TOUS {
          isSelected
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfContracts",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNumbers",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BaseProduct",
    "kind": "LinkedField",
    "name": "bases",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baseId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseOptionProduct",
        "kind": "LinkedField",
        "name": "options",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optionId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "C",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "NC",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "AM",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "TOUS",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "APP",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractDescription",
  "kind": "LinkedField",
  "name": "contractDescription",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isSelected",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "StaffFigures",
  "kind": "LinkedField",
  "name": "staff",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "C",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "NC",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "AM",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "APP",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "TOUS",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Amendment",
  "kind": "LinkedField",
  "name": "amendment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAmendment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formerNumberOfContracts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formerColleges",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDescription",
      "kind": "LinkedField",
      "name": "formerContractDescription",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Proposition",
    "kind": "LinkedField",
    "name": "proposition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "declarativeAnnexe",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "outstandingAnnexe",
        "storageKey": null
      },
      (v5/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractHealth",
        "kind": "LinkedField",
        "name": "health",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v8/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractNumbersStepQuery",
    "selections": (v9/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractNumbersStepQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "id": "740a0483278ea0a7b5c16626944db4ff",
    "metadata": {},
    "name": "ContractNumbersStepQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '922e4858db6d4272c61c2d97c5149af8';

module.exports = node;
