import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
  mutation GenerateContractNumbersMutation($propositionId: ID!, $offerType: OfferTypeEnum!) {
    generateContractNumbers(propositionId: $propositionId, offerType: $offerType) {
      ok
      error
      contractNumbers
    }
  }
`;

export default ({ propositionId, offerType }, done) => {
  const variables = {
    propositionId,
    offerType
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: ({ generateContractNumbers: { ok, error, contractNumbers } }) => {
        done(ok, error, contractNumbers);
      },
      onError: error => {
        console.error(error);
        done(false, error);
      },
    }
  );
};
