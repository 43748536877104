import * as R from 'ramda';
import React from 'react';
import { withRouter } from 'react-router-dom';
import getQueryParams from '../../lib/getQueryParams';

const Message = ({ children, error = false }) => {
  return <div
    style={{
      marginTop: '20px',
      ...(error ? {
        color: 'red',
        fontWeight: 'bold'
      } : {})
    }}
  >
    {children}
  </div>;
};

const ConfirmAccount = ({ location }) => {
  const { error } = getQueryParams(location);

  return R.isNil(error) || R.isEmpty(error) ?
    <Message>La demande de création de compte a bien été validée.</Message>
    :
    <Message error>
      { error === 'user_does_not_exist' ? 'L\'utilisateur n\'existe pas.' :
        error === 'user_is_confirmed' ? 'Le compte de cet utilisateur est déjà confirmé.' :
          'Une erreur est survenue.'
      }
    </Message>
  ;
};

export default withRouter(ConfirmAccount);
