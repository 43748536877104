/**
 * @flow
 * @relayHash 029150958a5e975d5016f41ace91c7fe
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ICPSInput = {|
  reason?: ?string,
  terminationDate?: ?string,
|};
export type ReopenContractMutationVariables = {|
  contractId: string,
  ICPS: ICPSInput,
|};
export type ReopenContractMutationResponse = {|
  +reopenContract: ?{|
    +ok: boolean,
    +error: ?string,
    +newContractId: ?string,
  |}
|};
export type ReopenContractMutation = {|
  variables: ReopenContractMutationVariables,
  response: ReopenContractMutationResponse,
|};
*/


/*
mutation ReopenContractMutation(
  $contractId: ID!
  $ICPS: ICPSInput!
) {
  reopenContract(contractId: $contractId, ICPS: $ICPS) {
    ok
    error
    newContractId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ICPS",
    "type": "ICPSInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ICPS",
        "variableName": "ICPS"
      },
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      }
    ],
    "concreteType": "ReopenContractMutationResponse",
    "kind": "LinkedField",
    "name": "reopenContract",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "newContractId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReopenContractMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReopenContractMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "029150958a5e975d5016f41ace91c7fe",
    "metadata": {},
    "name": "ReopenContractMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8c4ada7bad5f00df7b88e705970c7ee7';

module.exports = node;
