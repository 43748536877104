import './ContractDescriptionStep.less';

import * as R from 'ramda';
import { connect } from 'react-redux';
import withUser from '../../../withUser';

import {
  propositionSetContractDescription,
  propositionGoNextStep,
  propositionGoPreviousStep,
  propositionSetAmendment, propositionSetHealthContractDescription
} from '../../../reduxActions/proposition';

import React, { useState } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../graphql/Environment';
import S from 'string';
import CcnPackStep from './CcnPackStep';
import ContractDescriptionPrevoyance from '../components/ContractDescriptionPrevoyance';
import { hooks } from '@fasstech/spid-front';
import ContractDescriptionSante from '../components/ContractDescriptionSante';
import Error from '../../../components/Error';
import getAmendmentInfos from '../../../lib/getAmendmentInfos';

const ContractDescriptionStepQuery = graphql`
  query ContractDescriptionStepQuery($ccnId: String!, $offerType: OfferTypeEnum! $brokerage: Boolean, $postCode: Int!, $ccnVersion: Int!, $provider: String, $tariffStructure: String) {
    bases(ccnId: $ccnId, offerType: $offerType, brokerage: $brokerage, postCode: $postCode, ccnVersion: $ccnVersion, provider: $provider, tariffStructure: $tariffStructure) {
      id
      name
      college
      bundledWith
      bundledWithOption
      type
      workWith
      copyTo {
        collegeId
        baseId
        whenAmendment
        whenOriginAmendment
        selectOnly
      }
      isRequired
      isRequiredAmendment
      notAvailableWhenCollegeContains
      notAvailableWhenCollegeDoesNotContain
      dependsOf
      group
      disabled
      historical
      possibleAmendments
      unselect {
        collegeId
        baseId
      }
      unavailableAfter {
        date
        message
      }
      options {
        id
        name
        copyTo {
          collegeId
          baseId
          optionId
          on
        }
        isRequired
        group
        workWith
        unselect {
          collegeId
          baseId
          optionId
        }
        unavailableAfter {
          date
          message
        }
        displayPopup
        mustBeSelectedTogetherWithAtLeast
      }
    }
  }
`;

const oneBaseIsSelected = (college, value) => {
  if (!R.has(college, value)) return false;
  let acc = false;
  R.forEachObjIndexed(v => {
    acc = acc || v.selected;
  })(R.prop(college, value));
  return acc;
};
const sortColleges = (colleges, collegesOrder = ['NC', 'APP', 'AM', 'C', 'TOUS']) => {
  const sortedColleges = [];
  R.forEachObjIndexed((sortedCollege) => {
    if (colleges.includes(sortedCollege)) sortedColleges.push(sortedCollege);
  })(collegesOrder);
  return sortedColleges;
};

const useNextStep = (goNextStep, setContractDescription, value, offerType) => {
  const [loading, setLoading] = useState(false);

  const onNext = (v) => {
    try {
      setLoading(true);

      if (R.isNil(v)) setContractDescription(value, offerType);
      else setContractDescription(v, offerType);
      goNextStep();
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    onNext,
    loading
  };
};

const buildValue = (staff, contractDescription) => R.compose(
  R.mapObjIndexed((v, k) => R.pathOr(false, [k, 'isSelected'], staff) ? v : undefined),
)(contractDescription);

const useContractDescription = (staff, contractDescription) => {

  const [value, setValue] = useState(buildValue(staff, contractDescription));

  const hasValue = (colleges) => {
    return R.reduce(
      (r, college) => r && oneBaseIsSelected(college, value),
      true
    )(colleges);
  };

  const onUpdate = (updatedValue) => {
    setValue(updatedValue);
  };

  return {
    value,
    hasValue,
    onUpdate
  };
};

const useModal = () => {
  const [show, setShow] = useState(false);

  const onToggleModal = () => {
    setShow(!show);
  };

  return {
    showModal: show,
    onToggleModal
  };
};

const useUnknownAmendment = ({ amendment, setAmendment, isKnownAmendment, staff }) => {
  const isUnknownAmendment = R.propEq('isAmendment', true, amendment) && !isKnownAmendment;
  const { formerContractDescription } = amendment;

  const [stepAmendment, setStepAmendment] = useState(isUnknownAmendment);

  const onUpdateAmendment = (value) => {
    setAmendment({
      ...amendment,
      formerContractDescription: value
    });
    setStepAmendment(false);
  };

  const goPreviousStepAmendment = () => {
    setStepAmendment(true);
  };

  return {
    formerContractDescription: buildValue(staff, formerContractDescription),
    goPreviousStepAmendment,
    isUnknownAmendment,
    onUpdateAmendment,
    stepAmendment
  };
};

const ContractDescriptionStep = ({
  propositionId,
  staff,
  contractDescription,
  setContractDescription,
  setAmendment,
  goNextStep,
  ccnId,
  colleges,
  postCode,
  amendment,
  isKnownAmendment,
  brokerageIsSelected,
  goPreviousStep,
  visionServiceUrl,
  user,
  userRoleIs,
  ccnVersion,
  startDate,
  duplicata,
  ccn,
  ccnIsPack,
  provider,
  offerType,
  tariffStructure,
  courseIsAmendment
}) => {
  const {
    formerContractDescription,
    goPreviousStepAmendment,
    isUnknownAmendment,
    onUpdateAmendment,
    stepAmendment
  } = useUnknownAmendment({ amendment, setAmendment, isKnownAmendment, staff });

  const {
    hasValue,
    value,
    onUpdate
  } = useContractDescription(staff, contractDescription);

  const {
    mustHaveAtLeastOneOfIsValid
  } = hooks.useCcn(ccn, value);

  const {
    onNext,
    loading
  } = useNextStep(goNextStep, setContractDescription, value, offerType);

  const {
    showModal,
    onToggleModal
  } = useModal();

  return ccnIsPack ? <CcnPackStep
    onNext={onNext}
    onPrevious={goPreviousStep}
    colleges={colleges}
    brokerage={brokerageIsSelected}
    postCode={postCode}
    ccnVersion={ccnVersion}
  /> : (
    <QueryRenderer
      environment={environment}
      query={ContractDescriptionStepQuery}
      variables={{
        ccnId,
        offerType,
        brokerage: brokerageIsSelected,
        postCode,
        ccnVersion,
        provider: userRoleIs('apporteur') ? provider : undefined,
        tariffStructure
      }}
      render={({ error, props }) => {
        if (error) {
          return <Error/>;
        } else if (!props) return null;

        const { bases } = props;

        const contractDescriptionProps = {
          amendment,
          bases,
          ccnId,
          colleges,
          formerContractDescription,
          goPreviousStep,
          goPreviousStepAmendment,
          hasValue,
          isUnknownAmendment,
          onNext,
          onToggleModal,
          onUpdate,
          onUpdateAmendment,
          propositionId,
          showModal,
          stepAmendment,
          user,
          value,
          visionServiceUrl,
          startDate,
          duplicata,
          ccn,
          mustHaveAtLeastOneOfIsValid,
          loading,
          courseIsAmendment
        };

        if (offerType === 'SANTE') {
          return <ContractDescriptionSante {...contractDescriptionProps}/>;
        }

        return <ContractDescriptionPrevoyance {...contractDescriptionProps}/>;
      }}
    />
  );
};

export default connect(
  (state) => {
    const data = R.pathOr({}, ['proposition', 'data'], state);
    const v = k => R.path(k.split('.'), data);

    const currentOfferTypeIs = is => R.pathEq(['proposition', 'data', 'currentOfferType'], is)(state);
    const staff = currentOfferTypeIs('SANTE') && !R.isNil(v('health.staff')) ? v('health.staff') : v('staff');
    const ccnVersion = currentOfferTypeIs('SANTE') ? R.pathOr(1, ['health', 'ccnVersion'], data) : R.pathOr(1, ['ccnVersion'])(data);

    return {
      propositionId: v('propositionId'),
      brokerageIsSelected: v('brokerage.isSelected'),
      ccnId: v('ccn.id'),
      ccnIsPack: R.pathOr(false, ['ccn', 'isPack'], data),
      visionServiceUrl: v('ccn.visionServiceUrl'),
      staff,
      colleges: sortColleges(R.keys(R.pickBy(R.propEq('isSelected', true))(staff)), v(`ccn.${v('currentOfferType')}.collegesOrder`)),
      postCode: S(v('postCode')).toInt(),
      contractDescription: currentOfferTypeIs('SANTE') ? v('health.contractDescription') : v('contractDescription'),
      ccnVersion,
      ...getAmendmentInfos(data),
      startDate: R.path(['startDate'], data),
      duplicata: R.path(['duplicata'], data),
      ccn: R.path(['ccn'], data),
      provider: R.path(['brokerage', 'provider'], data),
      offerType: R.propOr('PREVOYANCE', 'currentOfferType')(data),
      tariffStructure: R.path(['health', 'tariffStructure'], data)
    };
  },
  (dispatch) => ({
    setContractDescription: (value, offerType) => offerType === 'SANTE' ? dispatch(propositionSetHealthContractDescription(value)) : dispatch(propositionSetContractDescription(value)),
    setAmendment: (value) => dispatch(propositionSetAmendment(value)),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    goNextStep: () => dispatch(propositionGoNextStep())
  })
)(withUser(ContractDescriptionStep));
