import * as R from 'ramda';
import React, { useState } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../graphql/Environment';
import MenuLayout from '../../layout/menu/Layout';
import PropositionInfoDisplay from './components/PropositionInfoDisplay';
import FieldTitle from '../../uikit/text/FieldTitle';
import Button from '../../uikit/buttons/Button';
import { withRouter } from 'react-router-dom';
import DuplicateContractMutation from '../../graphql/mutations/DuplicateContractMutation';
import withUser from '../../withUser';
import ReopenContractMutation from '../../graphql/mutations/ReopenContractMutation';
import ICPSForm from './components/ICPSForm';
import moment from 'moment';
import { assoc, compose, modify, objOf } from 'ramda';
import Error from '../../components/Error';

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const PropositionInfoQuery = graphql`
  query PropositionInfoQuery($id: ID) {
    propositionInfo(id: $id) {
      id
      ccn {
        id
        shortTitle
        PREVOYANCE {
          collegesLabels {
            AM
            NC
            C
          }
        }
        SANTE {
          collegesLabels {
            AM
            NC
            C
          }
        }
      }
      ccnVersion
      postCode
      contractNumbers
      background {
        hasFormerContract
        atmtt {
          isSelected
          count
        }
      }
      brokerage {
        isSelected
      }
      startDate
      dates {
        creation
        lastUpdate
      }
      propositionNumber
      siren
      siret
      siretInfo {
        siret
        siren
        name
        legalForm
        address {
          city
          postCode
          fields
        }
      }
      status
      medicalCheck
      declarativeAnnexe
      outstandingAnnexe
      outstandingCheck
      companyInformation {
        contactFirstname
        contactLastname
        contactEmail
        contactMobile
        contactRole
      }
      staff {
        NC {
          isSelected
          count
        }
        AM {
          isSelected
          count
        }
        C {
          isSelected
          count
        }
      }
      contractDescription {
        NC {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        AM {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        C {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        APP {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
      }
      health {
        contractNumbers
        contractDescription {
          TOUS {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          NC {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          AM {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          C {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
        }
        staff {
          TOUS {
            isSelected
            count
          }
          NC {
            isSelected
            count
          }
          AM {
            isSelected
            count
          }
          C {
            isSelected
            count
          }
        }
      }
      duplicata
      relatedContract {
        checked
        oldLegalForm
        contractNumbers
      }
      user {
        email
        role
        extra {
          userCode
          region
          managementCenter
          lastname
          firstname
          sales
        }
      }
      overPricedRate
      regionTitle
      managementCenterName
      basesAndOptionsNamesC
      basesAndOptionsNamesNC
      basesAndOptionsNamesAM
      basesAndOptionsNamesTOUS
    }
  }
`;

const usePropositionInfo = ({
  propositionId,
  history,
  user
}) => {
  const isSigned = (status) => R.includes(status, ['confirmed', 'sentToManagementSystem', 'confirmedInManagementSystem', 'confirmedInManagementSystemReopened', 'rejectedByManagementSystem']);
  const getButtonLabel = (status) => R.includes(status, ['active', 'sentForSignature', 'sentForSignatureDelayed', 'unhold', 'ready', 'duplicate']) ? 'Reprendre la souscription' : 'Accéder au contrat';
  const contractCanBeDuplicated = status => R.includes(status, ['sentForSignature', 'sentForSignatureDelayed', 'confirmed', 'confirmedInManagementSystem']);

  const duplicateContract = () => DuplicateContractMutation(propositionId, (ok, error, newPropositionId) => {
    if (ok && !error && !R.isNil(newPropositionId)) {
      history.push(`/proposition?propositionId=${newPropositionId}`);
    }
  });

  const contractCanBeReopened = status => status === 'confirmedInManagementSystem' && R.propEq('role', 'groupe_adhesion', user);

  const [reopeningStarted, setReopeningStarted] = useState(false);
  const [ICPS, setICPS] = useState({});

  const onStartReopening = () => setReopeningStarted(true);

  const validateReopenContractIsDisabled = isNilOrEmpty(ICPS.terminationDate) || isNilOrEmpty(ICPS.reason);

  const reopenContract = () => {
    const data = compose(
      assoc('contractId', propositionId),
      objOf('ICPS'),
      modify('terminationDate', v => moment(v).format('DD/MM/YYYY'))
    )(ICPS);

    ReopenContractMutation(data, (ok, error, newContractId) => {
      if (ok && !error && !R.isNil(newContractId)) {
        history.push(`/proposition?propositionId=${newContractId}`);
      }
    });
  };

  return {
    isSigned,
    getButtonLabel,
    contractCanBeDuplicated,
    duplicateContract,
    contractCanBeReopened,
    onStartReopening,
    reopeningStarted,
    reopenContract,
    validateReopenContractIsDisabled,
    ICPS,
    setICPS
  };
};

const buttonStyle = { minWidth: '200px', margin: '10px auto', height: '40px' };

const PropositionInfo = ({
  propositionId,
  history,
  user
}) => {
  const {
    isSigned,
    getButtonLabel,
    contractCanBeDuplicated,
    duplicateContract,
    contractCanBeReopened,
    onStartReopening,
    reopeningStarted,
    reopenContract,
    validateReopenContractIsDisabled,
    ICPS,
    setICPS
  } = usePropositionInfo({
    propositionId,
    history,
    user
  });

  return <MenuLayout>
    <QueryRenderer
      environment={environment}
      query={PropositionInfoQuery}
      variables={{
        id: propositionId
      }}
      render={({ error, props }) => {
        if (error) {
          return <Error/>;
        } else if (!props) return null;

        const { propositionInfo } = props;
        const { status } = propositionInfo;

        return <div>
          <Button to={`selectionnerproposition?siren=${propositionInfo.siren}`} size="small" style={{ width: '200px', marginBottom: '-30px' }}>Retourner à la liste</Button>
          <FieldTitle
            text={`Proposition n°${propositionInfo.propositionNumber}`}
          />

          <PropositionInfoDisplay proposition={propositionInfo}/>

          {reopeningStarted && <ICPSForm
            ICPS={ICPS}
            setICPS={setICPS}
          />}

          <div className="flex items-center">
            {!isSigned(status) && (
              <Button
                to={`/proposition?propositionId=${propositionId}`}
                style={buttonStyle}
              >
                {getButtonLabel(status)}
              </Button>
            )}

            {contractCanBeDuplicated(status) && (
              <Button
                style={buttonStyle}
                onClick={duplicateContract}
              >
                Dupliquer le contrat
              </Button>
            )}

            {contractCanBeReopened(status) && <>
              {!reopeningStarted && <Button
                style={buttonStyle}
                onClick={onStartReopening}
              >
                Réouvrir une nouvelle proposition
              </Button>}

              {reopeningStarted && <Button
                style={buttonStyle}
                onClick={reopenContract}
                disabled={validateReopenContractIsDisabled}
              >
                Valider réouvrir un nouveau contrat
              </Button>}
            </>}
          </div>
        </div>;
      }}
    />
  </MenuLayout>;
};

export default withUser(withRouter(PropositionInfo));
