/**
 * @flow
 * @relayHash 89c4a42b0be2293822c1802eb8d4db48
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getPropositionQueryVariables = {|
  id?: ?string
|};
export type getPropositionQueryResponse = {|
  +proposition: ?{|
    +additionalInformations: ?{|
      +PREVOYANCE: ?{|
        +hasLessThan20Employees: ?boolean
      |}
    |}
  |}
|};
export type getPropositionQuery = {|
  variables: getPropositionQueryVariables,
  response: getPropositionQueryResponse,
|};
*/


/*
query getPropositionQuery(
  $id: ID
) {
  proposition(id: $id) {
    additionalInformations {
      PREVOYANCE {
        hasLessThan20Employees
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AdditionalInformations",
  "kind": "LinkedField",
  "name": "additionalInformations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OfferTypeAdditionalInformations",
      "kind": "LinkedField",
      "name": "PREVOYANCE",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasLessThan20Employees",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getPropositionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Proposition",
        "kind": "LinkedField",
        "name": "proposition",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getPropositionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Proposition",
        "kind": "LinkedField",
        "name": "proposition",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "89c4a42b0be2293822c1802eb8d4db48",
    "metadata": {},
    "name": "getPropositionQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '145f3f00f73393f8390d36cb2c962af8';

module.exports = node;
