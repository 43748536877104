import React, { Suspense } from 'react';

import Layout from '../../layout/admin/Layout';

const Admin = React.lazy(() => import(/* webpackChunkName: "Admin" */ './Admin'));

const Loading = () => (
  <div style={{ margin: '16px auto' }}>
    {'chargement de la page d\'administration...'}
  </div>
);
const AdminLazyLoading = ({ isCommercialNetwork }) => (
  <Layout>
    <Suspense fallback={Loading}>
      <Admin isCommercialNetwork={isCommercialNetwork}/>
    </Suspense>
  </Layout>
);

export default AdminLazyLoading;
