import './SelectButtons.less';

import React from 'react';

import {
  Button,
  Text
} from '../UIKit';

import {
  compose,
  defaultProps
} from 'recompose';

const enhance = compose(
  defaultProps({
    value: 'option2',
    options: [
      {
        value: 'option1',
        title: 'option1'
      },
      {
        value: 'option2',
        title: 'option2'
      },
      {
        value: 'option3',
        title: 'option3'
      },
    ]
  })
);

const SelectButtons = enhance(
  ({
    options,
    value,
    onChange
  }) => (
    <div className="uikit-select-buttons">
      {options.map((option, index) => (
        <Button
          key={index}
          look="white"
          selected={option.value === value}
          onClick={() => onChange(option.value)}
        >
          <Text
            text={option.title}
          />
        </Button>
      )
      )}
    </div>
  )
);

export default SelectButtons;
