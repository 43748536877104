import * as R from 'ramda';
import React, { useState } from 'react';
import S from 'string';
import { TextInput } from '../../../uikit/UIKit';
import { validations } from '@fasstech/spid-front';

const Siren = ({
  onKeyPress,
  onChange
}) => {
  const [siren, setSiren] = useState('');
  const [error, setError] = useState(true);

  const _onChange = e => {
    e.preventDefault();
    let value = S(e.target.value).replaceAll(' ', '').s;
    if (R.isEmpty(value) || validations.isNumeric(value)) {
      setError(!validations.isSiren(value));
      setSiren(R.splitEvery(3, value).join(' '));
      onChange(validations.isSiren(value) ? value : null);
    } else {
      onChange(null);
    }
  };

  return <TextInput
    style={{ color: error ? 'red' : 'green' }}
    value={siren}
    onChange={_onChange}
    onKeyPress={onKeyPress}
    placeholder="n° de SIREN"
  />;
};

export default Siren;
