import React from 'react';
import { compose, defaultTo, filter, find, keys, map, pathOr, propEq, propOr, whereEq, __, includes } from 'ramda';
import { libs } from '@fasstech/spid-front';
import TurnoverInput from './TurnoverInput';
import { connect } from 'react-redux';
import {
  propositionSetHealthTurnover, propositionSetTurnover,
} from '../../../../../reduxActions/proposition';

const useTurnovers = ({
  staff,
  offerType,
  contractNumberIndex,
  ccn,
  getTurnoverValue,
  updateTurnover,
}) => {
  const sortedColleges = pathOr(['NC', 'APP', 'AM', 'C', 'TOUS'], [offerType, 'collegesOrder'], ccn);

  const selectedColleges = compose(
    keys,
    filter(propEq('isSelected', true))
  )(staff);

  const colleges = filter(includes(__, selectedColleges))(sortedColleges);

  const props = {
    colleges,
    staff,
    offerType,
    contractNumberIndex,
    ccn,
    getTurnoverValue,
    updateTurnover
  };

  return {
    props
  };
};

const HealthTurnover = ({
  getTurnoverValue,
  updateTurnover
}) => {
  return <div className="max-w-[140px]">
    <TurnoverInput
      turnover={getTurnoverValue()}
      updateTurnover={updateTurnover}
    />
  </div>;
};

const ProtectionTurnovers = ({
  contractNumberIndex,
  ccn,
  offerType,
  colleges,
  getTurnoverValue,
  updateTurnover
}) => {
  return <div className="flex flex-col">
    {map(college => {
      return <div
        key={`turnover-${contractNumberIndex}-${college}`}
        className="flex items-center"
      >
        <div className="w-[180px]">
          {libs.collegeLabel(college, ccn, offerType)}
        </div>

        <div className="w-[140px]">
          <TurnoverInput
            turnover={getTurnoverValue(college)}
            updateTurnover={v => updateTurnover(v, college)}
          />
        </div>
      </div>;
    })(colleges)}
  </div>;
};

const TurnoversByOfferType = ({
  offerType,
  staff,
  contractNumberIndex,
  ccn,
  getTurnoverValue,
  updateTurnover,
  hideTurnover
}) => {
  const {
    props
  } = useTurnovers({
    staff,
    offerType,
    contractNumberIndex,
    ccn,
    getTurnoverValue,
    updateTurnover,
  });

  if (hideTurnover) {
    return null;
  }

  return <div className="field field-text-input">
    <span>
      {'Chiffre d\'affaires annuel estimé TTC*'}
    </span>

    {offerType === 'SANTE' && <HealthTurnover {...props}/>}
    {offerType === 'PREVOYANCE' && <ProtectionTurnovers {...props}/>}
  </div>;
};

export default connect(
  ({ proposition }, { offerType, contractNumberIndex }) => {
    const getTurnoverValue = pred => compose(
      propOr('', 'value'),
      defaultTo({}),
      find(pred),
    );

    if (offerType === 'SANTE') {
      return {
        getTurnoverValue: () => compose(
          getTurnoverValue(propEq('contractNumberIndex', contractNumberIndex)),
          pathOr([], ['data', 'health', 'turnovers'])
        )(proposition)
      };
    }

    return {
      getTurnoverValue: college => compose(
        getTurnoverValue(whereEq({ contractNumberIndex, college })),
        pathOr([], ['data', 'turnovers'])
      )(proposition)
    };
  },
  (dispatch, { offerType, contractNumberIndex }) => {
    return {
      updateTurnover: (value, college) => dispatch(offerType === 'SANTE' ? propositionSetHealthTurnover(value, contractNumberIndex) : propositionSetTurnover(value, contractNumberIndex, college))
    };
  }
)(TurnoversByOfferType);
