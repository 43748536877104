/**
 * @flow
 * @relayHash daac1685d9bfe6b7fc947fe5d1f0e51b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type OrderByEnum = "asc" | "desc" | "%future added value";
export type PropositionStatusEnum = "active" | "closed" | "confirmed" | "confirmedInManagementSystem" | "confirmedInManagementSystemReopened" | "done" | "duplicate" | "ready" | "rejected" | "rejectedByManagementSystem" | "sendingForSignature" | "sentForSignature" | "sentForSignatureDelayed" | "sentToManagementSystem" | "unhold" | "%future added value";
export type UserRoleEnum = "admin" | "apporteur" | "groupe_adhesion" | "manager" | "reseau_commercial" | "%future added value";
export type PropositionFilterInput = {|
  managementCenter?: ?string,
  siren?: ?string,
  status?: ?$ReadOnlyArray<?PropositionStatusEnum>,
  userEmail?: ?string,
  userId?: ?string,
  userRole?: ?UserRoleEnum,
  username?: ?string,
|};
export type PropositionOrderByInput = {|
  lastUpdateDate?: ?OrderByEnum
|};
export type PropositionListPageQueryVariables = {|
  count: number,
  filter?: ?PropositionFilterInput,
  orderBy?: ?PropositionOrderByInput,
|};
export type PropositionListPageQueryResponse = {|
  +propositions: ?$ReadOnlyArray<?{|
    +id: ?string,
    +siren: ?string,
    +siret: ?string,
    +propositionNumber: ?number,
    +offerTypes: ?$ReadOnlyArray<?OfferTypeEnum>,
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +status: ?PropositionStatusEnum,
    +amendment: ?{|
      +isAmendment: ?boolean
    |},
    +relatedContract: ?{|
      +checked: ?boolean
    |},
  |}>
|};
export type PropositionListPageQuery = {|
  variables: PropositionListPageQueryVariables,
  response: PropositionListPageQueryResponse,
|};
*/


/*
query PropositionListPageQuery(
  $count: Int!
  $filter: PropositionFilterInput
  $orderBy: PropositionOrderByInput
) {
  propositions(count: $count, filter: $filter, orderBy: $orderBy) {
    id
    siren
    siret
    propositionNumber
    offerTypes
    dates {
      creation
      lastUpdate
    }
    status
    amendment {
      isAmendment
    }
    relatedContract {
      checked
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter",
    "type": "PropositionFilterInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "PropositionOrderByInput"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "count",
        "variableName": "count"
      },
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      }
    ],
    "concreteType": "Proposition",
    "kind": "LinkedField",
    "name": "propositions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "siren",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "siret",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "propositionNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offerTypes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Dates",
        "kind": "LinkedField",
        "name": "dates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Amendment",
        "kind": "LinkedField",
        "name": "amendment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAmendment",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RelatedContract",
        "kind": "LinkedField",
        "name": "relatedContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checked",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropositionListPageQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropositionListPageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "daac1685d9bfe6b7fc947fe5d1f0e51b",
    "metadata": {},
    "name": "PropositionListPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a5beb1c199b21de1ee63af1b2534d385';

module.exports = node;
