import './Layout.less';
import React from 'react';

import Top from './Top';

const Layout = (
  {
    children
  }) => {
  return (
    <div className="layout-connection">
      <Top/>
      <div className="layout-connection-body-wrapper">
        <div>
          <div style={{ paddingLeft: '16px', paddingRight: '16px', width: '100%' }}>
            {children}
          </div>
        </div>
        <div/>
      </div>
    </div>
  );
};

export default Layout;