import * as R from 'ramda';
import React, { useState } from 'react';

import {
  Button,
  TextInput
} from '../../../../uikit/UIKit';
import Checkbox from 'rc-checkbox';
import { validations } from '@fasstech/spid-front';
import withForm from '@thecodeisgreen/withform';
import LegalPersonsError from '../../steps/companyEstablishmentStep/LegalPersonsError';

const CompanyManualEstablishment = ({
  display,
  displayError,
  form,
  isAdding,
  onSelectManual,
  setManualState,
  siren,
  siretInfo,
  sirets,
  legalPersonsHasError,
  resetLegalPersonsHasError
}) => {

  const [isHeadOffice, setIsHeadOffice] = useState(false);
  const [error, setError] = useState(false);
  const resetError = () => {
    if (error) setError(false);
    resetLegalPersonsHasError();
  };

  const siretExists = (siret) => R.includes(siret, sirets);

  const fieldProps = (path) => {
    return {
      styleOnNotValid: { borderColor: 'orange' },
      defaultValue: R.pathOr('', path)(siretInfo),
      isUpdated: () => resetError(),
      isValid: v => !R.isEmpty(v)
    };
  };

  return (
    <div style={{ display, marginTop: '10px' }}>
      {displayError && <span style={{ color: 'red', fontSize: '0.9em' }}>Le SIREN entré n&#39;a pas été trouvé dans la base, veuillez saisir les informations requises manuellement.</span>}

      <div className="field field-text-input" style={{ marginTop: '10px' }}>
        <span>{'Nom de l\'entreprise*'}</span>
        {form.manageField('companyName', fieldProps(['name']))(<TextInput placeholder="Nom"/>)}
      </div>

      <div className="field field-text-input">
        <span>{'Forme juridique*'}</span>
        {form.manageField('legalForm', fieldProps(['legalForm']))(<TextInput placeholder="Forme juridique (ex: SARL...)"/>)}
      </div>

      <div className="field field-text-input">
        <span>{'NAF/APE*'}</span>
        {form.manageField('naf', fieldProps(['naf']))(<TextInput placeholder="NAF/APE"/>)}
      </div>

      <div className="field field-text-input">
        <span>{'NIC*'}</span>
        {form.manageField('nic', {
          ...fieldProps(['nic']),
          isValid: v => validations.isSiret(`${siren}${v}`) && !siretExists(`${siren}${v}`)
        })(<TextInput placeholder="NIC" type="number"/>)}
      </div>

      <div className="field field-text-input">
        <span>{'Adresse*'}</span>
        {form.manageField('address', fieldProps(['address', 'fields', '0']))(<TextInput placeholder="Adresse"/>)}
      </div>

      <div className="field field-text-input">
        <span>{'Ville*'}</span>
        {form.manageField('city', fieldProps(['address', 'city']))(<TextInput placeholder="Ville"/>)}
      </div>

      <div className="field field-text-input">
        <span>{'Code postal*'}</span>
        {form.manageField('postCode', {
          ...fieldProps(['address', 'postCode']),
          isValid: v => !R.isEmpty(v) && v.length === 5
        })(<TextInput placeholder="Code postal" type="number"/>)}
      </div>

      <div className="field field-checkbox">
        <Checkbox
          checked={isHeadOffice}
          onChange={e => setIsHeadOffice(e.target.checked)}
        />
        <span>{'Cet établissement est le siège de l\'entreprise'}</span>
      </div>

      {legalPersonsHasError && <LegalPersonsError/>}

      {isAdding && <Button
        onClick={() => setManualState('isNotManual')}
        style={{ display: 'inline-block' }}
      >
        Annuler
      </Button>}

      <Button
        disabled={!form.isValid() || legalPersonsHasError}
        onClick={() => onSelectManual({ ...form.values(), isHeadOffice })}
        style={{ display: 'inline-block' }}
      >
        Valider les informations
      </Button>
    </div>
  );
};

export default withForm(CompanyManualEstablishment);
