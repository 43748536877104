import './SalaryContinuationInfo.less';

import React from 'react';
import ReactMarkdown from 'react-markdown';

const SalaryContinuationInfo = () => (
  <div>
    <h2>{'Le maintien de salaire par l\'employeur'}</h2>
    <div className="salary-continuation-info-text1">
      <div className="salary-continuation-info-text1-icon">
        <img src="/icon/icon-obligations.svg" alt=""/>
      </div>
      <ReactMarkdown source={text1} />
    </div>
    <img 
      src="/image/salaryContinuation-graphic1.svg"
      className="salary-continuation-info-image"
      alt=""
    />
    <h2>{'La durée de versement des indemnités dépend de l’ancienneté du salarié'}</h2>
    <img 
      src="/image/salaryContinuation-graphic2.svg"
      className="salary-continuation-info-image"
      alt=""
    />
    <div className="salary-continuation-info-warning">
      <img 
        src="/icon/icon-info.svg"
        style={{ marginRight: '8px' }}
        alt=""
      />
      <ReactMarkdown source={text2} />
    </div>
  </div>
);

const text1 = 'En cas d’arrêt maladie, **l’employeur doit maintenir le salaire de son salarié ayant au moins 1 an d’ancienneté**.  Ces indemnités viennent compléter celles versées par la sécurité sociale. Un **délai de carence de 7 jours**  est prévu pour chaque arrêt de travail sauf en cas d\'accident du travail ou de maladie professionnelle avec une indemnisation dès le 1er jour.';
const text2 = 'Attention votre convention collective peut prévoir des conditions plus favorables en termes de durée ou de montant.';
export default SalaryContinuationInfo;