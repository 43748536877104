/**
 * @flow
 * @relayHash 2b91a1d1ce37002b52ce23ae480906df
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EnvEnum = "DISABLE_SSO" | "NODE_ENV" | "UNIVERSIGN_IFRAME_URL" | "%future added value";
export type QEnvQueryVariables = {|
  envVar: EnvEnum
|};
export type QEnvQueryResponse = {|
  +env: ?string
|};
export type QEnvQuery = {|
  variables: QEnvQueryVariables,
  response: QEnvQueryResponse,
|};
*/


/*
query QEnvQuery(
  $envVar: EnvEnum!
) {
  env(envVar: $envVar)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "envVar",
    "type": "EnvEnum!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envVar",
        "variableName": "envVar"
      }
    ],
    "kind": "ScalarField",
    "name": "env",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QEnvQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QEnvQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "2b91a1d1ce37002b52ce23ae480906df",
    "metadata": {},
    "name": "QEnvQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd7ea432ebad861a66b32148064e21db4';

module.exports = node;
