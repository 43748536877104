
import * as R from 'ramda';
import React, { useState } from 'react';
import classNames from 'classnames';
import { Spring, config } from 'react-spring';

const useTarification = (value) => {
  let tarifications, defaultTarification;

  if (R.is(Number, value.gauge) || R.is(String, value.gauge)) {
    tarifications = ['ALL'];
    defaultTarification = 'ALL';
  } else {
    tarifications = R.keys(value.gauge);
    defaultTarification = tarifications[0];
  } 

  const [selectedTarification, setSelectedTarification] = useState(defaultTarification);
  
  const onSelectTarification = (tarification) => {
    setSelectedTarification(tarification);
  };
  
  return {
    tarifications,
    selectedTarification,
    onSelectTarification
  };
};
const ringProps = (radius, value, selectedTarification) => {

  const gaugeValue = selectedTarification === 'ALL' ? value.gauge : value.gauge[selectedTarification];
  const strokeWidth = 10;
  const getCircleProps = (radius) => {
    const normalizedRadius = radius - strokeWidth * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    return {
      radius,
      normalizedRadius,
      circumference,
      gaugeValue,
      strokeWidth
    };
  };

  return {
    height: gaugeValue <= 100 ? radius * 2 : (radius + 20) * 2,
    width: gaugeValue <= 100 ? radius * 2 : (radius + 20) * 2,
    circle1: getCircleProps(radius),
    circle2: gaugeValue <= 100 ? undefined : getCircleProps(radius + 20),
    gaugeValue,
    strokeWidth
  };
};

const Ring = ({
  radius,
  value,
}) => {

  const {
    tarifications,
    selectedTarification,
    onSelectTarification
  } = useTarification(value);

  const {
    height,
    width,
    circle1,
    circle2,
    gaugeValue,
    strokeWidth
  } = ringProps(radius, value, selectedTarification);

  return (
    <div>
      <Spring 
        config={config.slow}
        from={{ gaugeValue: 0 }}
        to={{ gaugeValue }}
        delay={250}
      >
        {
          ({ gaugeValue }) => (
            <div className="block-ring" style={{ width, height }}>
              <div
                className="block-ring__value"
                style={{ width, height }}
              >
                <div>
                  {`${Math.round(gaugeValue)} %`}
                </div>
                { 
                  selectedTarification !== 'ALL' && (
                    <ul className="block-tarification-selector">
                      {
                        tarifications.map(tarification => (
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                          <li
                            className={classNames('block-tarification-selector-item', { 'block-tarification-selector-item-selected': tarification === selectedTarification })}
                            key={tarification}
                            onClick={() => onSelectTarification(tarification)}
                          >
                            {tarification}
                          </li>
                        ))}
                    </ul>
                  )}
              </div>
              <svg
                className="block-ring__circle"
                height={height}
                width={width}
              >
                {
                  [
                    !R.isNil(circle2) &&
                    <circle
                      key="circle-2-background"
                      className="block-ring__circle_background"
                      fill="transparent"
                      strokeWidth={ strokeWidth }
                      r={ circle2.normalizedRadius }
                      cx={ circle2.radius }
                      cy={ circle2.radius }
                    />,
                    !R.isNil(circle2) && <circle
                      key="circle-2-gauge"
                      className="block-ring__circle"
                      fill="transparent"
                      strokeDasharray={ circle2.circumference + ' ' + circle2.circumference }
                      style={ { strokeDashoffset: gaugeValue > 100 ? circle2.circumference - (Math.min(200, gaugeValue) - 100) / 100 * circle2.circumference : circle2.circumference } }
                      strokeWidth={ strokeWidth }
                      r={ circle2.normalizedRadius }
                      cx={ circle2.radius }
                      cy={ circle2.radius }
                    />,
                    <circle
                      key="circle-1-background"
                      className="block-ring__circle_background"
                      fill="transparent"
                      strokeWidth={ strokeWidth }
                      r={ circle1.normalizedRadius }
                      cx={ !R.isNil(circle2) ? circle2.radius : circle1.radius }
                      cy={ !R.isNil(circle2) ? circle2.radius : circle1.radius }
                    />,
                    <circle
                      key="circle-1-gauge"
                      className="block-ring__circle"
                      fill="transparent"
                      strokeDasharray={ circle1.circumference + ' ' + circle1.circumference }
                      style={ { strokeDashoffset: gaugeValue <= 100 ? circle1.circumference - gaugeValue / 100 * circle1.circumference : 0 } }
                      strokeWidth={ strokeWidth }
                      r={ circle1.normalizedRadius }
                      cx={ !R.isNil(circle2) ? circle2.radius : circle1.radius }
                      cy={ !R.isNil(circle2) ? circle2.radius : circle1.radius }
                    />
                  ]
                }
              </svg>
            </div>
          )
        }
      </Spring>
    </div>
  );
};

export default Ring;
