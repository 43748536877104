import * as R from 'ramda';
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import {
  propositionFetchingSendEmailReset,
  propositionSetCurrentStep
} from '../../../reduxActions/proposition';

import {
  Button,
  FieldTitle
} from '../../../uikit/UIKit';
import ClosePropositionMutation from '../../../graphql/mutations/ClosePropositionMutation';
import ConvertPropositionsCommercialesMutation
from '../../../graphql/mutations/ConvertPropositionsCommercialesMutation';
import { message } from 'antd';
import Loading from '../../../components/loading';
import PropositionsCommercialesMutation from '../../../graphql/mutations/PropositionsCommercialesMutation';
import QCollegesWithMedicalCheck from '../../../graphql/queries/QCollegesWithMedicalCheck';
import GenerateMedicalCheckLinkMutation from '../../../graphql/mutations/GenerateMedicalCheckLinkMutation';
import SendEmailMutation from '../../../graphql/mutations/SendEmailMutation';

const useMedicalCheckStep = ({ reset, error, propositionId, goTo }) => {
  useEffect(() => {
    reset();
  }, []);

  const hasError = !R.isNil(error) && !R.isEmpty(error);
  const [loading, setLoading] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState({
    sendQM: false
  });

  const onClose = () => {
    ClosePropositionMutation({ propositionId, reason: 'QM' }, (ok, error) => {
      if (ok && !error) {
        goTo('PROPOSITION_IS_CLOSED_STEP');
      }
    });
  };

  const onDownloadPropositionsCommerciales = () => {
    setLoading(true);

    ConvertPropositionsCommercialesMutation(propositionId, (ok, token) => {
      if (ok) {
        const retrieveDocument = (token) => {
          setTimeout(() => {
            PropositionsCommercialesMutation(token, (ok, token, status, archiveName) => {
              if (!ok || status === 'conversion_failed') {
                setLoading(false);
                message.error('Une erreur est survenue, veuillez réessayer.');
              } else if (ok && status === 'documents_converted') {
                setLoading(false);
                if (!R.isNil(archiveName) && !R.isEmpty(archiveName)) {
                  window.open(`/archive/${archiveName}/download`, '_blank');
                }
              } else {
                retrieveDocument(token);
              }
            });
          }, 500);
        };

        retrieveDocument(token);
      }
    });
  };

  const onReturn = () => window.location.href = '/';

  const onSendQM = () => {
    setLoading(true);
    GenerateMedicalCheckLinkMutation({ propositionId }, (ok, error, link) => {
      setLoading(false);

      if (ok && !error && !R.isNil(link)) {
        window.open(link, '_blank');
        setButtonsDisabled(R.assoc('sendQM', true));
      } else {
        message.error('Une erreur est survenue, veuillez réessayer.');
      }
    });
  };

  const onSendEmailToClient = () => {
    setLoading(true);
    SendEmailMutation({ propositionId, emailId: 'medical_check_to_client' }, (ok, error) => {
      setLoading(false);
      if (ok && !error) {
        message.success('Le mail a été envoyé au client.');
      } else {
        message.error('Une erreur est survenue, veuillez réessayer.');
      }
    });
  };

  return {
    hasError,
    onClose,
    onDownloadPropositionsCommerciales,
    onReturn,
    onSendQM,
    onSendEmailToClient,
    loading,
    buttonsDisabled
  };
};

const MedicalCheckStep = ({
  status,
  error,
  reset,
  propositionId,
  goTo
}) => {
  const {
    hasError,
    onClose,
    onDownloadPropositionsCommerciales,
    onReturn,
    onSendQM,
    onSendEmailToClient,
    loading,
    buttonsDisabled
  } = useMedicalCheckStep({ reset, error, propositionId, goTo });

  return <>
    <FieldTitle
      text="Votre dossier nécessite un questionnaire médical."
    />

    <QCollegesWithMedicalCheck args={{ contractId: propositionId }}>
      {({ collegesWithMedicalCheck }) => {
        // Les collèges NC et AM comptent pour le collège NC
        const _collegesWithMedicalCheck = R.compose(
          R.uniq,
          R.map(
            R.when(
              R.equals('AM'),
              R.always('NC')
            ),
          ),
          R.defaultTo([])
        )(collegesWithMedicalCheck);

        return <>
          {R.isNil(status) && <div>
            {R.length(_collegesWithMedicalCheck) > 1 && <div className="text-red-500 text-[16px] font-bold">
              ATTENTION :<br/>
              Le dossier est soumis à questionnaire médical sur les 2 collèges.<br/>
              L’envoi vers E-Sélection Médicale se fera sur 1 collège, vous devez saisir le 2ème dossier sur E-Sélection Médicale.<br/>
              Il est nécessaire de renseigner la CCN sur votre dossier E-selection Médicale.
            </div>}

            <div className="flex flex-col my-12 gap-y-4 w-[450px]">
              <Button onClick={onSendQM} disabled={loading || buttonsDisabled.sendQM}>Envoyer le QM avec E-Sélection Médicale</Button>

              <Button onClick={onSendEmailToClient} disabled={loading}>
                Envoyer le dossier au client
              </Button>

              <Button onClick={onDownloadPropositionsCommerciales} disabled={loading}>Editer la proposition commerciale</Button>
              <Button onClick={onClose} disabled={loading}>Clôturer la proposition commerciale</Button>

              <Button onClick={onReturn} disabled={loading}>Retour</Button>

              {loading && <Loading/>}
            </div>

          </div>}

          {status && <div>
            {'Préparation de l\'envoi.'}
          </div>}

          {hasError && <div>Le mail n&#39;a pas pu être envoyé.</div>}

          {!R.isNil(status) && !status && !hasError &&
          <div>
            {'Le mail est en route.'}
            <div style={{ display: 'flex', marginTop: '80px' }}>
              <Button to="/menu">Retour au menu</Button>
            </div>
          </div>}
        </>;
      }}
    </QCollegesWithMedicalCheck>
  </>;
};

export default connect(
  ({ proposition }) => ({
    status: proposition.fetchingSendEmail.status,
    error: proposition.fetchingSendEmail.error,
    userRole: proposition.userRole,
    propositionId: R.path(['data', 'propositionId'], proposition)
  }),
  dispatch => ({
    reset: () => dispatch(propositionFetchingSendEmailReset()),
    goTo: step => dispatch(propositionSetCurrentStep(step))
  })
)(MedicalCheckStep);
