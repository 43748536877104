import * as R from 'ramda';
import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation ResetPasswordMutation($currentPassword: String, $password: String!, $token: String) {
  resetPassword(currentPassword: $currentPassword, password: $password, token: $token) {
    ok
    error
  }
}
`;

export default ({ currentPassword, token, password }, done) => {
  const variables = {
    currentPassword,
    token,
    password,
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore, { resetPassword: { ok } }) => {
        if (ok) {
          const user = proxyStore.get('user');
          if (!R.isNil(user)) {
            user.setValue(false, 'resetPasswordRequired');
          }
        }
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.resetPassword;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
