/**
 * @flow
 * @relayHash 74a2c2b66d4403117afe9dd5ff37eb63
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type useCheckSiretsAreUsedQueryVariables = {|
  sirets: $ReadOnlyArray<?string>,
  ccnId: string,
  contractId?: ?string,
|};
export type useCheckSiretsAreUsedQueryResponse = {|
  +siretsAreUsed: ?$ReadOnlyArray<?{|
    +siret: ?string,
    +isUsed: ?boolean,
  |}>
|};
export type useCheckSiretsAreUsedQuery = {|
  variables: useCheckSiretsAreUsedQueryVariables,
  response: useCheckSiretsAreUsedQueryResponse,
|};
*/


/*
query useCheckSiretsAreUsedQuery(
  $sirets: [String]!
  $ccnId: String!
  $contractId: String
) {
  siretsAreUsed(sirets: $sirets, ccnId: $ccnId, contractId: $contractId) {
    siret
    isUsed
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sirets",
    "type": "[String]!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnId",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "String"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ccnId",
        "variableName": "ccnId"
      },
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      },
      {
        "kind": "Variable",
        "name": "sirets",
        "variableName": "sirets"
      }
    ],
    "concreteType": "SiretIsUsed",
    "kind": "LinkedField",
    "name": "siretsAreUsed",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "siret",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isUsed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCheckSiretsAreUsedQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCheckSiretsAreUsedQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "74a2c2b66d4403117afe9dd5ff37eb63",
    "metadata": {},
    "name": "useCheckSiretsAreUsedQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '95358c2e5622b7ef95b633d59bf338c6';

module.exports = node;
