import './Layout.less';
import Dimensions from 'react-dimensions';
import React from 'react';
import Top from './Top';

const max_width = 1200;

const Layout = ({
  children,
  withMessage = false,
  bodyStyle = {},
  containerWidth,
  staticLayout = false
}) => {
  const paddingLeft = Math.max(0, containerWidth - max_width) / 2;

  return <div className="layout-connection">
    <Top
      withMessage={withMessage}
      staticLayout={staticLayout}
    />

    <div className="layout-connection-body-wrapper">
      <div>
        <div style={{ paddingLeft, paddingRight: '16px', width: '100%', ...bodyStyle }}>
          {children}
        </div>
      </div>
      <div/>
    </div>
  </div>;
};

export default Dimensions()(Layout);
