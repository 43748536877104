import './Documents.less';
import * as R from 'ramda';

import React from 'react';
import environment from '../../../graphql/Environment';
import { graphql, QueryRenderer } from 'react-relay';
import { libs } from '@fasstech/spid-front';
import Error from '../../../components/Error';
const {
  documentTypeLabel,
  collegeLabel
} = libs;

const forEachIndexed = R.addIndex(R.forEach);

// const sortMensu = (bases) => { // le BA maintien de salaire doit être affiché en premier
//   return R.sort(base => {
//     const typeFirstDocument = R.pathOr('', ['documents', 0, 'type'], base);
//     if (R.includes('mensu', typeFirstDocument)) return -1;
//     return 1;
//   })(bases);
// };

const DocumentsQuery = graphql`
  query DocumentsQuery($documents: [DocumentInput]!) {
    sortDocuments(documents: $documents) {
      sortedDocumentId
      type
      url
    }
  }
`;

const SortedDocuments = ({ documents }) => {
  if (R.isNil(documents) || R.isEmpty(documents)) {
    return null;
  }

  return <QueryRenderer
    environment={environment}
    query={DocumentsQuery}
    variables={{ documents }}
    render={({ error, props }) => {
      if (error) {
        return <Error/>;
      } else if (!props) return null;

      const { sortDocuments = [] } = props;

      return R.map(document => {
        if (R.isNil(document)) {
          return null;
        }

        const { type, url, sortedDocumentId } = document;

        return <div key={url} className="documents">
          <div>{documentTypeLabel(type, sortedDocumentId)}</div>
          <div><a href={url} target="_blank" rel="noopener noreferrer">voir</a></div>
        </div>;
      })(sortDocuments);
    }}
  />;
};

const Documents = ({ value, isAmendment, ccn, staff, offerType }) => {
  const colleges = R.propOr(['NC', 'APP', 'AM', 'C', 'TOUS'], 'collegesOrder', ccn);

  const hasOptionsDocuments = R.compose(
    R.lt(0),
    R.length,
    R.flatten,
    R.pluck('documents'),
    R.flatten,
    R.pluck('options'),
    R.flatten,
    R.pluck('bases'),
    R.pluck(1),
    R.toPairs
  )(value);

  const sections = (value) => {
    let output = [];

    forEachIndexed((c, index) => {
      const college = value[c];

      const collegeIsSelected = R.pathEq([c, 'isSelected'], true, staff);

      const { basesDocuments, optionsDocuments } = R.applySpec({
        basesDocuments: R.compose(
          R.flatten,
          R.pluck('documents')
        ),
        optionsDocuments: R.compose(
          R.flatten,
          R.pluck('documents'),
          R.flatten,
          R.pluck('options')
        ),
      })(R.path(['bases'], college));

      let baseDocumentsAlreadyDisplayed = index !== 1, optionDocumentsAlreadyDisplayed = index !== 1;

      const amendmentMustHide = isAmendment && R.reduce((emptyDocuments, base) => emptyDocuments && R.isEmpty(R.propOr([], 'documents')(base)), true)(R.prop('bases', college));

      if (collegeIsSelected || !R.isEmpty(basesDocuments) || !R.isEmpty(optionsDocuments)) {
        output = R.append(
          <div key={c}>
            <h3 style={{ fontWeight: 'bold' }}>{collegeLabel(c, ccn, offerType)}</h3>

            {!amendmentMustHide && !R.isNil(basesDocuments) && !R.isEmpty(basesDocuments) && <h4>Base(s)</h4>}
            {baseDocumentsAlreadyDisplayed && R.isEmpty(basesDocuments) && !amendmentMustHide &&
            <div>{'Les documents des bases sélectionnées sont disponibles dans les collèges précédents.'}</div>
            }

            <SortedDocuments documents={basesDocuments}/>

            {!R.isNil(optionsDocuments) && !R.isEmpty(optionsDocuments) && <h4>Option(s)</h4>}

            {(optionDocumentsAlreadyDisplayed && R.isEmpty(optionsDocuments) && hasOptionsDocuments) && <div>{'Les documents des options sélectionnées sont disponibles dans les collèges précédents.'}</div>}

            <SortedDocuments documents={optionsDocuments}/>
          </div>

        )(output);
      }
    })(colleges);
    return output;
  };

  return <div>
    {sections(value)}
  </div>;
};

export default Documents;
