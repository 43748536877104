import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose, fromPairs, isNil, map, prop, propOr, replace, split } from 'ramda';
import ClosePropositionMutation from '../graphql/mutations/ClosePropositionMutation';

const getPropositionId = compose(
  prop('propositionId'),
  fromPairs,
  map(split('=')),
  split('&'),
  replace('?', ''),
  propOr('', 'search'),
);

const Error = ({
  message = 'Une erreur technique est survenue. Merci de fermer votre session, de vous reconnecter et de créer une nouvelle proposition.',
  location
}) => {
  React.useEffect(() => {
    const propositionId = getPropositionId(location);

    if (!isNil(propositionId)) {
      ClosePropositionMutation({ propositionId, reason: 'encountered_error' }, () => {});
    }
  }, []);

  return <div
    className="text-lg text-red-400 font-bold h-full flex items-center text-center"
  >
    {message}
  </div>;
};

export default withRouter(Error);
