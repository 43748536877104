import * as R from 'ramda';
import React from 'react';
import './PropositionInfoDisplay.less';

const PropositionInfoDisplayProp = ({
  label,
  value,
  placeholder
}) => {

  const displayPlaceholder = R.isNil(value) || R.isEmpty(value);
  const getPlaceholder = R.isNil(placeholder) || R.isEmpty(placeholder) ? 'Information non trouvée.' : placeholder;

  return <div className="proposition-info-prop">
    <div className="proposition-info-label">{label}</div>
    <div className="proposition-info-value" style={{ backgroundColor: displayPlaceholder ? '#f1cccc' : '#fafafa' }}>{!displayPlaceholder ? value : getPlaceholder}</div>
  </div>;
};

export default PropositionInfoDisplayProp;
