import './RequestPassword.less';

import React, { useState } from 'react';
import withForm from '@thecodeisgreen/withform';
import { validations } from '@fasstech/spid-front';
const { emailIsValid } = validations;

import {
  Button,
  TextInput,
  FieldTitle
} from '../../uikit/UIKit';

import ResetPasswordRequestMutation from '../../graphql/mutations/ResetPasswordRequestMutation';
import useKeyPressEnter from '../../hooks/useKeyPressEnter';

const fieldProps = {
  defaultValue: '',
  styleOnNotValid: { borderColor: 'orange' }
};

const RequestPassword = ({
  form
}) => {
  const [request, setRequest] = useState({ sent: false, error: false });

  const onReset = () => {
    setRequest({ sent: false, error: false });
  };

  const onRequest = () => {
    setRequest({ sent: false, error: false });

    const { email } = form.values();

    if (emailIsValid(email)) {
      ResetPasswordRequestMutation({ email }, (ok, error) => {
        if (error) return setRequest({ sent: true, error });
        setRequest({ sent: true, error: false });
      });
    }
  };

  const buttonDisabled = !form.isValid();
  useKeyPressEnter(request.sent ? onReset : onRequest, !buttonDisabled);

  return <div className="connection-box">
    <FieldTitle text="Changer de mot de passe"/>
    <div style={{ display: request.sent ? 'none' : 'block' }}>
      {
        form.manageField('email', {
          isValid: v => emailIsValid(v),
          ...fieldProps
        })(
          <TextInput
            title="Email"
            type="email"
            placeholder="Votre email"
          />
        )
      }
    </div>
    {request.sent && request.error && (
      <div>
        {'Votre demande n\'a pas pu aboutir. Veuillez réessayer.'}
        <Button
          style={{ marginTop: '16px' }}
          onClick={onReset}
        >
          {'Réessayer'}
        </Button>
      </div>
    )}
    {request.sent && !request.error && (
      <div className="">
        {'Si un compte existe pour cette adresse, vous recevrez un email de réinitialisation.'}
        <Button
          style={{ marginTop: '16px' }}
          onClick={onReset}
        >
          {'Je n\'ai pas reçu le mail'}
        </Button>
      </div>
    )}
    {!request.sent && (
      <div className="sign-in-button-row">
        <Button
          onClick={onRequest}
          disabled={buttonDisabled}
        >
          {'Envoyer'}
        </Button>
      </div>
    )}
  </div>;
};

export default withForm(RequestPassword);
