import './EmployeesStep.less';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import {
  propositionSetCompanyInformation,
  propositionGoPreviousStep,
  propositionGoNextStep
} from '../../../reduxActions/proposition';
import Checkbox from 'rc-checkbox';
import { FieldTitle, TextInput } from '../../../uikit/UIKit';
import { BtnNext } from '../components';
import Select from 'react-select';
import { datas, validations } from '@fasstech/spid-front';
import useAboutYouStep from './aboutYouStep/useAboutYouStep';
import EscAdmin from './aboutYouStep/EscAdmin';
import Loading from '../../../components/loading';

const styleOnNotValid = { borderColor: 'orange' };

const AboutYouStep = ({
  companyInformation,
  goPreviousStep,
  customerDetailsOptional,
  goNextStep,
  propositionId,
  setCompanyInformation,
  duplicata
}) => {
  const {
    onNext,
    selectedRole,
    escAdminSelectedRole,
    values,
    setValues,
    propValidations,
    displayStar,
    valuesAreValid,
    escAdminPropValidations,
    escBirthCountryIsFrance,
    resetEmailUnicityCheck,
    displayEmailUnicityError,
    emailUnicityCheckLoading,
    loading
  } = useAboutYouStep({
    companyInformation,
    goNextStep,
    propositionId,
    setCompanyInformation,
    customerDetailsOptional,
    duplicata
  });

  return <>
    <FieldTitle
      text="Représentant de l’entreprise"
      onBack={goPreviousStep}
    />

    <div className="field field-text-input">
      <span>Enseigne commerciale</span>

      <TextInput
        placeholder="Enseigne commerciale"
        onChange={e => setValues(R.assoc('commercialName', e.target.value))}
        value={R.propOr('', 'commercialName', values)}
      />
    </div>

    <div className="field field-text-input">
      <span>{`Nom${displayStar}`}</span>

      <TextInput
        placeholder="Nom"
        onChange={e => setValues(R.assoc('contactLastname', e.target.value))}
        style={propValidations.contactLastname(values.contactLastname) ? {} : styleOnNotValid}
        value={R.propOr('', 'contactLastname', values)}
      />
    </div>

    <div className="field field-text-input">
      <span>{`Prénom${displayStar}`}</span>

      <TextInput
        placeholder="Prénom"
        onChange={e => setValues(R.assoc('contactFirstname', e.target.value))}
        style={propValidations.contactFirstname(values.contactFirstname) ? {} : styleOnNotValid}
        value={R.propOr('', 'contactFirstname', values)}
      />
    </div>

    <div className="field field-text-input">
      <span>{`Statut${displayStar}`}</span>

      <Select
        value={selectedRole}
        placeholder={'Sélectionner mon statut...'}
        options={datas.roles}
        onChange={v => setValues(R.compose(
          R.assoc('contactRole', v.label),
          R.assoc('contactRoleCode', v.value),
        ))}
        valueKey="label"
        style={propValidations.contactRole(values.contactRoleCode) ? {} : styleOnNotValid}
        clearable={false}
        arrowRenderer={null}
      />
    </div>

    <div className="field field-text-input"><span>Email pour signature*</span>
      <TextInput
        placeholder="Email client ou votre email pour impression papier"
        type="email"
        value={R.propOr('', 'contactEmail', values)}
        style={propValidations.contactEmail(values.contactEmail) ? {} : styleOnNotValid}
        onChange={e => setValues(R.assoc('contactEmail', e.target.value))}
      />
    </div>

    <div className="field field-text-input">
      <span>Numéro de portable</span>
      <TextInput
        placeholder="06 xx xx xx xx"
        type="tel"
        pattern="^[0][6-7][0-9]{8}$"
        value={R.propOr('', 'contactMobile', values)}
        onChange={e => setValues(R.assoc('contactMobile', e.target.value))}
        style={propValidations.contactMobile(values.contactMobile) ? {} : styleOnNotValid}
      />
    </div>

    {!R.isEmpty(values.contactMobile) && R.length(values.contactMobile) === 10 && !validations.phoneNumberIsValid(values.contactMobile) &&
    <span style={{ color: 'orangered', fontSize: '0.8em', marginLeft: '250px' }}>Merci de renseigner un numéro de portable.</span>
    }

    <div className="field field-checkbox" style={{ marginTop: '30px' }}>
      <Checkbox
        checked={R.propOr(false, 'sendToAccountant', values)}
        onChange={e => setValues(R.assoc('sendToAccountant', e.target.checked))}
      />
      <span>Envoyer par mail un double au cabinet comptable</span>
    </div>

    <div className="field field-text-input">
      <span>Email de mon cabinet comptable</span>

      <TextInput
        placeholder="Ex : cabinet-comptable@admin.com"
        type="email"
        value={R.propOr('', 'accountantEmail', values)}
        onChange={e => setValues(R.assoc('accountantEmail', e.target.value))}
        style={propValidations.accountantEmail(values.accountantEmail) ? {} : styleOnNotValid}
      />
    </div>

    <div className="field field-checkbox" style={{ marginTop: '30px' }}>
      <Checkbox
        checked={R.pathOr(false, ['fixedAddress', 'isChecked'], values)}
        onChange={e => setValues(R.assocPath(['fixedAddress', 'isChecked'], e.target.checked))}
      />
      <span>Renseigner une adresse postale</span>
    </div>

    <div style={{ display: R.pathOr(false, ['fixedAddress', 'isChecked'], values) ? 'block' : 'none' }}>
      <div className="field field-text-input"><span>Numéro et voie</span>
        <TextInput
          placeholder="55 Rue du Faubourg Saint-Honoré"
          value={R.pathOr('', ['fixedAddress', 'fields', 0], values)}
          onChange={e => setValues(R.assocPath(['fixedAddress', 'fields'], [e.target.value]))}
          style={propValidations.fixedAddress(R.path(['fixedAddress', 'fields', 0], values), 'fields') ? {} : styleOnNotValid}
        />
      </div>

      <div className="field field-text-input"><span>Code postal</span>
        <TextInput
          placeholder="75000"
          type="number"
          value={R.path(['fixedAddress', 'postCode'], values)}
          onChange={e => setValues(R.assocPath(['fixedAddress', 'postCode'], e.target.value))}
          style={propValidations.fixedAddress(R.path(['fixedAddress', 'postCode'], values), 'postCode') ? {} : styleOnNotValid}
        />
      </div>

      <div className="field field-text-input"><span>Ville</span>
        <TextInput
          placeholder="Paris"
          value={R.pathOr('', ['fixedAddress', 'city'], values)}
          onChange={e => setValues(R.assocPath(['fixedAddress', 'city'], e.target.value))}
          style={propValidations.fixedAddress(R.path(['fixedAddress', 'city'], values), 'city') ? {} : styleOnNotValid}
        />
      </div>
    </div>

    {!duplicata && <EscAdmin
      values={values}
      setValues={setValues}
      escAdminPropValidations={escAdminPropValidations}
      escAdminSelectedRole={escAdminSelectedRole}
      escBirthCountryIsFrance={escBirthCountryIsFrance}
      resetEmailUnicityCheck={resetEmailUnicityCheck}
      displayEmailUnicityError={displayEmailUnicityError}
    />}

    <div style={{ fontSize: '0.8em' }}>
      * les informations sont obligatoires
    </div>

    {emailUnicityCheckLoading && <Loading/>}

    <BtnNext
      disabled={!valuesAreValid}
      loading={loading}
      onClick={onNext}
      title={'Suivant'}
      style={{ marginTop: '30px', marginBottom: '20px' }}
    />
  </>;
};

export default connect(
  ({ proposition }) => ({
    companyInformation: R.path(['data', 'companyInformation'], proposition),
    propositionId: R.path(['data', 'propositionId'], proposition),
    customerDetailsOptional: R.pathOr(false, ['data', 'ccn', 'customerDetailsOptional'])(proposition),
    duplicata: R.pathOr(false, ['data', 'duplicata'])(proposition)
  }),
  dispatch => ({
    setCompanyInformation: (value) => dispatch(propositionSetCompanyInformation(value)),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    goNextStep: () => dispatch(propositionGoNextStep())
  })
)(AboutYouStep);
