import * as R from 'ramda';
import './Button.less';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Icon } from 'antd';
import { Link } from 'react-router-dom';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const Button = ({
  children,
  disabled,
  fetching,
  look,
  onClick,
  size,
  selected,
  style,
  title,
  to,
  type
}) => {
  const renderFetching = () => {
    if (fetching) return <Spin indicator={antIcon} style={{ marginLeft: 8 }}/>; else return null;
  };

  const component = R.isNil(to) ? 'button' : Link;

  return React.createElement(
    component,
    {
      to,
      style,
      className: classNames(
        'uikit-btn',
        {
          'uikit-btn-disabled': disabled,
          'uikit-btn-default-selected': look === 'default' && selected,
          'uikit-btn-white-selected': look === 'white' && selected,
          'uikit-btn-default': look === 'default',
          'uikit-btn-white': look === 'white',
          'uikit-btn-small': size === 'small',
          'uikit-btn-medium': size === 'medium'
        }
      ),
      type,
      title,
      onClick: () => { onClick ? onClick() : ''; }
    },
    <div>
      {children}
      {renderFetching()}
    </div>
  );
};
Button.defaultPrpos = {
  className: '',
  fetching: false,
  type: null,
  to: null,
  size: 'large',
  disabled: false,
  selected: false,
  look: 'default',
  title: null
};

Button.propTypes = {
  className: PropTypes.string,
  fetching: PropTypes.bool,
  type: PropTypes.string,
  style: PropTypes.object,
  look: PropTypes.oneOf(['default', 'white']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  to: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string
};

export default Button;
