import * as R from 'ramda';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import React from 'react';
import { Button } from '../../../../../uikit/UIKit';

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: '8px 16px 3px 16px',
  margin: '0 0 7px 0',
  background: isDragging ? '#c5e6b4b5' : '#cedae0',
  border: isDragging ? '1px inset #808080b5' : '1px outset grey',
  borderRadius: '5px',
  fontSize: '0.9em',
  //minHeight: '130px',
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  padding: '16px',
  width: '320px',
  display: 'block',
  minHeight: '300px',
  margin: '10px',
  borderRadius: '5px',
  border: isDraggingOver ? '1px solid' : '1px dashed'
});

const hasHeadOffice = (establishments) => R.reduce((hasHeadOffice, establishment) => hasHeadOffice || establishment.isHeadOffice, false)(establishments);

const EstablishmentDroppable = (
  ({
    actionLabel,
    droppableId,
    establishments,
    onAction,
    textColor,
    title,
    disabled
  }) => {

    const displayInformation = (index, isHeadOffice) => droppableId === 'selectedEstablishments' && (hasHeadOffice(establishments) ? isHeadOffice : index === 0);

    return <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}>
          <h4 style={{ unselectable: 'on', margin: '5px 0 5px 0', textAlign: 'center' }}>{title}</h4>
          {R.addIndex(R.map)((establishment, index) => (
            <Draggable style={{ marginTop: '40px', padding: '2px' }} key={establishment.siret} index={index} draggableId={establishment.siret} isDragDisabled={disabled}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{ ...getItemStyle(snapshot.isDragging, provided.draggableProps.style), color: textColor }}
                >
                  <span style={{ fontWeight: 'bold' }}>SIRET : <span style={{ color: textColor === 'grey' ? textColor : '#bf0909' }}>{establishment.siret}</span></span><br/>
                  <span><b>Nom : </b>{establishment.name}</span><br/>
                  <span><b>Adresse : </b>{establishment.address.fields[0]} {establishment.address.postCode} {establishment.address.city}</span><br/>
                  <span><b>Code APE : </b>{establishment.ape}</span><br/><br/>

                  { establishment.isHeadOffice && <span style={{ fontWeight: 'bold' }}>{'Siège social'}</span> }

                  <Button size="small" onClick={() => onAction(droppableId, index)} style={{ margin: '-10px 0 5px 60%' }} disabled={disabled}>{actionLabel}</Button>

                  { displayInformation(index, establishment.isHeadOffice) && <span style={{ color: 'darkred' }}>Cet établissement sera utilisé pour la génération des documents.<br/></span> }
                  { establishment.manual && <span style={{ fontSize: '0.6em' }}>{ 'Établissement issu d\'une saisie manuelle' }</span>}
                </div>)}
            </Draggable>
          ))(establishments)}
          {provided.placeholder}
        </div>)}
    </Droppable>;

  }
);

export default EstablishmentDroppable;
