import './GaugeInput.less';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import GaugeInputContainer from './GaugeInputContainer';

import {
  compose, 
  defaultProps,
  setPropTypes,
  withHandlers } from 'recompose';

const enhance = compose(
  setPropTypes({
    type: PropTypes.oneOf(['from', 'until']),
    look: PropTypes.oneOf(['green', 'gray']),
    value: PropTypes.number,
    xMin: PropTypes.number,
    xMax: PropTypes.number,
    onChange: PropTypes.func
  }),
  defaultProps({
    look: 'gray',
    type: 'from',
    xMin: 0,
    xMax: 100
  }),
  withHandlers({
    decValue: ({ onChange, value, xMin }) => () => onChange(value > xMin ? value - 1 : value),
    incValue: ({ onChange, value, xMax }) => () => onChange(value < xMax ? value + 1 : value)
  }),
  
);

const getClassName = (look, type) => classNames(
  'uikit-gauge-input',
  {
    'uikit-gauge-input-green': look === 'green',
    'uikit-gauge-input-gray': look === 'gray',
    'uikit-gauge-input-from': type === 'from',
    'uikit-gauge-input-until': type === 'until',
  }
);

const GaugeInput = enhance(
  ({ 
    type, 
    look,
    value,
    incValue,
    decValue,
    xMin,
    xMax
  }) => (
    <div className={getClassName(look, type)}>
      <button onClick={decValue}>-</button>
      <GaugeInputContainer value={value} type={type} xMin={xMin} xMax={xMax}/>
      <button onClick={incValue}>+</button>
    </div>
  )
);

export default GaugeInput;