import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation DocumentsConvertionMutation($propositionId: String!, $offerType: OfferTypeEnum!) {
  documentsConvertion(propositionId: $propositionId, offerType: $offerType) { 
    ok
    token
  }
}
`;

export default ({ propositionId, offerType }, done) => {
  const variables = {
    propositionId,
    offerType
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, token, error } = response.documentsConvertion;
        done(ok, token, error);
      },
      onError: err => console.error(err),
    }
  );
};
