/**
 * @flow
 * @relayHash 79363062294096c7219eb93a6f2e324c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EstablishmentsQueryVariables = {|
  search: string,
  department?: ?string,
|};
export type EstablishmentsQueryResponse = {|
  +searchEstablishment: ?{|
    +establishments: ?$ReadOnlyArray<?{|
      +name: ?string,
      +siren: ?string,
      +siret: ?string,
      +ape: ?string,
      +legalForm: ?string,
      +activity: ?string,
      +idccsFromDsn: ?$ReadOnlyArray<?string>,
      +address: ?{|
        +fields: ?$ReadOnlyArray<?string>,
        +city: ?string,
        +postCode: ?string,
      |},
    |}>
  |}
|};
export type EstablishmentsQuery = {|
  variables: EstablishmentsQueryVariables,
  response: EstablishmentsQueryResponse,
|};
*/


/*
query EstablishmentsQuery(
  $search: String!
  $department: String
) {
  searchEstablishment(search: $search, department: $department) {
    establishments {
      name
      siren
      siret
      ape
      legalForm
      activity
      idccsFromDsn
      address {
        fields
        city
        postCode
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "department",
    "type": "String"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "department",
        "variableName": "department"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "SirenInfo",
    "kind": "LinkedField",
    "name": "searchEstablishment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SiretInfo",
        "kind": "LinkedField",
        "name": "establishments",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "siren",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "siret",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ape",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "legalForm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "idccsFromDsn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fields",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postCode",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EstablishmentsQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EstablishmentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "79363062294096c7219eb93a6f2e324c",
    "metadata": {},
    "name": "EstablishmentsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '166b8710e55e361c34978141e5b18926';

module.exports = node;
