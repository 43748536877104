/**
 * @flow
 * @relayHash cde8ec2dd51d3aa6be3ca93bf54e9722
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type GenerateContractNumbersMutationVariables = {|
  propositionId: string,
  offerType: OfferTypeEnum,
|};
export type GenerateContractNumbersMutationResponse = {|
  +generateContractNumbers: ?{|
    +ok: boolean,
    +error: ?string,
    +contractNumbers: ?$ReadOnlyArray<string>,
  |}
|};
export type GenerateContractNumbersMutation = {|
  variables: GenerateContractNumbersMutationVariables,
  response: GenerateContractNumbersMutationResponse,
|};
*/


/*
mutation GenerateContractNumbersMutation(
  $propositionId: ID!
  $offerType: OfferTypeEnum!
) {
  generateContractNumbers(propositionId: $propositionId, offerType: $offerType) {
    ok
    error
    contractNumbers
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propositionId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerType",
    "type": "OfferTypeEnum!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "offerType",
        "variableName": "offerType"
      },
      {
        "kind": "Variable",
        "name": "propositionId",
        "variableName": "propositionId"
      }
    ],
    "concreteType": "GenerateContractNumbersMutationResponse",
    "kind": "LinkedField",
    "name": "generateContractNumbers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contractNumbers",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateContractNumbersMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateContractNumbersMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "cde8ec2dd51d3aa6be3ca93bf54e9722",
    "metadata": {},
    "name": "GenerateContractNumbersMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd30b7c2e0d58eae4c9fad49f58adf9b1';

module.exports = node;
