const civilitiesEnum = {
  M: 'M',
  F: 'F'
};

const civilities = [
  { value: civilitiesEnum.M, label: 'M.' },
  { value: civilitiesEnum.F, label: 'Mme' }
];

export {
  civilities,
  civilitiesEnum
};
