const escAdminTypesEnum = {
  SIGNATAIRE: 'SIGNATAIRE',
  AUTRE: 'AUTRE',
  AUCUN: 'AUCUN'
};

const escAdminTypes = [
  { value: escAdminTypesEnum.SIGNATAIRE, label: 'Signataire du contrat' },
  { value: escAdminTypesEnum.AUTRE, label: 'Autre représentant interne de l\'entreprise' },
  { value: escAdminTypesEnum.AUCUN, label: 'Ne souhaite pas ouvrir automatiquement l\'espace client' }
];

export {
  escAdminTypes,
  escAdminTypesEnum
};
