import * as R from 'ramda';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../graphql/Environment';
import S from 'string';
import Select from 'react-select';
import './SelectCcn.less';
import withUser from '../../../withUser';
import { connect } from 'react-redux';
import Loading from '../../../components/loading';

const SelectCcnQuery = graphql`
  query SelectCcnQuery {
    ccns {
      id
      offerTypes
      idcc
      codeBrochure
      title
      shortTitle
      naf
      PREVOYANCE {
        colleges
        mandatoryColleges
        bundledCollege
        staffMustBeAboveZero
        atLeastOneStaffMustBeAboveZero
        collegesLabels {
          AM
          NC
          C
        }
        collegesOrder
        displayPopupForColleges {
          colleges
          message
        }
        maxNumberOfEmployees {
          NC
          C
          AM
        }
        maxNumberOfTotalEmployees
        mustHaveAtLeastOneOfIfBaseIsSelected {
          basesIds
          selectedBaseId
        }
        conflictingColleges {
          colleges
        }
        contractDescriptionStepMessage
        contractNumbersByCollege
        unavailableBefore
        unavailableAfter {
          date
          message
        }
      }
      SANTE {
        colleges
        mandatoryColleges
        bundledCollege
        staffMustBeAboveZero
        atLeastOneStaffMustBeAboveZero
        collegesLabels {
          AM
          NC
          C
        }
        collegesOrder
        meltedColleges {
          colleges
          targetCollege
        }
        conflictingColleges {
          colleges
        }
        contractNumbersByCollege
        displayPopupForColleges {
          colleges
          message
        }
        maxNumberOfEmployees {
          NC
          C
          AM
        }
        maxNumberOfTotalEmployees
        mustHaveAtLeastOneOfIfBaseIsSelected {
          basesIds
          selectedBaseId
        }
        garantiesRdgUrl
        cotisationsRdgUrl
        contractDescriptionStepMessage
        unavailableBefore
        unavailableAfter {
          date
          message
        }
        insurer
      }
      hasBrokerage
      visionServiceUrl
      customerDetailsOptional
      unavailableBefore
      unavailableAfter {
        date
        message
      }
      alert
      hasParitarism
      isPack
      disableAmendments
      withClassicNumberOfContracts
      forbiddenDepartments
      amendmentsSettings {
        canUnselectOrigin
        collegesMustHaveBase
        collegesMustHaveZeroEmployees
      }
      relatedContractsSettings {
        contractNumbersCheckDisabled
      }
      brokerageSettings {
        bundledCollege
        unavailableBefore
      }
      brokerageOnly
      tariffStructures
    }
  }
`;

const Label = ({ children }) => {
  return <span
    style={{
      fontWeight: 'bold',
      fontSize: '1.2em'
    }}
  >
    {children}
  </span>;
};

const searchWithSeparator = (valueToSearch, separator) => R.compose(
  R.reduce((acc, v) => {
    return acc || R.includes(valueToSearch)(v);
  }, false),
  R.split(separator)
);

const _SelectCcn = ({
  ccns,
  onChange,
  disabled,
  placeholder,
  style,
  value
}) => <Select
  options={ccns}
  labelKey="shortTitle"
  valueKey="id"
  placeholder={placeholder}
  onChange={onChange}
  value={value}
  disabled={disabled}
  simpleValue={false}
  style={style}
  noResultsText="Aucune CCN trouvée"
  filterOptions={(options, filterString) => {
    options = R.filter(option => {
      const getString = R.when(R.isNil, R.always(''));
      const codeBrochure = getString(R.prop('codeBrochure')(option));
      const idcc = getString(R.prop('idcc')(option));

      return (
        S(R.propOr('', 'title', option).toLowerCase()).latinise().contains(S(filterString.toLowerCase()).latinise().s) ||
        S(R.propOr('', 'shortTitle', option).toLowerCase()).latinise().contains(S(filterString.toLowerCase()).latinise().s) ||
        searchWithSeparator(filterString, '-')(codeBrochure) ||
        searchWithSeparator(filterString, '-')(idcc) ||
        R.reduce((value, code) => {
          return value || S(code).startsWith(filterString);
        }, false)(option.naf)
      );
    })(options);
    return R.map(option => ({
      ...option,
      title: `${option.codeBrochure} - ${option.idcc} - ${option.title}`
    }))(options);
  }}
/>;

const SelectCcn = ({
  onChange,
  disabled,
  isAmendment,
  isDuplicata,
  isRelatedContract,
  value,
  userRoleIs
}) => {
  const ccnFilter = userRoleIs('apporteur') ? R.propEq('hasBrokerage', true) : R.allPass([
    R.propEq('isPack', false),
    R.complement(R.propEq('brokerageOnly', true))
  ]);

  return <QueryRenderer
    environment={environment}
    query={SelectCcnQuery}
    render={({ error, props }) => {
      if (error) {
        throw new Error('300');
      } else if (!props) {
        return <Loading/>;
      }

      let { ccns } = props;

      if (R.isNil(ccns)) {
        throw new Error('300');
      }

      ccns = R.compose(
        R.sortBy(R.prop('shortTitle')),
        R.reject(R.propSatisfies(R.isEmpty, 'offerTypes'))
      )(ccns);

      return <>
        {!isAmendment && !isDuplicata && !isRelatedContract && !userRoleIs('apporteur') && <>
          <Label>Offres Prévoyance standards :</Label>

          <_SelectCcn
            ccns={R.filter(R.propEq('isPack', true))(ccns)}
            onChange={onChange} disabled={disabled}
            value={R.prop('isPack', value) ? value : null}
            placeholder="Nom de l'offre standard"
            style={{ marginBottom: '15px' }}
          />
        </>}

        <Label>Offres CCN :</Label>

        <_SelectCcn
          ccns={R.filter(ccnFilter)(ccns)}
          onChange={onChange}
          disabled={disabled}
          value={R.prop('isPack', value) ? null : value}
          placeholder="Nom de la CCN, code NAF, IDCC, brochure…"
        />
      </>;
    }}
  />;
};

export default connect(
  ({ proposition }) => ({
    isAmendment: R.pathOr(false, ['data', 'amendment', 'isAmendment'])(proposition),
    isDuplicata: R.pathOr(false, ['data', 'duplicata'])(proposition),
    isRelatedContract: R.pathOr(false, ['data', 'relatedContract', 'checked'])(proposition)
  }),
  null
)(withUser(SelectCcn));
