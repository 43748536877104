import React from 'react';

const SignedUpPending = () => {
  return <div>
    <p>{'Votre compte a été créé, votre responsable doit maintenant le valider.'}</p>
    <p>{'Une fois votre compte validé vous pourrez vous connecter via cet écran'} : <a onClick={() => window.location.reload()}>{'écran de connexion'}</a></p>
  </div>;
};

export default SignedUpPending;
