import * as R from 'ramda';
import React from 'react';
import Loading from '../../uikit/misc/Loading';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../graphql/Environment';
import MenuLayout from '../../layout/menu/Layout';
import Error from '../../components/Error';
import { validations } from '@fasstech/spid-front';
import AmendmentHealth from './amendment/AmendmentHealth';
import AmendmentProtection from './amendment/AmendmentProtection';

const AmendmentQuery = graphql`
  query AmendmentQuery($contractNumber: String, $status: [String]) {
    propositionInfo(contractNumber: $contractNumber, status: $status) {
      id
      ccn {
        id
        shortTitle
        disableAmendments
        isPack
        SANTE {
          colleges
        }
      }
      ccnVersion
      postCode
      contractNumbers
      background {
        hasFormerContract
        atmtt {
          isSelected
          count
        }
      }
      brokerage {
        isSelected
      }
      startDate
      dates {
        creation
        lastUpdate
      }
      propositionNumber
      siren
      siret
      siretInfo {
        siret
        siren
        name
        legalForm
        address {
          city
          postCode
          fields
        }
      }
      status
      medicalCheck
      declarativeAnnexe
      outstandingAnnexe
      outstandingCheck
      companyInformation {
        contactFirstname
        contactLastname
        contactEmail
        contactMobile
        contactRole
      }
      staff {
        NC {
          isSelected
          count
        }
        AM {
          isSelected
          count
        }
        C {
          isSelected
          count
        }
      }
      contractDescription {
        NC {
          bases {
            baseId
            type
            options {
              optionId
            }
          }
        }
        AM {
          bases {
            baseId
            type
            options {
              optionId
            }
          }
        }
        C {
          bases {
            baseId
            type
            options {
              optionId
            }
          }
        }
      }
      health {
        contractNumbers
        tariffStructure
        staff {
          TOUS {
            isSelected
            count
          }
          NC {
            isSelected
            count
          }
          AM {
            isSelected
            count
          }
          C {
            isSelected
            count
          }
        }
        contractDescription {
          TOUS {
            bases {
              baseId
              type
              options {
                optionId
              }
            }
          }
          NC {
            bases {
              baseId
              type
              options {
                optionId
              }
            }
          }
          AM {
            bases {
              baseId
              type
              options {
                optionId
              }
            }
          }
          C {
            bases {
              baseId
              type
              options {
                optionId
              }
            }
          }
        }
      }
      duplicata
      relatedContract {
        checked
        oldLegalForm
        contractNumbers
      }
      user {
        email
        role
        extra {
          userCode
          region
          managementCenter
          lastname
          firstname
          sales
        }
      }
      overPricedRate
      regionTitle
      managementCenterName
      basesAndOptionsNamesC
      basesAndOptionsNamesNC
      basesAndOptionsNamesAM
    }
  }
`;

const Amendment = ({
  contractNumber
}) => {
  const contractNumberIsHealth = validations.contractNumberIsValid(contractNumber, 'SANTE');
  const contractNumberIsProtection = validations.contractNumberIsValid(contractNumber, 'PREVOYANCE');

  const contractNumberIsValid = contractNumberIsProtection || contractNumberIsHealth;

  if (!contractNumberIsValid) {
    return <MenuLayout>
      {'Le numéro saisi n\'est pas valide.'}
    </MenuLayout>;
  }

  const variables = {
    contractNumber,
    status: ['confirmedInManagementSystem']
  };

  return <MenuLayout>
    <QueryRenderer
      environment={environment}
      query={AmendmentQuery}
      variables={variables}
      render={({ error, props }) => {
        if (error) {
          return <Error/>;
        } else if (!props) {
          return <Loading/>;
        }

        const propositionInfo = R.pathOr({}, ['propositionInfo'])(props);

        if (contractNumberIsHealth) {
          return <AmendmentHealth
            contractNumber={contractNumber}
            propositionInfo={propositionInfo}
          />;
        }

        return <AmendmentProtection
          contractNumber={contractNumber}
          propositionInfo={propositionInfo}
        />;
      }}
    />
  </MenuLayout>;
};

export default Amendment;
