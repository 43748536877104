import React from 'react';

const SignedOut = () => {
  return <div className="mt-8">
    <div className="text-xl font-bold">Déconnexion avec succès</div>
    <div>Vous vous êtes déconnecté avec succès.</div>
  </div>;
};

export default SignedOut;
