import React from 'react';
import { Button } from '../../../uikit/UIKit';
import { Dropdown } from 'antd';
import HelpMenu from './HelpMenu';

const HelpButton = () => {
  return <Dropdown overlay={<HelpMenu/>} trigger={['click']}>
    <Button
      look="white"
      style={{
        width: '64px',
        position: 'absolute',
        right: '10px'
      }}
      title="Aide"
    >
      ?
    </Button>
  </Dropdown>;
};
  
export default HelpButton;