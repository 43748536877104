/**
 * @flow
 * @relayHash 524d9b91015a220d28a9534db14d8776
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CcnsQueryVariables = {||};
export type CcnsQueryResponse = {|
  +ernestCcns: ?$ReadOnlyArray<?{|
    +ape: ?$ReadOnlyArray<?string>,
    +idcc: ?string,
    +ccnRef: ?string,
    +title: ?string,
    +shortTitle: ?string,
  |}>
|};
export type CcnsQuery = {|
  variables: CcnsQueryVariables,
  response: CcnsQueryResponse,
|};
*/


/*
query CcnsQuery {
  ernestCcns {
    ape
    idcc
    ccnRef
    title
    shortTitle
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ErnestCcn",
    "kind": "LinkedField",
    "name": "ernestCcns",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ape",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "idcc",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ccnRef",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortTitle",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CcnsQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CcnsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "524d9b91015a220d28a9534db14d8776",
    "metadata": {},
    "name": "CcnsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd904eabd51048bd8eaa20d69589ca111';

module.exports = node;
