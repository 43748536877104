import React, { useState } from 'react';
import { Icon, Menu } from 'antd';
import HelpModal from './HelpModal';
import withUser from '../../../withUser';

const { Divider, Item, SubMenu } = Menu;

const fontSize = '16px';

const MenuItemContent = ({ icon, text }) => <React.Fragment>
  <Icon type={icon} style={{ fontSize }}/>
  <span style={{ fontSize, color: '#0000c1' }}>{text}</span>
</React.Fragment>;

const StyledItem = props => <Item
  {...props}
  style={{ fontSize }}
/>;

const HelpMenu = ({ userRoleIs }) => {
  const [modalText, setModalText] = useState();
  const [visible, setVisible] = useState(false);

  const openModal = modalText => () => {
    setModalText(modalText);
    setVisible(true);
  };

  const openFile = fileName => () => {
    window.open(`/help/file/${fileName}`, '_blank');
  };

  return <React.Fragment>
    <HelpModal
      text={modalText}
      openFile={openFile}
      visible={visible}
      setVisible={setVisible}
    />

    <Menu>
      {!userRoleIs('apporteur') &&
        <SubMenu title={
          <MenuItemContent
            icon="check"
            text="Pour commencer"
          />
        }>
          <SubMenu
            title="Je suis manager"
            style={{ fontSize }}
          >
            <StyledItem onClick={openModal('managerDirectionCommerciale')}>Direction commerciale</StyledItem>
            <StyledItem onClick={openModal('managerGroupeAdhesion')}>Groupe adhésion</StyledItem>
          </SubMenu>

          <StyledItem onClick={openModal('gestionnaire')}>Je suis gestionnaire</StyledItem>
          <StyledItem onClick={openModal('commercial')}>Je suis commercial(e) ou assistant(e)</StyledItem>
        </SubMenu>
      }

      {!userRoleIs('apporteur') && <Divider/>}

      <SubMenu title={
        <MenuItemContent
          icon="copy"
          text="Guides utilisateurs et fiches pratiques"
        />
      }>
        <StyledItem onClick={openFile(userRoleIs('apporteur') ? 'manuel_utilisateur_umanens.pdf' : 'manuel_utilisateur.pdf')}>Manuel utilisateur Santé et Prévoyance</StyledItem>
        {!userRoleIs('apporteur') && <StyledItem onClick={openFile('selection_medicale.pptx')}>Sélection médicale</StyledItem>}
        {!userRoleIs('apporteur') && <StyledItem onClick={openFile('validation_compte.pdf')}>{'Validation d\'un compte groupe adhésion'}</StyledItem>}
        {!userRoleIs('apporteur') && <StyledItem onClick={openFile('administrateur_espace_entreprise.pdf')}>{'Désignation de l\'Administrateur de l\'Espace Client'}</StyledItem>}
      </SubMenu>

      <Divider/>

      <Item onClick={openModal(userRoleIs('apporteur') ? 'problemeApporteur' : 'probleme')}>
        <MenuItemContent
          icon="tool"
          text="J'ai un problème"
        />
      </Item>

      {!userRoleIs('apporteur') && <Divider/>}

      {!userRoleIs('apporteur') && <Item onClick={openModal('FAQ')}>
        <MenuItemContent
          icon="message"
          text="Foire aux questions"
        />
      </Item>}
    </Menu>
  </React.Fragment>;
};

export default withUser(HelpMenu);
