import React from 'react';
import * as R from 'ramda';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../graphql/Environment';
import { Spin } from 'antd';
import { libs } from '@fasstech/spid-front';

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const CcnRecommandationsQuery = graphql`
  query CcnRecommandationsQuery($idcc: String!, $siret: String!) {
    ccnRecommandations(idcc: $idcc, siret: $siret) {
      offerName
      offerType
      idccs
      oav
      VAD
      excluded
    }
  }
`;

const Wrapper = ({ title, children }) => {
  return <div style={{ marginBottom: '20px' }}>
    <span style={{
      color: '#2c91f1',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      borderBottom: '4px solid #2c91f1',
      paddingBottom: '5px'
    }}>
      {title}
    </span>

    <br/>
    <br/>

    {children}

  </div>;
};

const CcnRecommandation = ({
  title,
  ccnRecommandation,
  ccn,
  establishmentIsAntillesGuyane = false
}) => {
  const offer = R.when(R.isNil, R.always(ccn.shortTitle))(R.prop('offerName', ccnRecommandation));
  const isExcluded = isNilOrEmpty(ccnRecommandation) ? false : R.propEq('excluded', true, ccnRecommandation);

  if (establishmentIsAntillesGuyane) {
    return <Wrapper title={title}>
      <div>
        L’entreprise est invitée à contacter la Mutuelle Interprofessionnelle Antilles Guyane : agence Océan Atlantique joignable de 7h30* à 17h30* par téléphone (Isabelle Forestier au 06 90 58 03 17) ou par mail (miag@ag2rlamondiale.fr).
        <br/>
        <span style={{ fontSize: '14px', fontStyle: 'italic' }}>* heure locale avec -5h ou -6h sur l’heure de la France continentale.</span>
      </div>
    </Wrapper>;
  }

  return <Wrapper title={title}>

    {isExcluded ? <span>L’entreprise doit contacter son courtier.</span>
      : (isNilOrEmpty(ccnRecommandation) || isNilOrEmpty(ccnRecommandation.oav)
        ? <span>Aucune offre AG2R LA MONDIALE pour cette CCN.</span>
        : <ul style={{ listStyleType: 'circle' }}>
          <li>{`Type d'offre : ${offer}`}</li>
          <li>{`OAV : ${ccnRecommandation.oav}`}</li>
          <li>{`VAD Compétente : ${ccnRecommandation.VAD}`}</li>
        </ul>
      )}

  </Wrapper>;
};

const CcnRecommandations = ({ ccn, establishment }) => {
  if (R.isNil(ccn)) {
    return;
  }

  const establishmentIsAntillesGuyane = R.compose(
    R.includes(R.__, ['971', '972', '973']),
    libs.getPostCode,
    R.pathOr('', ['address', 'postCode']),
    R.defaultTo({})
  )(establishment);

  return <QueryRenderer
    query={CcnRecommandationsQuery}
    environment={environment}
    variables={{
      idcc: ccn.idcc,
      siret: establishment.siret
    }}
    render={({ error, props }) => {
      if (error) {
        return <span>Une erreur est survenue.</span>;
      }

      if (!props) {
        return <Spin spinning/>;
      }

      const ccnRecommandations = R.propOr([], 'ccnRecommandations', props);

      const ccnPrev = R.find(R.propEq('offerType', 'prevoyance'))(ccnRecommandations);
      const ccnHealth = R.find(R.propEq('offerType', 'sante'))(ccnRecommandations);

      return <div style={{
        marginTop: '30px',
        paddingLeft: '35px',
        borderLeft: '3px solid #2c91f1',
        fontSize: '16px'
      }}>
        <CcnRecommandation
          title="Prévoyance"
          ccnRecommandation={ccnPrev}
          ccn={ccn}
        />

        <CcnRecommandation
          title="Santé"
          ccnRecommandation={ccnHealth}
          ccn={ccn}
          establishmentIsAntillesGuyane={establishmentIsAntillesGuyane}
        />
      </div>;
    }}
  />;
};

export default CcnRecommandations;
