import React from 'react';

const MedicalCheck = () => {
  return <div style={{ fontSize: '1.05em' }}>
    <span style={{ color: 'red' }}>{'Le questionnaire médical doit être envoyé à l\'entreprise à partir de l\'application E-Sélection Médicale.'}</span>
    <br/>
    Vous devez vous connecter à <b>E-Sélection Médicale</b> pour créer le dossier de {'l\'entreprise.'}
  </div>;
};

export default MedicalCheck;
