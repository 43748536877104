import * as R from 'ramda';
import { graphql } from 'react-relay';
import { fetchQuery } from 'relay-runtime';
import environment from '../graphql/Environment';

const QCheckCommercialCode = graphql`
 query useCheckCommercialCodeQuery($code: String!) {
   commercialCode(code: $code) {
     email
     code
   }
 }
`;

const useCheckCommercialCode = () => {
  return ({ code }, callback) => {
    try {
      code = R.when(R.is(Number), R.toString, code);

      fetchQuery(environment, QCheckCommercialCode, { code }).then(data => {
        if (!R.isNil(callback) && R.is(Function, callback)) {
          const { commercialCode } = data;
          callback(!R.isNil(commercialCode));
        }
      });
    } catch (error) {
      console.error(error);
      callback(false);
    }
  };
};

export default useCheckCommercialCode;
