import './Top.less';
import React from 'react';
import { Link } from 'react-router-dom';
import HelpButton from '../menu/help/HelpButton';
import Logo from '../components/Logo';

const Top = () => (
  <div className="layout-connection-top">
    <div className="layout-connection-top-wrapper">
      <div>
        <div>
          <Link to="/"><Logo className="layout-connection-top-logo"/></Link>
        </div>
      </div>
    </div>

    <HelpButton/>
  </div>
);

export default Top;
