import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation DeclarativeAnnexeOrOutstandingCheckMutation($propositionId: String!) {
  declarativeAnnexeOrOutstandingCheck(propositionId: $propositionId) { 
    ok
    error
    outstandingAnnexe
    declarativeAnnexe
  }
}
`;

export default (propositionId, done) => {
  const variables = {
    propositionId
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error, outstandingAnnexe, declarativeAnnexe } = response.declarativeAnnexeOrOutstandingCheck;
        done(ok, error, { outstandingAnnexe, declarativeAnnexe });
      },
      onError: err => console.error(err),
    }
  );
};
