import * as R from 'ramda';
import { graphql } from 'react-relay';
import { fetchQuery } from 'relay-runtime';
import environment from '../Environment';

const QGetAvailableOfferTypes = graphql`
  query getAvailableOfferTypesQuery($ccnId: String!, $siret: String!, $contractId: ID) {
    availableOfferTypes(ccnId: $ccnId, siret: $siret, contractId: $contractId)
  }
`;

const getAvailableOfferTypes = ({ ccnId, contractId, siret, isProtectionAmendment, isHealthAmendment, duplicata = false }, callback) => {
  if (isProtectionAmendment || duplicata) {
    return callback(['PREVOYANCE']);
  }

  if (isHealthAmendment) {
    return callback(['SANTE']);
  }

  fetchQuery(environment, QGetAvailableOfferTypes, { ccnId, contractId, siret }).then(data => {
    if (!R.isNil(callback) && R.is(Function, callback)) {
      callback(data.availableOfferTypes);
    }
  });
};

export default getAvailableOfferTypes;
