import { useState } from 'react';
import SavePropositionMutation from '../../../../graphql/mutations/SavePropositionMutation';
import { formatters, validations } from '@fasstech/spid-front';
import {
  assoc,
  pathOr,
  lensPath,
  length,
  set,
  either,
  isNil,
  isEmpty,
  reduce,
  equals,
  propOr,
  uniq,
  mergeLeft,
  compose,
  values,
  and,
  includes,
  when,
  complement,
  has,
  propSatisfies,
} from 'ramda';
import useKeyPressEnter from '../../../../hooks/useKeyPressEnter';
import { isSIREN, isSIRET } from 'siret';
import moment from 'moment';

const isNilOrEmpty = either(isNil, isEmpty);
const hasNot = complement(has);
const buildStyle = (error, additionalStyle = {}) => mergeLeft(
  { borderColor: error ? 'orange' : undefined },
  additionalStyle
);

const propDefaultTo = (prop, defaultValue) => when(
  either(
    hasNot(prop),
    propSatisfies(isNilOrEmpty, prop)
  ),
  assoc(prop, defaultValue)
);

const useManageRelatedContract = ({
  relatedContract,
  propositionId,
  setRelatedContract,
  goNextStep,
  numberOfContracts,
  ccn,
  startDate
}) => {
  const contractNumbersCheckIsDisabled = pathOr(false, ['relatedContractsSettings', 'contractNumbersCheckDisabled'], ccn);
  const [newRelatedContract, setNewRelatedContract] = useState(compose(
    propDefaultTo('hasKBIS', true),
    propDefaultTo('type', 'TOTAL'),
  )(relatedContract));

  const onSaveContract = () => {
    SavePropositionMutation(propositionId, { relatedContract: newRelatedContract }, (ok) => {
      if (ok) {
        setRelatedContract(newRelatedContract);
        goNextStep();
      }
    });
  };

  const onUpdateContractNumber = index => v => {
    setNewRelatedContract(set(
      lensPath(['contractNumbers', index]),
      formatters.formatContractNumber(0, v, 'SANTE')
    ));
  };

  const onUpdate = (k, v) => setNewRelatedContract(assoc(k, v));

  const getProp = (prop, or = '') => pathOr(or, prop.split('.'))(newRelatedContract);
  const propIsDefined = prop => !isNilOrEmpty(getProp(prop));

  const contractNumbersAreUniques = contractNumbersCheckIsDisabled || compose(
    equals(numberOfContracts),
    length,
    uniq,
    propOr([], 'contractNumbers')
  )(newRelatedContract);

  const getStartDate = () => moment(startDate);
  const startDateOptions = [
    { value: getStartDate().date(1).format('DD/MM/YYYY') },
    { value: getStartDate().date(1).add(1, 'month').format('DD/MM/YYYY') }
  ];

  const nicIsValid = isSIRET(`${getProp('oldSiren')}${getProp('oldNic')}`);

  const isDisabled = compose(
    includes(false),
    values
  )({
    oldLegalFormIsValid: propIsDefined('oldLegalForm'),
    sirenIsValid: and(
      propIsDefined('oldSiren'),
      isSIREN(getProp('oldSiren'))
    ),
    nicIsValid,
    contractNumbersAreUniques,
    contractNumbersLengthIsValid: length(getProp('contractNumbers', [])) === numberOfContracts,
    contractNumbersAreValid: !reduce((contractNumbersAreNotValid, contractNumber) => {
      return contractNumbersAreNotValid
          || isEmpty(contractNumber)
          || !validations.contractNumberIsValid(contractNumber, 'SANTE');
    }, false)(getProp('contractNumbers', [])),
    startDateIsValid: propIsDefined('startDate'),
    typeIsValid: propIsDefined('type'),
    hasKBIS: getProp('hasKBIS') === true
  });

  useKeyPressEnter(onSaveContract, !isDisabled);

  return {
    getProp,
    propIsDefined,
    onSaveContract,
    onUpdateContractNumber,
    isDisabled,
    onUpdate,
    buildStyle,
    startDateOptions,
    nicIsValid
  };
};

export default useManageRelatedContract;
