/**
 * @flow
 * @relayHash 88febe19ed208f3255dd5d59e1e9ad41
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResetPasswordRequestMutationVariables = {|
  email: string
|};
export type ResetPasswordRequestMutationResponse = {|
  +resetPasswordRequest: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type ResetPasswordRequestMutation = {|
  variables: ResetPasswordRequestMutationVariables,
  response: ResetPasswordRequestMutationResponse,
|};
*/


/*
mutation ResetPasswordRequestMutation(
  $email: String!
) {
  resetPasswordRequest(email: $email) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "BasicResponse",
    "kind": "LinkedField",
    "name": "resetPasswordRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPasswordRequestMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPasswordRequestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "88febe19ed208f3255dd5d59e1e9ad41",
    "metadata": {},
    "name": "ResetPasswordRequestMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '093d95a42c358016b2817a93b31a46cd';

module.exports = node;
