import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation IsMedicalCheckRequiredMutation($propositionId: String!, $ccnId: String!, $offerType: OfferTypeEnum!, $ccnVersion: Int, $staff: StaffFiguresInput, $brokerage: BrokerageInput, $contractDescription: ContractDescriptionInput, $postCode: String, $amendment: AmendmentInput, $duplicata: Boolean!, $tariffStructure: String) {
  isMedicalCheckRequired(propositionId: $propositionId, ccnId: $ccnId, offerType: $offerType, ccnVersion: $ccnVersion, staff: $staff, brokerage: $brokerage, contractDescription: $contractDescription, postCode: $postCode, amendment: $amendment, duplicata: $duplicata, tariffStructure: $tariffStructure) { 
    ok
    error
    medicalCheck
  }
}
`;

export default ({ propositionId, ccnId, offerType, ccnVersion, brokerage, contractDescription, staff, postCode, amendment, duplicata, tariffStructure }, done) => {
  const variables = {
    propositionId,
    ccnId,
    offerType,
    ccnVersion,
    brokerage,
    contractDescription,
    staff,
    postCode,
    amendment,
    duplicata,
    tariffStructure
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, medicalCheck, error } = response.isMedicalCheckRequired;
        done(ok, error, medicalCheck);
      },
      onError: err => console.error(err),
    }
  );
};
