import React from 'react';
import QAuthorizationUrl from '../../graphql/queries/QAuthorizationUrl';
import { isEmpty, isNil, pathOr } from 'ramda';
import { useLocation } from 'react-router-dom';
import AuthError from './AuthError';
import withSSO from '../../withSSO';

const Auth = ({ SSO_ENABLED }) => {
  const location = useLocation();

  if (!SSO_ENABLED) {
    return null;
  }

  return <QAuthorizationUrl args={{ redirectUrl: pathOr('/', ['state', 'pathname'], location) }}>
    {({ authorizationUrl }) => {
      if (isNil(authorizationUrl) || isEmpty(authorizationUrl)) {
        return <AuthError/>;
      }

      window.open(authorizationUrl, '_self');

      return null;
    }}
  </QAuthorizationUrl>;
};

export default withSSO(Auth);
