import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation SendEmailMutation($propositionId: String!, $emailId: EmailTypeEnum!) {
  sendEmail(propositionId: $propositionId, emailId: $emailId) { 
    ok
    error
  }
}
`;

export default ({ propositionId, emailId }, done) => {
  const variables = {
    propositionId,
    emailId
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.sendEmail;
        done(ok, error);
      },
      onError: err => {
        console.error(err);
        done(false, err);
      }
    }
  );
};
