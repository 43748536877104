/**
 * @flow
 * @relayHash 7d0bd576907e71ca3e05d3eedafb4e4a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenerateMedicalCheckLinkMutationVariables = {|
  propositionId: string
|};
export type GenerateMedicalCheckLinkMutationResponse = {|
  +generateMedicalCheckLink: ?{|
    +ok: boolean,
    +error: ?string,
    +link: ?string,
  |}
|};
export type GenerateMedicalCheckLinkMutation = {|
  variables: GenerateMedicalCheckLinkMutationVariables,
  response: GenerateMedicalCheckLinkMutationResponse,
|};
*/


/*
mutation GenerateMedicalCheckLinkMutation(
  $propositionId: ID!
) {
  generateMedicalCheckLink(propositionId: $propositionId) {
    ok
    error
    link
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propositionId",
    "type": "ID!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propositionId",
        "variableName": "propositionId"
      }
    ],
    "concreteType": "LinkMutationResponse",
    "kind": "LinkedField",
    "name": "generateMedicalCheckLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "link",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateMedicalCheckLinkMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateMedicalCheckLinkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7d0bd576907e71ca3e05d3eedafb4e4a",
    "metadata": {},
    "name": "GenerateMedicalCheckLinkMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '810480c89d0fb3ebd9c6f51642c2f0ac';

module.exports = node;
