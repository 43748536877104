import * as R from 'ramda';
import { graphql } from 'react-relay';
import { fetchQuery } from 'relay-runtime';
import environment from '../graphql/Environment';

const QCheckEmailUnicity = graphql`
 query useCheckEmailUnicityQuery($values: EscAdminInput!) {
   checkEmailUnicity(values: $values)
 }
`;

const useCheckEmailUnicity = () => {
  return (values, callback) => {
    try {
      fetchQuery(environment, QCheckEmailUnicity, { values }).then(data => {
        if (!R.isNil(callback) && R.is(Function, callback)) {
          callback(data.checkEmailUnicity);
        }
      });
    } catch (error) {
      console.error(error);
      callback(false);
    }
  };
};

export default useCheckEmailUnicity;
