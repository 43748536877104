import * as R from 'ramda';

const getNewIds = ({
  amendment = {},
  contractDescription
}) => {
  if (amendment.isAmendment) {
    const formerContractDescription = R.propOr({}, 'formerContractDescription', amendment);
    const getSelectedIds = R.compose(
      R.keys,
      R.filter(R.pathEq(['selected'], true)),
      R.reduce((acc, [id, base]) => {
        return R.mergeAll([
          acc,
          R.propOr({}, 'options', base),
          R.assoc(id, base, {})
        ]);
      }, {}),
      R.toPairs,
      R.dissoc('bases'),
      R.mergeAll,
      R.values
    );

    return R.difference(
      getSelectedIds(contractDescription),
      getSelectedIds(formerContractDescription)
    );
  }
    
  return [];
};

export default getNewIds;
