import * as R from 'ramda';
import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation SignOutMutation {
  signOut { 
    ok
    error
    user {
      id
      username
      email
    }
  }
}
`;

export default (done) => {
  const variables = {};

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, error } = response.signOut;
        if (!R.isNil(done)) done(ok, error);
      },
      onError: err => {
        console.error(err);
      }
    }
  );
};
