import * as R from 'ramda';
import { connect } from 'react-redux';
import React from 'react';
import { redux } from '@fasstech/spid-front';

import {
  compose,
  lifecycle
} from 'recompose';

import {
  Contracts
} from './components';

const enhance = compose(
  connect(
    state => ({
      value: state.contractDescription.value
    }),
    (dispatch, { colleges, amendment }) => ({
      updateBases: (bases) => dispatch(redux.actions.contractDescription.contractDescriptionSetBases(bases, colleges, R.propOr(false, 'isAmendment', amendment))),
      initValue: (value) => dispatch(redux.actions.contractDescription.contractDescriptionSetValue(value))
    })
  ),
  lifecycle({
    componentWillMount () {
      this.props.initValue(this.props.defaultValue);
      this.props.updateBases(this.props.bases);
      this.props.onChange(this.props.value);
    },
    componentDidUpdate (prevProps) {
      if (!R.equals(prevProps.value, this.props.value)) {
        this.props.onChange(this.props.value);
      }
    }
  })
);

const ContractDescriptionManager = enhance(
  ({
    value,
    colleges,
    amendment,
    stepAmendment,
    ccn
  }) => {
    return <>
      {colleges.map(college => {
        return <Contracts
          key={college}
          college={college}
          value={value[college]}
          amendment={amendment}
          stepAmendment={stepAmendment}
          ccn={ccn}
        />;
      })}
    </>;
  }
);

export default ContractDescriptionManager;
