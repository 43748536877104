import './HomeMessage.less';
import * as R from 'ramda';
import { graphql, QueryRenderer } from 'react-relay';
import React from 'react';
import environment from '../../graphql/Environment';
import withUser from '../../withUser';

const HomeMessageQuery = graphql`
query HomeMessageQuery {
  homemessage {
    message
    display
    length
  }
}`;

const HomeMessage = ({ user }) => {
  const isApporteur = R.propEq('role', 'apporteur', user);

  if (isApporteur) {
    return null;
  }

  return <QueryRenderer
    environment={environment}
    query={HomeMessageQuery}
    render={({ error, props }) => {
      if (error || !props) return null;

      const { homemessage } = props;
      if (R.isNil(homemessage)) return null;

      const { message, display } = homemessage;

      if (R.isNil(message) || R.isEmpty(message) || !display) return null;

      const length = R.pathOr(20, ['length'], homemessage);

      return <div className="defile-container">
        <span
          className="defile"
          style={{ animationDuration: `${length}s` }}
          data-text={message}
        >
          {message}
        </span>
      </div>;
    }}
  />;
};

export default withUser(HomeMessage);
