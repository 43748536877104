import React from 'react';
import { TextInput } from '../../../../../uikit';
import formatTurnover from '../../../../../lib/formatTurnover';

const TurnoverInput = ({
  turnover,
  updateTurnover
}) => {
  return <TextInput
    placeholder=""
    value={turnover}
    onChange={e => updateTurnover(formatTurnover(e.target.value))}
  />;
};

export default TurnoverInput;
