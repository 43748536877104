import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation DuplicateContractMutation($originContractId: String!) {
  duplicateContract(originContractId: $originContractId) { 
    ok
    error
    propositionId
  }
}
`;

export default (originContractId, done) => {
  const variables = {
    originContractId
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, propositionId, error } = response.duplicateContract;
        done(ok, error, propositionId);
      },
      onError: error => {
        console.error(error);
      },
    }
  );
};
