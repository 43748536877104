import { libs } from '@fasstech/spid-front';
import * as R from 'ramda';
import getNewIds from './getNewIds';

const checkContractNumberIsOld = ({
  contractNumbers,
  contractDescription,
  amendment = {},
  ccn,
  offerType,
  defaultContractNumbers,
  isHealthAmendment
}) => i => {
  const label = libs.getContractNumberLabel(i, R.length(contractNumbers), contractDescription, ccn.id, ccn, offerType);
  const { formerNumberOfContracts } = amendment;

  const newIds = getNewIds({ amendment, contractDescription });

  const disableByType = () => {
    let condition;
    if (R.includes('IDR', label))
      condition = R.includes('IDR');
    else if (R.includes('IDL', label))
      condition = R.includes('IDL');
    else if (R.includes('Option', label))
      condition = R.compose(
        R.propEq(3, 'S'),
        R.split(''),
        R.prop(1),
        R.split('_')
      );
    else if (R.includes('Classique', label))
      condition = R.compose(
        R.propEq(3, 'C'),
        R.split(''),
        R.prop(1),
        R.split('_')
      );
    else {
      return true;
    }

    return R.not(
      R.reduce((acc, id) => {
        return acc || condition(id);
      }, false)(newIds)
    );
  };

  return R.propOr(false, 'isAmendment')(amendment)
      && !R.isNil(formerNumberOfContracts)
      && disableByType()
      && !R.isEmpty(R.nth(i, defaultContractNumbers))
  && (!isHealthAmendment || !R.eqProps(i, contractNumbers, defaultContractNumbers))
  ;
};

export default checkContractNumberIsOld;
