import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation ConvertPropositionsCommercialesMutation($propositionId: String!) {
  convertPropositionsCommerciales(propositionId: $propositionId) { 
    ok
    token
  }
}
`;

export default (propositionId, done) => {
  const variables = {
    propositionId,
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, token } = response.convertPropositionsCommerciales;
        done(ok, token);
      },
      onError: err => console.error(err),
    }
  );
};
