import './Brokerage.less';

import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import withForm from '@thecodeisgreen/withform';
import { TextInput } from '../../../uikit/UIKit';
import Select from 'react-select';
import withUser from '../../../withUser';
import { connect } from 'react-redux';
import { redux } from '@fasstech/spid-front';
import { propositionSetContractDescription } from '../../../reduxActions/proposition';

const useBrokerage = ({
  value,
  onChange,
  user,
  resetContractDescription
}) => {
  const [displayInfo, setDisplayInfo] = useState(false);

  const providers = R.compose(
    R.map(provider => ({ provider })),
    R.pathOr([], ['extra', 'providers'])
  )(user);

  useEffect(() => {
    if (R.length(providers) === 1 && R.isNil(value.provider)) {
      onUpdateProvider(R.nth(0, providers));
      resetContractDescription();
    }
  }, [user, providers]);

  const onUpdateBrokerageId = (e) => {
    onChange(R.assoc('commercialCode', e.target.value, value));
  };

  const onUpdateProvider = v => {
    onChange(R.assoc('provider', v.provider, value));
    resetContractDescription();
  };

  return {
    displayInfo,
    setDisplayInfo,
    onUpdateProvider,
    onUpdateBrokerageId,
    providers
  };
};

const Brokerage = ({
  value,
  onChange,
  showBrokerage,
  onKeyPress,
  user,
  userSalesIs,
  resetContractDescription
}) => {
  const {
    displayInfo,
    setDisplayInfo,
    onUpdateBrokerageId,
    onUpdateProvider,
    providers
  } = useBrokerage({
    value,
    onChange,
    user,
    resetContractDescription
  });

  return <div>
    <div className="brokerage">

      {!showBrokerage && <div
        className="brokerage-code"
        onMouseEnter={() => setDisplayInfo(true)}
        onMouseLeave={() => setDisplayInfo(false)}
      >
        <span>Code conseiller&nbsp;:</span>
        <TextInput
          placeholder="code"
          type="text"
          style={{ width: '150px' }}
          value={R.propOr(null, 'commercialCode', value)}
          onChange={onUpdateBrokerageId}
          onKeyPress={onKeyPress}
          disabled={userSalesIs('VADC')}
        />
      </div>}

      {showBrokerage && <div
        className="brokerage-code"
      >
        <span
          style={{
            fontWeight: 'bold',
            fontSize: '18px'
          }}
        >
          Apporteur*
        </span>

        <Select
          options={providers}
          labelKey="provider"
          valueKey="provider"
          placeholder="Sélectionner un apporteur"
          onChange={onUpdateProvider}
          value={R.propOr(undefined, 'provider', value)}
          disabled={R.length(providers) === 1}
          simpleValue={false}
          clearable={false}
          noResultsText="Aucun apporteur trouvé"
          style={{
            width: '450px',
            marginLeft: '20px'
          }}
        />
      </div>}

    </div>

    <span style={{ display: displayInfo ? 'block' : 'none' }} className="brokerage-code-info">À renseigner uniquement par les assistantes entreprises qui travaillent pour plusieurs commerciaux</span>
  </div>;
};

export default connect(
  () => ({}),
  dispatch => ({
    resetContractDescription: () => {
      dispatch(redux.actions.contractDescription.contractDescriptionSetValue({}));
      dispatch(propositionSetContractDescription({}));
    }
  })
)(withForm(withUser(Brokerage)));
