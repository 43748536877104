import * as R from 'ramda';
import Tokens from '../lib/Tokens';
import assert from 'assert';

const {
  Environment,
  Network,
  RecordSource,
  Store,
} = require('relay-runtime');

const store = new Store(new RecordSource());

const sendRequest = async (url, headers, body, refresh_token = false) => {
  let access_token = await Tokens.getAccessToken(refresh_token);
  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      ...headers,
      'Authorization': `Bearer ${access_token}`
    },
    body
  });

  if (response.status === 200) {
    const json = await response.json();
    const { errors } = json;

    if (!R.isNil(errors) && !R.isEmpty(errors)) throw new Error(R.pathOr('error', [0, 'message'])(errors));

    return json;
  }

  if (response.status === 401) {
    assert(!refresh_token, 'can not refresh access token');
    return await sendRequest(url, headers, body, true);
  }

  return new Error('graphql request failed');
};

const network = Network.create(async (operation, variables) => {
  let body = new FormData();
  body = JSON.stringify({
    queryId: operation.id,
    variables,
  });
  let headers = {
    Accept: '*/*',
    'Content-Type': 'application/json'
  };

  return await sendRequest('/graphql', headers, body);
});

const uploadDocument = async (file) => {
  let formData = new FormData();
  formData.append('document', file);
  return await sendRequest('/document/upload', {}, formData);
};

const scanDocument = async (file) => {
  let formData = new FormData();
  formData.append('name', file.name);
  formData.append('file', file);
  return R.prop('ok', await sendRequest('/document/scan', {}, formData));
};

const environment = new Environment({
  network,
  store
});

export default environment;

export {
  uploadDocument,
  scanDocument
};
