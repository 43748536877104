/**
 * @flow
 * @relayHash 7bdb421d7a66da325f8a03b9ac61513a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DuplicateContractMutationVariables = {|
  originContractId: string
|};
export type DuplicateContractMutationResponse = {|
  +duplicateContract: ?{|
    +ok: boolean,
    +error: ?string,
    +propositionId: ?string,
  |}
|};
export type DuplicateContractMutation = {|
  variables: DuplicateContractMutationVariables,
  response: DuplicateContractMutationResponse,
|};
*/


/*
mutation DuplicateContractMutation(
  $originContractId: String!
) {
  duplicateContract(originContractId: $originContractId) {
    ok
    error
    propositionId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "originContractId",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "originContractId",
        "variableName": "originContractId"
      }
    ],
    "concreteType": "PropositionResponse",
    "kind": "LinkedField",
    "name": "duplicateContract",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "propositionId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DuplicateContractMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DuplicateContractMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7bdb421d7a66da325f8a03b9ac61513a",
    "metadata": {},
    "name": "DuplicateContractMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e041a1be9492c457e7deb0b7da37bc22';

module.exports = node;
