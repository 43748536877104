const R = require('ramda');
const { __, always, compose, concat, endsWith, ifElse, is, replace, toString, when } = R;

const numberToString = when(
  is(Number),
  toString
);

const formatTurnover = value => {
  const stringValue = compose(
    replace(',', '.'),
    numberToString
  )(value);

  const addFloatingSuffix = suffix => ifElse(
    isNaN,
    always(null),
    compose(
      when(
        always(endsWith(suffix, stringValue)),
        concat(__, suffix)
      ),
      numberToString
    )
  );

  const toFixed = compose(
    parseFloat,
    v => v.toFixed(2),
    parseFloat,
  );

  return compose(
    addFloatingSuffix('.0'),
    addFloatingSuffix('.'),
    toFixed,
    replace(/[^\d.]+/g, ''),
  )(stringValue);
};

export default formatTurnover;
