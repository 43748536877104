/**
 * @flow
 * @relayHash 52223df2e84924b8d9ee72b2050d9732
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelectCGQueryVariables = {||};
export type SelectCGQueryResponse = {|
  +data: ?{|
    +managementCenters: ?$ReadOnlyArray<?{|
      +name: ?string,
      +code: ?string,
    |}>
  |}
|};
export type SelectCGQuery = {|
  variables: SelectCGQueryVariables,
  response: SelectCGQueryResponse,
|};
*/


/*
query SelectCGQuery {
  data {
    managementCenters {
      name
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Data",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Center",
        "kind": "LinkedField",
        "name": "managementCenters",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectCGQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SelectCGQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "52223df2e84924b8d9ee72b2050d9732",
    "metadata": {},
    "name": "SelectCGQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3de70c9e7bddc92282160ce501e47195';

module.exports = node;
