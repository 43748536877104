const R = require('ramda');

const disableContractNumberInput = ({
  contractNumbersAutoGeneration,
  defaultContractNumbers,
  amendment,
  index
}) => {
  const isAmendment = R.propEq('isAmendment', true, amendment);

  if (!isAmendment || !contractNumbersAutoGeneration) {
    return false;
  }

  const defaultContractNumber = R.nth(index, defaultContractNumbers);

  return !R.isNil(defaultContractNumber) && !R.isEmpty(defaultContractNumber);
};

export default disableContractNumberInput;
