import environment from '../Environment';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import * as R from 'ramda';
import Error from '../../components/Error';

const CollegesWithMedicalCheckQuery = graphql`
  query QCollegesWithMedicalCheckQueryQuery($contractId: ID!) {
    collegesWithMedicalCheck(contractId: $contractId)
  }
`;

const QCollegesWithMedicalCheck = ({ children, args }) => {
  return <QueryRenderer
    environment={environment}
    query={CollegesWithMedicalCheckQuery}
    variables={args}
    render={({ error, props }) => {
      if (error) {
        return <Error/>;
      } else if (!props) {
        return null;
      }

      const collegesWithMedicalCheck = R.propOr([], 'collegesWithMedicalCheck', props);

      if (R.is(Function, children)) {
        return children({ collegesWithMedicalCheck });
      }
      return React.cloneElement(children, { collegesWithMedicalCheck });
    }}
  />;
};

export default QCollegesWithMedicalCheck;
