import './ContractOptions.less';
import * as R from 'ramda';
import React from 'react';
import ContractOption from './ContractOption';

const ContractOptions = ({
  onChange,
  options,
  value
}) => {
  if (R.length(options) === 0 || R.isNil(options)) {
    return null;
  }

  return (
    <ul className="contract-options">
      {options.map(option => <ContractOption
        key={option.id}
        option={option}
        value={R.pathOr(false, [option.id, 'selected'], value)}
        onChange={onChange}
      />
      )}
    </ul>
  );
};

export default ContractOptions;
