import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../graphql/Environment';
import * as R from 'ramda';
import { PropositionList } from '.';
import withUser from '../../../withUser';

const PropositionListPageQuery = graphql`
  query PropositionListPageQuery($count: Int!, $filter: PropositionFilterInput, $orderBy: PropositionOrderByInput) {
    propositions(count: $count, filter: $filter, orderBy: $orderBy) {
      id
      siren
      siret
      propositionNumber
      offerTypes
      dates {
        creation
        lastUpdate
      }
      status
      amendment {
        isAmendment
      }
      relatedContract {
        checked
      }
    }
  }
`;

const PropositionListPage = ({ siren, userRoleIs, user }) => {
  return <div style={{ width: '80%' }}>
    <QueryRenderer
      lookup
      environment={environment}
      query={PropositionListPageQuery}
      variables={{
        count: 50,
        orderBy: {
          lastUpdateDate: 'desc'
        },
        filter: {
          siren,
          username: userRoleIs('apporteur') ? R.prop('email', user) : undefined,
          status: ['active', 'unhold', 'ready', 'sentForSignature', 'sentForSignatureDelayed', 'duplicate', 'sentToManagementSystem', 'confirmed', 'confirmedInManagementSystem', 'confirmedInManagementSystemReopened', 'rejectedByManagementSystem', 'closed']
        }
      }}
      render={({ props }) => {
        if (props) {
          return <PropositionList propositions={props.propositions}/>;
        }

        return null;
      }}
    />
  </div>;
};

export default withUser(PropositionListPage);
