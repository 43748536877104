import React from 'react';
import Button from '../../uikit/buttons/Button';
import ErreurLayout from '../../layout/systemError/Layout';
import './SystemError.less';
import errorCode from './errorCodes';

const getErrorMessage = (errorId) => {
  const message = errorCode[errorId];
  return message !== undefined ? message : errorCode[0];
};

const SystemError = ({
  errorId
}) => (
  <ErreurLayout>
    <div className="erreur">
      <h2>Une erreur est survenue.</h2>
      <h5>Code d&#39;erreur [#{errorId}]</h5>
      <p className="erreurDesc">{getErrorMessage(errorId)}</p>
      <Button to="/">Retourner à l&#39;accueil</Button>
    </div>
  </ErreurLayout>
);

export default SystemError;