import './Checkbox.less';

import React from 'react';
import {
  compose,
  mapProps
} from 'recompose';

import Icon from './Icon';

const enhance = compose(
  mapProps(
    ({ title, checked }) => ({
      checked,
      title
    })
  )
);
const Checkbox = enhance(
  ({
    checked,
    title
  }) => (
    <div className="uikit-checkbox">
      <Icon
        name={checked ? 'checkbox-checked' : 'checkbox-not-checked'}
        style={{ marginRight: '8px' }}
      />
      <span style={{ paddingLeft: '8px' }}>{title}</span>
    </div>
  )
);

export default Checkbox;
