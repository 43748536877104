/**
 * @flow
 * @relayHash b97bcc9fe8ba3d8ec44f4446d67b14fe
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type useSearchCommercialCodesQueryVariables = {|
  email: string
|};
export type useSearchCommercialCodesQueryResponse = {|
  +commercialCodes: ?$ReadOnlyArray<?{|
    +email: ?string,
    +code: ?string,
  |}>
|};
export type useSearchCommercialCodesQuery = {|
  variables: useSearchCommercialCodesQueryVariables,
  response: useSearchCommercialCodesQueryResponse,
|};
*/


/*
query useSearchCommercialCodesQuery(
  $email: String!
) {
  commercialCodes(email: $email) {
    email
    code
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "CommercialCode",
    "kind": "LinkedField",
    "name": "commercialCodes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSearchCommercialCodesQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSearchCommercialCodesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "b97bcc9fe8ba3d8ec44f4446d67b14fe",
    "metadata": {},
    "name": "useSearchCommercialCodesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '973396312aa412e30bcc9c48aa47ad79';

module.exports = node;
