import './PropositionIsClosedStep.less';

import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  compose,
  withHandlers
} from 'recompose';

import {
  Icon,
  Button
} from '../../../uikit/UIKit';

const enhance = compose(
  withRouter,
  withHandlers({
    onReset: ({ history }) => () => {
      history.replace('/menu');
    }
  })
);
const PropositionIsClosedStep = enhance(
  ({ onReset }) => (
    <div className="send-step">
      <Icon name="icon-ok" height={60}/>
      <div className="send-step-title">
        {'La proposition est fermée.'}
      </div>
      <Button style={{ display: 'inline-block', marginTop: '20px' }} onClick={onReset}>Accueil</Button>
    </div>
  )
);

export default PropositionIsClosedStep;