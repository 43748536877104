import React from 'react';
import { isNil } from 'ramda';
import withUser from '../../../../withUser';

const VisionService = ({
  visionServiceUrl,
  userRoleIs
}) => {
  return <div className="contract-description-actions-right">
    {!isNil(visionServiceUrl) && !userRoleIs('apporteur') && (
      <div className="vision-services-logo">
        <a
          href={visionServiceUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/images/logo_vision_services.jpg"
            alt="logo_vision_services"
          />
        </a>
      </div>
    )}
  </div>;
};

export default withUser(VisionService);
