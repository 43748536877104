import * as R from 'ramda';
import React from 'react';
import S from 'string';
import { validations } from '@fasstech/spid-front';

import {
  compose,
  withState,
  withHandlers
} from 'recompose';

import TextInput from './TextInput';

const enhance = compose(
  withState(
    'siret',
    'setSiret',
    ''
  ),
  withState(
    'error',
    'setError',
    true
  ),
  withHandlers({
    onChange: ({ setError, setSiret, onChange }) => (e) => {
      e.preventDefault();
      let value = S(e.target.value).replaceAll(' ', '').s;
      if (R.isEmpty(value) || validations.isNumeric(value)) {
        setError(!validations.isSiret(value));
        let siret = R.splitEvery(3, value.slice(0, 9)).join(' ');
        const suffix = value.slice(9, 14);
        siret = siret + (!R.isEmpty(suffix) ? ' ' + suffix : '');
        setSiret(siret);
        onChange(validations.isSiret(value) ? value : null);
      } else {
        onChange(null);
      }
    }
  })
);

const SiretInput = enhance(
  ({
    style,
    siret,
    onChange,
    error
  }) => (
    <TextInput
      style={{ ...style, color: error ? 'red' : 'green' }}
      value={siret}
      onChange={onChange}
      placeholderIntlId="siret"
    />
  )
);

export default SiretInput;
