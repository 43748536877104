import React from 'react';

import {
  FieldTitle
} from '../../../uikit/UIKit';

const PutUnholdDoneStep = () => (
  <React.Fragment>
    <FieldTitle 
      text="La proposition a été mise en attente."
    />
  </React.Fragment>
);

export default PutUnholdDoneStep;