import React, { useState } from 'react';
import withForm from '@thecodeisgreen/withform';
import FieldTitle from '../../uikit/text/FieldTitle';
import TextInput from '../../uikit/input/TextInput';
import Button from '../../uikit/buttons/Button';
import { withRouter } from 'react-router-dom';
import ResetPasswordMutation from '../../graphql/mutations/ResetPasswordMutation';
import useKeyPressEnter from '../../hooks/useKeyPressEnter';
import { validations } from '@fasstech/spid-front';
import getQueryParams from '../../lib/getQueryParams';

const { passwordIsValid } = validations;

const fieldProps = {
  defaultValue: '',
  styleOnNotValid: { borderColor: 'orange' }
};

const ResetPassword = ({
  form,
  location
}) => {

  const [request, setRequest] = useState({ sent: false, error: false });

  const onSave = () => {
    setRequest({ sent: false, error: false });

    const { token } = getQueryParams(location);
    const { password } = form.values();

    if (passwordIsValid(password)) {
      ResetPasswordMutation({ token, password }, (ok, error) => {
        if (error) return setRequest({ sent: true, error: true });
        setRequest({ sent: true, error: false });
      });
    }
  };

  const buttonDisabled = !form.isValid();
  useKeyPressEnter(onSave, !buttonDisabled);

  return <div className="connection-box">
    <FieldTitle text="Nouveau mot de passe"/>
    <div style={{ display: request.sent ? 'none' : 'block' }}>
      {
        form.manageField('password', {
          isValid: v => passwordIsValid(v),
          ...fieldProps
        })(
          <TextInput
            title="Nouveau mot de passe"
            type="password"
            placeholder="Votre mot de passe"
          />
        )
      }
      <div
        className="password-hint">{'Votre mot de passe doit contenir au moins 8 caractères (dont au moins un de type lettre, de type chiffre, de type symbole). Il ne peut pas contenir votre prénom, nom ou adresse mail.'}</div>
    </div>
    {
      !request.sent && (
        <div className="sign-in-button-row">
          <Button
            onClick={onSave}
            disabled={buttonDisabled}
          >
            {'Enregistrer'}
          </Button>
        </div>
      )
    }
    {
      request.sent && !request.error && (
        <div>
          <span>{'Votre mot de passe a bien été enregistré'}</span>
          <br/>
          <Button
            style={{ marginTop: '16px' }}
            to="/account/signin"
          >
            {'Me connecter'}
          </Button>
        </div>
      )
    }
    {
      request.sent && request.error && (
        <div>
          <span>{'Votre mot de passe n\'a pas pu être mis à jour, veuillez réessayer'}</span>
          <br/>
          <Button
            style={{ marginTop: '16px' }}
            to="/account/resetpasswordrequest"
          >
            Changer mon mot de passe
          </Button>
        </div>
      )
    }

  </div>;
};

export default withForm(withRouter(ResetPassword));
