/**
 * @flow
 * @relayHash c4be25de160dc4a7c68b3518d235d97a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type CcnPackStepQueryVariables = {|
  ccnId: string,
  offerType: OfferTypeEnum,
  brokerage?: ?boolean,
  postCode: number,
  ccnVersion: number,
|};
export type CcnPackStepQueryResponse = {|
  +bases: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +college: ?string,
    +type: ?string,
    +group: ?string,
  |}>,
  +ccn: ?{|
    +id: ?string,
    +PREVOYANCE: ?{|
      +offers: ?$ReadOnlyArray<?{|
        +version: ?string,
        +offers: ?{|
          +TA: ?{|
            +_1: ?{|
              +offerId: ?string,
              +name: ?string,
              +incapacityOfWork: ?{|
                +amount: ?string,
                +franchise: ?string,
                +duration: ?string,
              |},
              +disability: ?{|
                +categ1: ?string,
                +categ2: ?string,
                +categ3: ?string,
              |},
              +deathBenefit: ?{|
                +doubleAccident: ?string,
                +aloneWithNoCharge: ?string,
                +marriedWithNoCharge: ?string,
                +aloneWithCharge: ?string,
                +marriedWithCharge: ?string,
                +increase: ?string,
              |},
              +reductedDeathBenefit: ?{|
                +reducted: ?string,
                +doubleAccident: ?string,
                +rate: ?string,
                +rent: ?string,
              |},
              +absoluteDisability: ?string,
              +doubleEffect: ?string,
              +TA: ?string,
              +TB: ?string,
            |},
            +_2: ?{|
              +offerId: ?string,
              +name: ?string,
              +incapacityOfWork: ?{|
                +amount: ?string,
                +franchise: ?string,
                +duration: ?string,
              |},
              +disability: ?{|
                +categ1: ?string,
                +categ2: ?string,
                +categ3: ?string,
              |},
              +deathBenefit: ?{|
                +doubleAccident: ?string,
                +aloneWithNoCharge: ?string,
                +marriedWithNoCharge: ?string,
                +aloneWithCharge: ?string,
                +marriedWithCharge: ?string,
                +increase: ?string,
              |},
              +reductedDeathBenefit: ?{|
                +reducted: ?string,
                +doubleAccident: ?string,
                +rate: ?string,
                +rent: ?string,
              |},
              +absoluteDisability: ?string,
              +doubleEffect: ?string,
              +TA: ?string,
              +TB: ?string,
            |},
            +_3: ?{|
              +offerId: ?string,
              +name: ?string,
              +incapacityOfWork: ?{|
                +amount: ?string,
                +franchise: ?string,
                +duration: ?string,
              |},
              +disability: ?{|
                +categ1: ?string,
                +categ2: ?string,
                +categ3: ?string,
              |},
              +deathBenefit: ?{|
                +doubleAccident: ?string,
                +aloneWithNoCharge: ?string,
                +marriedWithNoCharge: ?string,
                +aloneWithCharge: ?string,
                +marriedWithCharge: ?string,
                +increase: ?string,
              |},
              +reductedDeathBenefit: ?{|
                +reducted: ?string,
                +doubleAccident: ?string,
                +rate: ?string,
                +rent: ?string,
              |},
              +absoluteDisability: ?string,
              +doubleEffect: ?string,
              +TA: ?string,
              +TB: ?string,
            |},
            +_4: ?{|
              +offerId: ?string,
              +name: ?string,
              +incapacityOfWork: ?{|
                +amount: ?string,
                +franchise: ?string,
                +duration: ?string,
              |},
              +disability: ?{|
                +categ1: ?string,
                +categ2: ?string,
                +categ3: ?string,
              |},
              +deathBenefit: ?{|
                +doubleAccident: ?string,
                +aloneWithNoCharge: ?string,
                +marriedWithNoCharge: ?string,
                +aloneWithCharge: ?string,
                +marriedWithCharge: ?string,
                +increase: ?string,
              |},
              +reductedDeathBenefit: ?{|
                +reducted: ?string,
                +doubleAccident: ?string,
                +rate: ?string,
                +rent: ?string,
              |},
              +absoluteDisability: ?string,
              +doubleEffect: ?string,
              +TA: ?string,
              +TB: ?string,
            |},
          |},
          +TA_TB: ?{|
            +_1: ?{|
              +offerId: ?string,
              +name: ?string,
              +incapacityOfWork: ?{|
                +amount: ?string,
                +franchise: ?string,
                +duration: ?string,
              |},
              +disability: ?{|
                +categ1: ?string,
                +categ2: ?string,
                +categ3: ?string,
              |},
              +deathBenefit: ?{|
                +doubleAccident: ?string,
                +aloneWithNoCharge: ?string,
                +marriedWithNoCharge: ?string,
                +aloneWithCharge: ?string,
                +marriedWithCharge: ?string,
                +increase: ?string,
              |},
              +reductedDeathBenefit: ?{|
                +doubleAccident: ?string,
                +rate: ?string,
                +rent: ?string,
              |},
              +absoluteDisability: ?string,
              +doubleEffect: ?string,
              +TA: ?string,
              +TB: ?string,
            |},
            +_2: ?{|
              +offerId: ?string,
              +name: ?string,
              +incapacityOfWork: ?{|
                +amount: ?string,
                +franchise: ?string,
                +duration: ?string,
              |},
              +disability: ?{|
                +categ1: ?string,
                +categ2: ?string,
                +categ3: ?string,
              |},
              +deathBenefit: ?{|
                +doubleAccident: ?string,
                +aloneWithNoCharge: ?string,
                +marriedWithNoCharge: ?string,
                +aloneWithCharge: ?string,
                +marriedWithCharge: ?string,
                +increase: ?string,
              |},
              +reductedDeathBenefit: ?{|
                +doubleAccident: ?string,
                +rate: ?string,
                +rent: ?string,
              |},
              +absoluteDisability: ?string,
              +doubleEffect: ?string,
              +TA: ?string,
              +TB: ?string,
            |},
            +_3: ?{|
              +offerId: ?string,
              +name: ?string,
              +incapacityOfWork: ?{|
                +amount: ?string,
                +franchise: ?string,
                +duration: ?string,
              |},
              +disability: ?{|
                +categ1: ?string,
                +categ2: ?string,
                +categ3: ?string,
              |},
              +deathBenefit: ?{|
                +doubleAccident: ?string,
                +aloneWithNoCharge: ?string,
                +marriedWithNoCharge: ?string,
                +aloneWithCharge: ?string,
                +marriedWithCharge: ?string,
                +increase: ?string,
              |},
              +reductedDeathBenefit: ?{|
                +doubleAccident: ?string,
                +rate: ?string,
                +rent: ?string,
              |},
              +absoluteDisability: ?string,
              +doubleEffect: ?string,
              +TA: ?string,
              +TB: ?string,
            |},
            +_4: ?{|
              +offerId: ?string,
              +name: ?string,
              +incapacityOfWork: ?{|
                +amount: ?string,
                +franchise: ?string,
                +duration: ?string,
              |},
              +disability: ?{|
                +categ1: ?string,
                +categ2: ?string,
                +categ3: ?string,
              |},
              +deathBenefit: ?{|
                +doubleAccident: ?string,
                +aloneWithNoCharge: ?string,
                +marriedWithNoCharge: ?string,
                +aloneWithCharge: ?string,
                +marriedWithCharge: ?string,
                +increase: ?string,
              |},
              +reductedDeathBenefit: ?{|
                +doubleAccident: ?string,
                +rate: ?string,
                +rent: ?string,
              |},
              +absoluteDisability: ?string,
              +doubleEffect: ?string,
              +TA: ?string,
              +TB: ?string,
            |},
          |},
        |},
      |}>
    |},
  |},
|};
export type CcnPackStepQuery = {|
  variables: CcnPackStepQueryVariables,
  response: CcnPackStepQueryResponse,
|};
*/


/*
query CcnPackStepQuery(
  $ccnId: String!
  $offerType: OfferTypeEnum!
  $brokerage: Boolean
  $postCode: Int!
  $ccnVersion: Int!
) {
  bases(ccnId: $ccnId, offerType: $offerType, brokerage: $brokerage, postCode: $postCode, ccnVersion: $ccnVersion) {
    id
    name
    college
    type
    group
  }
  ccn(ccnId: $ccnId) {
    id
    PREVOYANCE {
      offers {
        version
        offers {
          TA {
            _1 {
              offerId: id
              name
              incapacityOfWork {
                amount
                franchise
                duration
              }
              disability {
                categ1
                categ2
                categ3
              }
              deathBenefit {
                doubleAccident
                aloneWithNoCharge
                marriedWithNoCharge
                aloneWithCharge
                marriedWithCharge
                increase
              }
              reductedDeathBenefit {
                reducted
                doubleAccident
                rate
                rent
              }
              absoluteDisability
              doubleEffect
              TA
              TB
            }
            _2 {
              offerId: id
              name
              incapacityOfWork {
                amount
                franchise
                duration
              }
              disability {
                categ1
                categ2
                categ3
              }
              deathBenefit {
                doubleAccident
                aloneWithNoCharge
                marriedWithNoCharge
                aloneWithCharge
                marriedWithCharge
                increase
              }
              reductedDeathBenefit {
                reducted
                doubleAccident
                rate
                rent
              }
              absoluteDisability
              doubleEffect
              TA
              TB
            }
            _3 {
              offerId: id
              name
              incapacityOfWork {
                amount
                franchise
                duration
              }
              disability {
                categ1
                categ2
                categ3
              }
              deathBenefit {
                doubleAccident
                aloneWithNoCharge
                marriedWithNoCharge
                aloneWithCharge
                marriedWithCharge
                increase
              }
              reductedDeathBenefit {
                reducted
                doubleAccident
                rate
                rent
              }
              absoluteDisability
              doubleEffect
              TA
              TB
            }
            _4 {
              offerId: id
              name
              incapacityOfWork {
                amount
                franchise
                duration
              }
              disability {
                categ1
                categ2
                categ3
              }
              deathBenefit {
                doubleAccident
                aloneWithNoCharge
                marriedWithNoCharge
                aloneWithCharge
                marriedWithCharge
                increase
              }
              reductedDeathBenefit {
                reducted
                doubleAccident
                rate
                rent
              }
              absoluteDisability
              doubleEffect
              TA
              TB
            }
          }
          TA_TB {
            _1 {
              offerId: id
              name
              incapacityOfWork {
                amount
                franchise
                duration
              }
              disability {
                categ1
                categ2
                categ3
              }
              deathBenefit {
                doubleAccident
                aloneWithNoCharge
                marriedWithNoCharge
                aloneWithCharge
                marriedWithCharge
                increase
              }
              reductedDeathBenefit {
                doubleAccident
                rate
                rent
              }
              absoluteDisability
              doubleEffect
              TA
              TB
            }
            _2 {
              offerId: id
              name
              incapacityOfWork {
                amount
                franchise
                duration
              }
              disability {
                categ1
                categ2
                categ3
              }
              deathBenefit {
                doubleAccident
                aloneWithNoCharge
                marriedWithNoCharge
                aloneWithCharge
                marriedWithCharge
                increase
              }
              reductedDeathBenefit {
                doubleAccident
                rate
                rent
              }
              absoluteDisability
              doubleEffect
              TA
              TB
            }
            _3 {
              offerId: id
              name
              incapacityOfWork {
                amount
                franchise
                duration
              }
              disability {
                categ1
                categ2
                categ3
              }
              deathBenefit {
                doubleAccident
                aloneWithNoCharge
                marriedWithNoCharge
                aloneWithCharge
                marriedWithCharge
                increase
              }
              reductedDeathBenefit {
                doubleAccident
                rate
                rent
              }
              absoluteDisability
              doubleEffect
              TA
              TB
            }
            _4 {
              offerId: id
              name
              incapacityOfWork {
                amount
                franchise
                duration
              }
              disability {
                categ1
                categ2
                categ3
              }
              deathBenefit {
                doubleAccident
                aloneWithNoCharge
                marriedWithNoCharge
                aloneWithCharge
                marriedWithCharge
                increase
              }
              reductedDeathBenefit {
                doubleAccident
                rate
                rent
              }
              absoluteDisability
              doubleEffect
              TA
              TB
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnId",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerType",
    "type": "OfferTypeEnum!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brokerage",
    "type": "Boolean"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "postCode",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnVersion",
    "type": "Int!"
  }
],
v1 = {
  "kind": "Variable",
  "name": "ccnId",
  "variableName": "ccnId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": "offerId",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "OfferIncapacityOfWork",
  "kind": "LinkedField",
  "name": "incapacityOfWork",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "franchise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "OfferDisability",
  "kind": "LinkedField",
  "name": "disability",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categ1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categ2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categ3",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "doubleAccident",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "OfferDeathBenefit",
  "kind": "LinkedField",
  "name": "deathBenefit",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aloneWithNoCharge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "marriedWithNoCharge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aloneWithCharge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "marriedWithCharge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "increase",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rent",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "absoluteDisability",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "doubleEffect",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TA",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TB",
  "storageKey": null
},
v15 = [
  (v4/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "OfferReductedDeathBenefit",
    "kind": "LinkedField",
    "name": "reductedDeathBenefit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reducted",
        "storageKey": null
      },
      (v7/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "storageKey": null
  },
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/)
],
v16 = [
  (v4/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "OfferReductedDeathBenefit",
    "kind": "LinkedField",
    "name": "reductedDeathBenefit",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "storageKey": null
  },
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/)
],
v17 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brokerage",
        "variableName": "brokerage"
      },
      (v1/*: any*/),
      {
        "kind": "Variable",
        "name": "ccnVersion",
        "variableName": "ccnVersion"
      },
      {
        "kind": "Variable",
        "name": "offerType",
        "variableName": "offerType"
      },
      {
        "kind": "Variable",
        "name": "postCode",
        "variableName": "postCode"
      }
    ],
    "concreteType": "Base",
    "kind": "LinkedField",
    "name": "bases",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "college",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "group",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v1/*: any*/)
    ],
    "concreteType": "Ccn",
    "kind": "LinkedField",
    "name": "ccn",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "OfferTypeInformations",
        "kind": "LinkedField",
        "name": "PREVOYANCE",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VersionOffers",
            "kind": "LinkedField",
            "name": "offers",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Offers",
                "kind": "LinkedField",
                "name": "offers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RateOffer",
                    "kind": "LinkedField",
                    "name": "TA",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Offer",
                        "kind": "LinkedField",
                        "name": "_1",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Offer",
                        "kind": "LinkedField",
                        "name": "_2",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Offer",
                        "kind": "LinkedField",
                        "name": "_3",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Offer",
                        "kind": "LinkedField",
                        "name": "_4",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RateOffer",
                    "kind": "LinkedField",
                    "name": "TA_TB",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Offer",
                        "kind": "LinkedField",
                        "name": "_1",
                        "plural": false,
                        "selections": (v16/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Offer",
                        "kind": "LinkedField",
                        "name": "_2",
                        "plural": false,
                        "selections": (v16/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Offer",
                        "kind": "LinkedField",
                        "name": "_3",
                        "plural": false,
                        "selections": (v16/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Offer",
                        "kind": "LinkedField",
                        "name": "_4",
                        "plural": false,
                        "selections": (v16/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CcnPackStepQuery",
    "selections": (v17/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CcnPackStepQuery",
    "selections": (v17/*: any*/)
  },
  "params": {
    "id": "c4be25de160dc4a7c68b3518d235d97a",
    "metadata": {},
    "name": "CcnPackStepQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '72ba59caa2156c60a9bfeed87730e0ed';

module.exports = node;
