import * as R from 'ramda';
import React, { useState } from 'react';

import { connect } from 'react-redux';

import {
  propositionSetBrokerage,
  propositionSetLocation,
  propositionSetStartDate,
  propositionSetAdhesionDate,
  propositionGoNextStep,
  propositionGoPreviousStep
} from '../../../reduxActions/proposition';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import withForm from '@thecodeisgreen/withform';
import moment from 'moment';

import { FieldTitle } from '../../../uikit/UIKit';
import { hooks, libs } from '@fasstech/spid-front';
const { calculateDays, getPostCode } = libs;
import { BtnNext, Brokerage, } from '../components/.';
import useKeyPressEnter from '../../../hooks/useKeyPressEnter';
import withUser from '../../../withUser';
import CommercialCode from './locationAndDateStep/CommercialCode';
import userMustHaveCommercialCode from '../../../lib/userMustHaveCommercialCode';
import useCheckCommercialCode from '../../../hooks/useCheckCommercialCode';
import { message } from 'antd';
import getAvailableOfferTypes from '../../../graphql/libs/getAvailableOfferTypes';
import getAmendmentInfos from '../../../lib/getAmendmentInfos';

const useAdhesionDate = ({ form, adhesionDate }) => {
  const adhesionDateIsAboveToday = () => {
    return !R.isNil(adhesionDate) ? calculateDays(moment(adhesionDate)) > 0 : true;
  };

  const contractHasRetroActivity = () => {
    const { startDate } = form.values();
    return (adhesionDate && startDate) ? moment(adhesionDate).diff(startDate, 'days') > 90 : false;
  };

  return {
    adhesionDateIsAboveToday,
    contractHasRetroActivity
  };
};

const ErrorDate = ({ children }) => <div
  style={{
    color: 'red',
    fontSize: '0.9em'
  }}
>
  {children}
</div>;

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const LocationAndDateStep = ({
  brokerage,
  ccn,
  companyPostCode,
  data,
  duplicata,
  form,
  goPreviousStep,
  goNextStep,
  amendment,
  isKnownAmendment,
  courseIsAmendment,
  isProtectionAmendment,
  isHealthAmendment,
  postCode,
  setBrokerage,
  setLocation,
  setStartDate,
  startDate,
  setAdhesionDate,
  adhesionDate,
  unavailableAfter,
  unavailableBefore,
  relatedContract,
  userRoleIs,
  brokerageUnavailableBefore,
  userSalesIs,
  user,
  protectionUnavailableBefore,
  healthUnavailableBefore,
  protectionUnavailableAfter,
  healthUnavailableAfter,
  siret,
  contractId
}) => {
  const v = (k) => R.path(k.split('.'))(form.values());
  const [localAdhesionDate, setLocalAdhesionDate] = useState(adhesionDate);
  const [departmentIsForbidden, setDepartmentIsForbidden] = useState(false);
  const [noOfferTypesAreAvailable, setNoOfferTypesAreAvailable] = useState(false);

  const forbiddenDepartments = R.propOr([], 'forbiddenDepartments', ccn);
  const _userMustHaveCommercialCode = userMustHaveCommercialCode(user);

  const selectedPostCode = v('postCode');
  const postCodeIsValid = !R.anyPass([
    R.isNil,
    R.isEmpty,
    postCode => R.compose(
      R.isNil,
      R.find(R.propEq('code', postCode)),
      R.propOr([], 'departments')
    )(data)
  ])(selectedPostCode);
  const onNextIsDisabled = !postCodeIsValid || R.isNil(v('startDate')) || (_userMustHaveCommercialCode && isNilOrEmpty(v('brokerage.commercialCode')));
  const isPack = R.propEq('isPack', true, ccn);
  const isRelatedContractChecked = R.propOr(false, 'checked', relatedContract);

  if (userSalesIs('VADC')) {
    brokerage = R.assoc('commercialCode', 'VADTPE', brokerage);
  }

  const {
    startDateIsAfterUnavailability,
    startDateIsBeforeUnavailability,
    startDateCompliesAmendment,
    startDateAboveOneYear,
    // startDateBelowOneYear,
    startDateCompliesProviderRole,
    startDateIsFirstDay,
    startDatesAreBeforeUnavailability,
    startDatesAreAfterUnavailability
  } = hooks.useStartDate({
    form,
    startDate,
    brokerageUnavailableBefore,
    unavailableBefore,
    unavailableAfter,
    amendment,
    duplicata,
    isRelatedContractChecked,
    userRoleIs,
    protectionUnavailableBefore,
    healthUnavailableBefore,
    protectionUnavailableAfter,
    healthUnavailableAfter
  });

  const {
    isBeforeUnavailability,
    beforeUnavailabilityDate,
  } = startDateIsBeforeUnavailability();

  const {
    beforeUnavailabilityDates
  } = startDatesAreBeforeUnavailability();

  const {
    afterUnavailabilityMessages
  } = startDatesAreAfterUnavailability();

  const { adhesionDateIsAboveToday, contractHasRetroActivity } = useAdhesionDate({ form, adhesionDate: localAdhesionDate });
  const amendmentDisabled = courseIsAmendment && isProtectionAmendment && R.includes(parseInt(v('postCode')), R.pathOr([], ['disableAmendments'], ccn));
  const checkCommercialCode = useCheckCommercialCode();

  let unavailableOfferTypes = R.compose(
    R.uniq,
    R.pluck('offerType'),
    R.flatten,
    R.reject(R.isNil)
  )([beforeUnavailabilityDates, afterUnavailabilityMessages]);

  const onNext = () => {
    if (R.includes(v('postCode'), forbiddenDepartments)) {
      setDepartmentIsForbidden(true);
    } else {
      const next = () => {
        getAvailableOfferTypes({
          ccnId: ccn.id,
          contractId,
          siret,
          isProtectionAmendment,
          isHealthAmendment,
          duplicata
        }, availableOfferTypes => {
          const noOfferTypesAreAvailable = R.compose(
            R.isEmpty,
            R.without(unavailableOfferTypes)
          )(availableOfferTypes);

          if (noOfferTypesAreAvailable) {
            setNoOfferTypesAreAvailable(true);
          } else {
            setBrokerage(v('brokerage'));
            setLocation(v('postCode'));
            setStartDate(moment(v('startDate')).format());
            setAdhesionDate(moment(localAdhesionDate).format());
            goNextStep();
          }
        });
      };

      if (!_userMustHaveCommercialCode) {
        return next();
      }

      checkCommercialCode({ code: v('brokerage.commercialCode') }, codeExists => {
        if (codeExists) {
          next();
        } else {
          message.error('Le code commercial n\'existe pas.');
        }
      });
    }
  };

  const providerDisabled = userRoleIs('apporteur') && (R.propSatisfies(R.either(R.isNil, R.isEmpty), 'provider', R.propOr({}, 'brokerage', form.values())) || !startDateCompliesProviderRole());

  const buttonDisabled = onNextIsDisabled || amendmentDisabled || providerDisabled || (
    (
      startDateAboveOneYear() ||
      startDateIsBeforeUnavailability().isBeforeUnavailability ||
      startDatesAreBeforeUnavailability().areBeforeUnavailability ||
      startDatesAreAfterUnavailability().areAfterUnavailability ||
      startDateIsAfterUnavailability() ||
      !startDateIsFirstDay() ||
      !startDateCompliesAmendment()
    ) ||
    (
      adhesionDateIsAboveToday()
    )
    &&
      (
        !duplicata
          ||
              duplicata && adhesionDateIsAboveToday()
      )
  );

  useKeyPressEnter(onNext, !buttonDisabled);

  const onChangeAdhesionDate = (date) => {
    setLocalAdhesionDate(date || moment());
  };

  return <React.Fragment>
    <FieldTitle
      text="Dans quel département est votre société ?"
      onBack={goPreviousStep}
    />

    {form.manageField('postCode', { defaultValue: !R.isNil(companyPostCode) ? getPostCode(companyPostCode) : postCode })(
      <Select
        options={data.departments}
        labelKey="title"
        valueKey="code"
        simpleValue={true}
        placeholder="Sélectionner votre département..."
      />
    )}

    <FieldTitle
      text="Quelle est la date de demande d'adhésion de la part du client ?"
      showCcn={false}
    />

    <DatePicker
      locale="fr"
      onChange={onChangeAdhesionDate}
      selected={moment(localAdhesionDate)}
    />

    {adhesionDateIsAboveToday() &&
    <ErrorDate>{'La date de demande d\'adhésion est supérieure à la date du jour.'}</ErrorDate>
    }

    <FieldTitle
      text="Quelle est la date d'effet souhaitée du contrat ?"
      showCcn={false}
    />

    {form.manageField('startDate', { defaultValue: !R.isNil(startDate) ? moment(startDate) : moment().date(1), valueKey: 'selected' })(
      <DatePicker locale="fr"/>
    )}

    {!_userMustHaveCommercialCode && form.manageField('brokerage', { defaultValue: brokerage })(
      <Brokerage
        showBrokerage={userRoleIs('apporteur')}
      />
    )}

    {_userMustHaveCommercialCode && form.manageField('brokerage', { defaultValue: brokerage })(
      <CommercialCode defaultValue={brokerage}/>
    )}

    {!adhesionDateIsAboveToday() && contractHasRetroActivity() && !duplicata && !isRelatedContractChecked &&
    <ErrorDate>Attention, vous traitez un dossier avec rétroactivité !</ErrorDate>
    }

    {startDateAboveOneYear() && !duplicata &&
    <ErrorDate>La date d&#39;effet est supérieure à un an.</ErrorDate>
    }

    {isBeforeUnavailability && !duplicata &&
      <ErrorDate>La date d&#39;effet du contrat ne peut pas être antérieure à {userRoleIs('apporteur') ? brokerageUnavailableBefore : beforeUnavailabilityDate} pour la {isPack ? 'formule' : 'CCN'} {R.propOr('', 'shortTitle', ccn)}.</ErrorDate>
    }

    {!isNilOrEmpty(beforeUnavailabilityDates) && !duplicata && !courseIsAmendment &&
        R.map(({ offerType, date }) => {
          return <ErrorDate key={offerType}>
            <span className="font-bold">{offerType === 'PREVOYANCE' ? 'Prévoyance' : 'Santé'} :</span> La date d&#39;effet du contrat ne peut pas être antérieure à {date} pour la {isPack ? 'formule' : 'CCN'} {R.propOr('', 'shortTitle', ccn)}.
          </ErrorDate>;
        })(beforeUnavailabilityDates)
    }

    {startDateIsAfterUnavailability() && !duplicata &&
    <ErrorDate>{R.isNil(unavailableAfter.message) ? `La date d'effet du contrat ne peut pas être postérieure à ${unavailableAfter.date} pour la CCN ${R.propOr('', 'shortTitle', ccn)}.` : unavailableAfter.message}</ErrorDate>
    }

    {!isNilOrEmpty(afterUnavailabilityMessages) && !duplicata && !courseIsAmendment &&
        R.map(({ offerType, date, message }) => {
          return <ErrorDate key={offerType}>
            <span className="font-bold">{offerType === 'PREVOYANCE' ? 'Prévoyance' : 'Santé'} :</span> {R.isNil(message) ? `La date d'effet du contrat ne peut pas être postérieure à ${date} pour la CCN ${R.propOr('', 'shortTitle', ccn)}.` : message}
          </ErrorDate>;
        })(afterUnavailabilityMessages)
    }

    {!startDateIsFirstDay() &&
    <ErrorDate>{'La date d\'effet doit être le premier jour du mois.'}</ErrorDate>
    }

    {amendmentDisabled &&
    <ErrorDate>{'Cette CCN ne propose pas d\'avenant.'}</ErrorDate>
    }

    {noOfferTypesAreAvailable && <ErrorDate>{'Aucune offre n\'est disponible pour cette entreprise.'}</ErrorDate>}

    {!startDateCompliesAmendment() && !duplicata &&
      <ErrorDate>
        {isKnownAmendment ?
          <span>{`La date d'effet du contrat ne peut pas être antérieure à la date d'effet du contrat d'origine (${moment(startDate).format('DD/MM/YYYY')}) et doit être le premier jour du mois.`}</span>
          :
          <span>{'La date d\'effet doit être le premier jour du mois.'}</span>
        }
      </ErrorDate>
    }

    {departmentIsForbidden && <div className="text-red-500">{'Une souscription pour ce département n\'est pas autorisée.'}</div>}

    <BtnNext
      disabled={buttonDisabled}
      onClick={onNext}
      title={'Je personnalise mon contrat'}
      style={{ marginTop: '40px' }}
    />
  </React.Fragment>;
};

export default connect(
  ({ proposition }) => ({
    duplicata: R.pathOr('', ['data', 'duplicata'])(proposition),
    siret: R.path(['data', 'siret'], proposition),
    contractId: R.path(['data', 'propositionId'], proposition),
    ccn: R.pathOr({}, ['data', 'ccn'])(proposition),
    userRole: R.propOr('', 'userRole')(proposition),
    brokerageUnavailableBefore: R.pathOr('', ['data', 'ccn', 'brokerageSettings', 'unavailableBefore'])(proposition),
    unavailableBefore: R.pathOr('', ['data', 'ccn', 'unavailableBefore'])(proposition),
    protectionUnavailableBefore: R.pathOr(null, ['data', 'ccn', 'PREVOYANCE', 'unavailableBefore'])(proposition),
    healthUnavailableBefore: R.pathOr(null, ['data', 'ccn', 'SANTE', 'unavailableBefore'])(proposition),
    unavailableAfter: R.pathOr({}, ['data', 'ccn', 'unavailableAfter'])(proposition),
    protectionUnavailableAfter: R.pathOr({}, ['data', 'ccn', 'PREVOYANCE', 'unavailableAfter'])(proposition),
    healthUnavailableAfter: R.pathOr({}, ['data', 'ccn', 'SANTE', 'unavailableAfter'])(proposition),
    companyPostCode: R.pathOr('', ['data', 'siretInfo', 'address', 'postCode'])(proposition),
    adhesionDate: R.pathOr(moment(), ['data', 'adhesionDate'])(proposition),
    ...R.compose(
      R.pick(['brokerage', 'postCode', 'startDate', 'relatedContract']),
      R.pathOr({}, ['data'])
    )(proposition),
    ...getAmendmentInfos(proposition.data)
  }),
  dispatch => ({
    setLocation: location => dispatch(propositionSetLocation(location)),
    setStartDate: startDate => dispatch(propositionSetStartDate(startDate)),
    setAdhesionDate: adhesionDate => dispatch(propositionSetAdhesionDate(adhesionDate)),
    setBrokerage: value => dispatch(propositionSetBrokerage(value)),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    goNextStep: () => dispatch(propositionGoNextStep())
  })
)(withForm(withUser(LocationAndDateStep)));
