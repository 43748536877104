import * as R from 'ramda';
import { graphql } from 'react-relay';
import { fetchQuery } from 'relay-runtime';
import environment from '../graphql/Environment';

const QCheckSiretsAreUsed = graphql`
 query useCheckSiretsAreUsedQuery($sirets: [String]!, $ccnId: String!, $contractId: String) {
   siretsAreUsed(sirets: $sirets, ccnId: $ccnId, contractId: $contractId) {
     siret
     isUsed
   }
 }
`;

const useCheckSiretsAreUsed = () => {
  return ({ sirets, ccnId, contractId }, callback) => {
    fetchQuery(environment, QCheckSiretsAreUsed, { sirets, ccnId, contractId }).then(data => {
      if (!R.isNil(callback) && R.is(Function, callback)) {
        callback(data.siretsAreUsed);
      }
    });
  };
};

export default useCheckSiretsAreUsed;
