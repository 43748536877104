import * as R from 'ramda';
import React, { useState } from 'react';

import { connect } from 'react-redux';
import withUser from '../../../withUser';

import {
  propositionMakeDuplicata,
  propositionSaveClientEmail
} from '../../../reduxActions/proposition';

import {
  Button,
  FieldTitle,
  TextInput
} from '../../../uikit/UIKit';
import { validations } from '@fasstech/spid-front';

const DuplicataSentStep = (
  ({
    contactEmail,
    makeDuplicata,
    makeDuplicataError,
    makeDuplicataStatus,
    saveClientMail
  }) => {

    const [editing, setEditing] = useState(false);
    const [newClientEmail, setNewClientEmail] = useState(contactEmail);
    const onSaveAddress = () => {
      saveClientMail(newClientEmail);
      setEditing(false);
    };

    return <React.Fragment>
      <FieldTitle
        text="Contrat duplicata"
      />
      <div className="confirm-contract-title">
        {'Le duplicata du contrat a été envoyé par mail, si vous ne l\'avez pas reçu, vous pouvez le renvoyer à l\'aide du bouton ci-dessous.'}
      </div>

      <div className="field field-text-input" style={{ margin: '10px 0 5px 0' }}>
        <TextInput
          disabled={!editing}
          placeholder="Email du destinataire"
          type="email" size="small"
          style={{ width: '480px', borderColor: validations.emailIsValid(newClientEmail) ? '' : 'orange' }}
          value={newClientEmail}
          onChange={(e) => setNewClientEmail(e.target.value)}
        />
      </div>
      <Button disabled={editing} onClick={() => setEditing(true)} size="small" style={{ display: 'inline-block' }}>Modifier</Button>
      <Button disabled={!validations.emailIsValid(newClientEmail)} onClick={onSaveAddress} size="small" style={{ display: editing ? 'inline-block' : 'none' }}>Enregistrer la nouvelle adresse</Button><br/>

      <Button size="medium" style={{ display: 'inline-block', marginTop: '40px' }} onClick={makeDuplicata} disabled={editing}>{'Renvoyer le duplicata'}</Button>
      <div style={{ marginLeft: '8px', marginTop: '16px', fontSize: '12px', color: '#444"' }}>
        {makeDuplicataError && (
          <div>{'Nous avons recontré un problème.'}</div>
        )}
        {!R.isNil(makeDuplicataStatus) && makeDuplicataStatus === 'conversion_failed' && (
          <div>{'La conversion des documents a échoué.'}</div>
        )}
        {!makeDuplicataError && !R.isNil(makeDuplicataStatus) && makeDuplicataStatus !== 'email_sent_to_user' && makeDuplicataStatus !== 'conversion_failed' && (
          <div>{'Préparation de l\'envoi.'}</div>
        )}
        {!makeDuplicataError && makeDuplicataStatus === 'email_sent_to_user' && (
          <div>{'L\'email a été envoyé.'}</div>
        )}
      </div>
    </React.Fragment>;
  }
);

export default connect(
  (state) => {
    const proposition = R.propOr({}, 'proposition')(state);

    return {
      makeDuplicataStatus: R.path(['makeDuplicataStatus', 'value'], proposition),
      makeDuplicataError: R.path(['makeDuplicataStatus', 'error'], proposition),
      contactEmail: R.pathOr('', ['data', 'companyInformation', 'contactEmail'])(proposition)
    };
  },
  (dispatch) => ({
    makeDuplicata: () => dispatch(propositionMakeDuplicata()),
    saveClientMail: (email) => dispatch(propositionSaveClientEmail(email))
  })
)(withUser(DuplicataSentStep));
