import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation DocumentsMutation($token: String!) {
  documents(token: $token) { 
    ok
    token
    status
    contractDescription {
      NC {
        bases {
          baseId
          documents {
            type
            url
            id
          }
          options {
            optionId
            documents {
              type
              url
              id
            }
          }
        }
      }
      APP {
        bases {
          baseId
          documents {
            type
            url
            id
          }
          options {
            optionId
            documents {
              type
              url
              id
            }
          }
        }
      }
      AM {
        bases {
          baseId
          documents {
            type
            url
            id
          }
          options {
            optionId
            documents {
              type
              url
              id
            }
          }
        }
      }
      C { 
        bases {
          baseId
          documents {
            type
            url
            id
          }
          options {
            optionId
            documents {
              type
              url
              id
            }
          }
        }
      }
      TOUS {
        bases {
          baseId
          documents {
            type
            url
            id
          }
          options {
            optionId
            documents {
              type
              url
              id
            }
          }
        }
      }
    }
  }
}
`;

export default (token, done) => {
  const variables = {
    token
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, token, status, contractDescription, error } = response.documents;
        done(ok, token, status, contractDescription, error);
      },
      onError: err => console.error(err),
    }
  );
};
