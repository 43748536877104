/**
 * @flow
 * @relayHash 77d8587ae3683b071c5af59a6dcee27c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QConfigQueryVariables = {||};
export type QConfigQueryResponse = {|
  +config: ?{|
    +arpejLink: ?string,
    +disableContractNumbersAutoGeneration: ?boolean,
    +disableProviders: ?boolean,
    +disableSignatureDelay: ?boolean,
  |}
|};
export type QConfigQuery = {|
  variables: QConfigQueryVariables,
  response: QConfigQueryResponse,
|};
*/


/*
query QConfigQuery {
  config {
    arpejLink
    disableContractNumbersAutoGeneration
    disableProviders
    disableSignatureDelay
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Config",
    "kind": "LinkedField",
    "name": "config",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "arpejLink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "disableContractNumbersAutoGeneration",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "disableProviders",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "disableSignatureDelay",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QConfigQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QConfigQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "77d8587ae3683b071c5af59a6dcee27c",
    "metadata": {},
    "name": "QConfigQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd3ab31b814a3dc590074d0573c36a2f1';

module.exports = node;
