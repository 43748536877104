import React from 'react';
import Dimensions from 'react-dimensions';

import {
  compose, 
  withProps
} from 'recompose';

const enhance = compose(
  Dimensions(),
  withProps(
    ({ containerWidth, value, xMin, xMax }) => ({
      xPos: (value - xMin) * containerWidth / (xMax - xMin)
    })
  )
);
const GaugeInputContainer = enhance(
  ({
    type,
    xPos
  }) => (
    <div className="uikit-gauge-input-container">
      <div className="uikit-gauge-input-container-background">
        <div className="uikit-gauge-input-container-value" style={{ width: `${xPos}px` }}/>
      </div>
      <div className="uikit-gauge-input-container-cursor" style={{ [type === 'from' ? 'left' : 'right']: `${xPos - 4}px` }}/>
    </div>
  )
);

export default GaugeInputContainer;
