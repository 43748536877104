import React from 'react';
import { Checkbox } from 'antd';

const CustomizableCheckbox = props => {
  const {
    children,
    width = 25,
  } = props;

  return <Checkbox
    width={width}
    iconHeight={width * 0.6}
    iconWidth={width * 0.32}
    fontSize={width * 0.56}
    {...props}
  >
    {children}
  </Checkbox>;
};

export default CustomizableCheckbox;

