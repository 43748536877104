/**
 * @flow
 * @relayHash d336d39f02c9f3d4f2c1e23a480d06ca
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QAuthorizationUrlQueryVariables = {|
  redirectUrl?: ?string
|};
export type QAuthorizationUrlQueryResponse = {|
  +authorizationUrl: ?string
|};
export type QAuthorizationUrlQuery = {|
  variables: QAuthorizationUrlQueryVariables,
  response: QAuthorizationUrlQueryResponse,
|};
*/


/*
query QAuthorizationUrlQuery(
  $redirectUrl: String
) {
  authorizationUrl(redirectUrl: $redirectUrl)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "redirectUrl",
    "type": "String"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "redirectUrl",
        "variableName": "redirectUrl"
      }
    ],
    "kind": "ScalarField",
    "name": "authorizationUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QAuthorizationUrlQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QAuthorizationUrlQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d336d39f02c9f3d4f2c1e23a480d06ca",
    "metadata": {},
    "name": "QAuthorizationUrlQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd20d034ddd392ed098cac0b9338e6003';

module.exports = node;
