import './SelectSales.less';

import * as R from 'ramda';
import React from 'react';

import PropTypes from 'prop-types';

import Select from 'react-select';

const SelectSales = ({
  options,
  title,
  value,
  onChange,
  region
}) => {
  const sales = R.compose(
    R.filter(({ value }) => {
      if (R.isNil(region)) {
        return true;
      }

      if (region === 'R00' && value === 'VADC') {
        return true;
      }

      return region !== 'R00' && value !== 'VADC';
    }),
    R.sortBy(R.prop('value'))
  )(options);

  return <div className="uikit-select-struct">
    <span className="uikit-selection-struct-title">
      {title}
    </span>
    <Select
      value={value}
      labelKey="value"
      valueKey="value"
      options={sales}
      onChange={onChange}
      simpleValue={true}
      placeholder="Sélectionner"
    />
  </div>;
};

SelectSales.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  title: PropTypes.string,
  value: PropTypes.string
};

export default SelectSales;
