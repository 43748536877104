/**
 * @flow
 * @relayHash f810da8cea79d71a04fc452742d3a0d8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConvertPropositionCommercialeMutationVariables = {|
  propositionId: string,
  filename: string,
|};
export type ConvertPropositionCommercialeMutationResponse = {|
  +convertPropositionCommerciale: ?{|
    +ok: boolean,
    +token: ?string,
  |}
|};
export type ConvertPropositionCommercialeMutation = {|
  variables: ConvertPropositionCommercialeMutationVariables,
  response: ConvertPropositionCommercialeMutationResponse,
|};
*/


/*
mutation ConvertPropositionCommercialeMutation(
  $propositionId: String!
  $filename: String!
) {
  convertPropositionCommerciale(propositionId: $propositionId, filename: $filename) {
    ok
    token
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propositionId",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filename",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filename",
        "variableName": "filename"
      },
      {
        "kind": "Variable",
        "name": "propositionId",
        "variableName": "propositionId"
      }
    ],
    "concreteType": "TokenResponse",
    "kind": "LinkedField",
    "name": "convertPropositionCommerciale",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConvertPropositionCommercialeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConvertPropositionCommercialeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "f810da8cea79d71a04fc452742d3a0d8",
    "metadata": {},
    "name": "ConvertPropositionCommercialeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b185fc752af5fdb84c3f16512d082fa9';

module.exports = node;
