import React from 'react';
import * as R from 'ramda';
import environment from '../../../graphql/Environment';
import { Spin } from 'antd';
import Establishment from './Establishment';
import { graphql, QueryRenderer } from 'react-relay';

const SearchEstablishmentsQuery = graphql`
  query EstablishmentsQuery($search: String!, $department: String) {
    searchEstablishment(search: $search, department: $department) {
      establishments {
        name
        siren
        siret
        ape
        legalForm
        activity
        idccsFromDsn
        address {
          fields
          city
          postCode
        }
      }
    }
  }
`;

const Establishments = ({
  search,
  department,
  selectedEstablishment,
  onSelectEstablishment
}) => {
  return <QueryRenderer
    environment={environment}
    query={SearchEstablishmentsQuery}
    variables={{ search, department }}
    render={({ error, props }) => {
      if (error) {
        return <div>Une erreur est survenue.</div>;
      }

      if (!props) {
        return <Spin spinning/>;
      }

      const establishments = R.pathOr([], ['searchEstablishment', 'establishments'])(props);

      return <>
        {R.map(establishment => {
          return <Establishment
            key={establishment.siret}
            establishment={establishment}
            onSelect={onSelectEstablishment}
            selectedEstablishment={selectedEstablishment}
          />;
        })(establishments)}
      </>;
    }}
  />;
};

export default Establishments;
