
import './ToggleButton.less';
import React from 'react';

import {
  compose,
  withHandlers,
  withProps
} from 'recompose';

const enhance = compose(
  withHandlers({
    onClick: ({ value, options, onChange }) => () => {
      if (value === options[0].value) return onChange(options[1].value);
      return onChange(options[0].value);
    }
  }),
  withProps(
    ({ value, options }) => ({
      position: options[0].value === value ? '0px' : '150px',
      text: options[0].value === value ? options[0].text : options[1].text,
      positionBack: options[0].value === value ? '150px' : '0px',
      textBack: options[0].value === value ? options[1].text : options[0].text,
    })
  )
);

const ToggleButton = enhance(
  ({
    onClick,
    position,
    text,
    positionBack,
    textBack,
    style
  }) => {
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    return <div className="uikit-toggle-button" onClick={onClick} style={style}>
      <div className="uikit-toggle-button-text-back" style={{ left: positionBack }}>
        {textBack}
      </div>
      <div className="uikit-toggle-button-moving" style={{ left: position }}>
        {text}
      </div>
    </div>;
  }
);

export default ToggleButton;
