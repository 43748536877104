import './BackgroundStep.less';
import * as R from 'ramda';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  propositionSetBackgroundHasFormerContract,
  propositionSetBackgroundAtmtt,
  propositionSetStudyAtIndem,
  propositionGoPreviousStep,
  propositionGoNextStep
} from '../../../reduxActions/proposition';
import withForm from '@thecodeisgreen/withform';
import { FieldTitle, SelectButtons, } from '../../../uikit/UIKit';
import { BtnNext, WorkStoppage } from '../components';
import useKeyPressEnter from '../../../hooks/useKeyPressEnter';
import environment from '../../../graphql/Environment';
import { graphql, QueryRenderer } from 'react-relay';
import Error from '../../../components/Error';

const BackgroundStepQuery = graphql`
  query BackgroundStepQuery($id: ID!) {
    checkRecommendedOffer(id: $id) 
  }
`;

const Background = ({
  atmtt,
  form,
  goPreviousStep,
  goNextStep,
  hasFormerContract,
  studyAtIndem,
  userRole,
  setHasFormerContract,
  setATMTT,
  setStudyATIndem,
  hasLessThan20Employees,
  propositionId
}) => {
  const [loading, setLoading] = useState(false);

  const onNext = () => {
    try {
      setLoading(true);

      const values = form.values();
      setHasFormerContract(values.hasFormerContract);
      setATMTT({
        isSelected: R.pathOr(false, ['atmtt', 'isSelected'])(values),
        count: parseInt(R.pathOr(0, ['atmtt', 'count'])(values))
      });
      setStudyATIndem(R.pathOr(false, ['atmtt', 'isSelected'])(values) ? values.studyAtIndem : false);
      goNextStep();
    } catch (error) {
      setLoading(false);
    }
  };

  useKeyPressEnter(onNext, true);

  return <>
    <FieldTitle
      text="Aviez-vous un contrat prévoyance précédemment ?"
      onBack={goPreviousStep}
    />

    <div style={{ marginTop: '33px' }}>
      {form.manageField('hasFormerContract', { defaultValue: hasFormerContract })(
        <SelectButtons options={[
          { title: 'Oui', value: true },
          { title: 'Non', value: false },
        ]}/>
      )}
    </div>

    <div style={{ marginTop: '33px' }}>
      <div className="title">{'Avez-vous des salariés en arrêt de travail ou en mi-temps thérapeutique ?'}</div>
      {form.manageField('atmtt', { defaultValue: atmtt })(
        <WorkStoppage/>
      )}
    </div>

    {userRole !== 'groupe_adhesion' && !hasLessThan20Employees && <div style={{
      marginTop: '33px',
      display: R.pathOr(false, ['atmtt', 'isSelected'], form.values()) ? 'block' : 'none'
    }}>
      <div className="title">{'Souhaitez-vous étudier l\'indemnisation de ces arrêts de travail ?'}</div>
      {form.manageField('studyAtIndem', { defaultValue: studyAtIndem })(
        <SelectButtons options={[
          { title: 'Oui', value: true },
          { title: 'Non', value: false }
        ]}/>
      )}
    </div>}

    <QueryRenderer
      environment={environment}
      query={BackgroundStepQuery}
      variables={{
        id: propositionId
      }}
      render={({ error, props }) => {
        if (error) {
          return <Error/>;
        } else if (!props) {
          return null;
        }

        const { checkRecommendedOffer } = props;

        const {
          hasFormerContract,
          atmtt
        } = form.values();

        const offerIsNotRecommended = checkRecommendedOffer && !hasFormerContract && R.propEq('isSelected', true, atmtt);

        return <>
          <BtnNext
            disabled={offerIsNotRecommended}
            loading={loading}
            onClick={onNext}
            title={'Suivant'}
          />

          {offerIsNotRecommended && <div className="text-red-500 font-semibold mt-4">
            Refus de souscription : AG2R PREVOYANCE n’est pas recommandé par la branche professionnelle pour cette offre prévoyance, les sinistres en cours à la date d’effet ne sont pas mutualisés et l’entreprise existe depuis plus de 3 ans, sans précédent assureur.
          </div>}
        </>;
      }}
    />
  </>;
};

export default connect(
  ({ proposition }) => ({
    hasFormerContract: R.path(['data', 'background', 'hasFormerContract'], proposition),
    atmtt: R.path(['data', 'background', 'atmtt'], proposition),
    studyAtIndem: R.path(['data', 'studyAtIndem'], proposition),
    userRole: R.prop('userRole', proposition),
    hasLessThan20Employees: R.pathOr(false, ['data', 'additionalInformations', 'PREVOYANCE', 'hasLessThan20Employees'], proposition),
    propositionId: R.path(['data', 'propositionId'], proposition)
  }),
  dispatch => ({
    setHasFormerContract: (value) => dispatch(propositionSetBackgroundHasFormerContract(value)),
    setATMTT: (value) => dispatch(propositionSetBackgroundAtmtt(value)),
    setStudyATIndem: (value) => dispatch(propositionSetStudyAtIndem(value)),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    goNextStep: () => dispatch(propositionGoNextStep())
  })
)(withForm(Background));
