import * as R from 'ramda';
import React from 'react';
import { PropositionListItem } from './components';

const PropositionList = ({ propositions }) => {
  if (R.isEmpty(propositions)) return (
    <div>
      Pas de réponse.
    </div>
  );

  return <div>
    {propositions.map((proposition, index) => <PropositionListItem key={index} proposition={proposition}/>)}
  </div>;
};

export default PropositionList;
