import * as R from 'ramda';

import ConvertPropositionCommercialeMutation from '../../../../graphql/mutations/ConvertPropositionCommercialeMutation';
import PropositionCommercialeMutation from '../../../../graphql/mutations/PropositionCommercialeMutation';

import {
  CommercialInfoBlock,
  CommercialInfoBlocksSelectCollege,
  SalaryContinuationInfo
} from '.';

import React, { useState } from 'react';

import {
  Button,
} from '../../../../uikit/UIKit';

const useInfoBlocks = (blocks) => {
  const [selectedCollege, setSelectedCollege] = useState(R.nth(0, R.keys(blocks)));

  const onSelectCollege = (college) => {
    setSelectedCollege(college);
  };

  return {
    title: R.path([selectedCollege, 'title'], blocks),
    bases: R.path([selectedCollege, 'bases'], blocks),
    selectedCollege,
    colleges: R.keys(blocks),
    onSelectCollege
  };
};

const useDownloadCommercialProposition = (propositionId) => {

  const [downloadState, setDownloadState] = useState(null);

  const onDownload = (filename) => {
    setDownloadState('converting_documents');

    ConvertPropositionCommercialeMutation(propositionId, filename, (ok, token) => {
      if (ok) {
        const retrieveDocument = (token) => {
          setTimeout(() => {
            PropositionCommercialeMutation(token, (ok, token, status, url) => {
              if (!ok || status === 'conversion_failed') setDownloadState('conversion_failed');
              else if (ok && status === 'documents_converted') {
                setDownloadState(null);
                if (!R.isNil(url) && !R.isEmpty(url)) window.location.href = `/file/download?url=${url}`;
              } else retrieveDocument(token);
            });
          }, 500);
        };
        retrieveDocument(token);
      }
    });
  };

  return {
    downloadState,
    onDownload
  };
};

const CommercialInfoBlocks = ({
  blocks,
  propositionId
}) => {

  const [showSalaryContinuation, setShowSalaryContinuation] = useState(false);
  const {
    title,
    bases,
    selectedCollege,
    colleges,
    onSelectCollege
  } = useInfoBlocks(blocks);

  const {
    downloadState,
    onDownload
  } = useDownloadCommercialProposition(propositionId);

  return (
    <div className="info">
      {!showSalaryContinuation && <h2>{'Information sur l\'offre'}</h2>}
      <div>
        {title}
      </div>
      <div className="info-buttons">
        {
          !showSalaryContinuation && <CommercialInfoBlocksSelectCollege
            selectedCollege={selectedCollege}
            colleges={colleges}
            onChange={onSelectCollege}
          />
        }
        {
          <div
            role="button"
            tabIndex={0}
            className="info-btn-salary-continuation"
            onClick={() => {setShowSalaryContinuation(!showSalaryContinuation);}}
          >
            {showSalaryContinuation === false ? 'A propos du maintien de salaire' : 'Retour vers la visualisation des offres'}
          </div>
        }
      </div>
      {
        showSalaryContinuation && <SalaryContinuationInfo/>
      }
      {
        !showSalaryContinuation && <div className="info-bases">
          {
            bases.map(({ title, blocks, propositionCommerciale }, index) => (
              <div key={index} className={`info-base-${index === 0 ? 'green' : 'gray'}`}>
                <div className="info-base-title">
                  {title}
                </div>
                <div className="info-base-body">
                  {
                    blocks.map((block, index) => (
                      <CommercialInfoBlock
                        key={`${selectedCollege}-${index}`}
                        value={block}
                      />
                    ))
                  }
                </div>
                <div className="info-base-download">
                  {
                    !R.isNil(propositionCommerciale) && !R.isEmpty(propositionCommerciale) && (
                      <Button
                        look="white"
                        onClick={() => onDownload(propositionCommerciale)}
                        disabled={R.equals(downloadState, 'converting_documents')}
                      >
                        {'Télécharger l\'offre'}
                      </Button>
                    )
                  }
                </div>

              </div>
            ))
          }
        </div>
      }
    </div>
  );
};

export default CommercialInfoBlocks;
