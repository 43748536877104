/**
 * @flow
 * @relayHash 0f5a92c40f76df5990262f7d08a413e0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClosePropositionMutationVariables = {|
  propositionId: string,
  reason?: ?string,
|};
export type ClosePropositionMutationResponse = {|
  +closeProposition: ?{|
    +ok: boolean
  |}
|};
export type ClosePropositionMutation = {|
  variables: ClosePropositionMutationVariables,
  response: ClosePropositionMutationResponse,
|};
*/


/*
mutation ClosePropositionMutation(
  $propositionId: String!
  $reason: String
) {
  closeProposition(propositionId: $propositionId, reason: $reason) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propositionId",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reason",
    "type": "String"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propositionId",
        "variableName": "propositionId"
      },
      {
        "kind": "Variable",
        "name": "reason",
        "variableName": "reason"
      }
    ],
    "concreteType": "BasicResponse",
    "kind": "LinkedField",
    "name": "closeProposition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClosePropositionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClosePropositionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "0f5a92c40f76df5990262f7d08a413e0",
    "metadata": {},
    "name": "ClosePropositionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'be9f0248f50418cbf7dafd5fb4a9ef24';

module.exports = node;
