import * as R from 'ramda';
import React, { useState } from 'react';

import withUser from '../../withUser';
import withForm from '@thecodeisgreen/withform';
import { validations } from '@fasstech/spid-front';
const { passwordIsValid } = validations;

import {
  withRouter
} from 'react-router-dom';

import {
  Button,
  TextInput,
  FieldTitle
} from '../../uikit/UIKit';

const fieldProps = {
  defaultValue: '',
  styleOnNotValid: { borderColor: 'orange' }
};

import ResetPasswordMutation from '../../graphql/mutations/ResetPasswordMutation';
import SignOutMutation from '../../graphql/mutations/SignOutMutation';

const useNewPasswordRequested = (history, form) => {
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const onLostCurrentPassword = () => {
    SignOutMutation(() => {
      history.replace('/account/resetpasswordrequest');
    });
  };
  const onUpdatePassword = () => {
    const { currentPassword, password } = form.values();
    setProcessing(true);
    ResetPasswordMutation({ currentPassword, password }, (ok, error) => {
      setProcessing(false);
      if (ok) return setIsDone(true);
      setError(error);
    });
  };

  const onResetForm = () => {
    setIsDone(false);
    setError(null);
  };

  return {
    onLostCurrentPassword,
    onResetForm,
    onUpdatePassword,
    passwordIsUpdated: isDone,
    processing,
    error
  };
};

const render = (content) => (
  <div className="connection-box">
    <FieldTitle text="Changer de mot de passe"/>
    {content}
  </div>
);

const NewPasswordRequired = ({
  history,
  form,
  user
}) => {
  const {
    processing,
    onResetForm,
    onLostCurrentPassword,
    onUpdatePassword,
    passwordIsUpdated,
    error
  } = useNewPasswordRequested(history, form, user);

  if (processing) {
    return render(
      'Nous mettons à jour votre mot de passe...'
    );
  } else if (!processing && R.isNil(error) && !passwordIsUpdated) {
    return render(
      <React.Fragment>
        {'Notre politique de sécurité évolue, nous vous demandons de redéfinir votre mot de passe afin de respecter nos nouvelles règles'}
        <br/>
        <br/>
        <div style={{ display: R.F() ? 'none' : 'block' }}>
          {
            form.manageField('currentPassword', {
              isValid: () => R.T(),
              ...fieldProps
            })(
              <TextInput
                title="Votre mot de passe courant"
                type="password"
                placeholder="Votre mot de passe courant"
              />
            )
          }
        </div>
        <div style={{ display: R.F() ? 'none' : 'block' }}>
          <React.Fragment>
            {
              form.manageField('password', {
                isValid: v => passwordIsValid(v, [user.email, user.extra.lastname, user.extra.firstname]),
                ...fieldProps
              })(
                <TextInput
                  title="Votre nouveau mot de passe"
                  type="password"
                  placeholder="Votre nouveau mot de passe"
                />
              )
            }
            <div className="password-hint">{'Votre mot de passe doit contenir au moins 8 caractères (dont au moins un de type lettre, de type chiffre, de type symbole)'}</div>
          </React.Fragment>
        </div>
        <div className="sign-in-lost-password">
          <a
            href="javascript:void(0)"
            onClick={onLostCurrentPassword}
          >
            {'J\'ai perdu mon mot de passe courant'}
          </a>
        </div>
        <div className="sign-in-button-row">
          <Button
            onClick={onUpdatePassword}
            disabled={!form.isValid()}
          >
            {'Mettre à jour'}
          </Button>
        </div>
      </React.Fragment>
    );
  } else if (passwordIsUpdated) {
    return render (
      <React.Fragment>
        {'Votre mot de passe a bien été mis à jour'}
        <br/><br/>
        {'Vous allez être redirigé vers le menu'}
      </React.Fragment>
    );
  } else {
    return render (
      <React.Fragment>
        {error === 'password_ko' ? 'Votre mot de passe n\'est pas correct' : 'Nous avons rencontré un problème'}
        <Button
          style={{ marginTop: 16 }}
          onClick={onResetForm}
          disabled={!form.isValid()}
        >
          {'Recommencer'}
        </Button>
      </React.Fragment>
    );
  }
};

export default withRouter(withUser(withForm(NewPasswordRequired)));
