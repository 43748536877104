/**
 * @flow
 * @relayHash 5689e99f6eebfac3728b37eff0c75c63
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentInput = {|
  id?: ?string,
  type?: ?string,
  url?: ?string,
|};
export type DocumentsQueryVariables = {|
  documents: $ReadOnlyArray<?DocumentInput>
|};
export type DocumentsQueryResponse = {|
  +sortDocuments: ?$ReadOnlyArray<?{|
    +sortedDocumentId: ?string,
    +type: ?string,
    +url: ?string,
  |}>
|};
export type DocumentsQuery = {|
  variables: DocumentsQueryVariables,
  response: DocumentsQueryResponse,
|};
*/


/*
query DocumentsQuery(
  $documents: [DocumentInput]!
) {
  sortDocuments(documents: $documents) {
    sortedDocumentId
    type
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documents",
    "type": "[DocumentInput]!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documents",
        "variableName": "documents"
      }
    ],
    "concreteType": "SortedDocument",
    "kind": "LinkedField",
    "name": "sortDocuments",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sortedDocumentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentsQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocumentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "5689e99f6eebfac3728b37eff0c75c63",
    "metadata": {},
    "name": "DocumentsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1df815921bad698ba48d0bb82fab01ba';

module.exports = node;
