import './SelectProposition.less';

import * as R from 'ramda';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PropositionListPage } from './components';
import { FieldTitle } from '../../uikit/UIKit';
import MenuLayout from '../../layout/menu/Layout';

const SelectProposition = ({ siren }) => {
  const history = useHistory();
  const goHome = () => {
    history.push('/');
  };

  return <MenuLayout>
    <FieldTitle
      text="Quel contrat souhaitez-vous reprendre ?"
      onBack={goHome}
    />
    {!R.isNil(siren) && !R.isEmpty(siren) ?
      <PropositionListPage
        siren={siren}
      />
      :
      <span>Vous devez entrer un SIREN valide.</span>
    }
  </MenuLayout>;
};

export default SelectProposition;
