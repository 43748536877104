import React from 'react';
import { isNil } from 'ramda';

const TextSpan = ({ text, style, className }) => {
  if (isNil(text)) {
    return null;
  }

  return <span className={className} style={style}>{text}</span>;
};

export default TextSpan;
