/**
 * @flow
 * @relayHash fe35d4047b80d75e20865be007294590
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HomeMessageQueryVariables = {||};
export type HomeMessageQueryResponse = {|
  +homemessage: ?{|
    +message: ?string,
    +display: ?boolean,
    +length: ?number,
  |}
|};
export type HomeMessageQuery = {|
  variables: HomeMessageQueryVariables,
  response: HomeMessageQueryResponse,
|};
*/


/*
query HomeMessageQuery {
  homemessage {
    message
    display
    length
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Message",
    "kind": "LinkedField",
    "name": "homemessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "display",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "length",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeMessageQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomeMessageQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "fe35d4047b80d75e20865be007294590",
    "metadata": {},
    "name": "HomeMessageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '58d4c92e934a4eb9dca13377cc84b574';

module.exports = node;
