import 'react-circular-progressbar/dist/styles.css';
import './CircularSliderInput.less';

import React from 'react';

import { CircularProgressbar } from 'react-circular-progressbar';

import classNames from 'classnames';

import {
  compose,
  defaultProps,
  withHandlers
} from 'recompose';

const getClassNames = (color, value) => classNames({
  'uikit-circular-slider-green': color === 'green',
  'uikit-circular-slider-gray': color === 'gray' && value !== 100,
  'uikit-circular-slider-dark-gray': color === 'gray' && value === 100
});

const enhance = compose(
  defaultProps({
    value: 50,
    width: undefined,
    color: 'green'
  }),
  withHandlers({
    decValue: ({ onChange, value }) => () => onChange(value > 0 ? value - 1 : value),
    incValue: ({ onChange, value }) => () => onChange(value < 100 ? value + 1 : value)
  })
);

const CircularSliderInput = enhance(
  ({
    color,
    value,
    width,
    incValue,
    decValue
  }) => (
    <div
      className="uikit-circular-slider-input"
      style={{ width }}
    >
      <button onClick={decValue}>-</button>
      <CircularProgressbar percentage={value} className={getClassNames(color, value)}/>
      <button onClick={incValue}>+</button>
    </div>
  )
);

export default CircularSliderInput;
