import React from 'react';
import { map, isNil, pathOr } from 'ramda';
import { escAdminTypesEnum, escAdminTypes } from './escAdminTypes';
import Checkbox from 'rc-checkbox';
import Select from 'react-select';
import { civilities } from './civilities';
import { TextInput } from '../../../../uikit/UIKit';
import { datas } from '@fasstech/spid-front';
import environment from '../../../../graphql/Environment';
import { graphql, QueryRenderer } from 'react-relay';
import { Spin } from 'antd';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import SelectCity from './SelectCity';
import useEscAdmin from './useEscAdmin';
import InputMask from 'react-input-mask';
import birthDateIsValid from './birthDateIsValid';

const EscAdminQuery = graphql`
  query EscAdminQuery {
    data {
      countries {
        code
        country
      }
    }
  }
`;

const Infos = () => {
  return <div className="text-red-500 flex mt-4">
    <img
      src="/icons/lightbulb.svg"
      style={{
        width: '40px'
      }}
    />

    <div>
      L’Espace client entreprise donne accès aux services liés aux contrats prévoyance et santé, dont l’affiliation en ligne. L’interlocuteur sera « Administrateur Web », il aura les droits de type “Administrateur” du compte entreprise et pourra donner des droits d’accès à d’autres personnes.
    </div>
  </div>;
};

const EscAdmin = ({
  values,
  setValues,
  escAdminPropValidations,
  escAdminSelectedRole,
  escBirthCountryIsFrance,
  resetEmailUnicityCheck,
  displayEmailUnicityError,
}) => {
  const {
    getOption,
    styleOnNotValid,
    selectedType,
    typeIs,
    setValue,
    getValue,
    getStyle,
    updateEscAdminType
  } = useEscAdmin({
    escAdminPropValidations,
    setValues,
    values,
    resetEmailUnicityCheck
  });

  return <div className="my-10">
    <div className="font-bold text-[22px] text-ag2r-brown mb-4">{'Désignation de l\'Administrateur de l\'Espace Client *'}</div>

    {map(({ label, value }) => {
      return <div
        key={value}
        className="field field-checkbox !mb-3"
      >
        <Checkbox
          checked={selectedType === value}
          onChange={() => updateEscAdminType(value)}
        />

        <span
          onClick={() => updateEscAdminType(value)}
          className="cursor-default select-none"
        >
          {label}
        </span>
      </div>;
    })(escAdminTypes)}

    {typeIs(escAdminTypesEnum.AUCUN) && <Infos/>}

    {!isNil(selectedType) && !typeIs(escAdminTypesEnum.AUCUN) && <QueryRenderer
      environment={environment}
      query={EscAdminQuery}
      render={({ error, props }) => {
        if (error) {
          return <div>Une erreur est survenue.</div>;
        }

        if (!props) {
          return <Spin spinning/>;
        }

        const countries = pathOr([], ['data', 'countries'])(props);

        return <div className="mt-8">
          <div className="field field-text-input">
            <span>{'Civilité*'}</span>

            <Select
              value={getOption(getValue('civility'))(civilities)}
              placeholder={'Sélectionner ma civilité...'}
              options={civilities}
              onChange={v => setValue('civility', v.value)}
              style={getStyle('civility')}
              clearable={false}
              arrowRenderer={null}
            />
          </div>

          <div className="field field-text-input">
            <span>{'Nom*'}</span>

            <TextInput
              placeholder="Nom"
              onChange={e => setValue('lastName', e.target.value)}
              style={getStyle('lastName')}
              value={getValue('lastName')}
            />
          </div>

          <div className="field field-text-input">
            <span>{'Prénom*'}</span>

            <TextInput
              placeholder="Prénom"
              onChange={e => setValue('firstName', e.target.value)}
              style={getStyle('firstName')}
              value={getValue('firstName')}
            />
          </div>

          <div className="field field-text-input">
            <span>{'Fonction*'}</span>

            <Select
              value={escAdminSelectedRole}
              placeholder={'Sélectionner la fonction...'}
              options={datas.roles}
              onChange={v => {
                setValue('role', v.label);
                setValue('roleCode', v.value);
              }}
              style={escAdminPropValidations.role(getValue('roleCode')) ? {} : styleOnNotValid}
              clearable={false}
              arrowRenderer={null}
            />
          </div>

          <div className="field field-text-input">
            <span>Email*</span>
            <TextInput
              placeholder="Email"
              type="email"
              value={getValue('email')}
              style={getStyle('email')}
              onChange={e => setValue('email', e.target.value)}
            />
          </div>

          <div className="field field-text-input">
            <span>Téléphone</span>
            <TextInput
              placeholder="06 xx xx xx xx"
              type="tel"
              pattern="^[0][1-9][0-9]{8}$"
              value={getValue('phoneNumber')}
              onChange={e => setValue('phoneNumber', e.target.value)}
              style={getStyle('phoneNumber')}
            />
          </div>

          <div className="field field-text-input">
            <span>Date de naissance*</span>

            <DatePicker
              locale="fr"
              onChange={v => setValue('birthDate', isNil(v) ? null : v.format('DD/MM/YYYY'))}
              selected={isNil(getValue('birthDate')) ? null : moment(getValue('birthDate'), 'DD/MM/YYYY')}
              style={getStyle('birthDate')}
              filterDate={birthDateIsValid}
              customInput={<InputMask mask="99/99/9999"/>}
            />
          </div>

          <div className="field field-text-input">
            <span>{'Pays de naissance*'}</span>

            <Select
              value={getValue('birthCountry')}
              placeholder={'Sélectionner le pays de naissance...'}
              options={countries}
              labelKey="country"
              valueKey="country"
              onChange={v => {
                setValue('birthCountry', v.country);

                if (v.country !== 'FRANCE') {
                  setValue('birthCity', v.code);
                } else {
                  setValue('birthCity', null);
                }
              }}
              style={getStyle('birthCountry')}
              clearable={false}
              arrowRenderer={null}
            />
          </div>

          {escBirthCountryIsFrance && <div className="field field-text-input">
            <span>{'Ville de naissance*'}</span>

            <SelectCity
              placeholder="Sélectionner la ville de naissance..."
              style={getStyle('birthCity')}
              value={getValue('birthCity')}
              onChange={v => {
                setValue('birthCity', v.code);
              }}
            />
          </div>}

          {displayEmailUnicityError && <div className="text-red-500">
            {'Alerte : L’email est déjà associé à un compte ou incorrecte. Merci de la modifier. Le compte administrateur ne sera pas créé.'}
          </div>}
        </div>;
      }}
    />}
  </div>;
};

export default EscAdmin;
