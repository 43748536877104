/**
 * @flow
 * @relayHash a786d9d0e61f197760e4ba332ddd07c6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PropositionStatusEnum = "active" | "closed" | "confirmed" | "confirmedInManagementSystem" | "confirmedInManagementSystemReopened" | "done" | "duplicate" | "ready" | "rejected" | "rejectedByManagementSystem" | "sendingForSignature" | "sentForSignature" | "sentForSignatureDelayed" | "sentToManagementSystem" | "unhold" | "%future added value";
export type RelatedContractStepQueryVariables = {|
  id: string
|};
export type RelatedContractStepQueryResponse = {|
  +proposition: ?{|
    +id: ?string,
    +numberOfContracts: ?number,
    +status: ?PropositionStatusEnum,
  |}
|};
export type RelatedContractStepQuery = {|
  variables: RelatedContractStepQueryVariables,
  response: RelatedContractStepQueryResponse,
|};
*/


/*
query RelatedContractStepQuery(
  $id: ID!
) {
  proposition(id: $id) {
    id
    numberOfContracts
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Proposition",
    "kind": "LinkedField",
    "name": "proposition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numberOfContracts",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RelatedContractStepQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RelatedContractStepQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "a786d9d0e61f197760e4ba332ddd07c6",
    "metadata": {},
    "name": "RelatedContractStepQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c547cdaf4b381e9fb3a2f70a8b5b093';

module.exports = node;
