/**
 * @flow
 * @relayHash 7af55a47536f172e0db28a589673fb33
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CivilityEnum = "F" | "M" | "%future added value";
export type EscAdminTypeEnum = "AUCUN" | "AUTRE" | "SIGNATAIRE" | "%future added value";
export type EscAdminInput = {|
  birthCity?: ?string,
  birthCountry?: ?string,
  birthDate?: ?string,
  civility?: ?CivilityEnum,
  email?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  phoneNumber?: ?string,
  role?: ?string,
  roleCode?: ?string,
  type?: ?EscAdminTypeEnum,
  unicityCheck?: ?UnicityCheckInput,
|};
export type UnicityCheckInput = {|
  unique?: ?boolean
|};
export type useCheckEmailUnicityQueryVariables = {|
  values: EscAdminInput
|};
export type useCheckEmailUnicityQueryResponse = {|
  +checkEmailUnicity: ?boolean
|};
export type useCheckEmailUnicityQuery = {|
  variables: useCheckEmailUnicityQueryVariables,
  response: useCheckEmailUnicityQueryResponse,
|};
*/


/*
query useCheckEmailUnicityQuery(
  $values: EscAdminInput!
) {
  checkEmailUnicity(values: $values)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "values",
    "type": "EscAdminInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "values",
        "variableName": "values"
      }
    ],
    "kind": "ScalarField",
    "name": "checkEmailUnicity",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCheckEmailUnicityQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCheckEmailUnicityQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7af55a47536f172e0db28a589673fb33",
    "metadata": {},
    "name": "useCheckEmailUnicityQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd32ba8faa87e8341f8ac5faf073b25ce';

module.exports = node;
