/**
 *
 * svg files should be installed into /public/icons
 */

import * as R from 'ramda';

import React from 'react';

const mapTo = {
};

const Icon = ({ name, height }) => (
  <img
    alt="icone"
    src={`/icon/${R.propOr(name, name, mapTo)}.svg`}
    style={{
      height
    }}
  /> 
);

Icon.defaultProp = {
  height: 'auto'
};

export default Icon;
