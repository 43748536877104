import './CommercialInfoBlocksSelectCollege.less';
import React from 'react';
import classNames from 'classnames';

const CommercialInfoBlocksSelectCollege = ({
  selectedCollege,
  colleges,
  onChange
}) => (
  <div className="commercialinfoblocksselectcollege">
    {colleges.map((college) => (
      <div
        role="button"
        tabIndex={0}
        className={classNames('commercialinfoblocksselectcollege-item', { 'commercialinfoblocksselectcollege-item-selected': selectedCollege === college })}
        key={college}
        onClick={() => onChange(college)}
      >
        {{
          C: 'Cadre',
          NC: 'Non Cadre',
          AM: 'Agent de Maîtrise'
        }[college]}
      </div>
    ))}
  </div>
);

export default CommercialInfoBlocksSelectCollege;