import * as R from 'ramda';
import React from 'react';
import ContractNumbers from './ContractNumbers';

const ContractNumbersByOfferType = ({
  contractNumbers,
  numberOfContracts,
  contractDescription,
  offerTypeLabel,
  offerType,
  commonProps,
  staff
}) => {
  if (R.isNil(numberOfContracts) || numberOfContracts < 0) {
    return null;
  }

  return <div>
    <div className="font-bold text-[26px] mb-4">Offres {offerTypeLabel}</div>

    <ContractNumbers
      value={numberOfContracts}
      contractNumbers={contractNumbers}
      contractDescription={contractDescription}
      offerType={offerType}
      staff={staff}
      {...commonProps}
    />
  </div>;
};

export default ContractNumbersByOfferType;
