/**
 * @flow
 * @relayHash 74f7600e51ceb43caf63d5d0a0e8fb2e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResumePropositionMutationVariables = {|
  propositionId: string
|};
export type ResumePropositionMutationResponse = {|
  +resumeProposition: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type ResumePropositionMutation = {|
  variables: ResumePropositionMutationVariables,
  response: ResumePropositionMutationResponse,
|};
*/


/*
mutation ResumePropositionMutation(
  $propositionId: String!
) {
  resumeProposition(propositionId: $propositionId) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propositionId",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propositionId",
        "variableName": "propositionId"
      }
    ],
    "concreteType": "PropositionResponse",
    "kind": "LinkedField",
    "name": "resumeProposition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResumePropositionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResumePropositionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "74f7600e51ceb43caf63d5d0a0e8fb2e",
    "metadata": {},
    "name": "ResumePropositionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '129a9a08cb3a7483ec41cf8bfe4b57c7';

module.exports = node;
