import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation SendForSignatureMutation($propositionId: String!, $type: SignatureTypeEnum!, $mode: SignatureModeEnum) {
  sendForSignature(propositionId: $propositionId, type: $type, mode: $mode) { 
    ok
    token
    error
  }
}
`;

export default ({ propositionId, type, mode }, done) => {
  const variables = {
    propositionId,
    type,
    mode,
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error, token } = response.sendForSignature;
        done(ok, token, error);
      },
      onError: err => console.error(err),
    }
  );
};
