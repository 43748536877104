
import './Blocks.less';
import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';

import { Spring, config } from 'react-spring';

const useHBars = (value) => {
  const max = R.reduce((acc, value) => R.max(acc, value), 0, value);
  const values = R.map(v => [v, v * 100 / max], value);
  return {
    max,
    values
  };
};

const HBars = ({
  label,
  value,
  round,
  background,
}) => {

  const { max, values } = useHBars(value);
  return (
    <Spring
      config={config.slow}
      from={{ height: 0, gauge: 0 }}
      to={{ height: 100, gauge: max }}
      delay={250}
    >
      {
        ({ gauge, height }) => (
          <div className={`block-hbars__bars-container block-hbars__bar-container-${background}`}>
            {
              values.map((v, index) => (
                <div
                  className="block-hbars__bar-container"
                  key={index}
                >
                  <div>{label.gauge}</div>
                  <div 
                    className="block-hbars__bar"
                  >
                    <div className="block-hbars__bar-label-left">{label.bars[index]}</div>
                    <div
                      className="block-hbars__bar-value" 
                      style={{ width: `${R.min(v[1], height)}px` }}
                    />
                    <div style={{ marginLeft: 8 }}>
                      {`${Number(R.min(v[0], gauge)).toFixed(round)} %`}
                    </div>
                  </div>
                  <div>{label.bottom}</div>
                </div>
              ))
            }
          </div>
        )
      }
    </Spring>
  ); 
};

HBars.defaultProps = {
  background: null,
  round: 0
};

HBars.propTypes = {
  background: PropTypes.oneOf([null, 'green']),
  round: PropTypes.number,
  value: PropTypes.number,
  label: PropTypes.string
};

export default HBars;
