import { always, compose, fromPairs, is, map, propOr, replace, split, unless } from 'ramda';

const getQueryParams = compose(
  fromPairs,
  map(split('=')),
  split('&'),
  replace('?', ''),
  unless(is(String), always('')),
  propOr('', 'search'),
);

export default getQueryParams;
