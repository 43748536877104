import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation ConvertPropositionCommercialeMutation($propositionId: String!, $filename: String!) {
  convertPropositionCommerciale(propositionId: $propositionId, filename: $filename) { 
    ok
    token
  }
}
`;

export default (propositionId, filename, done) => {
  const variables = {
    propositionId,
    filename
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, token } = response.convertPropositionCommerciale;
        done(ok, token);
      },
      onError: err => console.error(err),
    }
  );
};
