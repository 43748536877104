/**
 * @flow
 * @relayHash 665e3c355d508bfb2bd11bc8457993b4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CivilityEnum = "F" | "M" | "%future added value";
export type CollegeEnum = "AM" | "APP" | "C" | "NC" | "TOUS" | "%future added value";
export type EscAdminTypeEnum = "AUCUN" | "AUTRE" | "SIGNATAIRE" | "%future added value";
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type PropositionSourceEnum = "SPID" | "SPID_CLIENT" | "%future added value";
export type RelatedContractTypeEnum = "PARTIEL" | "TOTAL" | "%future added value";
export type TariffStructureEnum = "FAMILLE" | "ISOLE" | "UNIFORME" | "%future added value";
export type PropositionInput = {|
  ICPS?: ?ICPSInput,
  adhesionDate?: ?string,
  amendment?: ?AmendmentInput,
  background?: ?BackgroundInput,
  brokerage?: ?BrokerageInput,
  ccnId?: ?string,
  companyInformation?: ?CompanyInformationInput,
  contractDescription?: ?ContractDescriptionInput,
  contractNumbers?: ?$ReadOnlyArray<?string>,
  duplicata?: ?boolean,
  extraEstablishments?: ?$ReadOnlyArray<?SiretInfoInput>,
  health?: ?ContractHealthInput,
  medicalCheck?: ?boolean,
  offerTypes?: ?$ReadOnlyArray<?OfferTypeEnum>,
  overPricedRate?: ?number,
  postCode?: ?string,
  propositionId?: ?string,
  relatedContract?: ?RelatedContractInput,
  siren?: ?string,
  siret?: ?string,
  siretInfo?: ?SiretInfoInput,
  source?: ?PropositionSourceEnum,
  staff?: ?StaffFiguresInput,
  startDate?: ?string,
  status?: ?string,
  studyAtIndem?: ?boolean,
  turnovers?: ?$ReadOnlyArray<?TurnoverInput>,
|};
export type ICPSInput = {|
  reason?: ?string,
  terminationDate?: ?string,
|};
export type AmendmentInput = {|
  formerColleges?: ?$ReadOnlyArray<?string>,
  formerContractDescription?: ?ContractDescriptionInput,
  formerContractId?: ?string,
  formerNumberOfContracts?: ?number,
  formerStaff?: ?StaffFiguresInput,
  isAmendment?: ?boolean,
|};
export type ContractDescriptionInput = {|
  AM?: ?ProductInput,
  APP?: ?ProductInput,
  C?: ?ProductInput,
  NC?: ?ProductInput,
  NC_C?: ?ProductInput,
  TOUS?: ?ProductInput,
|};
export type ProductInput = {|
  bases?: ?$ReadOnlyArray<?BaseProductInput>
|};
export type BaseProductInput = {|
  baseId?: ?string,
  options?: ?$ReadOnlyArray<?BaseProductOptionInput>,
|};
export type BaseProductOptionInput = {|
  optionId?: ?string
|};
export type StaffFiguresInput = {|
  AM?: ?StaffFigureInput,
  APP?: ?StaffFigureInput,
  C?: ?StaffFigureInput,
  NC?: ?StaffFigureInput,
  TOUS?: ?StaffFigureInput,
|};
export type StaffFigureInput = {|
  count?: ?number,
  isSelected?: ?boolean,
|};
export type BackgroundInput = {|
  atmtt?: ?BackgroundAtmttInput,
  hasFormerContract?: ?boolean,
|};
export type BackgroundAtmttInput = {|
  count?: ?number,
  isSelected?: ?boolean,
|};
export type BrokerageInput = {|
  commercialCode?: ?string,
  commercialCodeUserEmail?: ?string,
  isSelected?: ?boolean,
  provider?: ?string,
|};
export type CompanyInformationInput = {|
  accountantEmail?: ?string,
  commercialName?: ?string,
  contactEmail?: ?string,
  contactFirstname?: ?string,
  contactIsSigner?: ?boolean,
  contactLastname?: ?string,
  contactMobile?: ?string,
  contactRole?: ?string,
  contactRoleCode?: ?string,
  escAdmin?: ?EscAdminInput,
  fixedAPE?: ?string,
  fixedAddress?: ?FixedAddressInput,
  sendToAccountant?: ?boolean,
|};
export type EscAdminInput = {|
  birthCity?: ?string,
  birthCountry?: ?string,
  birthDate?: ?string,
  civility?: ?CivilityEnum,
  email?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  phoneNumber?: ?string,
  role?: ?string,
  roleCode?: ?string,
  type?: ?EscAdminTypeEnum,
  unicityCheck?: ?UnicityCheckInput,
|};
export type UnicityCheckInput = {|
  unique?: ?boolean
|};
export type FixedAddressInput = {|
  city?: ?string,
  fields?: ?$ReadOnlyArray<?string>,
  isChecked?: ?boolean,
  postCode?: ?string,
|};
export type SiretInfoInput = {|
  address?: ?AddressInput,
  ape?: ?string,
  codeApplication?: ?string,
  creationDate?: ?string,
  identifiantDansSilo?: ?string,
  isHeadOffice?: ?boolean,
  legalForm?: ?string,
  manual?: ?boolean,
  naf?: ?string,
  name?: ?string,
  nbrOfEstablishment?: ?number,
  nic?: ?string,
  releaseStatusIsPartial?: ?boolean,
  siren?: ?string,
  siret?: ?string,
|};
export type AddressInput = {|
  city?: ?string,
  fields?: ?$ReadOnlyArray<?string>,
  postCode?: ?string,
|};
export type ContractHealthInput = {|
  amendment?: ?AmendmentInput,
  ccnVersion?: ?number,
  contractDescription?: ?ContractDescriptionInput,
  contractNumbers?: ?$ReadOnlyArray<?string>,
  insurer?: ?string,
  medicalCheck?: ?boolean,
  numberOfContracts?: ?number,
  regime?: ?string,
  staff?: ?StaffFiguresInput,
  tariffStructure?: ?TariffStructureEnum,
  turnovers?: ?$ReadOnlyArray<?TurnoverInput>,
|};
export type TurnoverInput = {|
  college?: ?CollegeEnum,
  contractNumberIndex?: ?number,
  value?: ?number,
|};
export type RelatedContractInput = {|
  checked?: ?boolean,
  closePreviousContract?: ?boolean,
  contractNumbers?: ?$ReadOnlyArray<?string>,
  hasKBIS?: ?boolean,
  oldLegalForm?: ?string,
  oldNic?: ?string,
  oldSiren?: ?string,
  startDate?: ?string,
  type?: ?RelatedContractTypeEnum,
|};
export type SavePropositionMutationVariables = {|
  proposition: PropositionInput
|};
export type SavePropositionMutationResponse = {|
  +saveProposition: ?{|
    +ok: boolean,
    +error: ?string,
    +propositionId: ?string,
    +contractNumbers: ?$ReadOnlyArray<?string>,
    +ccnVersion: ?number,
    +healthCcnVersion: ?number,
  |}
|};
export type SavePropositionMutation = {|
  variables: SavePropositionMutationVariables,
  response: SavePropositionMutationResponse,
|};
*/


/*
mutation SavePropositionMutation(
  $proposition: PropositionInput!
) {
  saveProposition(proposition: $proposition) {
    ok
    error
    propositionId
    contractNumbers
    ccnVersion
    healthCcnVersion
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "proposition",
    "type": "PropositionInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "proposition",
        "variableName": "proposition"
      }
    ],
    "concreteType": "PropositionResponse",
    "kind": "LinkedField",
    "name": "saveProposition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "propositionId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contractNumbers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ccnVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "healthCcnVersion",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SavePropositionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SavePropositionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "665e3c355d508bfb2bd11bc8457993b4",
    "metadata": {},
    "name": "SavePropositionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'be71201895827fdae6e5106781c1e66a';

module.exports = node;
