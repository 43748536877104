import './style.css';
import React, { useState, useEffect } from 'react';
import MenuLayout from '../../layout/menu/Layout';
import * as R from 'ramda';
import { Button, Input, Select, Spin } from 'antd';
import departements from '../../../server/data/departments';
import { graphql, QueryRenderer } from 'react-relay';
import EstablishmentCcns from './components/EstablishmentCcns';
import Establishments from './components/Establishments';
import environment from '../../graphql/Environment';
import Title from './components/Title';

const CcnsQuery = graphql`
  query CcnsQuery {
    ernestCcns {
      ape
      idcc
      ccnRef
      title
      shortTitle
    }
  }
`;

const useSearchCompanyCcn = () => {
  const [state, setState] = useState({
    search: '',
    department: undefined,
    showSearch: false,
    selectedEstablishment: undefined
  });

  useEffect(() => {
    setState(R.compose(
      R.assoc('showSearch', false),
      R.assoc('selectedEstablishment', undefined)
    ));
  }, [state.search, state.department]);

  const updateState = (k, v) => setState(R.assoc(k, v));

  const onSelectEstablishment = (checked, establishment) => {
    updateState('selectedEstablishment', checked ? establishment : undefined);
  };

  return {
    state,
    updateState,
    onSelectEstablishment
  };
};

const Ccns = () => {
  const {
    state,
    updateState,
    onSelectEstablishment
  } = useSearchCompanyCcn();

  return <MenuLayout bodyStyle={{ padding: '0 16px 20px 17px' }}>
    <Title>{'Trouver la CCN d\'une entreprise'}</Title>

    <div>
      <Input
        className="ccn-recommendations-input"
        placeholder="SIRET ou entreprise"
        style={{ textTransform: 'uppercase' }}
        value={state.search}
        onChange={e => {
          updateState('showSearch', false);
          updateState('search', e.target.value);
        }}
      />

      <Select
        className="ccn-recommendations-select"
        placeholder="Département"
        value={state.department}
        onChange={v => updateState('department', v)}
        allowClear
        showSearch
      >
        {R.map(([code, name]) => (
          <Select.Option
            key={code}
            value={code}
          >
            {code} - {name}
          </Select.Option>
        ))(departements)}
      </Select>

      <Button
        className="ccn-recommendations-button-blue"
        disabled={R.isEmpty(state.search)}
        onClick={() => updateState('showSearch', true)}
      >
        {'Rechercher'}
      </Button>

      {!R.isEmpty(state.search) && state.showSearch && (
        <Establishments
          department={state.department}
          onSelectEstablishment={onSelectEstablishment}
          selectedEstablishment={state.selectedEstablishment}
          search={state.search}
        />
      )}
    </div>

    {!R.isNil(state.selectedEstablishment) && (
      <>
        <Title>{'Choix de l\'offre CCN'}</Title>

        <QueryRenderer
          environment={environment}
          query={CcnsQuery}
          render={({ error, props }) => {
            if (error) {
              return <span>Une erreur est survenue.</span>;
            }

            if (!props) {
              return <Spin spinning/>;
            }

            const ccns = R.propOr([], 'ernestCcns')(props);

            return <EstablishmentCcns
              establishment={state.selectedEstablishment}
              ccns={ccns}
            />;
          }}
        />
      </>
    )}

  </MenuLayout>;
};

export default Ccns;
