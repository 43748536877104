import moment from 'moment';
const R = require('ramda');

const dateFormat = 'DD/MM/YYYY';
const minDate = moment('01/01/1910', dateFormat);

const birthDateIsValid = birthDate => {
  if (R.either(R.isNil, R.isEmpty)(birthDate)) {
    return false;
  }

  const momentDate = moment(birthDate, dateFormat);

  if (!momentDate.isValid()) {
    return false;
  }

  return momentDate.isSameOrAfter(minDate, 'day') && momentDate.isSameOrBefore(moment(), 'day');

};

export default birthDateIsValid;
