/**
 * @flow
 * @relayHash 65a5eaa2c2c0770ae089a881f4759089
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollegeEnum = "AM" | "APP" | "C" | "NC" | "TOUS" | "%future added value";
export type QCollegesWithMedicalCheckQueryQueryVariables = {|
  contractId: string
|};
export type QCollegesWithMedicalCheckQueryQueryResponse = {|
  +collegesWithMedicalCheck: ?$ReadOnlyArray<?CollegeEnum>
|};
export type QCollegesWithMedicalCheckQueryQuery = {|
  variables: QCollegesWithMedicalCheckQueryQueryVariables,
  response: QCollegesWithMedicalCheckQueryQueryResponse,
|};
*/


/*
query QCollegesWithMedicalCheckQueryQuery(
  $contractId: ID!
) {
  collegesWithMedicalCheck(contractId: $contractId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      }
    ],
    "kind": "ScalarField",
    "name": "collegesWithMedicalCheck",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QCollegesWithMedicalCheckQueryQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QCollegesWithMedicalCheckQueryQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "65a5eaa2c2c0770ae089a881f4759089",
    "metadata": {},
    "name": "QCollegesWithMedicalCheckQueryQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7752ae7cca205b6156719294315466e2';

module.exports = node;
