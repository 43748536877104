import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation PropositionsCommercialesMutation($token: String!) {
  propositionsCommerciales(token: $token) { 
    ok
    token
    status
    archiveName
  }
}
`;

export default (token, done) => {
  const variables = {
    token
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, token, status, archiveName, error } = response.propositionsCommerciales;
        done(ok, token, status, archiveName, error);
      },
      onError: err => console.error(err),
    }
  );
};
