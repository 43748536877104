import './Layout.less';
import React from 'react';

import Top from './Top';

const Layout = ({
  children
}) => {
  return (
    <div className="layout-admin">
      <Top/>
      <div className="layout-admin-body">
        {children}
      </div>
    </div>
  );
};

export default Layout;