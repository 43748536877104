import * as R from 'ramda';

import React from 'react';

import {
  HBars,
  VBars,
  Gauge,
  Ring,
  NotApplicable
} from './infoBlocks';

const blockTypeIs = (value) => (blockType) => R.propEq('blockType', blockType, value);

const InfoBlock = ({
  value
}) => {
  const blockIs = blockTypeIs(value);
  const background = R.pathOr('normal', ['blockProps', 'background'], value);
  return (
    <div className={`info-block-${background}`}>
      <div className="info-block-title">
        {value.title}
      </div>
      {
        R.isNil(value.value) && <NotApplicable/>
      }
      {
        !R.isNil(value.value) && (
          <div className={'info-block-body'}>
            {
              blockIs('gauge') && <Gauge {...value.blockProps} value={value.value} label={R.propOr({}, 'label', value)}/>
            }
            {
              blockIs('ring') && <Ring radius={97} {...value.blockProps} value={value.value} label={R.propOr({}, 'label', value)}/>
            }
            {
              blockIs('vbars') && <VBars {...value.blockProps} value={value.value} label={R.propOr({}, 'label', value)}/>
            }
            {
              blockIs('hbars') && <HBars {...value.blockProps} value={value.value} label={R.propOr({}, 'label', value)}/>
            }
          </div>
        )
      }
    </div>
  );
};

export default InfoBlock;
