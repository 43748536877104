import * as R from 'ramda';
import './Account.less';
import React, { useEffect, useState } from 'react';

import withUser from '../../withUser';

import {
  withRouter
} from 'react-router-dom';

import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import SignIn from './SignIn';
import SignUp from './SignUp';

import RequestPassword from './RequestPassword';
import ResetPassword from './ResetPassword';
import NewPasswordRequired from './NewPasswordRequired';
import ConnectionLayout from '../../layout/connection/Layout';
import RejectConfirm from './RejectConfirm';
import ConfirmAccount from './ConfirmAccount';
import Auth from './Auth';
import SignedOut from './SignedOut';
import SignedUpPending from './components/SignedUpPending';
import withSSO from '../../withSSO';

const userIsConnected = user => !R.isNil(user) && !R.isNil(user.username);
const userIsConnectedAndNotPending = user => !R.isNil(user) && !R.isNil(user.username) && !userStatusIsPending(user);
const userStatusIsPending = user => R.propOr('', 'status')(user) === 'pending';
const userMustUpdateIsPassword = user => R.propOr(false, 'resetPasswordRequired', user);

const Account = ({
  user,
  match,
  SSO_ENABLED
}) => {
  const [passwordMustBeUpdated, setPasswortMustBeUpdated] = useState(userMustUpdateIsPassword(user));
  const signUpNeeded = R.pathOr(false, ['extra', 'signUpNeeded'], user);

  useEffect(() => {
    let timeoutId = null;
    if (!user.resetPasswordRequired && passwordMustBeUpdated) {
      timeoutId = setTimeout(() => {
        setPasswortMustBeUpdated(false);
      }, 2000);
    }
    return (() => clearInterval(timeoutId));
  }, [passwordMustBeUpdated, user]);

  return (
    <ConnectionLayout>
      <div className="connection">
        <Switch>
          {!userIsConnectedAndNotPending(user) && <Route exact path={`${match.url}/signedout`} component={SignedOut} />}
          <Route exact path={`${match.url}/rejectconfirm`} component={RejectConfirm} />
          <Route exact path={`${match.url}/confirm`} component={ConfirmAccount} />
          {userIsConnectedAndNotPending(user) && passwordMustBeUpdated && <Route exact path={`${match.url}/newpasswordrequired`} component={NewPasswordRequired} />}
          {userIsConnectedAndNotPending(user) && !SSO_ENABLED && <Redirect to="/menu"/>}
          {userIsConnectedAndNotPending(user) && SSO_ENABLED && !signUpNeeded && <Redirect to="/menu"/>}
          <Route exact path={`${match.url}/signin`} component={SignIn} />
          <Route exact path={`${match.url}/auth`} component={Auth} />
          {userStatusIsPending(user) && !SSO_ENABLED && <Redirect to="/account/signin"/>}
          {((SSO_ENABLED && userIsConnected(user) && signUpNeeded) || (!SSO_ENABLED && !userIsConnected(user))) && <Route exact path={`${match.url}/signup`} component={SignUp} />}
          <Route exact path={`${match.url}/resetpasswordrequest`} component={RequestPassword} />
          <Route exact path={`${match.url}/resetpassword`} component={ResetPassword} />
          <Route exact path={`${match.url}/signeduppending`} component={SignedUpPending}/>
          {userStatusIsPending(user) && !signUpNeeded && SSO_ENABLED && <Redirect to="/account/signeduppending"/>}
          {!userIsConnectedAndNotPending(user) && SSO_ENABLED && <Redirect to="/account/auth"/>}
          {!userIsConnectedAndNotPending(user) && !SSO_ENABLED && <Redirect to="/account/signin"/>}
          {userIsConnected(user) && SSO_ENABLED && signUpNeeded && <Redirect to="/account/signup"/>}
        </Switch>
      </div>
    </ConnectionLayout>
  );
};

export default withSSO(withUser(withRouter(Account)));
