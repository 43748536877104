import { combineReducers } from 'redux';

import { proposition } from './reduxReducers/proposition';
import { redux } from '@fasstech/spid-front';

const reducers = combineReducers({
  contractDescription: redux.reducers.contractDescription,
  proposition
});

export default reducers;
