import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import CcnRecommandations from './CcnRecommandations';
import Checkbox from './CustomCheckbox';

const useEstablishmentCcns = ({ establishment, ccns }) => {
  const [selectedCcn, setSelectedCcn] = useState();
  const [ccnsFrom, setCcnsFrom] = useState();
  const [filteredCcns, setFilteredCcns] = useState([]);

  const filterCcns = () => {
    let filteredCcns = R.filter(
      R.compose(
        R.includes(R.__, R.propOr([], 'idccsFromDsn', establishment)),
        R.propOr('', 'idcc')
      )
    )(ccns);

    if (!R.isEmpty(filteredCcns)) {
      setCcnsFrom('dsn');
    } else {
      setCcnsFrom('ape');
      filteredCcns = R.filter(
        R.compose(
          R.includes(establishmentApe),
          R.propOr([], 'ape')
        )
      )(ccns);
    }

    setFilteredCcns(filteredCcns);
  };

  useEffect(() => {
    filterCcns();
  }, [establishment, ccns]);

  const onSelect = (checked, ccn) => {
    setSelectedCcn(checked ? ccn : undefined);
  };

  const establishmentApe = R.compose(
    R.replace(/\./g, ''),
    R.propOr('', 'ape')
  )(establishment);

  return {
    selectedCcn,
    onSelect,
    ccnsFrom,
    establishmentApe,
    filteredCcns
  };
};

const Ccn = ({
  ccn,
  selectedCcn = {},
  onSelect
}) => {
  return <div
    style={{
      padding: '20px',
      borderRadius: '8px',
      border: 'solid 1px #efebeb',
      backgroundColor: '#f6f6f6',
      marginTop: '20px'
    }}
  >
    <Checkbox
      style={{ float: 'right' }}
      onChange={e => onSelect(e.target.checked, ccn)}
      checked={ccn.ccnRef === selectedCcn.ccnRef}
    />

    {ccn.shortTitle}
  </div>;
};

const EstablishmentCcns = ({
  establishment,
  ccns
}) => {
  const {
    selectedCcn,
    onSelect,
    establishmentApe,
    filteredCcns,
    ccnsFrom
  } = useEstablishmentCcns({ establishment, ccns });

  if (R.either(R.isNil, R.isEmpty)(establishmentApe)) {
    return null;
  }

  if (R.isEmpty(filteredCcns)) {
    return <div style={{ marginTop: '20px' }}>
      <h3>{'Aucune convention collective n\'a pu être rapprochée par le code APE.'}</h3>
    </div>;
  }

  return <div style={{ marginTop: '20px' }}>
    <h3>{ccnsFrom === 'dsn' ? 'La convention collective déclarée dans la DSN' : 'La convention collective rapprochée par le code APE'}</h3>

    {R.map(ccn => (
      <Ccn
        key={ccn.ccnRef}
        ccn={ccn}
        selectedCcn={selectedCcn}
        onSelect={onSelect}
      />
    ))(filteredCcns)}

    {!R.isNil(selectedCcn) && (
      <CcnRecommandations
        ccn={selectedCcn}
        establishment={establishment}
      />
    )}
  </div>;
};

Ccn.propTypes = {
  ccn: PropTypes.object.isRequired,
  selectedCcn: PropTypes.object,
  onSelect: PropTypes.func.isRequired
};

EstablishmentCcns.propTypes = {
  establishment: PropTypes.object.isRequired,
  ccns: PropTypes.array.isRequired
};

export default EstablishmentCcns;
