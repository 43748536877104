import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation CloseAndDuplicateContractMutation($contractId: ID!) {
  closeAndDuplicateContract(contractId: $contractId) { 
    ok
    error
    newContractId
  }
}
`;

export default ({ contractId }, done) => {
  const variables = {
    contractId,
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, error, newContractId } = response.closeAndDuplicateContract;
        done(ok, error, newContractId);
      },
      onError: err => console.error(err),
    }
  );
};
