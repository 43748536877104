import React from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const Link = ({ onClick, children }) => <span
  onClick={onClick}
  style={{ color: 'blue', cursor: 'pointer' }}
>
  {children}
</span>;

const PanelHeader = ({ text }) => <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>{text}</span>;

export default openFile => ({
  managerDirectionCommerciale: <span>
    {'Votre compte est créé avec un mot de passe provisoire. Pour vous connecter à SPID, vous devez modifier votre mot de passe via le bouton « mot de passe perdu ». Un mail vous sera envoyé pour réinitialiser votre mot de passe.'}
    <br/><br/>
    Pour plus de détail, veuillez consulter la fiche pratique <Link onClick={openFile('reinitialisation_mot_de_passe.pdf')}>« Réinitialiser mon mot de passe ».</Link>
  </span>,
  managerGroupeAdhesion: <span>
    {'Vous devez créer votre compte via le bouton « Première visite, créer votre compte ». Et contacter la BG MOA SPID afin de valider votre compte.'}
  </span>,
  gestionnaire: <span>
    {'Vous devez créer votre compte via le bouton « Première visite, créer votre compte ».'}
    <br/>
    {'A la fin de la création de votre compte, un mail sera automatiquement envoyé à votre manager pour qu’il valide votre compte et ainsi vous donner accès à l’outil SPID.'}
  </span>,
  commercial: <span>
    {'Vous devez créer votre compte via le bouton « Première visite, créer votre compte ».'}
    <br/>
    {'A la fin de la création de votre compte, un mail sera automatiquement envoyé à votre manager pour qu’il valide votre compte et ainsi vous donner accès à l’outil SPID.'}
    <br/>
    {'Si vous ne trouvez le nom de votre manager sur la liste, veuillez contacter le help desk.'}
  </span>,
  probleme: <span>
    {'Pour toute question, nous vous invitons à contacter le help desk.'}
  </span>,
  problemeApporteur: <span>
    {'Pour toute question, nous vous invitons à contacter votre commercial référent.'}
  </span>,
  FAQ: <Collapse>
    <Panel key="1" header={<PanelHeader text={'Comment puis-je utiliser l’outil SPID ?'}/>}>
      <i>{'Vous devez avoir un compte validé pour utiliser l’outil SPID. Pour cela, vous devez créer un compte en vous connectant à SPID via DECLIC.'}</i>
    </Panel>
    <Panel key="2" header={<PanelHeader text={'Je tente de créer mon compte, mais je ne trouve pas mon manager dans la liste. Que dois-je faire ?'}/>}>
      <i>Votre manager doit contacter la BG MOA SPID.</i>
    </Panel>
    <Panel
      key="3" header={<PanelHeader
        text={'L’outil SPID m’informe que mon dossier est soumis à sélection médicale, que dois-je faire ?'}/>}>
      Vous devez cliquer sur « Envoyer le QM avec E-Sélection Médicale ».<br/><br/>

      Le client recevra par mail le questionnaire médical en version dématérialisé.<br/><br/>

      Le(s) salarié(s) complète(nt) le QM version dématérialisé.<br/><br/>

      La Direction des acceptations médicales vous contactera par mail à la finalisation de l’étude pour vous signifier
      qu’une réponse vous attend dans CATTIAA. Ensuite, vous pourrez reprendre votre proposition dans l’outil SPID.<br/><br/>

      Afficher fiche pratique <Link onClick={openFile('selection_medicale.pptx')}>« sélection médicale »</Link>.
    </Panel>
    <Panel key="4" header={<PanelHeader text={'Ma proposition est au statut « en attente de document », que cela veut-il dire ?'}/>}>
      La souscription est en attente d’un document pour être finalisée.<br/><br/>
      Soit la souscription est soumise à sélection médicale, il faut donc reprendre la proposition et télécharger l’attestation médicale transmise via CATIIAA ou la Direction des acceptations médicales pour transmettre les documents contractuels au client.
      <br/><br/>
      Soit la souscription est en attente d’une étude tarifaire, il faut reprendre la proposition et télécharger l’attestation d’encours récupérée dans l’outil « Saisie des encours ».
    </Panel>
  </Collapse>
});
