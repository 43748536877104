import './Top.less';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../uikit/UIKit';
import withUser from '../../withUser';
import Logo from '../components/Logo';
import { isEmpty, propOr } from 'ramda';
import onSignOut from '../../lib/onSignOut';

const SignOutButton = ({ user }) => {
  if (isEmpty(propOr('', 'email', user))) {
    return null;
  }

  return <Button
    onClick={onSignOut}
    look="white"
  >
    Déconnexion
  </Button>;
};

const Top = ({ user }) => {

  return <div className="layout-connection-top">
    <div className="layout-connection-top-wrapper">
      <div>
        <Link to="/"><Logo className="layout-connection-top-logo"/></Link>
      </div>
      <div>
        <SignOutButton user={user}/>
      </div>
    </div>
  </div>;
};

export default withUser(Top);
