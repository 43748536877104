import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

import * as R from 'ramda';

const mutation = graphql`
mutation ClosePropositionMutation($propositionId: String!, $reason: String) {
  closeProposition(propositionId: $propositionId, reason: $reason) { 
    ok
  }
}
`;

export default ({ propositionId, reason }, done) => {
  const variables = {
    propositionId,
    reason
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (store, response) => {
        const { ok } = response.closeProposition;
        if (ok) {
          const rec = store.get(propositionId);
          if (!R.isNil(rec))
            rec.setValue('closed', 'status');
        }
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.closeProposition;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
