import './SelectManager.less';

import * as R from 'ramda';
import React from 'react';

import PropTypes from 'prop-types';

import Select from 'react-select';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../graphql/Environment';
import Error from './Error';

const SelectManagerQuery = graphql`
query SelectManagerQuery($region: String) {
  managers(region: $region){
    id
    extra {
      firstname
      lastname
    }
  }
}
`;

const SelectManager = ({
  region,
  title,
  value,
  onChange
}) => (
  <QueryRenderer
    environment={environment}
    query={SelectManagerQuery}
    variables={{
      region
    }}
    render={({ error, props }) => {
      if (error) {
        return <Error/>;
      } else if (!props) {
        return null;
      }

      const managers = R.compose(
        R.map(manager => {
          return R.assoc(
            'name',
            `${R.pathOr('', ['extra', 'firstname'], manager)} ${R.pathOr('', ['extra', 'lastname'], manager)}`
          )(manager);
        }),
        R.pathOr([], ['managers'])
      )(props);

      return <div className="uikit-select-manager">
        <span className="uikit-selection-manager">
          {title}
        </span>

        <Select
          value={value}
          labelKey="name"
          valueKey="id"
          options={R.sortBy(R.prop('name'))(managers)}
          onChange={onChange}
          simpleValue={true}
          placeholder="Sélectionner"
          noResultsText={'Aucun manager trouvé'}
        />
      </div>;
    }}
  />
);

SelectManager.propTypes = {
  onChange: PropTypes.func,
  region: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string
};

export default SelectManager;
