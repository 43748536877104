import * as R from 'ramda';
import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import {
  propositionGoPreviousStep,
  propositionFetchingSendEmailReset,
  propositionPutUnhold, propositionSendEmail
} from '../../../reduxActions/proposition';

import {
  Button,
  FieldTitle
} from '../../../uikit/UIKit';
import MedicalCheck from '../components/medicalCheck/MedicalCheck';

const OutstandingCheck_mutualized_Step = ({
  medicalCheck,
  goPreviousStep,
  putUnholdWaitingForOutstandingAnnexe,
  userRole,
  reset,
  sendEmailToCommercial,
  status
}) => {

  useEffect(() => {
    reset();
  }, [reset]);

  const isCommercialNetwork = userRole === 'reseau_commercial';

  return <>
    <FieldTitle
      text={'Votre dossier nécessite une reprise d\'encours' + (medicalCheck ? ' et un questionnaire médical.' : '.')}
      onBack={goPreviousStep}
    />

    {R.isNil(status) && <div>
      <div style={{ marginBottom: '20px' }}>{isCommercialNetwork ? 'Ce dossier nécessite une étude tarifaire, merci de demander une tarification via ISIS' : 'Votre dossier sera envoyé au réseau commercial.'}</div>

      {medicalCheck && <MedicalCheck/>}

      <Button
        style={{ marginTop: '80px' }}
        onClick={isCommercialNetwork ? putUnholdWaitingForOutstandingAnnexe : sendEmailToCommercial}
      >
        {isCommercialNetwork ? 'Mettre la proposition en attente' : 'Envoyer le dossier au réseau commercial'}
      </Button>
    </div>}

    {
      status &&
      <div>
        {'Préparation de l\'envoi.'}
      </div>
    }

    {!R.isNil(status) && !status &&
      <div>
        {'Le mail est en route.'}
        <Button style={{ marginTop: '80px' }} to="/menu">Retour au menu</Button>
      </div>
    }
  </>;
};

export default connect(
  ({ proposition }) => ({
    status: proposition.fetchingSendEmail.status,
    medicalCheck: proposition.data.medicalCheck,
    userRole: proposition.userRole
  }),
  dispatch => ({
    reset: () => dispatch(propositionFetchingSendEmailReset()),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    putUnholdWaitingForOutstandingAnnexe: () => dispatch(propositionPutUnhold(true)),
    sendEmailToCommercial: () => dispatch(propositionSendEmail('outstanding_check_to_commercial'))
  })
)(OutstandingCheck_mutualized_Step);
