/**
 * @flow
 * @relayHash aa559a924178bbeb9a17eec6ba57beb1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentTypeEnum = "declarativeAnnexe" | "medicalFeedback" | "outstandingAnnexe" | "%future added value";
export type UploadedDocumentInput = {|
  imported?: ?boolean,
  mimeType?: ?string,
  originalName?: ?string,
  path?: ?string,
  size?: ?number,
|};
export type StoreUploadedDocumentMutationVariables = {|
  propositionId: string,
  documentType: DocumentTypeEnum,
  uploadedDocument: UploadedDocumentInput,
|};
export type StoreUploadedDocumentMutationResponse = {|
  +storeUploadedDocument: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type StoreUploadedDocumentMutation = {|
  variables: StoreUploadedDocumentMutationVariables,
  response: StoreUploadedDocumentMutationResponse,
|};
*/


/*
mutation StoreUploadedDocumentMutation(
  $propositionId: String!
  $documentType: DocumentTypeEnum!
  $uploadedDocument: UploadedDocumentInput!
) {
  storeUploadedDocument(propositionId: $propositionId, documentType: $documentType, uploadedDocument: $uploadedDocument) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propositionId",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentType",
    "type": "DocumentTypeEnum!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uploadedDocument",
    "type": "UploadedDocumentInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentType",
        "variableName": "documentType"
      },
      {
        "kind": "Variable",
        "name": "propositionId",
        "variableName": "propositionId"
      },
      {
        "kind": "Variable",
        "name": "uploadedDocument",
        "variableName": "uploadedDocument"
      }
    ],
    "concreteType": "BasicResponse",
    "kind": "LinkedField",
    "name": "storeUploadedDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StoreUploadedDocumentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StoreUploadedDocumentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "aa559a924178bbeb9a17eec6ba57beb1",
    "metadata": {},
    "name": "StoreUploadedDocumentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c7d2d27fc2e23db8f0e998bebec2e5a1';

module.exports = node;
