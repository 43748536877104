import * as R from 'ramda';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { BtnNext } from '../components/.';
import { FieldTitle, TextInput } from '../../../uikit/UIKit';
import Checkbox from 'rc-checkbox';
import {
  propositionGoNextStep,
  propositionGoPreviousStep,
  propositionSetRelatedContract
} from '../../../reduxActions/proposition';
import withUser from '../../../withUser';
import SavePropositionMutation from '../../../graphql/mutations/SavePropositionMutation';
import useKeyPressEnter from '../../../hooks/useKeyPressEnter';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../graphql/Environment';
import { libs, formatters, validations } from '@fasstech/spid-front';
import Error from '../../../components/Error';

const useManageRelatedContract = ({
  relatedContract,
  propositionId,
  setRelatedContract,
  goNextStep,
  numberOfContracts,
  ccn
}) => {
  const contractNumbersCheckIsDisabled = R.pathOr(false, ['relatedContractsSettings', 'contractNumbersCheckDisabled'], ccn);
  const [newRelatedContract, setNewRelatedContract] = useState({ ...relatedContract });

  const onSaveContract = () => {
    setRelatedContract(newRelatedContract);
    SavePropositionMutation(propositionId, { relatedContract: newRelatedContract }, (ok) => {
      if (ok) goNextStep();
    });
  };

  const onUpdateOldLegalForm = (v) => {
    newRelatedContract.oldLegalForm = v;
    setNewRelatedContract(newRelatedContract);
  };

  const onUpdateContractNumber = index => v => {
    setNewRelatedContract(R.set(
      R.lensPath(['contractNumbers', index]),
      formatters.formatContractNumber(0, v, 'PREVOYANCE')
    ));
  };

  const onUpdateClosePreviousContract = (v) => {
    newRelatedContract.closePreviousContract = v;
    setNewRelatedContract(newRelatedContract);
  };

  const getProp = (prop, or = '') => R.pathOr(or, prop.split('.'))(newRelatedContract);

  const contractNumbersAreUniques = contractNumbersCheckIsDisabled || R.compose(
    R.equals(numberOfContracts),
    R.length,
    R.uniq,
    R.propOr([], 'contractNumbers')
  )(newRelatedContract);

  const isDisabled = R.either(R.isNil, R.isEmpty)(getProp('oldLegalForm')) || !contractNumbersAreUniques || R.length(getProp('contractNumbers', [])) !== numberOfContracts || R.reduce((contractNumbersAreNotValid, contractNumber) => {
    return contractNumbersAreNotValid || R.isEmpty(contractNumber) || !validations.contractNumberIsValid(contractNumber, 'PREVOYANCE');
  }, false)(getProp('contractNumbers', []));

  return {
    getProp,
    onSaveContract,
    onUpdateClosePreviousContract,
    onUpdateContractNumber,
    onUpdateOldLegalForm,
    isDisabled
  };
};

const RelatedContractStepQuery = graphql`
  query RelatedContractStepQuery($id: ID!) {
    proposition(id: $id) {
      id
      numberOfContracts
      status
    }
  }
`;

const RelatedContract = ({
  relatedContract,
  propositionId,
  setRelatedContract,
  goNextStep,
  numberOfContracts,
  goPreviousStep,
  contractDescription,
  ccnId,
  ccn
}) => {
  const {
    getProp,
    onSaveContract,
    onUpdateClosePreviousContract,
    onUpdateContractNumber,
    onUpdateOldLegalForm,
    isDisabled
  } = useManageRelatedContract({ numberOfContracts, relatedContract, propositionId, setRelatedContract, goNextStep, ccn });
  const [update, setUpdate] = useState(0);

  const onChange = (value, onUpdate) => {
    setUpdate(update + 1);
    onUpdate(value);
  };

  useKeyPressEnter(onSaveContract, !isDisabled);

  return <div>
    <FieldTitle
      text="Suite économique Prévoyance"
      onBack={goPreviousStep}
    />

    <div style={{ marginTop: '16px' }}>
      <div className="field field-text-input">
        <span>Ancienne dénomination sociale*</span>
        <TextInput
          type="text"
          placeholder=" "
          value={getProp('oldLegalForm')}
          onChange={e => onChange(e.target.value, onUpdateOldLegalForm)}
          style={{ borderColor: R.isNil(getProp('oldLegalForm')) || R.isEmpty(getProp('oldLegalForm')) ? 'orange' : undefined }}
        />
      </div>

      {R.times(index => {
        return <div className="field field-text-input" key={index}>
          <span>{libs.getContractNumberLabel(index, numberOfContracts, contractDescription, ccnId, ccn)} du prédecesseur*</span>
          <TextInput
            type="text"
            value={getProp(`contractNumbers.${index}`)}
            onChange={e => onChange(e.target.value, onUpdateContractNumber(index))}
            placeholder="Numéro du contrat"
            style={{
              maxWidth: '180px',
              borderColor: !validations.contractNumberIsValid(getProp(`contractNumbers.${index}`), 'PREVOYANCE') ? 'orange' : undefined
            }}
          />
        </div>;
      })(numberOfContracts)}

      <div className="field field-checkbox">
        <Checkbox
          checked={getProp('closePreviousContract')}
          onChange={e => onChange(e.target.checked, onUpdateClosePreviousContract)}
        />
        <span>Fermer le ou les contrat(s) prédécesseur(s)</span>
      </div>

      <span
        style={{ marginTop: '10px' }}>{'Une reprise des engagements doit être jointe à chaque contrat du prédécesseur.'}</span><br/><br/>
      <span>{'Pensez à vérifier que le ou les contrat(s) prédécesseur(s) n\'a / n\'ont pas de prestation en cours.'}</span>
    </div>

    <BtnNext
      disabled={isDisabled}
      onClick={() => onSaveContract()}
      title={'Valider'}
    />
  </div>;
};

const RelatedContractStep = ({
  goNextStep,
  goPreviousStep,
  propositionId,
  ccnId,
  ccn,
  contractDescription,
  relatedContract,
  setRelatedContract
}) => {
  return <QueryRenderer
    environment={environment}
    query={RelatedContractStepQuery}
    variables={{ id: propositionId }}
    render={({ error, props }) => {
      if (error) {
        return <Error/>;
      } else if (!props) return null;

      const { proposition } = props;

      if (!R.isNil(proposition) && proposition.status !== 'active') {
        return (
          <div>
            <h3>{'Cette proposition n\'est plus disponible.'}</h3>
          </div>
        );
      } else {
        const numberOfContracts = R.propOr(0, 'numberOfContracts', proposition);

        return <RelatedContract
          goNextStep={goNextStep}
          numberOfContracts={numberOfContracts}
          propositionId={propositionId}
          relatedContract={relatedContract}
          setRelatedContract={setRelatedContract}
          ccnId={ccnId}
          contractDescription={contractDescription}
          goPreviousStep={goPreviousStep}
          ccn={ccn}
        />;
      }
    }}
  />;
};

export default connect(
  (state) => {
    const data = R.pathOr({}, ['proposition', 'data'], state);
    const v = k => R.path(k.split('.'), data);

    return {
      propositionId: v('propositionId'),
      ccnId: v('ccn.id'),
      ccn: v('ccn'),
      contractDescription: v('contractDescription'),
      relatedContract: R.propOr({}, 'relatedContract')(data)
    };
  },
  (dispatch) => ({
    setRelatedContract: (value) => dispatch(propositionSetRelatedContract(value)),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    goNextStep: () => dispatch(propositionGoNextStep())
  })
)(withUser(RelatedContractStep));
