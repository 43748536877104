import './SelectRegion.less';

import * as R from 'ramda';
import React from 'react';

import PropTypes from 'prop-types';

import Select from 'react-select';

import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../graphql/Environment';

const SelectRegionQuery = graphql`
  query SelectRegionQuery {
    data {
      regions {
        code
        title
      }
    }
  }
`;

const SelectRegion = ({
  onChange,
  title,
  value
}) => (
  <QueryRenderer
    environment={environment}
    query={SelectRegionQuery}
    render={({ props }) => {
      if (R.isNil(props)) return null;

      const regions = R.pathOr([], ['data', 'regions'])(props);

      return (
        <div className="uikit-select-region">
          <span className="uikit-selection-region-title">
            {title}
          </span>
          <Select
            value={value}
            labelKey="title"
            valueKey="code"
            options={regions}
            onChange={onChange}
            simpleValue={true}
            placeholder="Sélectionner"
          />
        </div>
      );
    }}
  />
);

SelectRegion.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.number
};

export default SelectRegion;
