import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../graphql/Environment';
import CompanyManualEstablishment from './components/CompanyManualEstablishment';
import CompanyEstablishmentDragAndDrop from './components/CompanyEstablishmentDragAndDrop';
import Loading from '../../../components/loading';

const CompanySelectEstablishmentQuery = graphql`
  query CompanySelectEstablishmentQuery($siren: String!) {
    sirenInfo(siren: $siren) {
      siren
      nbrOfEstablishment
      establishments {
        name
        siret
        isHeadOffice
        nic
        naf
        ape
        legalForm
        address {
          fields
          postCode
          city
          country
        }
        releaseStatusIsPartial
        creationDate
      }
    }
  }
`;

const reorder = (establishments) => {

  const reorderedEstablishments = [];

  R.forEach(establishment => {
    if (establishment.isHeadOffice) reorderedEstablishments.push(establishment);
  })(establishments);
  R.forEach(establishment => {
    if (!establishment.isHeadOffice) reorderedEstablishments.push(establishment);
  })(establishments);

  return reorderedEstablishments;

};

const CompanySelectEstablishment = ({
  siren,
  siretInfo,
  onSelect,
  extraEstablishments,
  duplicata,
  disabled,
  legalPersonsHasError,
  resetLegalPersonsHasError
}) => {
  const [manualState, setManualState] = useState('isManual');
  const [selectedEstablishments, setSelectedEstablishments] = useState([]);
  const [foundEstablishments, setFoundEstablishments] = useState([]);
  const [reload, setReload] = useState(1);
  const [currentSiren, setCurrentSiren] = useState(siren);

  useEffect(() => {
    if (siren !== currentSiren) {
      setCurrentSiren(siren);
      setSelectedEstablishments([]);
      setFoundEstablishments([]);
    }
  }, [siren]);

  if (R.isNil(siren)) {
    return null;
  }

  const getSirets = R.compose(
    R.reject(R.isNil),
    R.uniq,
    R.pluck('siret'),
    R.flatten
  );

  const onSelectManual = (values) => {
    const siretInfo = {
      address: {
        fields: [values.address],
        city: values.city,
        postCode: values.postCode
      },
      name: values.companyName,
      siret: `${siren}${values.nic}`,
      siren,
      isHeadOffice: values.isHeadOffice,
      nic: values.nic,
      naf: values.naf,
      ape: values.naf,
      legalForm: values.legalForm,
      manual: true
    };
    setSelectedEstablishments(selectedEstablishments.concat([siretInfo]));
    setManualState('isNotManual');
    setReload(reload + 1);
  };

  return <QueryRenderer
    environment={environment}
    query={CompanySelectEstablishmentQuery}
    variables={{
      siren,
      reload
    }}
    render={({ error, props }) => {
      if (R.isNil(props)) {
        return <Loading/>;
      }

      const k = key => R.propOr('', key)(R.pathOr('', ['sirenInfo'])(props));

      setManualState(
        manualState === 'isAdding' ?
          manualState
          :
          (R.length(k('establishments')) === 0 && R.equals({}, siretInfo) && R.length(selectedEstablishments) === 0 && R.length(foundEstablishments) === 0) ?
            'isManual'
            :
            'isNotManual'
      );

      const sirets = getSirets([selectedEstablishments, foundEstablishments, R.pathOr([], ['sirenInfo', 'establishments'])(props), [siretInfo]]);

      return <div style={{ display: error || !props ? 'none' : 'block' }}>
        { manualState !== 'isNotManual' && <CompanyManualEstablishment
          onSelectManual={onSelectManual}
          siren={siren}
          isAdding={manualState === 'isAdding'}
          setManualState={setManualState}
          siretInfo={manualState === 'isAdding' ? {} : siretInfo}
          sirets={sirets}
          display={manualState !== 'isNotManual' ? 'block' : 'none'}
          displayError={R.length(k('establishments')) === 0}
          legalPersonsHasError={legalPersonsHasError}
          resetLegalPersonsHasError={resetLegalPersonsHasError}
        />}

        {props && manualState === 'isNotManual' && <CompanyEstablishmentDragAndDrop
          establishments={reorder(k('establishments'))}
          siretInfo={siretInfo}
          extraEstablishments={extraEstablishments}
          onSelect={onSelect}
          duplicata={duplicata}
          setManualState={setManualState}
          selectedEstablishments={selectedEstablishments}
          setSelectedEstablishments={setSelectedEstablishments}
          foundEstablishments={foundEstablishments}
          setFoundEstablishments={setFoundEstablishments}
          disabled={disabled}
          legalPersonsHasError={legalPersonsHasError}
          resetLegalPersonsHasError={resetLegalPersonsHasError}
        />}

      </div>;
    }}
  />;
};

export default CompanySelectEstablishment;
