/**
 * @flow
 * @relayHash ec63860bcf7d621bac5934b705b98937
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getLegalPersonsQueryVariables = {|
  sirets: $ReadOnlyArray<string>
|};
export type getLegalPersonsQueryResponse = {|
  +legalPersons: ?$ReadOnlyArray<?{|
    +codeApplication: ?string,
    +identifiantDansSilo: ?string,
    +siret: ?string,
  |}>
|};
export type getLegalPersonsQuery = {|
  variables: getLegalPersonsQueryVariables,
  response: getLegalPersonsQueryResponse,
|};
*/


/*
query getLegalPersonsQuery(
  $sirets: [String!]!
) {
  legalPersons(sirets: $sirets) {
    codeApplication
    identifiantDansSilo
    siret
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sirets",
    "type": "[String!]!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "sirets",
        "variableName": "sirets"
      }
    ],
    "concreteType": "LegalPerson",
    "kind": "LinkedField",
    "name": "legalPersons",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "codeApplication",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "identifiantDansSilo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "siret",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getLegalPersonsQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getLegalPersonsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ec63860bcf7d621bac5934b705b98937",
    "metadata": {},
    "name": "getLegalPersonsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12ca36dea2c5b9466072ffc65abf1bbd';

module.exports = node;
