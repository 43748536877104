import * as R from 'ramda';
import './SignIn.less';
import Moment from 'moment';
import React, { useState } from 'react';
import withForm from '@thecodeisgreen/withform';
import useKeyPressEnter from '../../hooks/useKeyPressEnter';

import {
  Link, useHistory
} from 'react-router-dom';

import {
  Error
} from './Components';

import {
  Button,
  TextInput,
  FieldTitle
} from '../../uikit/UIKit';

import SignInMutation from '../../graphql/mutations/SignInMutation';
import { validations } from '@fasstech/spid-front';
import withSSO from '../../withSSO';

const fieldProps = {
  defaultValue: '',
  styleOnNotValid: { borderColor: 'orange' }
};

const useSignIn = (form) => {
  const [fetchSignIn, setFetchSignIn] = useState(false);
  const [error, setError] = useState(null);
  const [lockDuration, setLockDuration] = useState(null);

  const onResetError = () => {
    if (error) setError(null);
  };

  const onSignIn = () => {
    const { email, password } = form.values();
    setError(null);
    setFetchSignIn(true);

    SignInMutation({ email, password }, (ok, error, lockDuration) => {
      if (ok && !error) {
        window.location = '/';
      } else {
        setFetchSignIn(false);
        setError(error);
        setLockDuration(lockDuration);
      }
    });
  };

  return {
    fetchSignIn,
    error,
    lockDuration,
    onResetError,
    onSignIn,
  };
};

const SignIn = ({
  form,
  SSO_ENABLED
}) => {
  const history = useHistory();
  const {
    fetchSignIn,
    error,
    lockDuration,
    onResetError,
    onSignIn,
  } = useSignIn(form);

  const buttonDisabled = !form.isValid() || fetchSignIn;

  useKeyPressEnter(onSignIn, !buttonDisabled);

  if (SSO_ENABLED) {
    history.replace('/');
    return null;
  }

  return <div className="connection-box">

    <FieldTitle text="Connexion"/>
    {
      form.manageField('email', {
        isValid: v => validations.emailIsValid(v),
        isUpdated: () => onResetError(),
        ...fieldProps
      })(
        <TextInput
          type="email"
          title="Identifiant"
          placeholder="Votre email"
        />
      )
    }
    {
      form.manageField('password', {
        isValid: v => v.length > 6,
        isUpdated: () => onResetError(),
        ...fieldProps
      })(
        <TextInput
          title="Mot de passe"
          type="password"
          placeholder="Votre mot de passe"
        />
      )
    }
    <div className="sign-in-lost-password">
      <Link to="/account/resetpasswordrequest">mot de passe perdu</Link>
    </div>
    {error === 'user_is_locked' && <Error message={`Votre compte est bloqué (pendant encore ${Moment.duration(lockDuration).humanize()})`}/>}
    {error === 'user_is_pending' && <Error message="Votre compte n'est pas validé"/>}
    {!R.isNil(error) && !R.includes(error, ['user_is_locked', 'user_is_pending']) && <Error/>}
    <div className="sign-in-button-row">
      <Button
        disabled={buttonDisabled}
        fetching={fetchSignIn}
        onClick={onSignIn}
      >
        {'Connexion'}
      </Button>
      <div>
        Première visite,&nbsp;
        <Link to="/account/signup">créer votre compte</Link>
      </div>
    </div>
  </div>;
};

export default withSSO(withForm(SignIn));
