import * as R from 'ramda';
import './Modal.less';
import { withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';

import {
  Button
} from '../../uikit/UIKit';

/*
const enhance = compose(
  withRouter,
  withHandlers({
    onClose: ({ history }) => () => 
  }),
  lifecycle({
    componentDidMount () {
      document.onkeydown = (evt) => {
        evt = evt || window.event;
        if (evt.keyCode == 27) {
          this.onClose();
        }
      };
    }
  })
);
*/

const useModal = (history, onClose) => {
  
  const onCloseModal = () => {
    if (!R.isNil(onClose) && R.is(Function, onClose)) return onClose();
    history.goBack();
  };

  useEffect(() => {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        onCloseModal();
      }
    };
  });

  return {
    onCloseModal
  };
};

const Modal = ({
  history,
  onClose,
  children,
  isPopup
}) => {
  const {
    onCloseModal
  } = useModal(history, onClose);

  const className = (suffix) => `${isPopup ? 'popup-' : ''}modal-box-${suffix}`;
  return (
    <div className={className('root')}>
      <div className={className('close-top')}>
        <Button size="small" onClick={onCloseModal}>Fermer</Button>
      </div>
      <div className={className('content')}>
        <div>
          {children}
        </div>
        <div className={className('content-bottom')}>
          <Button
            onClick={onCloseModal}
          >
            {'Fermer'}
          </Button>
        </div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  isPopup: false
};
export default withRouter(Modal);

