import './PropositionListItem.less';
import * as R from 'ramda';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';
import Button from '../../../../uikit/buttons/Button';

const PropositionListItem = ({
  history,
  proposition
}) => {
  const getStatus = () => {
    return R.compose(
      R.prop(R.__, {
        active: 'ouverte',
        unhold: 'en attente d\'un document',
        sentForSignature: 'envoyée pour signature',
        sentForSignatureDelayed: 'Signature DEM TEL 24h',
        duplicate: 'duplicata généré',
        confirmed: 'signé',
        sentToManagementSystem: 'mise en gestion en cours',
        confirmedInManagementSystem: 'mise en gestion confirmée',
        confirmedInManagementSystemReopened: 'mise en gestion confirmée réouvert',
        rejectedByManagementSystem: 'mise en gestion rejetée',
        closed: 'clos'
      }),
      R.prop('status')
    )(proposition);
  };

  const isSigned = () => {
    return R.includes(R.propOr('', 'status')(proposition), ['confirmed', 'sentToManagementSystem', 'confirmedInManagementSystem', 'confirmedInManagementSystemReopened', 'rejectedByManagementSystem']);
  };

  const onSelect = (route) => {
    history.push(`/proposition${route}?propositionId=${proposition.id}`);
  };

  const attr = k => R.path(k.split('.'), proposition);

  return <div className="proposition-list-item">
    <div>
      { attr('relatedContract.checked') && <span>SUITE ÉCONOMIQUE<br/></span> }
      { attr('amendment.isAmendment') && <span>AVENANT<br/></span> }

      {`SIRET : ${attr('siret')}`}
      <br/>
      {`proposition n°${attr('propositionNumber')}`}
      {` - du ${Moment(attr('dates.lastUpdate')).format('DD/MM/YYYY HH:mm:ss')}`}
      <div className="proposition-list-item-status">
        {`${getStatus()}`}
      </div>
      <div className="proposition-list-item-buttons">
        { !isSigned() && <Button size="small" onClick={() => onSelect('')}>Reprendre</Button> }
        <Button size="small" onClick={() => onSelect('Info')}>Consulter</Button>
      </div>
    </div>
  </div>;
};

export default withRouter(PropositionListItem);
