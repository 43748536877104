import './Contracts.less';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import Contract from './Contract';
import { libs } from '@fasstech/spid-front';

const Contracts = ({
  ccn,
  bases,
  college,
  value,
  amendment,
  stepAmendment,
  offerType
}) => {
  return <div className="contracts">
    <div className="contracts-title">
      {libs.collegeLabel(college, ccn, offerType)}
    </div>

    <div className="contracts-body">
      {bases.map(base => <Contract
        key={base.id}
        base={base}
        college={college}
        value={R.propOr({}, base.id)(value)}
        amendment={amendment}
        stepAmendment={stepAmendment}
      />)}
    </div>
    <div className="contracts-bottom-line"/>
  </div>;
};

export default connect(
  ({ contractDescription: { bases }, proposition }, { college }) => {
    return {
      offerType: R.pathOr('PREVOYANCE', ['data', 'currentOfferType'])(proposition),
      bases: R.compose(
        R.filter(R.propEq('college', college)),
        R.when(
          R.isNil,
          R.always([])
        )
      )(bases)
    };
  }
)(Contracts);
