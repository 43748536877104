import { always, applySpec, assoc, assocPath, equals, find, path, prop, propEq } from 'ramda';
import { escAdminTypesEnum } from './escAdminTypes';

const getOption = v => find(propEq('value', v));
const styleOnNotValid = { borderColor: 'orange' };

const useEscAdmin = ({ escAdminPropValidations, setValues, values, resetEmailUnicityCheck }) => {
  const selectedType = path(['escAdmin', 'type'], values);
  const typeIs = equals(selectedType);
  const setValue = (prop, value) => {
    resetEmailUnicityCheck(false);
    setValues(assocPath(['escAdmin', prop], value));
  };

  const getValue = prop => path(['escAdmin', prop], values);
  const getStyle = prop => escAdminPropValidations[prop](getValue(prop)) ? {} : styleOnNotValid;
  const updateEscAdminType = type => {
    setValue('type', type);

    if (type === escAdminTypesEnum.SIGNATAIRE) {
      setValues(values => assoc(
        'escAdmin',
        applySpec({
          type: always(type),
          lastName: prop('contactLastname'),
          firstName: prop('contactFirstname'),
          role: prop('contactRole'),
          roleCode: prop('contactRoleCode'),
          email: prop('contactEmail'),
          phoneNumber: prop('contactMobile')
        })(values)
      )(values));
    }
  };

  return {
    getOption,
    styleOnNotValid,
    selectedType,
    typeIs,
    setValue,
    getValue,
    getStyle,
    updateEscAdminType
  };
};

export default useEscAdmin;
