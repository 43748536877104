/**
 * @flow
 * @relayHash 7e0286ef53389b92ea759caade86ad6f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SignatureModeEnum = "delayed" | "email" | "iframe" | "%future added value";
export type SignatureTypeEnum = "electronic" | "manual" | "%future added value";
export type SendForSignatureMutationVariables = {|
  propositionId: string,
  type: SignatureTypeEnum,
  mode?: ?SignatureModeEnum,
|};
export type SendForSignatureMutationResponse = {|
  +sendForSignature: ?{|
    +ok: boolean,
    +token: ?string,
    +error: ?string,
  |}
|};
export type SendForSignatureMutation = {|
  variables: SendForSignatureMutationVariables,
  response: SendForSignatureMutationResponse,
|};
*/


/*
mutation SendForSignatureMutation(
  $propositionId: String!
  $type: SignatureTypeEnum!
  $mode: SignatureModeEnum
) {
  sendForSignature(propositionId: $propositionId, type: $type, mode: $mode) {
    ok
    token
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propositionId",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type",
    "type": "SignatureTypeEnum!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mode",
    "type": "SignatureModeEnum"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mode",
        "variableName": "mode"
      },
      {
        "kind": "Variable",
        "name": "propositionId",
        "variableName": "propositionId"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "TokenResponse",
    "kind": "LinkedField",
    "name": "sendForSignature",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendForSignatureMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendForSignatureMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7e0286ef53389b92ea759caade86ad6f",
    "metadata": {},
    "name": "SendForSignatureMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e92d601e35232e53ded14119ff5317da';

module.exports = node;
