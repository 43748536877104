import environment from '../Environment';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import Error from '../../components/Error';
import Loading from '../../components/loading';
import { isNil, is, prop } from 'ramda';

const QAuthorizationUrlQuery = graphql`
  query QAuthorizationUrlQuery($redirectUrl: String) {
    authorizationUrl(redirectUrl: $redirectUrl)
  }
`;

const QAuthorizationUrl = ({ children, args }) => {
  return <QueryRenderer
    environment={environment}
    query={QAuthorizationUrlQuery}
    variables={args}
    render={({ error, props }) => {
      if (error) {
        return <Error/>;
      } else if (!props) {
        return <Loading/>;
      }

      const authorizationUrl = prop('authorizationUrl', props);

      if (isNil(authorizationUrl)) {
        return <Error/>;
      }

      if (is(Function, children)) {
        return children({ authorizationUrl });
      }
      return React.cloneElement(children, { authorizationUrl });
    }}
  />;
};

export default QAuthorizationUrl;
