import { isNil, not, path, pathOr, prop } from 'ramda';
import React, { useReducer } from 'react';
import withUser from '../withUser';
import { connect } from 'react-redux';
import { Modal } from 'antd';

const Tag = ({ jsonData, children, className = '' }) => {
  const [modalOpened, updateModalOpened] = useReducer(not, false);
  const formattedJson = JSON.stringify(jsonData, null, 10);

  return <>
    <Modal
      visible={modalOpened}
      onOk={updateModalOpened}
      onCancel={updateModalOpened}
      className="!w-11/12"
    >
      <textarea
        value={formattedJson}
        readOnly
        className="w-full leading-normal outline-none"
        rows={30}
      />
    </Modal>

    <div
      className={`rounded-sm cursor-pointer p-1 ${className}`}
      title={formattedJson}
      onClick={updateModalOpened}
    >
      {children}
    </div>
  </>;
};

const DevTags = ({ user = {}, proposition = {} }) => {
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  const propositionId = path(['data', 'propositionId'], proposition);
  const userEmail = prop('email', user);

  return <div className="absolute left-2 top-4 text-white text-xs">
    {!isNil(user) && !isNil(userEmail) && <Tag
      jsonData={user}
      className="bg-red-400"
    >
      {user.email}<br/>
      {user.role} / {pathOr('', ['extra', 'sales'], user)}
    </Tag>}

    {!isNil(proposition) && !isNil(propositionId) && <Tag
      jsonData={proposition}
      className="bg-blue-400"
    >
      {propositionId}
    </Tag>}
  </div>;
};

export default connect(
  state => ({
    proposition: state.proposition
  }),
)(withUser(DevTags));
