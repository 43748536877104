import * as R from 'ramda';
import React from 'react';
import { BtnNext, ContractDescriptionManager } from './index';
import { FieldTitle } from '../../../uikit/UIKit';
import useKeyPressEnter from '../../../hooks/useKeyPressEnter';
import withUser from '../../../withUser';
import { hooks } from '@fasstech/spid-front';
import VisionService from './contractDescription/VisionService';

const ContractDescriptionPrevoyance = ({
  amendment,
  bases,
  ccn,
  colleges,
  duplicata,
  formerContractDescription,
  goPreviousStep,
  goPreviousStepAmendment,
  hasValue,
  isUnknownAmendment,
  onNext,
  onUpdate,
  onUpdateAmendment,
  startDate,
  stepAmendment,
  value,
  visionServiceUrl,
  mustHaveAtLeastOneOfIsValid,
  loading
}) => {
  const {
    isClassic,
    messages,
    buttonDisabled,
    buttonOnNext,
    displayIsOriginEquals,
    displayCollegesDontHaveBase
  } = hooks.useContractDescription({
    bases,
    value,
    startDate,
    duplicata,
    amendment,
    isUnknownAmendment,
    stepAmendment,
    offerType: 'PREVOYANCE',
    hasValue,
    colleges,
    onNext,
    onUpdateAmendment,
    ccn,
    mustHaveAtLeastOneOfIsValid
  });

  useKeyPressEnter(buttonOnNext, !buttonDisabled);

  const contractDescriptionStepMessage = R.path(['PREVOYANCE', 'contractDescriptionStepMessage'], ccn);
  const isAmendment = R.propOr(false, 'isAmendment', amendment);

  return <div>
    {isAmendment
      ? (
        <FieldTitle
          text={!stepAmendment ? 'Sélection de(s) garantie(s) à ajouter en Avenant' : 'Sélection de(s) garantie(s) du ou des contrat(s) en gestion'}
          onBack={!isUnknownAmendment || stepAmendment ? goPreviousStep : goPreviousStepAmendment}
        />
      ) : (
        <FieldTitle
          text="Les contrats possibles par collège"
          onBack={goPreviousStep}
        />
      )}

    <div className="contract-description-actions">
      <div className="contract-description-actions-left">
        {/*{*/}
        {/*  R.includes('FEATURE_COMMERCIAL_INFORMATION', user.grants) && (*/}
        {/*    <Button size="medium" onClick={onToggleModal}>{'Détail des garanties'}</Button>*/}
        {/*  )*/}
        {/*}*/}
      </div>

      <VisionService visionServiceUrl={visionServiceUrl}/>
    </div>
    <div className="contract-description-step-colleges">
      {/*{*/}
      {/*  R.includes('FEATURE_COMMERCIAL_INFORMATION', user.grants) && showModal && (*/}
      {/*    <Modal*/}
      {/*      onClose={onToggleModal}*/}
      {/*    >*/}
      {/*      <Suspense fallback={<div>Loading...</div>}>*/}
      {/*        <Info*/}
      {/*          propositionId={propositionId}*/}
      {/*          ccnId={ccnId}*/}
      {/*        />*/}
      {/*      </Suspense>*/}
      {/*    </Modal>*/}
      {/*  )*/}
      {/*}*/}
      <ContractDescriptionManager
        bases={bases}
        colleges={colleges}
        defaultValue={stepAmendment ? formerContractDescription : value}
        onChange={onUpdate}
        amendment={amendment}
        stepAmendment={stepAmendment}
        ccn={ccn}
      />
    </div>
    <div>
      {displayIsOriginEquals && <div style={{ color: 'red' }}>L’avenant est identique au contrat d’origine, la souscription de l’avenant est impossible.</div>}
      {displayCollegesDontHaveBase && <div style={{ color: 'red' }}>L’avenant est impossible.</div>}
      {isClassic && !stepAmendment && <div style={{ color: 'red' }}>Vous ne pouvez pas créer d’avenant, il est nécessaire de créer une nouvelle souscription.</div>}
      {!R.isEmpty(messages) && <div style={{ color: 'red' }}>
        {R.map(message => <span key={message}>{message}<br/></span>)(messages)}
      </div>}
      {!R.isNil(contractDescriptionStepMessage) && <div style={{ color: 'red' }}>{contractDescriptionStepMessage}</div>}

      <BtnNext
        disabled={buttonDisabled}
        onClick={buttonOnNext}
        loading={loading}
        title={stepAmendment ? 'Valider le contrat d\'origine' : 'Valider'}
      />
    </div>
  </div>;
};

export default withUser(ContractDescriptionPrevoyance);
