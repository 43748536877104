import * as R from 'ramda';
import React from 'react';
import './CompanyEstablishmentStep.less';
import { connect } from 'react-redux';
import withUser from '../../../withUser';

import {
  propositionSetCompanySiren,
  propositionSetCompanySiret,
  propositionSetCompanySiretInfo,
  propositionSetExtraEstablishments,
  propositionGoPreviousStep,
  propositionGoNextStep, propositionSetLegalPersonsHasError
} from '../../../reduxActions/proposition';

import withForm from '@thecodeisgreen/withform';
import { FieldTitle, SirenInput } from '../../../uikit/UIKit';
import { CompanySelectEstablishment, } from '../components';
import getAmendmentInfos from '../../../lib/getAmendmentInfos';

const formatEstablishment = R.dissocPath(['address', 'country']);

const CompanyEstablishmentStep = ({
  duplicata,
  extraEstablishments,
  form,
  goNextStep,
  goPreviousStep,
  isKnownAmendment,
  setCompanySiren,
  setCompanySiret,
  setCompanySiretInfo,
  setExtraEstablishments,
  siren,
  siret,
  siretInfo,
  legalPersonsHasError,
  resetLegalPersonsHasError
}) => {
  const onNext = (headOffice, establishments) => {
    setCompanySiren(form.values().siren);
    setCompanySiret(headOffice.siret);
    setCompanySiretInfo(formatEstablishment(headOffice));
    setExtraEstablishments(R.map(formatEstablishment, establishments));
    goNextStep();
  };

  return <>
    <FieldTitle
      text="Informations de l'entreprise"
      onBack={goPreviousStep}
    />

    <div>
      <div className="company-siret-step">
        <span>Numéro de SIREN</span>
        {form.manageField('siren', { defaultValue: siren })(<SirenInput disabled={isKnownAmendment}/>)}
      </div>

      <CompanySelectEstablishment
        siren={form.values().siren}
        siret={siret}
        siretInfo={siretInfo}
        onSelect={onNext}
        extraEstablishments={extraEstablishments}
        duplicata={duplicata}
        disabled={isKnownAmendment}
        legalPersonsHasError={legalPersonsHasError}
        resetLegalPersonsHasError={resetLegalPersonsHasError}
      />
    </div>
  </>;
};

export default withForm(connect(
  (state) => {
    const proposition = R.propOr({}, 'proposition')(state);

    return {
      siren: R.path(['data', 'siren'], proposition),
      siret: R.path(['data', 'siret'], proposition),
      siretInfo: R.pathOr({}, ['data', 'siretInfo'])(proposition),
      extraEstablishments: R.pathOr([], ['data', 'extraEstablishments'])(proposition),
      duplicata: R.pathOr(false, ['data', 'duplicata'])(proposition),
      legalPersonsHasError: R.propOr(false, 'legalPersonsHasError', proposition),
      ...getAmendmentInfos(proposition.data)
    };
  },
  (dispatch) => ({
    setCompanySiren: (siren) => dispatch(propositionSetCompanySiren(siren)),
    setCompanySiret: (siret) => dispatch(propositionSetCompanySiret(siret)),
    setCompanySiretInfo: (siretInfo) => dispatch(propositionSetCompanySiretInfo(siretInfo)),
    setExtraEstablishments: (extraEstablishments) => dispatch(propositionSetExtraEstablishments(extraEstablishments)),
    resetLegalPersonsHasError: () => dispatch(propositionSetLegalPersonsHasError(false)),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    goNextStep: () => dispatch(propositionGoNextStep())
  })
)(withUser(CompanyEstablishmentStep)));
