import * as R from 'ramda';
import { useEffect, useState } from 'react';

const useKeyPressEnter = (fn, condition) => {
  const [executed, setExecuted] = useState(false);

  useEffect(() => {
    const listener = event => {
      if (condition && event.key === 'Enter' && R.is(Function, fn) && !executed) {
        setExecuted(true);
      }
    };

    window.addEventListener('keypress', listener);

    return () => {
      window.removeEventListener('keypress', listener);
    };
  }, [fn, condition, executed]);

  useEffect(() => {
    if (executed) {
      fn();
    }
  }, [executed]);

};

export default useKeyPressEnter;
