/**
 * @flow
 * @relayHash 8119c0d691ff8bdb5cf6fa5298fcbc6b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserRoleEnum = "admin" | "apporteur" | "groupe_adhesion" | "manager" | "reseau_commercial" | "%future added value";
export type SignUpMutationVariables = {|
  email: string,
  password?: ?string,
  role: UserRoleEnum,
  userCode?: ?number,
  region?: ?string,
  managementCenter?: ?string,
  lastname?: ?string,
  firstname?: ?string,
  sales?: ?string,
  commercialManagerId?: ?string,
  providerEmail?: ?string,
|};
export type SignUpMutationResponse = {|
  +signUp: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type SignUpMutation = {|
  variables: SignUpMutationVariables,
  response: SignUpMutationResponse,
|};
*/


/*
mutation SignUpMutation(
  $email: String!
  $password: String
  $role: UserRoleEnum!
  $userCode: Int
  $region: String
  $managementCenter: String
  $lastname: String
  $firstname: String
  $sales: String
  $commercialManagerId: String
  $providerEmail: String
) {
  signUp(email: $email, password: $password, role: $role, userCode: $userCode, region: $region, managementCenter: $managementCenter, lastname: $lastname, firstname: $firstname, sales: $sales, commercialManagerId: $commercialManagerId, providerEmail: $providerEmail) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "role",
    "type": "UserRoleEnum!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userCode",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "region",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "managementCenter",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lastname",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "firstname",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sales",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "commercialManagerId",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "providerEmail",
    "type": "String"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "commercialManagerId",
        "variableName": "commercialManagerId"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "firstname",
        "variableName": "firstname"
      },
      {
        "kind": "Variable",
        "name": "lastname",
        "variableName": "lastname"
      },
      {
        "kind": "Variable",
        "name": "managementCenter",
        "variableName": "managementCenter"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "providerEmail",
        "variableName": "providerEmail"
      },
      {
        "kind": "Variable",
        "name": "region",
        "variableName": "region"
      },
      {
        "kind": "Variable",
        "name": "role",
        "variableName": "role"
      },
      {
        "kind": "Variable",
        "name": "sales",
        "variableName": "sales"
      },
      {
        "kind": "Variable",
        "name": "userCode",
        "variableName": "userCode"
      }
    ],
    "concreteType": "BasicResponse",
    "kind": "LinkedField",
    "name": "signUp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignUpMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignUpMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "8119c0d691ff8bdb5cf6fa5298fcbc6b",
    "metadata": {},
    "name": "SignUpMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '42de3c616d5bc3ad78d347e211e29059';

module.exports = node;
