import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { datas, validations } from '@fasstech/spid-front';
import useKeyPressEnter from '../../../../hooks/useKeyPressEnter';
import { find, pathSatisfies, whereEq } from 'ramda';
import { escAdminTypesEnum } from './escAdminTypes';
import useCheckEmailUnicity from '../../../../hooks/useCheckEmailUnicity';
import birthDateIsValid from './birthDateIsValid';

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const getRole = (role, roleCode) => find(whereEq({ label: role, value: roleCode }), datas.roles);

const phoneNumberIsValid = phoneNumber => {
  const reg = new RegExp('^0[1-9]([-. ]?[0-9]{2}){4}$');
  return reg.test(phoneNumber);
};

const useAboutYouStep = ({
  companyInformation,
  goNextStep,
  setCompanyInformation,
  propositionId,
  customerDetailsOptional,
  duplicata
}) => {
  const [values, setValues] = useState(companyInformation);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!R.isNil(history) && !R.isNil(propositionId)) {
      history.push(`/proposition?propositionId=${propositionId}`);
    }
  }, [propositionId, history]);

  const escTypeIs = type => R.pathEq(['escAdmin', 'type'], type, values);
  const escBirthCountryIsFrance = R.pathEq(['escAdmin', 'birthCountry'], 'FRANCE', values);
  const [emailUnicityChecked, setEmailUnicityChecked] = useState(!R.pathSatisfies(isNilOrEmpty, ['escAdmin', 'unicityCheck', 'unique'], companyInformation));
  const emailUnicityMustBeChecked = !escTypeIs(escAdminTypesEnum.AUCUN);
  const [displayEmailUnicityError, setDisplayEmailUnicityError] = useState(R.pathEq( ['escAdmin', 'unicityCheck', 'unique'], false, companyInformation));
  const [emailUnicityCheckLoading, setEmailUnicityCheckLoading] = useState(false);

  const onNext = () => {
    try {
      setLoading(true);

      if (!duplicata) {
        const sanitizeEscAdmin = R.compose(
          R.unless(
            R.always(emailUnicityMustBeChecked),
            R.dissoc('unicityCheck')
          ),
          R.when(
            R.always(escTypeIs(escAdminTypesEnum.AUCUN)),
            R.pick(['type'])
          ),
          R.reject(isNilOrEmpty)
        );

        setCompanyInformation(R.modify('escAdmin', sanitizeEscAdmin, values));
      } else {
        setCompanyInformation(R.dissoc('escAdmin', values));
      }

      goNextStep();
    } catch (error) {
      setLoading(false);
    }
  };

  const propValidations = {
    contactLastname: v => validations.nameIsValid(v) || (customerDetailsOptional && isNilOrEmpty(v)),
    contactFirstname: v => validations.nameIsValid(v) || (customerDetailsOptional && isNilOrEmpty(v)),
    contactRole: v => validations.nameIsValid(v) || (customerDetailsOptional && isNilOrEmpty(v)),
    contactRoleCode: v => validations.nameIsValid(v) || (customerDetailsOptional && isNilOrEmpty(v)),
    contactEmail: validations.emailIsValid,
    contactMobile: v => validations.phoneNumberIsValid(v) || isNilOrEmpty(v),
    accountantEmail: v => R.propOr(false, 'sendToAccountant', values) ? validations.emailIsValid(v) : isNilOrEmpty(v),
    fixedAddress: (v, field) => {
      if (R.equals(field, 'postCode')) {
        return !R.pathOr(false, ['fixedAddress', 'isChecked'], values) || (!isNilOrEmpty(v) && v.length === 5);
      } else if (R.equals(field, 'fields')) {
        return !(R.pathOr(false, ['fixedAddress', 'isChecked'], values) && (isNilOrEmpty(v) || isNilOrEmpty(v[0])));
      }
      return !(R.pathOr(false, ['fixedAddress', 'isChecked'], values) && isNilOrEmpty(v));
    }
  };

  const escAdminPropValidations = {
    civility: v => !isNilOrEmpty(v),
    lastName: validations.nameIsValid,
    firstName: validations.nameIsValid,
    role: validations.nameIsValid,
    roleCode: validations.nameIsValid,
    email: validations.emailIsValid,
    phoneNumber: v => phoneNumberIsValid(v) || isNilOrEmpty(v),
    birthDate: birthDateIsValid,
    birthCountry: v => !isNilOrEmpty(v),
    birthCity: v => !escBirthCountryIsFrance || !isNilOrEmpty(v)
  };

  const displayStar = customerDetailsOptional ? '' : '*';

  const companyInformationsValuesAreValid = R.compose(
    R.reduce((acc, key) => {
      if (R.includes('fixedAddress', key)) {
        return acc && R.reduce((acc2, fixedAddressKey) => {
          return acc2 && propValidations[key](R.path([key, fixedAddressKey], values), fixedAddressKey);
        }, true)(['postCode', 'fields', 'city']);
      }

      return acc && propValidations[key](R.prop(key, values));
    }, true),
    R.keys
  )(propValidations);

  const escAdminTypeIsDefined = !pathSatisfies(isNilOrEmpty, ['escAdmin', 'type'], values);

  const escAdminValuesAreValid = escTypeIs(escAdminTypesEnum.AUCUN) || R.compose(
    R.reduce((acc, key) => {
      return acc && escAdminPropValidations[key](R.path(['escAdmin', key], values));
    }, true),
    R.keys
  )(escAdminPropValidations);

  const valuesAreValid = companyInformationsValuesAreValid && (duplicata || escAdminTypeIsDefined && escAdminValuesAreValid && (!emailUnicityMustBeChecked || emailUnicityChecked));

  const checkEmailUnicityQuery = useCheckEmailUnicity();
  const checkEmailUnicity = () => {
    if (emailUnicityMustBeChecked && escAdminValuesAreValid && !emailUnicityChecked) {
      setEmailUnicityCheckLoading(true);

      checkEmailUnicityQuery(values.escAdmin, unique => {
        setEmailUnicityChecked(true);
        setDisplayEmailUnicityError(!unique);
        setValues(R.assocPath(['escAdmin', 'unicityCheck', 'unique'], unique));
        setEmailUnicityCheckLoading(false);
      });
    }
  };

  useEffect(() => {
    checkEmailUnicity();
  }, [emailUnicityMustBeChecked, escAdminValuesAreValid, emailUnicityChecked]);

  const resetEmailUnicityCheck = () => {
    setEmailUnicityChecked(false);
    setDisplayEmailUnicityError(false);
    setValues(R.dissocPath(['escAdmin', 'unicityCheck', 'unique']));
  };

  useKeyPressEnter(onNext, valuesAreValid);

  const selectedRole = getRole(values.contactRole, values.contactRoleCode);
  const escAdminSelectedRole = getRole(R.path(['escAdmin', 'role'], values), R.path(['escAdmin', 'roleCode'], values));

  return {
    onNext,
    selectedRole,
    escAdminSelectedRole,
    values,
    setValues,
    propValidations,
    displayStar,
    valuesAreValid,
    escAdminPropValidations,
    escBirthCountryIsFrance,
    resetEmailUnicityCheck,
    displayEmailUnicityError,
    emailUnicityCheckLoading,
    loading
  };
};

export default useAboutYouStep;
