/**
 * @flow
 * @relayHash 20be82ec3bbc9afb5445ff94a6193a38
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PropositionsCommercialesMutationVariables = {|
  token: string
|};
export type PropositionsCommercialesMutationResponse = {|
  +propositionsCommerciales: ?{|
    +ok: boolean,
    +token: ?string,
    +status: ?string,
    +archiveName: ?string,
  |}
|};
export type PropositionsCommercialesMutation = {|
  variables: PropositionsCommercialesMutationVariables,
  response: PropositionsCommercialesMutationResponse,
|};
*/


/*
mutation PropositionsCommercialesMutation(
  $token: String!
) {
  propositionsCommerciales(token: $token) {
    ok
    token
    status
    archiveName
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "PropositionsCommercialesMutationResponse",
    "kind": "LinkedField",
    "name": "propositionsCommerciales",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "archiveName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropositionsCommercialesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropositionsCommercialesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "20be82ec3bbc9afb5445ff94a6193a38",
    "metadata": {},
    "name": "PropositionsCommercialesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c230c2ca55eecb8bb5417a4dbb35b5f9';

module.exports = node;
