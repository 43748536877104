import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { assoc, isNil } from 'ramda';
import Select from 'react-select';

const DatePickerInput = React.forwardRef(({
  style = {},
  value,
  onClick
}, ref) => {
  return <input
    style={style}
    ref={ref}
    onClick={onClick}
    value={value}
  />;
});

const reasonOptions = [
  { value: 'Cessation d\'activité' },
  { value: 'Démission' },
  { value: 'Résiliation' },
  { value: 'Présemption de cess. act' },
  { value: 'Sortie sans cotisants' }
];

const ICPSForm = ({
  ICPS,
  setICPS
}) => {
  return <div className="mt-12 mb-6">
    <div className="grid grid-cols-2 items-center">
      <div className="font-bold">Date de cessation ICPS*</div>

      <DatePicker
        locale="fr"
        onChange={date => setICPS(assoc('terminationDate', date || moment()))}
        selected={!isNil(ICPS.terminationDate) ? ICPS.terminationDate : null}
        customInput={<DatePickerInput/>}
        calendarClassName="!w-full"
      />
    </div>

    <div className="grid grid-cols-2 items-center">
      <div className="font-bold">Motif de cessation ICPS*</div>

      <Select
        placeholder="Sélectionner un motif de cessation"
        value={ICPS.reason}
        onChange={v => setICPS(assoc('reason', v.value))}
        labelKey="value"
        valueKey="value"
        options={reasonOptions}
        arrowRenderer={null}
        clearable={false}
      />
    </div>
  </div>;
};

export default ICPSForm;
