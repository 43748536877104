import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation ResumePropositionMutation($propositionId: String!) {
  resumeProposition(propositionId: $propositionId) { 
    ok
    error
  }
}
`;

export default (propositionId, done) => {
  const variables = {
    propositionId,
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.resumeProposition;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
