import * as R from 'ramda';
import './FieldTitle.less';
import React from 'react';

import {
  Icon,
  Text
} from '../UIKit';
import { connect } from 'react-redux';
import getAmendmentInfos from '../../lib/getAmendmentInfos';

const FieldTitle = ({
  courseIsAmendment,
  bases,
  ccn,
  contractDescription,
  duplicata,
  onBack,
  relatedContract,
  showCcn = true,
  text
}) => {
  const stringDuplicata = !R.isNil(duplicata) && duplicata ? 'CONTRAT DUPLICATA | ' : '';
  const stringRelatedContract = !R.isNil(relatedContract) && relatedContract ? 'SUITE ÉCONOMIQUE | ' : '';
  const stringAmendment = courseIsAmendment ? 'AVENANT | ' : '';

  const CcnTitle = () => {
    if (R.isNil(ccn) || R.isEmpty(ccn) || !showCcn) return null;

    const isPack = R.propEq('isPack', true, ccn);
    const shortTitle = R.propOr('', 'shortTitle', ccn);
    let baseName = '';

    if (isPack) {
      const baseId = R.compose(
        R.reduce((name, [baseId, props]) => {
          if (R.propEq('selected', true, props)) name = baseId;
          return name;
        }, ''),
        R.toPairs,
        R.propOr({}, 'C')
      )(contractDescription);

      baseName = R.compose(
        R.propOr('', 'name'),
        R.find(R.propEq('id', baseId))
      )(bases);
    }

    return <div className="uikit-field-title-info">
      {isPack ?
        `${stringDuplicata}${stringRelatedContract}${stringAmendment}${shortTitle} sélectionnée : ${baseName}`
        :
        `${stringDuplicata}${stringRelatedContract}${stringAmendment}CCN sélectionnée : ${shortTitle}`
      }
    </div>;
  };

  return <div>
    <CcnTitle/>
    <div className="uikit-field-title">
      {!R.isNil(onBack) &&
      <div style={{ 'cursor': 'pointer', 'marginLeft': '-45px', 'marginRight': '33px' }} onClick={onBack} role="button" tabIndex={0}><Icon name="icon-chevron-left"/></div>
      }
      <Text className="uikit-field-title-text" text={text}/>
    </div>
  </div>;
};

FieldTitle.defaultProps = {
  onBack: undefined
};

export default connect(
  ({ contractDescription, proposition }) => ({
    ...getAmendmentInfos(proposition.data),
    bases: R.propOr([], 'bases')(contractDescription),
    ccn: R.pathOr({}, ['data', 'ccn'])(proposition),
    contractDescription: R.pathOr({}, ['data', 'contractDescription'])(proposition),
    duplicata: R.pathOr(false, ['data', 'duplicata'])(proposition),
    relatedContract: R.pathOr(false, ['data', 'relatedContract', 'checked'])(proposition)
  }),
  null
)(FieldTitle);
