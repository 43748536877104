import './Blocks.less';

import React from 'react';
import classNames from 'classnames';
import { Spring, config } from 'react-spring';

const Gauge = ({
  align,
  label,
  value
}) => (
  <Spring 
    config={config.slow}
    from={{ gaugeValue: 0 }}
    to={{ gaugeValue: value.gauge }}
    delay={250}
  >
    {
      ({ gaugeValue }) => (
        <div className={classNames('block-gauge', { 'block-gauge-align-left': align === 'left', 'block-gauge-align-right': align === 'right' })}>
          <div className="block-gauge-top">
            <span className={classNames(`block-text-${label.top[0]}`)}>{`${label.top[1]}`}</span>
            <span className={classNames(`block-text-${label.top[2]}`)}>{`${label.top[3]}`}</span>
          </div>
          <div className={classNames('block-gauge-line', { 'block-gauge-align-left': align === 'left', 'block-gauge-align-right': align === 'right' })}>
            <div 
              className={
                classNames(
                  'block-gauge-line-filled', 
                  { 
                    'block-gauge-line-filled-left': align === 'left',
                    'block-gauge-line-filled-right': align === 'right' 
                  }
                )
              } 
              style={{ width: `${gaugeValue}%` }}
            />
            <div className={classNames('block-gauge-cursor')} style={{ [align === 'left' ? 'right' : 'left']: `${100 - gaugeValue}%` }}/>
          </div>
          <div className="block-gauge-bottom">
            <span className={classNames(`block-text-${label.bottom[0]}`)}>{`${label.bottom[1]}`}</span>
            <span className={classNames(`block-text-${label.bottom[2]}`)}>{`${label.bottom[3]}`}</span>
          </div>
        </div>
      )
    }
  </Spring>
);

Gauge.defaultProps = {
  align: 'left'
};
  
export default Gauge;