import { Async as Select } from 'react-select';
import React, { useCallback, useEffect, useState } from 'react';
import useSearchCities from '../../../../hooks/useSearchCities';
import { assoc, isNil, map } from 'ramda';
import debounce from '../../../../lib/debounce';

const formatCity = city => assoc('label', `${city.city} (${city.postalCode})`, city);

const SelectCity = ({
  onChange,
  onBlur,
  value,
  placeholder,
  style = {}
}) => {
  const searchCities = useSearchCities();
  const [selectedCity, setSelectedCity] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isNil(value)) {
      searchCities({ search: value }, cities => {
        const defaultOptions = map(formatCity, cities);
        setDefaultOptions(defaultOptions);
        setSelectedCity(defaultOptions[0]);
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
  }, []);

  const loadOptions = useCallback(
    debounce(
      (inputValue, callback) => {

        searchCities({ search: inputValue }, cities => {
          callback(null, { options: map(formatCity, cities) });
        });
      },
      500
    ),
    []
  );

  if (!loaded) {
    return null;
  }

  return <Select
    valueKey="code"
    labelKey="label"
    placeholder={placeholder}
    onBlur={onBlur}
    onChange={v => {
      onChange(v);
      setSelectedCity(v);
    }}
    value={selectedCity}
    noResultsText="Aucune ville trouvée"
    style={style}
    clearable={false}
    arrowRenderer={null}
    simpleValue={false}
    defaultOptions={defaultOptions}
    loadOptions={loadOptions}
    searchPromptText="Rechercher une ville"
    loadingPlaceholder="Chargement..."
    isLoading={false}
  />;
};

export default SelectCity;
