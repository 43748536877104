import environment from '../Environment';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import * as R from 'ramda';
import Error from '../../components/Error';

const ConfigQuery = graphql`
  query QConfigQuery {
    config {
      arpejLink
      disableContractNumbersAutoGeneration
      disableProviders
      disableSignatureDelay
    }
  }
`;

const QConfig = ({ children }) => {
  return <QueryRenderer
    environment={environment}
    query={ConfigQuery}
    render={({ error, props }) => {
      if (error) {
        return <Error/>;
      } else if (!props) {
        return null;
      }

      const config = R.propOr({}, 'config', props);

      if (R.is(Function, children)) {
        return children(config);
      }
      return React.cloneElement(children, config);
    }}
  />;
};

export default QConfig;
