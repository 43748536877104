import * as R from 'ramda';
import { graphql } from 'react-relay';
import { fetchQuery } from 'relay-runtime';
import environment from '../graphql/Environment';

const QSearchCities = graphql`
 query useSearchCitiesQuery($search: String!) {
   cities(search: $search) {
     code
     city
     postalCode
   }
 }
`;

const useSearchCities = () => {
  return ({ search }, callback) => {
    try {
      search = R.unless(
        R.either(
          R.isNil,
          R.is(String)
        ),
        R.toString,
        search
      );

      if (R.isNil(search) || R.length(search) < 3) { // Recherche à partir de 3 caractères
        return callback([]);
      }

      fetchQuery(environment, QSearchCities, { search }).then(data => {
        if (!R.isNil(callback) && R.is(Function, callback)) {
          callback(data.cities);
        }
      });
    } catch (error) {
      console.error(error);
      callback([]);
    }
  };
};

export default useSearchCities;
