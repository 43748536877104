import { Button, FieldTitle } from '../../../uikit';
import React from 'react';
import { compose, filter, includes, isNil, keys, length, path, pathEq, pathOr, prop, propEq, without } from 'ramda';
import PropositionInfoDisplay from '../../propositionInfo/components/PropositionInfoDisplay';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../graphql/Environment';
import Error from '../../../components/Error';
import Loading from '../../../uikit/misc/Loading';

const AmendmentHealthQuery = graphql`
  query AmendmentHealthQuery($status: [String], $siret: String, $ccnId: String) {
    propositionInfo(status: $status, siret: $siret, ccnId: $ccnId, isHealthAmendment: true) {
      health {
        amendment {
          isAmendment
        } 
      }
    }
  }
`;

const AmendmentHealth = ({
  propositionInfo,
  contractNumber
}) => {
  const menuButton = <Button
    to="menu"
  >
    Retour au menu
  </Button>;

  const title = <FieldTitle
    text={`Un contrat a été trouvé avec le numéro ${contractNumber}`}
  />;

  if (isNil(propositionInfo.id)) {
    return <div>
      <div className="text-red-500 my-12">
        Santé : Nous n’avons pas trouvé de contrat en gestion avec ce numéro. Pour effectuer une nouvelle offre, retournez au menu principal et effectuez une nouvelle demande
      </div>

      {menuButton}
    </div>;
  }

  const selectedColleges = compose(
    keys,
    filter(propEq('isSelected', true)),
    pathOr({}, ['health', 'staff'])
  )(propositionInfo);

  const ccnColleges = pathOr([], ['ccn', 'SANTE', 'colleges'], propositionInfo);
  const availableColleges = without(selectedColleges, ccnColleges);

  const collegeTOUSisSelected = includes('TOUS', selectedColleges);
  const collegesNCAndCAreSelected = includes('NC', selectedColleges) && includes('C', selectedColleges);
  const onlyTOUSIsAvailable = includes('TOUS', availableColleges) && length(availableColleges) === 1;

  if (collegeTOUSisSelected || collegesNCAndCAreSelected) {
    return <div>
      {title}

      <div className="font-bold mb-8 text-lg">Santé : L’ensemble du personnel est déjà couvert par le contrat {contractNumber}.</div>

      {menuButton}
    </div>;
  }

  if (onlyTOUSIsAvailable) {
    return <div>
      {title}

      <div className="font-bold mb-8 text-lg">Avenant impossible. Pour souscrire l’Ensemble du personnel, annuler le contrat exisant et effectuer une nouvelle offre.</div>

      {menuButton}
    </div>;
  }

  return <QueryRenderer
    environment={environment}
    query={AmendmentHealthQuery}
    variables={{
      siret: prop('siret', propositionInfo),
      status: ['sentForSignature', 'confirmed', 'sentToManagementSystem', 'confirmedInManagementSystem'],
      ccnId: path(['ccn', 'id'], propositionInfo)
    }}
    render={({ error, props }) => {
      if (error) {
        return <Error/>;
      } else if (!props) {
        return <Loading/>;
      }

      // S'il existe déjà un avenant avec ce siret on bloque
      if (pathEq(['propositionInfo', 'health', 'amendment', 'isAmendment'], true, props)) {
        return <div>
          {title}

          <div className="font-bold mb-8 text-lg">Une souscription avec le SIRET suivant est déjà en cours sur SPID : {prop('siret', propositionInfo)}</div>

          {menuButton}
        </div>;
      }

      return <div>
        {title}

        <div className="font-bold mb-8 text-lg">Créer un contrat pour le collège non couvert par ce contrat : {contractNumber}.</div>

        <Button
          to={`proposition?amendment=true&amendmentOfferType=SANTE&formerContractId=${propositionInfo.id}`} size="medium"
        >
          Créer le contrat Santé
        </Button>

        <PropositionInfoDisplay
          proposition={propositionInfo}
          amendment
          style={{ marginTop: '25px' }}
          offerType="SANTE"
        />

        <Button
          to="menu"
          size="small"
          style={{ margin: '25px auto 0 auto', width: '200px' }}
        >
          Retour au menu
        </Button>
      </div>;
    }}
  />;

};

export default AmendmentHealth;
