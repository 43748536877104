import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation ReopenContractMutation($contractId: ID!, $ICPS: ICPSInput!) {
  reopenContract(contractId: $contractId, ICPS: $ICPS) { 
    ok
    error
    newContractId
  }
}
`;

export default ({ contractId, ICPS }, done) => {
  const variables = {
    contractId,
    ICPS
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, error, newContractId } = response.reopenContract;
        done(ok, error, newContractId);
      },
      onError: err => console.error(err),
    }
  );
};
