import './Note.less';
import React from 'react';

const Note = ({
  title,
  content,
}) => (
  <div className="uikit-note">
    <div className="uikit-note-wrapper">
      <div className="uikit-note-title">
        {title}
      </div>
      <div className="uikit-note-content">
        {content}
      </div>
    </div>
    <div className="uikit-note-corner"/>
  </div>
);

export default Note;