import 'react-select/dist/react-select.css';
import 'react-datepicker/dist/react-datepicker.css';
import './less/app.css';
import './less/custom.less';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import Tokens from './lib/Tokens';
import reducers from './reducers';
import Routes from './Routes';
import Moment from 'moment';
import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';
import { logger } from 'redux-logger';

Tokens.setKey('fasstag2r_com', 'BD0612D7-8601-4DD6-AD3F-F065DB559A8B');

const store = createStore(
  reducers,
  applyMiddleware(thunkMiddleware, logger)
);

const getDocumentRoot = () => {
  let element = document.createElement('div');
  document.body.appendChild(element);
  return element;
};

Moment.locale('fr');

const root = createRoot(getDocumentRoot());

root.render(
  <ConfigProvider locale={frFR}>
    <Routes store={store}/>
  </ConfigProvider>
);
