import React, { useState } from 'react';
import Checkbox from 'rc-checkbox';
import * as R from 'ramda';
import Modal from '../../../../uikit/modal/Modal';

const useContractOption = ({ option, onChange }) => {
  const [displayPopup, setDisplayPopup] = useState(false);
  const hasPopup = R.compose(
    R.not,
    R.either(R.isNil, R.isEmpty),
    R.prop('displayPopup')
  )(option);

  const onCheck = (e) => {
    if (e.target.checked && hasPopup) {
      setDisplayPopup(true);
    }

    onChange(option.id, e.target.checked);
  };

  return {
    onCheck,
    hasPopup,
    displayPopup,
    setDisplayPopup
  };
};

const ContractOption = ({
  option,
  onChange,
  value
}) => {
  const {
    onCheck,
    hasPopup,
    displayPopup,
    setDisplayPopup
  } = useContractOption({ option, onChange });

  return <li className="contract-option">
    {hasPopup && displayPopup && <Modal
      onClose={() => setDisplayPopup(false)}
      isPopup
    >
      {R.propOr('', 'displayPopup', option)}
    </Modal>}

    <Checkbox
      style={{ marginRight: '5px', height: '17px' }}
      checked={value ? 1 : 0}
      onChange={onCheck}
    />
    {option.name}
  </li>;
};

export default ContractOption;
