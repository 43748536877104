import { graphql } from 'react-relay';
import { fetchQuery } from 'relay-runtime';
import environment from '../Environment';
import { compose, isNil, is, append, reject, uniq, pluck, defaultTo, find, map, eqProps, mergeLeft } from 'ramda';

const QGetLegalPersons = graphql`
  query getLegalPersonsQuery($sirets: [String!]!) {
    legalPersons(sirets: $sirets) {
      codeApplication
      identifiantDansSilo
      siret
    }
  }
`;

const isFunction = fn => !isNil(fn) && is(Function, fn);

const getLegalPersons = ({ siretInfo, extraEstablishments, duplicata, userRole }, callback) => {
  if (userRole !== 'reseau_commercial' || duplicata) {
    return callback(siretInfo, extraEstablishments);
  }

  const sirets = compose(
    uniq,
    reject(isNil),
    pluck('siret'),
    reject(isNil),
    append(siretInfo),
    defaultTo([])
  )(extraEstablishments);

  fetchQuery(environment, QGetLegalPersons, { sirets }).then(data => {
    if (isFunction(callback)) {
      if (isNil(data) || isNil(data.legalPersons)) {
        return callback(null, null);
      }

      const { legalPersons } = data;

      const mergeSiretResult = establishment => compose(
        mergeLeft(establishment),
        find(eqProps('siret', establishment))
      )(legalPersons);

      const extraEstablishmentsData = map(mergeSiretResult, extraEstablishments);

      callback(mergeSiretResult(siretInfo), extraEstablishmentsData);
    }
  });
};

export default getLegalPersons;
