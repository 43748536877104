import React from 'react';

import {
  FieldTitle
} from '../../../uikit/UIKit';

const ContractIsConfirmedStep = () => (
  <React.Fragment>
    <FieldTitle 
      text="Un contrat est actif pour cette proposition"
    />
    <div className="confirm-contract-title">
      {'Votre demande de mise en gestion est bien prise en compte.'}
    </div>
  </React.Fragment>
);

export default ContractIsConfirmedStep;