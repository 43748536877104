/**
 * @flow
 * @relayHash 5583aede366a85e0749f8237e29788fe
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentsMutationVariables = {|
  token: string
|};
export type DocumentsMutationResponse = {|
  +documents: ?{|
    +ok: boolean,
    +token: ?string,
    +status: ?string,
    +contractDescription: ?{|
      +NC: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +documents: ?$ReadOnlyArray<?{|
            +type: ?string,
            +url: ?string,
            +id: ?string,
          |}>,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string,
            +documents: ?$ReadOnlyArray<?{|
              +type: ?string,
              +url: ?string,
              +id: ?string,
            |}>,
          |}>,
        |}>
      |},
      +APP: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +documents: ?$ReadOnlyArray<?{|
            +type: ?string,
            +url: ?string,
            +id: ?string,
          |}>,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string,
            +documents: ?$ReadOnlyArray<?{|
              +type: ?string,
              +url: ?string,
              +id: ?string,
            |}>,
          |}>,
        |}>
      |},
      +AM: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +documents: ?$ReadOnlyArray<?{|
            +type: ?string,
            +url: ?string,
            +id: ?string,
          |}>,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string,
            +documents: ?$ReadOnlyArray<?{|
              +type: ?string,
              +url: ?string,
              +id: ?string,
            |}>,
          |}>,
        |}>
      |},
      +C: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +documents: ?$ReadOnlyArray<?{|
            +type: ?string,
            +url: ?string,
            +id: ?string,
          |}>,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string,
            +documents: ?$ReadOnlyArray<?{|
              +type: ?string,
              +url: ?string,
              +id: ?string,
            |}>,
          |}>,
        |}>
      |},
      +TOUS: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +documents: ?$ReadOnlyArray<?{|
            +type: ?string,
            +url: ?string,
            +id: ?string,
          |}>,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string,
            +documents: ?$ReadOnlyArray<?{|
              +type: ?string,
              +url: ?string,
              +id: ?string,
            |}>,
          |}>,
        |}>
      |},
    |},
  |}
|};
export type DocumentsMutation = {|
  variables: DocumentsMutationVariables,
  response: DocumentsMutationResponse,
|};
*/


/*
mutation DocumentsMutation(
  $token: String!
) {
  documents(token: $token) {
    ok
    token
    status
    contractDescription {
      NC {
        bases {
          baseId
          documents {
            type
            url
            id
          }
          options {
            optionId
            documents {
              type
              url
              id
            }
          }
        }
      }
      APP {
        bases {
          baseId
          documents {
            type
            url
            id
          }
          options {
            optionId
            documents {
              type
              url
              id
            }
          }
        }
      }
      AM {
        bases {
          baseId
          documents {
            type
            url
            id
          }
          options {
            optionId
            documents {
              type
              url
              id
            }
          }
        }
      }
      C {
        bases {
          baseId
          documents {
            type
            url
            id
          }
          options {
            optionId
            documents {
              type
              url
              id
            }
          }
        }
      }
      TOUS {
        bases {
          baseId
          documents {
            type
            url
            id
          }
          options {
            optionId
            documents {
              type
              url
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token",
    "type": "String!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Document",
  "kind": "LinkedField",
  "name": "documents",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BaseProduct",
    "kind": "LinkedField",
    "name": "bases",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baseId",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseOptionProduct",
        "kind": "LinkedField",
        "name": "options",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optionId",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "DocumentsMutationResponse",
    "kind": "LinkedField",
    "name": "documents",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractDescription",
        "kind": "LinkedField",
        "name": "contractDescription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "NC",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "APP",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "AM",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "C",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "TOUS",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentsMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocumentsMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "5583aede366a85e0749f8237e29788fe",
    "metadata": {},
    "name": "DocumentsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4285859e5bfee8f2801113fb96accea7';

module.exports = node;
