
import './Blocks.less';
import * as R from 'ramda';
import React from 'react';

import {
  compose, 
  defaultProps,
  withProps
} from 'recompose';

import { Spring, config } from 'react-spring';

const enhance = compose(
  defaultProps({
    round: 0
  }),
  withProps(
    ({ value }) => {
      const max = R.reduce((acc, value) => R.max(acc, value), 0, value);
      value = R.map(v => [v, v * 100 / max], value);
      return {
        max,
        value
      };
    }
  )
);

const VBars = enhance(
  ({
    label,
    value,
    max,
    round
  }) => (
    <Spring
      config={config.slow}
      from={{ height: 0, gauge: 0 }}
      to={{ height: 100, gauge: max }}
      delay={250}
    >
      {
        ({ gauge, height }) => (
          <div className="block-vbars__bars-container">
            {
              value.map((v, index) => (
                <div
                  className="block-vbars__bar-container"
                  key={index}
                >
                  <div>{label.gauge}</div>
                  <div 
                    className="block-vbars__bar"
                  >
                    <div style={{ marginBottom: '8px' }}>
                      {`${Number(R.min(v[0], gauge)).toFixed(round)} %`}
                    </div>
                    <div className="block-vbars__bar-value" style={{ height: `${R.min(v[1], height)}px` }}/>
                    <div className="block-vbars__bar-label-bottom">{label.bars[index]}</div>
                  </div>
                  <div>{label.bottom}</div>
                </div>
              ))
            }
          </div>
        )
      }
    </Spring> 
  )
);

export default VBars;
