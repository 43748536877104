import * as R from 'ramda';
import { Button, FieldTitle } from '../../../uikit';
import PropositionInfoDisplay from '../../propositionInfo/components/PropositionInfoDisplay';
import React from 'react';
import amendmentDisabled from './helpers/amendmentDisabled';

const AmendmentProtection = ({
  propositionInfo,
  contractNumber
}) => {
  if (R.isNil(propositionInfo.id) || R.pathEq(['ccn', 'isPack'], true)(propositionInfo)) {
    return <div>
      <div style={{ margin: '20px 0' }}>
        {'Nous n\'avons pas trouvé de contrat avec ce numéro.'}<br/>
      </div>
      <div style={{ width: '50%', display: 'inline-block' }}>
        <Button to="menu">Retour au menu</Button>
      </div>
      <div style={{ width: '50%', display: 'inline-block', marginBottom: '5px' }}>
        <Button to={`proposition?amendment=true&contractNumber=${contractNumber}`}>Créer un avenant</Button>
      </div>
    </div>;
  }

  const isClassic = R.compose(
    R.reduce((b, [, obj]) => {
      const _isClassic = R.reduce((_b, base) => _b && R.propEq('type', 'classique')(base), true)(R.propOr([], 'bases', obj));
      return b && _isClassic;
    }, true),
    R.toPairs,
    R.propOr({}, 'contractDescription')
  )(propositionInfo);

  return <div>
    <FieldTitle
      text={`Un contrat a été trouvé avec le numéro ${contractNumber}`}
    />

    <Button
      disabled={isClassic || amendmentDisabled(propositionInfo)}
      to={`proposition?amendment=true&amendmentOfferType=PREVOYANCE&formerContractId=${propositionInfo.id}`} size="medium"
    >
      Créer un avenant pour ce contrat
    </Button>

    {isClassic && <div className="text-red-500">Vous ne pouvez pas créer d’avenant, il est nécessaire de créer une nouvelle souscription.</div>}
    {amendmentDisabled(propositionInfo) && <div className="text-red-500">{'Cette CCN ne propose pas d\'avenant.'}</div>}

    <PropositionInfoDisplay
      proposition={propositionInfo}
      amendment
      style={{ marginTop: '25px' }}
      offerType="PREVOYANCE"
    />

    <Button
      to="menu"
      size="small"
      style={{ margin: '25px auto 0 auto', width: '200px' }}
    >
      Retour au menu
    </Button>
  </div>;
};

export default AmendmentProtection;
