import { useEffect, useState } from 'react';
import * as R from 'ramda';
import { libs } from '@fasstech/spid-front';
import checkContractNumberIsOld from './checkContractNumberIsOld';
const rejectIndexed = R.addIndex(R.reject);

const useContractNumbers = ({
  resetSendForSignatureStatus,
  resetMakeDuplicataStatus,
  onEdit,
  contractNumbers,
  user,
  userRole,
  duplicata,
  config,
  amendment,
  turnovers,
  offerType,
  staff,
  ccn,
  contractDescription,
  isHealthAmendment,
}) => {
  useEffect(() => {
    resetSendForSignatureStatus();
    resetMakeDuplicataStatus();

    if (!R.isEmpty(contractNumbers)) {
      onEdit(false);
    }
  }, []);

  const isCommercialNetwork = userRole === 'reseau_commercial';
  const contractNumbersAutoGeneration = libs.contractNumbersAutoGenerationIsAllowed({ user, duplicata, config });

  const allowSignatureDelay = !R.propOr(false, 'disableSignatureDelay', config) && R.both(
    R.propEq('role', 'reseau_commercial'),
    R.either(
      R.pathEq(['extra', 'sales'], 'VAD'),
      R.pathEq(['extra', 'sales'], 'VADC'),
    )
  )(user);

  const colleges = R.compose(
    R.keys,
    R.filter(R.propEq('isSelected', true)),
    R.defaultTo({})
  )(staff);

  const checkHealthTurnoversLength = contractNumbers => R.eqProps('length', contractNumbers);
  const checkProtectionTurnoversLength = contractNumbers => R.compose(
    R.both(
      R.reduce((acc, c) => R.eqProps('length', c, colleges) && acc, true),
      R.eqProps('length', contractNumbers),
    ),
    R.values,
    R.groupBy(R.prop('contractNumberIndex'))
  );
  const checkTurnoversValues = R.compose(
    R.not,
    R.any(R.either(R.isNil, R.isEmpty)),
    R.pluck('value')
  );

  const [defaultContractNumbers] = useState(contractNumbers);
  const hideTurnovers = duplicata || userRole === 'groupe_adhesion';

  const turnoversAreValid = contractNumbers => {
    if (hideTurnovers) {
      return true;
    }

    const _checkContractNumberIsOld = checkContractNumberIsOld({
      contractNumbers,
      contractDescription,
      amendment,
      ccn,
      offerType,
      isHealthAmendment,
      defaultContractNumbers
    });

    const newContractNumbers = rejectIndexed((_, i) => _checkContractNumberIsOld(i))(contractNumbers);

    return R.both(
      checkTurnoversValues,
      R.ifElse(
        R.always(offerType === 'PREVOYANCE'),
        checkProtectionTurnoversLength(newContractNumbers),
        checkHealthTurnoversLength(newContractNumbers)
      )
    )(turnovers);
  };

  return {
    isCommercialNetwork,
    contractNumbersAutoGeneration,
    allowSignatureDelay,
    turnoversAreValid,
    hideTurnovers
  };
};

export default useContractNumbers;
