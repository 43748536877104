/**
 * @flow
 * @relayHash ee7ea1fc83196e980e0ddd46f253c0e5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AmendmentHealthQueryVariables = {|
  status?: ?$ReadOnlyArray<?string>,
  siret?: ?string,
  ccnId?: ?string,
|};
export type AmendmentHealthQueryResponse = {|
  +propositionInfo: ?{|
    +health: ?{|
      +amendment: ?{|
        +isAmendment: ?boolean
      |}
    |}
  |}
|};
export type AmendmentHealthQuery = {|
  variables: AmendmentHealthQueryVariables,
  response: AmendmentHealthQueryResponse,
|};
*/


/*
query AmendmentHealthQuery(
  $status: [String]
  $siret: String
  $ccnId: String
) {
  propositionInfo(status: $status, siret: $siret, ccnId: $ccnId, isHealthAmendment: true) {
    health {
      amendment {
        isAmendment
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status",
    "type": "[String]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siret",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnId",
    "type": "String"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ccnId",
    "variableName": "ccnId"
  },
  {
    "kind": "Literal",
    "name": "isHealthAmendment",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "siret",
    "variableName": "siret"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractHealth",
  "kind": "LinkedField",
  "name": "health",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Amendment",
      "kind": "LinkedField",
      "name": "amendment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAmendment",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AmendmentHealthQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Proposition",
        "kind": "LinkedField",
        "name": "propositionInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AmendmentHealthQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Proposition",
        "kind": "LinkedField",
        "name": "propositionInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ee7ea1fc83196e980e0ddd46f253c0e5",
    "metadata": {},
    "name": "AmendmentHealthQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fd04039941652c0b9f3cb9ba148cfc7b';

module.exports = node;
