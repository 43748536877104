import './Sentence.less';
import React from 'react';

const Sentence = ({ headline, text }) => (
  <div className="uikit-sentence">
    <div>
      {''}
    </div>
    <div>
      <span className="uikit-sentence-headline">{headline}</span>
      <span className="uikit-sentence-text">{text}</span>
    </div>
  </div>
);

export default Sentence;