import React from 'react';

import {
  Text
} from '../../../uikit/UIKit';

const Title = ({
  text,
  style
}) => (
  <div
    style={style}
    className="connection-title"
  >
    <Text text={text}/>
  </div>
);

export default Title;
