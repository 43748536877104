import * as R from 'ramda';
import './Menu.less';
import React, { useState, useEffect } from 'react';
import { validations } from '@fasstech/spid-front';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MenuLayout from '../../layout/menu/Layout';
import { Siren } from './Components';
import { propositionReset, } from '../../reduxActions/proposition';
import { formatters } from '@fasstech/spid-front';

import {
  Button,
  Text,
  TextInput
} from '../../uikit/UIKit';
import useKeyPressEnter from '../../hooks/useKeyPressEnter';
import withUser from '../../withUser';

const choices = {
  1: 'Effectuer une nouvelle demande',
  2: 'Générer un duplicata',
  3: 'Reprendre une proposition en cours',
  4: 'Effectuer une suite économique',
  5: 'Effectuer un avenant',
  6: 'Outil de recommandation des offres CCN'
};

const addChoice = (id, props) => <Button {...props} look="white"><Text text={choices[id]}/></Button>;

const Menu = ({
  history,
  resetProposition,
  user
}) => {

  useEffect(resetProposition, []);

  const [siren, setSiren] = useState(null);
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [contractNumber, setContractNumber] = useState(null);

  // eslint-disable-next-line react/display-name
  const ifSelectedChoiceEquals = (i) => (component) => selectedChoice === i ? component : null;
  const onNext = () => {
    if (selectedChoice === '3' ) history.push(`/selectionnerproposition?siren=${siren}`);
    if (selectedChoice === '5') history.push(`/amendment?contractNumber=${contractNumber}`);
  };

  const buttonSirenDisabled = R.isNil(siren);
  const buttonAmendmentDisabled = R.isNil(contractNumber) || (!validations.contractNumberIsValid(contractNumber, 'PREVOYANCE') && !validations.contractNumberIsValid(contractNumber, 'SANTE'));

  useKeyPressEnter(onNext, !buttonSirenDisabled || !buttonAmendmentDisabled);

  const userSalesIs = sales => {
    return R.both(
      R.propEq('role', 'reseau_commercial'),
      R.pathEq(['extra', 'sales'], sales)
    )(user);
  };

  const userRoleIs = role => R.propEq('role', role, user);

  return (
    <MenuLayout withMessage>
      <div className="menu">

        {!userSalesIs('VADC') && <>
          {addChoice('1', { to: '/proposition' })}
          {userRoleIs('groupe_adhesion') && addChoice('2', { to: '/proposition?duplicata=true' })}
          {!userRoleIs('apporteur') && addChoice('4', { to: '/proposition?relatedContract=true' })}

          {addChoice('3', { selected: selectedChoice === '3', onClick: () => setSelectedChoice('3') })}
          {ifSelectedChoiceEquals('3')(<Siren onChange={setSiren}/>)}
          {ifSelectedChoiceEquals('3')(<Button disabled={buttonSirenDisabled} onClick={onNext}>Suivant</Button>)}

          {!userRoleIs('apporteur') && addChoice('5', { selected: selectedChoice === '5', onClick: () => setSelectedChoice('5') })}
          {ifSelectedChoiceEquals('5')(<TextInput
            value={contractNumber}
            onChange={(e) => setContractNumber(formatters.formatContractNumber(0, e.target.value, null))}
            placeholder={'Entrez le numéro de contrat prévoyance ou santé'}/>
          )}

          {ifSelectedChoiceEquals('5')(<Button disabled={buttonAmendmentDisabled} onClick={onNext}>Suivant</Button>)}
        </>}

        {userSalesIs('VADC') && <>
          {addChoice('1', { to: '/proposition' })}

          {addChoice('3', { selected: selectedChoice === '3', onClick: () => setSelectedChoice('3') })}
          {ifSelectedChoiceEquals('3')(<Siren onChange={setSiren}/>)}
          {ifSelectedChoiceEquals('3')(<Button disabled={buttonSirenDisabled} onClick={onNext}>Suivant</Button>)}

          {addChoice('6', { to: '/recommandations_ccns' })}
        </>}
      </div>
    </MenuLayout>
  );
};

export default connect(
  null,
  dispatch => ({
    resetProposition: () => {
      dispatch(propositionReset());
    }
  })
)(withUser(withRouter(Menu)));
