import * as R from 'ramda';
import React from 'react';
import { Modal } from 'antd';
import modalTexts from './modalTexts';

const HelpModal = ({
  text,
  openFile,
  visible,
  setVisible
}) => {
  const getText = text => R.propOr(null, text, modalTexts(openFile));

  return <Modal
    visible={visible}
    closable
    centered
    onCancel={() => setVisible(false)}
    footer={null}
    width={800}
    bodyStyle={{ fontSize: '16px', padding: '40px' }}
  >
    {getText(text)}
  </Modal>;
};

export default HelpModal;
