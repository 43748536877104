import React from 'react';

const Title = ({ children }) => {
  return <div
    style={{
      textAlign: 'center',
      color: '#2f5597',
      margin: '30px 0',
      fontSize: '22px'
    }}
  >
    <img src="/image/ccn.png" style={{ marginRight: '10px' }}/>
    {children}
  </div>;
};

export default Title;
