import './EmployeesStep.less';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import {
  propositionSetStaff,
  propositionGoNextStep,
  propositionGoPreviousStep,
  propositionSetHealthStaff,
  propositionSetHealthContractDescription, propositionSetTariffStructure
} from '../../../reduxActions/proposition';
import Checkbox from 'rc-checkbox';
import { FieldTitle } from '../../../uikit/UIKit';
import { BtnNext, EmployeeNumber } from '../components/.';
import useKeyPressEnter from '../../../hooks/useKeyPressEnter';
import { libs, hooks } from '@fasstech/spid-front';
import withUser from '../../../withUser';
import SelectInput from '../../../uikit/input/SelectInput';
import RadioGroup from '../../../uikit/buttons/RadioGroup';
import { pathEq } from 'ramda';
import getAmendmentInfos from '../../../lib/getAmendmentInfos';

const useEmployeesStep = ({
  bundledCollege,
  amendment,
  staff,
  setStaff,
  goNextStep,
  ccn,
  offerType,
  userRole,
  setTariffStructure,
  tariffStructure,
  resetHealthContractDescription,
  isProtectionAmendment,
  isHealthAmendment,
  isAmendment
}) => {
  const {
    displayInfo,
    formerColleges,
    isSelected,
    collegeIs,
    colleges,
    isValid,
    onChange,
    collegesPopup,
    setSelectedCategories,
    getCount,
    selectedCategoriesToSave,
    totalNumberOfEmployeesIsValid,
    maxNumberOfTotalEmployees,
    disabledColleges
  } = hooks.useColleges({
    ccn: userRole === 'reseau_commercial' ? ccn : R.dissocPath([offerType, 'maxNumberOfTotalEmployees'], ccn),
    bundledCollege,
    staff,
    amendment,
    isProtectionAmendment,
    isHealthAmendment,
    isAmendment,
    offerType
  });

  const [takeChargeOfBeneficiaries, setTakeChargeOfBeneficiaries] = React.useState(R.includes(tariffStructure, ['FAMILLE', 'UNIFORME']));
  const [tariffStructureIsUniform, setTariffStructureIsUniform] = React.useState(tariffStructure === 'UNIFORME');

  const onNext = () => {
    setStaff(selectedCategoriesToSave, undefined, offerType);

    const newTariffStructure = R.cond([
      [R.propEq('takeChargeOfBeneficiaries', false), R.always('ISOLE')],
      [R.propEq('tariffStructureIsUniform', true), R.always('UNIFORME')],
      [R.propEq('takeChargeOfBeneficiaries', true), R.always('FAMILLE')],
      [R.T, R.always(null)]
    ])({ takeChargeOfBeneficiaries, tariffStructureIsUniform });

    setTariffStructure(newTariffStructure);

    if (offerType === 'SANTE') { // On nettoie contractDescription si la structure tarifaire a changé
      resetHealthContractDescription();
    }

    goNextStep();
  };

  useKeyPressEnter(onNext, isValid);

  const tariffStructures = R.propOr([], 'tariffStructures', ccn);

  return {
    formerColleges,
    isSelected,
    collegeIs,
    colleges,
    buttonDisabled: !isValid,
    onNext,
    onChange,
    collegesPopup,
    setSelectedCategories,
    getCount,
    displayInfo,
    totalNumberOfEmployeesErrorMessage: totalNumberOfEmployeesIsValid ? null : `Effectif maximum autorisé est de ${maxNumberOfTotalEmployees} salariés`,
    setTakeChargeOfBeneficiaries,
    takeChargeOfBeneficiaries,
    tariffStructures,
    tariffStructureIsUniform,
    setTariffStructureIsUniform,
    disabledColleges
  };
};

const HealthAdditionalInformations = ({ ccn }) => {
  const insurer = R.path(['SANTE', 'insurer'], ccn);

  return <div style={{
    display: 'grid',
    gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
    alignItems: 'center',
    justifyItems: 'end',
    rowGap: '1rem',
    columnGap: '1rem',
    width: '550px',
    marginTop: '50px'
  }}>
    <div style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
      {'Régime social de l\'entreprise *'}
    </div>

    <SelectInput
      options={[{ label: 'Régime Général' }]}
      value="Régime Général"
      disabled
    />

    <div style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
      Assureur *
    </div>

    <SelectInput
      options={[{ label: insurer }]}
      value={insurer}
      disabled
    />
  </div>;
};

const TakeChargeCheckbox = ({
  takeChargeOfBeneficiaries,
  setTakeChargeOfBeneficiaries,
  tariffStructures,
  tariffStructureIsUniform,
  setTariffStructureIsUniform
}) => {
  const hasTariffStructure = tariffStructure => R.includes(tariffStructure, tariffStructures);

  return <div
    className="flex justify-between"
  >
    <div
      className="grid grid-cols-[repeat(2,minmax(0,1fr))] gap-x-4 gap-y-4 items-center"
      style={{
        marginTop: '40px',
        width: '550px',
      }}>

      <div className="uppercase text-end font-bold">
        Souhaitez-vous prendre en charge les ayants droits *
      </div>

      <RadioGroup
        options={[
          { label: 'Oui', value: 'true' },
          { label: 'Non', value: 'false' }
        ]}
        name="takeChargeOfBeneficiaries"
        style={{ justifyContent: 'center' }}
        value={JSON.stringify(takeChargeOfBeneficiaries)}
        onChange={R.isEmpty(tariffStructures) ? null : v => setTakeChargeOfBeneficiaries(JSON.parse(v))}
      />

      {hasTariffStructure('UNIFORME') && takeChargeOfBeneficiaries && <>
        <div className="uppercase text-end font-bold">
          Régime Uniforme
        </div>

        <RadioGroup
          options={[
            { label: 'Oui', value: 'true' },
            { label: 'Non', value: 'false' }
          ]}
          name="tariffStructureIsUniform"
          style={{ justifyContent: 'center' }}
          value={JSON.stringify(tariffStructureIsUniform)}
          onChange={v => setTariffStructureIsUniform(JSON.parse(v))}
        />
      </>}
    </div>
  </div>;
};

const EmployeesStep = ({
  ccn,
  goPreviousStep,
  duplicata,
  userRole,
  amendment,
  selectAuto,
  brokerageBundledCollege,
  userRoleIs,
  staff,
  setStaff,
  goNextStep,
  offerType,
  setTariffStructure,
  tariffStructure,
  resetHealthContractDescription,
  isRelatedContract,
  isProtectionAmendment,
  isHealthAmendment,
  isAmendment
}) => {
  const {
    displayInfo,
    formerColleges,
    collegeIs,
    isSelected,
    colleges,
    buttonDisabled,
    onNext,
    onChange,
    getCount,
    collegesPopup,
    setSelectedCategories,
    totalNumberOfEmployeesErrorMessage,
    takeChargeOfBeneficiaries,
    setTakeChargeOfBeneficiaries,
    tariffStructures,
    tariffStructureIsUniform,
    setTariffStructureIsUniform,
    disabledColleges
  } = useEmployeesStep({
    bundledCollege: userRoleIs('apporteur') ? brokerageBundledCollege : R.pathOr([], [offerType, 'bundledCollege'], ccn),
    amendment,
    staff,
    setStaff,
    goNextStep,
    ccn,
    offerType,
    userRole,
    setTariffStructure,
    tariffStructure,
    resetHealthContractDescription,
    isProtectionAmendment,
    isHealthAmendment,
    isAmendment
  });

  return <React.Fragment>
    <FieldTitle
      text="Collège(s) à couvrir ?"
      onBack={goPreviousStep}
    />

    {R.map(college => {
      return <React.Fragment key={college}>
        {collegeIs(college) && <div className="employees-step-row">
          <Checkbox
            disabled={(R.includes('NC')(formerColleges) && isProtectionAmendment) || R.includes(college, disabledColleges)}
            checked={isSelected(college) || selectAuto}
            onChange={e => onChange(e, college)}
          />

          <EmployeeNumber
            value={getCount(college)}
            title={libs.collegeLabel(college, ccn, offerType)}
            disabled={R.includes(college, disabledColleges)}
            onChange={e => setSelectedCategories(R.assocPath([college, 'count'], parseInt(e.target.value)))}
          />
        </div>}
      </React.Fragment>;
    })(colleges)}

    {displayInfo && !duplicata && userRole === 'groupe_adhesion' && !isRelatedContract && (
      <div className="employees-step-info">{'L’effectif à couvrir est > ou = à 20 salariés, merci de transmettre le dossier au réseau commercial.'}</div>
    )}

    {userRole === 'reseau_commercial' && !R.isNil(totalNumberOfEmployeesErrorMessage) && (
      <div className="text-red-500 mt-4">{totalNumberOfEmployeesErrorMessage}</div>
    )}

    {collegesPopup}

    {offerType === 'SANTE' && <HealthAdditionalInformations ccn={ccn}/>}

    {offerType === 'SANTE' && !R.isEmpty(tariffStructures) && <TakeChargeCheckbox
      takeChargeOfBeneficiaries={takeChargeOfBeneficiaries}
      setTakeChargeOfBeneficiaries={setTakeChargeOfBeneficiaries}
      tariffStructures={tariffStructures}
      tariffStructureIsUniform={tariffStructureIsUniform}
      setTariffStructureIsUniform={setTariffStructureIsUniform}
    />}

    <BtnNext
      disabled={buttonDisabled}
      onClick={onNext}
      title={'Je personnalise mon contrat'}
    />
  </React.Fragment>;
};

export default connect(
  ({ proposition }) => {
    const currentOfferType = R.pathOr('PREVOYANCE', ['data', 'currentOfferType'])(proposition);

    return {
      ccn: R.path(['data', 'ccn'], proposition),
      staff: currentOfferType === 'SANTE' ? R.path(['data', 'health', 'staff'], proposition) : R.path(['data', 'staff'], proposition),
      brokerageBundledCollege: R.pathOr([], ['data', 'ccn', 'brokerageSettings', 'bundledCollege'], proposition),
      duplicata: R.pathOr(false, ['data', 'duplicata'])(proposition),
      userRole: R.propOr('groupe_adhesion', 'userRole')(proposition),
      ...getAmendmentInfos(proposition.data),
      offerType: R.pathOr('PREVOYANCE', ['data', 'currentOfferType'])(proposition),
      tariffStructure: R.path(['data', 'health', 'tariffStructure'], proposition),
      isRelatedContract: pathEq(['data', 'relatedContract', 'checked'], true, proposition)
    };
  },
  dispatch => ({
    setStaff: (categories, value, offerType) => offerType === 'PREVOYANCE' ? dispatch(propositionSetStaff(categories, value)) : dispatch(propositionSetHealthStaff(categories, value)),
    setTariffStructure: (tariffStructure) => dispatch(propositionSetTariffStructure(tariffStructure)),
    resetHealthContractDescription: () => dispatch(propositionSetHealthContractDescription({})),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    goNextStep: () => dispatch(propositionGoNextStep())
  })
)(withUser(EmployeesStep));

