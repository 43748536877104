import * as R from 'ramda';
import React from 'react';
import S from 'string';
import { validations } from '@fasstech/spid-front';

import {
  compose,
  withState,
  withHandlers
} from 'recompose';

import TextInput from './TextInput';

const enhance = compose(

  withState(
    'siren',
    'setSiren',
    ({ value }) => validations.isSiren(value) ? R.splitEvery(3, value).join(' ') : '',
  ),
  withState(
    'error',
    'setError',
    ({ value }) => !validations.isSiren(value)
  ),
  withHandlers({
    onChange: ({ setError, setSiren, onChange }) => (e) => {
      e.preventDefault();
      let value = S(e.target.value).replaceAll(' ', '').s;
      if (R.isEmpty(value) || validations.isNumeric(value)) {
        setError(!validations.isSiren(value));
        setSiren(R.splitEvery(3, value).join(' '));
        onChange(validations.isSiren(value) ? value : null);
      } else {
        onChange(null);
      }
    }
  })
);

const SirenInput = enhance(
  ({
    siren,
    onChange,
    error,
    disabled,
    placeholder = 'numéro SIREN',
    style = {}
  }) => {
    return <TextInput
      style={{ color: error ? 'red' : 'green', ...style }}
      value={siren}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
    />;
  }
);

export default SirenInput;
