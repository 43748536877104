/**
 * @flow
 * @relayHash 9934a792536866271887cc4b166b37b3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CcnRecommandationsQueryVariables = {|
  idcc: string,
  siret: string,
|};
export type CcnRecommandationsQueryResponse = {|
  +ccnRecommandations: ?$ReadOnlyArray<?{|
    +offerName: ?string,
    +offerType: ?string,
    +idccs: ?$ReadOnlyArray<?string>,
    +oav: ?string,
    +VAD: ?string,
    +excluded: ?boolean,
  |}>
|};
export type CcnRecommandationsQuery = {|
  variables: CcnRecommandationsQueryVariables,
  response: CcnRecommandationsQueryResponse,
|};
*/


/*
query CcnRecommandationsQuery(
  $idcc: String!
  $siret: String!
) {
  ccnRecommandations(idcc: $idcc, siret: $siret) {
    offerName
    offerType
    idccs
    oav
    VAD
    excluded
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "idcc",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siret",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "idcc",
        "variableName": "idcc"
      },
      {
        "kind": "Variable",
        "name": "siret",
        "variableName": "siret"
      }
    ],
    "concreteType": "CcnRecommandation",
    "kind": "LinkedField",
    "name": "ccnRecommandations",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offerName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offerType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "idccs",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "oav",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "VAD",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "excluded",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CcnRecommandationsQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CcnRecommandationsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "9934a792536866271887cc4b166b37b3",
    "metadata": {},
    "name": "CcnRecommandationsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4f1341f0a0e0b21209948ce4776c026a';

module.exports = node;
