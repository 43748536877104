import * as R from 'ramda';
import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import {
  propositionGoPreviousStep,
  propositionSendEmail,
  propositionFetchingSendEmailReset
} from '../../../reduxActions/proposition';

import {
  Button,
  FieldTitle
} from '../../../uikit/UIKit';

const DeclarativeAnnexeStep = (
  ({
    goPreviousStep,
    medicalCheck,
    sendEmailToClient,
    sendEmailToCommercial,
    sendEmailToMyself,
    reset,
    status,
    userRole
  }) => {

    useEffect(() => {
      reset();
    }, []);
    const isCommercialNetwork = () => userRole === 'reseau_commercial';

    return <React.Fragment>
      <FieldTitle
        text={'Cette offre intègre l’indemnisation des salariés en arrêt de travail' + (medicalCheck ? ' et nécessite un questionnaire médical.' : '.')}
        onBack={goPreviousStep}
      />
      {
        R.isNil(status) && (
          medicalCheck && !isCommercialNetwork() ?
            <div>
              <span style={{ fontSize: '1.05em' }}>{'Votre dossier sera envoyé au réseau commercial.'}</span>
              <br/>
              <Button
                style={{ marginTop: '50px', width: '450px' }}
                onClick={sendEmailToCommercial}>{'Envoyer le dossier au réseau commercial'}</Button>
            </div>
            :
            <div>
              {medicalCheck && isCommercialNetwork() ?
                <span
                  style={{ fontSize: '1.05em' }}>{'L\'annexe déclarative et le questionnaire médical doivent être remplis par l\'entreprise.'}</span>
                :
                <span
                  style={{ fontSize: '1.05em' }}>{'L\'annexe déclarative doit être remplie par l\'entreprise.'}</span>
              }
              <br/>
              <Button
                style={{ marginTop: '50px', width: '350px' }}
                onClick={() => sendEmailToMyself(medicalCheck ? 'outstanding_medical_check_to_myself' : 'outstanding_check_to_myself')}>{'Envoyer le dossier sur mon mail'}</Button>
              <Button
                style={{ margin: '10px 0 50px 0', width: '350px' }}
                onClick={() => sendEmailToClient(medicalCheck ? 'outstanding_medical_check_to_client' : 'outstanding_check_to_client')}>{'Envoyer le dossier au client'}</Button>
            </div>
        )
      }
      {
        status &&
        <div>
          {'Préparation de l\'envoi.'}
        </div>
      }
      {
        !R.isNil(status) && !status &&
        <div>
          {'Le mail est en route.'}
          <Button style={{ marginTop: '80px' }} to="/menu">Retour au menu</Button>
        </div>
      }
    </React.Fragment>;
  }
);

export default connect(
  (state) => {
    const proposition = R.propOr({}, 'proposition')(state);

    return {
      status: proposition.fetchingSendEmail.status,
      userRole: proposition.userRole,
      background: proposition.data.background,
      medicalCheck: proposition.data.medicalCheck
    };
  },
  (dispatch) => ({
    reset: () => dispatch(propositionFetchingSendEmailReset()),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    sendEmailToCommercial: () => dispatch(propositionSendEmail('outstanding_medical_check_to_commercial')),
    sendEmailToClient: (emailId) => dispatch(propositionSendEmail(emailId)),
    sendEmailToMyself: (emailId) => dispatch(propositionSendEmail(emailId))
  })
)(DeclarativeAnnexeStep);
