import { graphql } from 'react-relay';
import { fetchQuery } from 'relay-runtime';
import environment from '../Environment';
import { isNil, is } from 'ramda';

const QGetProposition = graphql`
  query getPropositionQuery($id: ID) {
    proposition(id: $id) {
      additionalInformations {
        PREVOYANCE {
          hasLessThan20Employees
        }
      }
    }
  }
`;

const isFunction = fn => !isNil(fn) && is(Function, fn);

const getProposition = (id, callback) => {
  fetchQuery(environment, QGetProposition, { id }).then(data => {
    if (isFunction(callback)) {
      if (isNil(data) || isNil(data.proposition)) {
        return callback(null);
      }

      return callback(data.proposition);
    }
  });
};

export default getProposition;
