/**
 * @flow
 * @relayHash 7259ef665e5c8073bbb954e5173d18c7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type getAvailableOfferTypesQueryVariables = {|
  ccnId: string,
  siret: string,
  contractId?: ?string,
|};
export type getAvailableOfferTypesQueryResponse = {|
  +availableOfferTypes: ?$ReadOnlyArray<?OfferTypeEnum>
|};
export type getAvailableOfferTypesQuery = {|
  variables: getAvailableOfferTypesQueryVariables,
  response: getAvailableOfferTypesQueryResponse,
|};
*/


/*
query getAvailableOfferTypesQuery(
  $ccnId: String!
  $siret: String!
  $contractId: ID
) {
  availableOfferTypes(ccnId: $ccnId, siret: $siret, contractId: $contractId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnId",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siret",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ccnId",
        "variableName": "ccnId"
      },
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      },
      {
        "kind": "Variable",
        "name": "siret",
        "variableName": "siret"
      }
    ],
    "kind": "ScalarField",
    "name": "availableOfferTypes",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getAvailableOfferTypesQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getAvailableOfferTypesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7259ef665e5c8073bbb954e5173d18c7",
    "metadata": {},
    "name": "getAvailableOfferTypesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '25ec9cdb797deaa1fd6e86e0843c4fe1';

module.exports = node;
