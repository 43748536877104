import { Button, FieldTitle } from '../../../../uikit/UIKit';
import React from 'react';

const DocumentsConversionFailed = ({
  duplicata,
  resetMakeDuplicataStatus,
  resetSendForSignatureStatus
}) => {
  return <div>
    <FieldTitle
      text="L'envoi du mail a échoué"
    />
    {'Un problème est survenu pendant la conversion des documents, veuillez réessayer l\'envoi du mail.'}
    <Button onClick={duplicata ? resetMakeDuplicataStatus : resetSendForSignatureStatus} style={{ marginTop: '10px' }}>Réessayer</Button>
  </div>;
};

export default DocumentsConversionFailed;
