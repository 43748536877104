/**
 * @flow
 * @relayHash e63ddf67817f914bdb2776da11b22f13
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollegeEnum = "AM" | "APP" | "C" | "NC" | "TOUS" | "%future added value";
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type ContractDescriptionStepQueryVariables = {|
  ccnId: string,
  offerType: OfferTypeEnum,
  brokerage?: ?boolean,
  postCode: number,
  ccnVersion: number,
  provider?: ?string,
  tariffStructure?: ?string,
|};
export type ContractDescriptionStepQueryResponse = {|
  +bases: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +college: ?string,
    +bundledWith: ?string,
    +bundledWithOption: ?string,
    +type: ?string,
    +workWith: ?$ReadOnlyArray<?string>,
    +copyTo: ?$ReadOnlyArray<?{|
      +collegeId: ?CollegeEnum,
      +baseId: ?string,
      +whenAmendment: ?boolean,
      +whenOriginAmendment: ?boolean,
      +selectOnly: ?boolean,
    |}>,
    +isRequired: ?boolean,
    +isRequiredAmendment: ?boolean,
    +notAvailableWhenCollegeContains: ?$ReadOnlyArray<?string>,
    +notAvailableWhenCollegeDoesNotContain: ?$ReadOnlyArray<?string>,
    +dependsOf: ?string,
    +group: ?string,
    +disabled: ?boolean,
    +historical: ?boolean,
    +possibleAmendments: ?$ReadOnlyArray<?string>,
    +unselect: ?$ReadOnlyArray<?{|
      +collegeId: ?CollegeEnum,
      +baseId: ?string,
    |}>,
    +unavailableAfter: ?{|
      +date: ?string,
      +message: ?string,
    |},
    +options: ?$ReadOnlyArray<?{|
      +id: ?string,
      +name: ?string,
      +copyTo: ?$ReadOnlyArray<?{|
        +collegeId: ?CollegeEnum,
        +baseId: ?string,
        +optionId: ?string,
        +on: ?string,
      |}>,
      +isRequired: ?boolean,
      +group: ?string,
      +workWith: ?$ReadOnlyArray<?string>,
      +unselect: ?$ReadOnlyArray<?{|
        +collegeId: ?CollegeEnum,
        +baseId: ?string,
        +optionId: ?string,
      |}>,
      +unavailableAfter: ?{|
        +date: ?string,
        +message: ?string,
      |},
      +displayPopup: ?string,
      +mustBeSelectedTogetherWithAtLeast: ?$ReadOnlyArray<?string>,
    |}>,
  |}>
|};
export type ContractDescriptionStepQuery = {|
  variables: ContractDescriptionStepQueryVariables,
  response: ContractDescriptionStepQueryResponse,
|};
*/


/*
query ContractDescriptionStepQuery(
  $ccnId: String!
  $offerType: OfferTypeEnum!
  $brokerage: Boolean
  $postCode: Int!
  $ccnVersion: Int!
  $provider: String
  $tariffStructure: String
) {
  bases(ccnId: $ccnId, offerType: $offerType, brokerage: $brokerage, postCode: $postCode, ccnVersion: $ccnVersion, provider: $provider, tariffStructure: $tariffStructure) {
    id
    name
    college
    bundledWith
    bundledWithOption
    type
    workWith
    copyTo {
      collegeId
      baseId
      whenAmendment
      whenOriginAmendment
      selectOnly
    }
    isRequired
    isRequiredAmendment
    notAvailableWhenCollegeContains
    notAvailableWhenCollegeDoesNotContain
    dependsOf
    group
    disabled
    historical
    possibleAmendments
    unselect {
      collegeId
      baseId
    }
    unavailableAfter {
      date
      message
    }
    options {
      id
      name
      copyTo {
        collegeId
        baseId
        optionId
        on
      }
      isRequired
      group
      workWith
      unselect {
        collegeId
        baseId
        optionId
      }
      unavailableAfter {
        date
        message
      }
      displayPopup
      mustBeSelectedTogetherWithAtLeast
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnId",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerType",
    "type": "OfferTypeEnum!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brokerage",
    "type": "Boolean"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "postCode",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnVersion",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tariffStructure",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workWith",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collegeId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baseId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRequired",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "group",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "OptionUnavailableAfter",
  "kind": "LinkedField",
  "name": "unavailableAfter",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "optionId",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brokerage",
        "variableName": "brokerage"
      },
      {
        "kind": "Variable",
        "name": "ccnId",
        "variableName": "ccnId"
      },
      {
        "kind": "Variable",
        "name": "ccnVersion",
        "variableName": "ccnVersion"
      },
      {
        "kind": "Variable",
        "name": "offerType",
        "variableName": "offerType"
      },
      {
        "kind": "Variable",
        "name": "postCode",
        "variableName": "postCode"
      },
      {
        "kind": "Variable",
        "name": "provider",
        "variableName": "provider"
      },
      {
        "kind": "Variable",
        "name": "tariffStructure",
        "variableName": "tariffStructure"
      }
    ],
    "concreteType": "Base",
    "kind": "LinkedField",
    "name": "bases",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "college",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bundledWith",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bundledWithOption",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseCopyTo",
        "kind": "LinkedField",
        "name": "copyTo",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "whenAmendment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "whenOriginAmendment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "selectOnly",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isRequiredAmendment",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notAvailableWhenCollegeContains",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notAvailableWhenCollegeDoesNotContain",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dependsOf",
        "storageKey": null
      },
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "disabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "historical",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "possibleAmendments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseUnselect",
        "kind": "LinkedField",
        "name": "unselect",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Option",
        "kind": "LinkedField",
        "name": "options",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OptionCopyTo",
            "kind": "LinkedField",
            "name": "copyTo",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "on",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OptionUnselect",
            "kind": "LinkedField",
            "name": "unselect",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayPopup",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mustBeSelectedTogetherWithAtLeast",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractDescriptionStepQuery",
    "selections": (v10/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractDescriptionStepQuery",
    "selections": (v10/*: any*/)
  },
  "params": {
    "id": "e63ddf67817f914bdb2776da11b22f13",
    "metadata": {},
    "name": "ContractDescriptionStepQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '42cd712b035caf8b8515d2bf6e634764';

module.exports = node;
