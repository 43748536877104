import React from 'react';
import * as R from 'ramda';

const UserContext = React.createContext();

export default function withUser (Component) {
  return function UserComponent (props) {
    return (
      <UserContext.Consumer>
        {user => <Component
          {...props}
          user={user}
          userRoleIs={role => R.propEq('role', role, user)}
          userSalesIs={sales => R.both(
            R.pathEq(['extra', 'sales'], sales),
            R.propEq('role', 'reseau_commercial')
          )(user)}
        />}
      </UserContext.Consumer>
    );
  };
}

export {
  UserContext
};
