import * as R from 'ramda';
import React from 'react';
import MenuLayout from '../../../layout/menu/Layout';

const SignatureError = ({ error }) => {

  const getError = () => R.propOr('La signature est impossible.', error)({
    'CONTRACT_DOES_NOT_EXIST': 'Ce contract n\'existe pas.',
    'URL_INVALID': 'Url invalide.',
    'WRONG_STATUS': 'Ce contrat n\'a pas été envoyé en signature ou est déjà signé.',
    'URL_NOT_DEFINED': 'Aucune adresse de signature n\'a été trouvée.',
    'CONTRACT_CLOSED': 'Le contrat a été clôturé.'
  });

  return <MenuLayout staticLayout>
    <h3>Signature électronique</h3>
    {getError()}
  </MenuLayout>;
};

export default SignatureError;
