import './RadioGroup.css';
import React from 'react';
import { compose, join, pluck, map, includes, isNil, is, identity } from 'ramda';

const RadioGroup = ({
  options = [],
  value,
  onChange,
  multi = false,
  radioStyle = '',
  style = {},
  name
}) => {
  const radioGroupName = name || compose(
    join(''),
    pluck('value')
  )(options);

  const $onChange = (value) => {
    if (!isNil(onChange) && is(Function, onChange)) {
      onChange(value);
    }
  };

  return <div
    style={{
      display: 'inline-flex',
      columnGap: '1.5rem',
      fontSize: '18px',
      fontWeight: 'bold',
      ...style
    }}
  >

    {map(option => {
      return <div
        key={option.value}
        onClick={() => $onChange(option.value)}
        style={{
          cursor: 'pointer',
          userSelect: 'none',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <input
          type="radio"
          name={multi ? undefined : radioGroupName}
          value={option.value}
          onChange={identity}
          checked={multi ? includes(option.value, value) : option.value === value}
          style={{
            cursor: 'pointer',
            ...radioStyle
          }}
        />

        <label
          htmlFor={option.value}
          style={{
            cursor: 'pointer',
            marginLeft: '1rem'
          }}
          className="cursor-pointer ml-4"
        >
          {option.label}
        </label>
      </div>;
    })(options)}

  </div>;
};

export default RadioGroup;
