import * as R from 'ramda';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Redirect
} from 'react-router-dom';

const useTimeout = (delay) => {
  const [countDown, setCountDown] = useState(Math.round(delay / 1000));
  const ref = useRef();

  const onTick = () => {
    if (countDown === 0) return clearInterval(ref.current);
    setCountDown(countDown => countDown - 1);
  };

  useEffect(() => {
    ref.current = setInterval(() => {
      onTick();
    }, 1000);
    return () => {clearInterval(ref.current);};
  }, []);

  return countDown;
};

const useRedirect = (href, redirect) => {
  useEffect(() => {
    if (redirect && !R.isNil(href)) return window.location.href = href;
  }, [redirect]);

  return redirect;
};
const RedirectWithDelay = ({
  to,
  href,
  delay,
  message
}) => {

  const countDown = useTimeout(delay);
  const redirect = useRedirect(href, countDown === 0);

  return (
    <React.Fragment>
      {!redirect && `${message} (${countDown})`}
      {redirect && <Redirect to={to}/>}
    </React.Fragment>
  );
};

RedirectWithDelay.propTypes = {
  message: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
  to: PropTypes.string,
  href: PropTypes.string

};

export default RedirectWithDelay;
