import React from 'react';

const ConfigContext = React.createContext();

export default function withConfig (Component) {
  return function ConfigComponent (props) {
    return <ConfigContext.Consumer>
      {config => <Component
        {...props}
        config={config}
      />}
    </ConfigContext.Consumer>;
  };
}

export {
  ConfigContext
};
