import environment from '../Environment';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import * as R from 'ramda';
import Error from '../../components/Error';

const EnvQuery = graphql`
  query QEnvQuery($envVar: EnvEnum!) {
    env(envVar: $envVar)
  }
`;

const QEnv = ({ children, envVar }) => {
  return <QueryRenderer
    environment={environment}
    query={EnvQuery}
    variables={{ envVar }}
    render={({ error, props }) => {
      if (error) {
        return <Error/>;
      } else if (!props) {
        return null;
      }

      const value = R.propOr(null, 'env', props);
      const _props = R.assoc(envVar, value, {});

      if (R.is(Function, children)) {
        return children(_props);
      }
      return React.cloneElement(children, _props);
    }}
  />;
};

export default QEnv;
