
import './SliderInput.less';

import React from 'react';
import ReactInputSlider from 'react-input-slider';

import {
  defaultProps,
  compose,
  withHandlers
} from 'recompose';

const enhance = compose(
  defaultProps({
    value: 50,
    min: 0,
    max: 100,
    unit: '',
    style: {}
  }),
  withHandlers({
    onChange: ({ onChange }) => value => onChange(value.x)
  })
);

const InputSlider = enhance(
  ({
    unit,
    value,
    min,
    max,
    style,
    onChange
  }) => (
    <div className="uikit-slider" style={style}>
      <div style={{ paddingLeft: `${100 * (value / (max - min))}%` }}>
        <div>
          {`${value}${unit}`}
        </div>
      </div>
      <ReactInputSlider
        className="uikit-slider-slider"
        axis="x"
        x={value}
        xmin={min}
        xmax={max}
        onChange={onChange}
      />
    </div>
  )
);

export default InputSlider;