import { pathEq, pathOr } from 'ramda';

const allowElectronicSignature = ({ userRoleIs, proposition }) => {
  const withDeclarativeAnnexe = pathEq(['data', 'declarativeAnnexe'], 'with', proposition);
  const withOutstandingAnnexe = pathEq(['data', 'outstandingAnnexe'], 'with', proposition);
  const hasLessThan20Employees = pathOr(false, ['data', 'additionalInformations', 'PREVOYANCE', 'hasLessThan20Employees'], proposition);
  const userIsCG = userRoleIs('groupe_adhesion');

  if (!hasLessThan20Employees) {
    return userIsCG || !withDeclarativeAnnexe;
  }

  return (userIsCG || !withDeclarativeAnnexe) && !withOutstandingAnnexe;
};

export default allowElectronicSignature;
