import * as R from 'ramda';

const amendmentDisabled = propositionInfo => {
  const disableAmendments = R.path(['ccn', 'disableAmendments'], propositionInfo);
  return !R.isNil(disableAmendments) && (
    R.isEmpty(disableAmendments)
        || R.includes(parseInt(R.prop('postCode', propositionInfo)), disableAmendments)
  );
};

export default amendmentDisabled;
