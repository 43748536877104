import React, { useState } from 'react';
import { TextInput } from '../../../../uikit/UIKit';
import * as R from 'ramda';
import Checkbox from 'rc-checkbox';
import { Async as Select } from 'react-select';
import useSearchCommercialCodes from '../../../../hooks/useSearchCommercialCodes';

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const CommercialCodeInput = ({
  value,
  onChange
}) => {
  return <div
    className="brokerage-code"
  >
    <span>Code conseiller :</span>
    <TextInput
      placeholder="code"
      type="text"
      style={{ width: '150px' }}
      value={R.propOr(null, 'commercialCode', value)}
      onChange={e => onChange(R.assoc('commercialCode', e.target.value, value))}
      disabled
    />
  </div>;
};

const CommercialCode = ({ defaultValue, value, onChange }) => {
  const [userIsNotCommercial, setUserIsNotCommercial] = useState(!isNilOrEmpty(value.commercialCodeUserEmail));
  const [selectedCommercialCode, setSelectedCommercialCode] = useState();
  const searchCommercialCodes = useSearchCommercialCodes();

  const onUpdateUserIsNotCommercial = value => {
    if (!value) { // Quand on décoche, il faut réinitialiser la valeur du code commercial
      onChange(defaultValue);
    }

    setUserIsNotCommercial(value);
  };

  return <div className="mt-8">
    <div className="flex gap-x-2 items-center mb-4">
      <Checkbox
        style={{ marginRight: '5px', height: '17px' }}
        checked={userIsNotCommercial ? 1 : 0}
        onChange={e => onUpdateUserIsNotCommercial(e.target.checked)}
      />

      <div>Je réalise l’offre pour un autre conseiller</div>
    </div>

    {!userIsNotCommercial && <CommercialCodeInput
      value={value}
      onChange={onChange}
    />}

    {userIsNotCommercial && <div>
      <Select
        labelKey="email"
        valueKey="code"
        placeholder="Rechercher un utilisateur"
        onChange={v => {
          setSelectedCommercialCode(v);
          onChange(R.compose(
            R.assoc('commercialCode', R.isNil(v) ? '' : v.code),
            R.assoc('commercialCodeUserEmail', R.isNil(v) ? null : v.email)
          )(value));
        }}
        value={selectedCommercialCode}
        simpleValue={false}
        noResultsText="Aucun utilisateur trouvé (4 caractères minimum)"
        loadOptions={(inputValue, callback) => {
          searchCommercialCodes({ email: inputValue }, commercialCodes => {
            callback(null, { options: commercialCodes });
          });
        }}
      />

      <CommercialCodeInput
        value={value}
        onChange={onChange}
      />
    </div>}
  </div>;
};

export default CommercialCode;
