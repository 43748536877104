import '../UIKit.less';
import './TextInput.less';

import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import { compose, defaultProps, setPropTypes, withHandlers } from 'recompose';

const enhance = compose(
  defaultProps({
    error: false,
    onChange: () => {},
    title: null
  }),
  setPropTypes({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: stylePropType,
    error: PropTypes.bool
  }),
  withHandlers({
    onClick: ({ onChange }) => (e, value) => {
      e.preventDefaut();
      onChange(value);
    }
  })
);

const YesNoInput = enhance(
  ({
    error,
    title,
    style,
    onClick
  }) => (
    <div style={style} error={error}>
      {
        (!R.isEmpty(title) && title !== '_undefined') &&
        // eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for
        <label>
          {title}
        </label>
      }
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onClick={e => onClick(e, true)}>
        Oui
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onClick={e => onClick(e, false)}>
        Non
      </div>
    </div>
  )
);

export default YesNoInput;
