import './Layout.less';

import Dimensions from 'react-dimensions';
import React from 'react';
import Top from './Top';

const max_width = 1200;

const Layout = ({ children, containerWidth }) => {
  return <div className="layout-connection">
    <Top/>
    <div className="layout-connection-body-wrapper">
      <div>
        <div style={{ paddingLeft: Math.max(0, containerWidth - max_width) / 2 }}>
          {children}
        </div>
      </div>
      <div/>
    </div>
  </div>;
};

export default Dimensions()(Layout);
