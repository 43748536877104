import React from 'react';

const SSOContext = React.createContext();

export default function withSSO (Component) {
  return function SSOComponent (props) {
    return <SSOContext.Consumer>
      {({ SSO_ENABLED }) => <Component
        {...props}
        SSO_ENABLED={SSO_ENABLED}
      />}
    </SSOContext.Consumer>;
  };
}

export {
  SSOContext
};
