import * as R from 'ramda';
import '../UIKit.less';
import './TextInput.less';

import React from 'react';

import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import {
  compose,
  defaultProps,
  setPropTypes
} from 'recompose';

import classNames from 'classnames';

import TextInputErrorMessage from './TextInputErrorMessage';
import { Text } from '../UIKit';

const enhance = compose(
  setPropTypes({
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: stylePropType,
    size: PropTypes.string,
    error: PropTypes.bool
  }),
  defaultProps({
    className: null,
    disabled: false,
    error: false,
    errorMessageEnabled: false,
    onChange: () => {},
    onKeyPress: () => {},
    placeholder: 'placeholder to be defined',
    placeholderIntlId: '_undefined_',
    size: 'normal',
    title: null,
    titleWidth: 250
  })
);

const TextInput = enhance(
  ({
    className,
    error,
    errorMessageEnabled,
    min,
    max,
    disabled,
    onChange,
    onKeyPress,
    onBlur,
    pattern,
    placeholder,
    title,
    titleWidth,
    style,
    size,
    type,
    value
  }) => (
    <div className="uikit-text-input-wrapper" style={style}>
      <div>
        <Text style={{ width: titleWidth }} text={title}/>
        <input
          className={classNames(
            R.when(R.isNil, R.always('uikit-text-input'))(className),
            {
              'uikit-text-input-small': size === 'small',
              'uikit-text-input-error': error,
            }
          )}
          disabled={disabled}
          value={value}
          style={style}
          type={type}
          min={min}
          max={max}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          pattern={pattern}
        />
        <TextInputErrorMessage errorMessageEnabled={errorMessageEnabled}/>
      </div>
    </div>
  )
);

export default TextInput;
