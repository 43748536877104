import './loading.css';
import React from 'react';

const Loading = ({
  fullPage = false
}) => {
  return <div className={`flex items-center justify-center ${fullPage ? 'h-screen' : ''}`}>
    <div className="lds-default">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>;
};

export default Loading;
