import './EmployeeNumber.less';
import React from 'react';

import {
  TextInput
} from '../../../uikit/UIKit';

const EmployeeNumber = ({
  title,
  value,
  onChange,
  onKeyPress,
  disabled
}) => (
  <div className="employee-number">
    <span>{title}</span>
    <TextInput
      type="number"
      min={0}
      style={{ width: '84px' }}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      disabled={disabled}
    />
  </div>
);

export default EmployeeNumber;
