import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation SavePropositionMutation($proposition: PropositionInput!) {
  saveProposition(proposition: $proposition) {
    ok
    error
    propositionId
    contractNumbers
    ccnVersion
    healthCcnVersion
  }
}
`;

export default (
  propositionId,
  {
    ccnId,
    brokerage,
    contractDescription,
    contractNumbers,
    companyInformation,
    postCode,
    background,
    medicalCheck,
    studyAtIndem,
    siret,
    siren,
    siretInfo,
    extraEstablishments,
    staff,
    startDate,
    adhesionDate,
    status,
    relatedContract,
    duplicata,
    overPricedRate,
    amendment,
    offerTypes,
    health,
    turnovers
  }, done) => {

  const variables = {
    proposition: {
      propositionId,
      ccnId,
      brokerage,
      contractNumbers,
      contractDescription,
      companyInformation,
      medicalCheck,
      postCode,
      background,
      studyAtIndem,
      siret,
      siren,
      siretInfo,
      extraEstablishments,
      staff,
      startDate,
      adhesionDate,
      status,
      relatedContract,
      duplicata,
      overPricedRate,
      amendment,
      source: 'SPID',
      offerTypes,
      health,
      turnovers
    }
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, propositionId, error, contractNumbers, ccnVersion, healthCcnVersion } = response.saveProposition;
        done(ok, error, propositionId, contractNumbers, ccnVersion, healthCcnVersion);
      },
      onError: error => {
        console.error(error);
        done(false, error);
      },
    }
  );
};
