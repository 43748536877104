import useManageRelatedContract from './useManageRelatedContract';
import { FieldTitle, SirenInput, TextInput } from '../../../../uikit/UIKit';
import { libs, validations } from '@fasstech/spid-front';
import { BtnNext } from '../../components';
import React from 'react';
import { isNil, times } from 'ramda';
import Select from 'react-select';
import RadioGroup from '../../../../uikit/buttons/RadioGroup';

const FormField = ({ label, children }) => {
  return <div className="field field-text-input">
    {!isNil(label) && <span>{label}</span>}
    {children}
  </div>;
};

const RelatedContractHealthForm = ({
  relatedContract,
  propositionId,
  setRelatedContract,
  goNextStep,
  numberOfContracts,
  goPreviousStep,
  contractDescription,
  ccnId,
  ccn,
  startDate
}) => {
  const {
    getProp,
    propIsDefined,
    onSaveContract,
    onUpdateContractNumber,
    onUpdate,
    isDisabled,
    buildStyle,
    startDateOptions,
    nicIsValid
  } = useManageRelatedContract({
    numberOfContracts,
    relatedContract,
    propositionId,
    setRelatedContract,
    goNextStep,
    ccn,
    startDate
  });

  return <div>
    <FieldTitle
      text="Suite économique Santé"
      onBack={goPreviousStep}
    />

    <FormField label="Numéro de SIREN prédécesseur*">
      <SirenInput
        value={getProp('oldSiren')}
        onChange={v => onUpdate('oldSiren', v)}
        placeholder="Numéro SIREN"
        style={buildStyle(!propIsDefined('oldSiren'))}
      />
    </FormField>

    <FormField label="NIC*">
      <TextInput
        type="number"
        value={getProp('oldNic')}
        onChange={e => onUpdate('oldNic', e.target.value)}
        placeholder="NIC"
        style={buildStyle(!propIsDefined('oldNic') || !nicIsValid)}
      />
    </FormField>

    <FormField label="Ancienne dénomination sociale*">
      <TextInput
        type="text"
        placeholder=" "
        value={getProp('oldLegalForm')}
        onChange={e => onUpdate('oldLegalForm', e.target.value)}
        style={buildStyle(!propIsDefined('oldLegalForm'))}
      />
    </FormField>

    <FormField label="Date d'effet souhaitée*">
      <Select
        placeholder="Sélectionner une date d'effet"
        value={getProp('startDate')}
        onChange={v => onUpdate('startDate', v.value)}
        labelKey="value"
        valueKey="value"
        options={startDateOptions}
        arrowRenderer={null}
        clearable={false}
        style={buildStyle(!propIsDefined('startDate'))}
      />
    </FormField>

    {times(index => {
      return <FormField
        label={`${libs.getContractNumberLabel(index, numberOfContracts, contractDescription, ccnId, ccn)} du prédecesseur*`}
        key={`contractNumber-${index}`}
      >
        <TextInput
          type="text"
          value={getProp(`contractNumbers.${index}`)}
          onChange={e => onUpdateContractNumber(index)(e.target.value)}
          placeholder="Numéro du contrat"
          style={{
            maxWidth: '180px',
            borderColor: !validations.contractNumberIsValid(getProp(`contractNumbers.${index}`), 'SANTE') ? 'orange' : undefined
          }}
        />
      </FormField>;
    })(numberOfContracts)}

    <FormField label="Est-ce une suite économique totale ou partielle ?*">
      <RadioGroup
        options={[
          { label: 'Totale', value: 'TOTAL' },
          { label: 'Partielle', value: 'PARTIEL' }
        ]}
        value={getProp('type')}
        onChange={v => onUpdate('type', v)}
      />
    </FormField>

    <FormField label="Avez-vous récupéré le KBIS ?*">
      <RadioGroup
        options={[
          { label: 'Oui', value: 'true' },
          { label: 'Non', value: 'false' }
        ]}
        value={JSON.stringify(getProp('hasKBIS'))}
        onChange={v => onUpdate('hasKBIS', JSON.parse(v))}
      />
    </FormField>

    {getProp('hasKBIS') === false && <div className="flex text-red-500 font-bold text-lg mb-6 gap-x-2 items-end">
      <img
        src="/icons/warning.svg"
        className="h-[30px]"
      />
      <div>{'Vous devez avoir récupéré le KBIS pour valider l\'opération juridique'}</div>
    </div>}

    <span style={{ marginTop: '10px' }}>
      Une reprise des engagements doit être jointe à chaque contrat du prédécesseur.
    </span><br/><br/>

    <BtnNext
      disabled={isDisabled}
      onClick={onSaveContract}
      title={'Valider'}
      style={{ margin: '20px 0' }}
    />
  </div>;
};

export default RelatedContractHealthForm;
