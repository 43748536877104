/**
 * @flow
 * @relayHash b3b9c0def35d3ac5fbea0bf6df7901f8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type useSearchCitiesQueryVariables = {|
  search: string
|};
export type useSearchCitiesQueryResponse = {|
  +cities: ?$ReadOnlyArray<?{|
    +code: ?string,
    +city: ?string,
    +postalCode: ?string,
  |}>
|};
export type useSearchCitiesQuery = {|
  variables: useSearchCitiesQueryVariables,
  response: useSearchCitiesQueryResponse,
|};
*/


/*
query useSearchCitiesQuery(
  $search: String!
) {
  cities(search: $search) {
    code
    city
    postalCode
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "City",
    "kind": "LinkedField",
    "name": "cities",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSearchCitiesQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSearchCitiesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "b3b9c0def35d3ac5fbea0bf6df7901f8",
    "metadata": {},
    "name": "useSearchCitiesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '42ae4822e70bc0bd0993c45c45dd7188';

module.exports = node;
