import './SelectInput.less';

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
  compose,
  defaultProps,
  mapProps,
  withHandlers,
  setPropTypes
} from 'recompose';

const enhance = compose(
  defaultProps({
    clearable: true,
    label: null,
    labelKey: 'label',
    valueKey: 'label',
    onChange: null,
    value: null,
    multi: false,
  }),
  setPropTypes({
    label: PropTypes.string,
    lang: PropTypes.string,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    multi: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape())
  }),
  mapProps(
    (props) => ({
      ...props,
      options: props.options
    })
  ),
  withHandlers({
    onChange: ({ multi, onChange }) => (v) => onChange(multi ? v.split(',') : v),
  })
);

const SelectInput = enhance(
  ({
    onChange,
    valueKey,
    labelKey,
    options,
    clearable,
    multi,
    style,
    value,
    placeholder = 'Sélectionner...',
    promptTextCreator = label => `Ajouter l'option ${label}`,
    disabled = false
  }) => (
    <Select.Creatable
      multi={multi}
      labelKey={labelKey}
      valueKey={valueKey}
      options={options}
      value={value}
      placeholder={placeholder}
      simpleValue={true}
      onChange={onChange}
      clearable={clearable}
      promptTextCreator={promptTextCreator}
      arrowRenderer={null}
      disabled={disabled}
      style={{
        ...{
          outline: 'none'
        },
        ...style
      }}
    />
  )
);

export default SelectInput;
