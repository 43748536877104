import './TextInputErrorMessage.less';

import * as R from 'ramda';
import React from 'react';

import {
  branch,
  renderComponent,
  renderNothing
} from 'recompose';

const TextInputErrorMessage = ({
  text
}) => (
  <div className="uikit-text-input-error-message">
    {text}
  </div>
);

export default branch(
  ({ errorMessageEnabled }) => !errorMessageEnabled,
  renderNothing
)(branch(
  ({ text }) => !R.isNil(text),
  renderComponent(TextInputErrorMessage)
)(() => <div style={{ height: '37px' }}/>));
