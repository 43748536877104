import './Contract.less';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { redux } from '@fasstech/spid-front';

import Checkbox from 'rc-checkbox';

import ContractOptions from './ContractOptions';

const Contract = ({
  ccnId,
  ccn,
  duplicata,
  value,
  base,
  selectBase,
  selectOption,
  amendment,
  stepAmendment
}) => {
  const { title, options } = R.applySpec({
    title: R.prop('name'),
    options: R.propOr([], 'options')
  })(base);

  const historical = R.propOr(false, 'historical', base);

  const isAmendment = R.propOr(false, 'isAmendment', amendment);
  const amendmentDisabling = isAmendment && !R.isNil(R.prop('formerContractId')(amendment));

  // Les bases "historiques" ne sont disponibles que pour les duplicatas et avenants
  if (historical && !duplicata && !isAmendment) {
    return null;
  }

  const baseDisabled = (
    R.propEq('disabled', true, base) // base.disabled === true
      && !duplicata // ET pas duplicata
      && (!isAmendment || !stepAmendment) // ET pas avenant OU pas étape avenant
  );

  return <div className="contract">
    <div className="contract-name" style={{ display: R.isEmpty(title) ? 'none' : 'undefined' }}>
      <Checkbox
        style={{ marginRight: '5px', height: '17px' }}
        checked={R.propOr(false, 'selected', value) ? 1 : 0}
        onChange={e => selectBase(e, ccnId, ccn)}
        disabled={baseDisabled}
        //disabled={R.propOr(false, 'selected', defaultValue) && amendmentDisabling}
      />
      {title}
    </div>
    <ContractOptions
      base={base}
      options={options}
      onChange={selectOption(ccn)}
      value={R.propOr({}, 'options', value)}
      defaultValue={R.propOr({}, 'options')(value)}
      amendmentDisabling={amendmentDisabling}
    />
  </div>;
};

export default connect(
  state => ({
    ccn: R.pathOr({}, ['proposition', 'data', 'ccn'], state),
    ccnId: R.path(['proposition', 'data', 'ccn', 'id'], state),
    duplicata: R.pathOr(false, ['proposition', 'data', 'duplicata'], state)
  }),
  (dispatch, { base, amendment, stepAmendment }) => {
    const isAmendment = R.propOr(false, 'isAmendment', amendment);
    return {
      selectBase: (e, ccnId, ccn) => dispatch(
        !isAmendment
          ? redux.actions.contractDescription.contractDescriptionSelectBase(
            base.college,
            base.id,
            e.target.checked
          )
          : redux.actions.contractDescription.contractDescriptionSelectBaseAmendment(
            ccnId,
            base.college,
            base.id,
            e.target.checked,
            stepAmendment,
            !stepAmendment ? R.propOr({}, 'formerContractDescription', amendment) : null,
            ccn
          )
      ),
      selectOption: ccn => (optionId, status) => dispatch(redux.actions.contractDescription.contractDescriptionSelectOption(base.college, base.id, optionId, status, isAmendment, stepAmendment, !stepAmendment ? R.propOr({}, 'formerContractDescription', amendment) : null, ccn))
    };
  }
)(Contract);
