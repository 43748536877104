import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation PropositionCommercialeMutation($token: String!) {
  propositionCommerciale(token: $token) { 
    ok
    token
    status
    url
  }
}
`;

export default (token, done) => {
  const variables = {
    token
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, token, status, url, error } = response.propositionCommerciale;
        done(ok, token, status, url, error);
      },
      onError: err => console.error(err),
    }
  );
};
