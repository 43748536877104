import React from 'react';

import {
  Button
} from '../../../uikit/UIKit';

const BtnNext = ({ disabled, loading, title, onClick, style = {} }) => (
  <Button disabled={disabled || loading} fetching={loading} style={{ marginTop: '80px', ...style }} onClick={onClick}>{title}</Button>
);

export default BtnNext;
