import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation StoreUploadedDocumentMutation($propositionId: String!, $documentType: DocumentTypeEnum!, $uploadedDocument: UploadedDocumentInput!) {
    storeUploadedDocument(propositionId: $propositionId, documentType: $documentType, uploadedDocument: $uploadedDocument) {
      ok
      error
    }
  }
`;

export default ({ propositionId, documentType }, uploadedDocument, done) => {
  const variables = {
    propositionId,
    documentType,
    uploadedDocument
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, error } = response.storeUploadedDocument;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
