import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import {
  propositionGoNextStep,
  propositionGoPreviousStep,
  propositionSetRelatedContract
} from '../../../reduxActions/proposition';
import withUser from '../../../withUser';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../graphql/Environment';
import RelatedContractHealthForm from './relatedContractHealthStep/RelatedContractHealthForm';
import Error from '../../../components/Error';

const RelatedContractHealthStepQuery = graphql`
  query RelatedContractHealthStepQuery($id: ID!) {
    proposition(id: $id) {
      id
      status
      health {
        numberOfContracts
      }
    }
  }
`;

const RelatedContractHealthStep = ({
  goNextStep,
  goPreviousStep,
  propositionId,
  ccnId,
  ccn,
  contractDescription,
  relatedContract,
  setRelatedContract,
  startDate
}) => {
  return <QueryRenderer
    environment={environment}
    query={RelatedContractHealthStepQuery}
    variables={{ id: propositionId }}
    render={({ error, props }) => {
      if (error) {
        return <Error/>;
      } else if (!props) return null;

      const { proposition } = props;

      if (!R.isNil(proposition) && proposition.status !== 'active') {
        return (
          <div>
            <h3>{'Cette proposition n\'est plus disponible.'}</h3>
          </div>
        );
      } else {
        const numberOfContracts = R.pathOr(0, ['health', 'numberOfContracts'], proposition);

        return <RelatedContractHealthForm
          goNextStep={goNextStep}
          numberOfContracts={numberOfContracts}
          propositionId={propositionId}
          relatedContract={relatedContract}
          setRelatedContract={setRelatedContract}
          ccnId={ccnId}
          contractDescription={contractDescription}
          goPreviousStep={goPreviousStep}
          ccn={ccn}
          startDate={startDate}
        />;
      }
    }}
  />;
};

export default connect(
  (state) => {
    const data = R.pathOr({}, ['proposition', 'data'], state);
    const v = k => R.path(k.split('.'), data);

    return {
      propositionId: v('propositionId'),
      ccnId: v('ccn.id'),
      ccn: v('ccn'),
      contractDescription: v('health.contractDescription'),
      relatedContract: R.propOr({}, 'relatedContract')(data),
      startDate: v('startDate')
    };
  },
  (dispatch) => ({
    setRelatedContract: (value) => dispatch(propositionSetRelatedContract(value)),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    goNextStep: () => dispatch(propositionGoNextStep())
  })
)(withUser(RelatedContractHealthStep));
